import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import AssessmentForm from "../Components/AssessmentForm";

export default function WebsiteHome({ children, page }) {
    const [state, setState] = useState({
        isShowForm: false,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            setState({
                ...state,
                isShowForm: true,
            });
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const closeForm = () => {
        setState({
            ...state,
            isShowForm: false,
        });
    };

    return (
        <>
            <div className="app">
                {state.isShowForm && <AssessmentForm popup={true} closeForm={closeForm} />}
                <Header page={page} />
                <Outlet />
                <Footer />
            </div>
        </>
    );
}
