import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Navigate, redirect, useSearchParams } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import { GrClose } from "react-icons/gr";
import { AiFillSetting } from "react-icons/ai";
import loader from '../../../images/loader.gif';
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";


const PAGE_SIZE = 10;
const AssessmentForms = () => {
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPagination, setShowPagination] = useState(true);
  const [ModalCode, setModalCode] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [state, setState] = useState({
    isWaiting: false,
    students: [],
    adminToken: getToken("admin"),
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
    dateRange: {
      startDate: null,
    },
  });

  console.log("dateRange", state?.dateRange)

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Full Name", value: "Full Name" },
    { label: "Phone", value: "Phone" },
    { label: "Email", value: "Email" },
    { label: "Country", value: "Country" },
    { label: "Created", value: "Created" },

  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsassessmentform")) || [
      "Sr.",
      "Full Name",
      "Phone",
      "Email",
      "Country",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Full Name",
    "Phone",
    "Email",
    "Country",
  ]);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Full Name",
      "Phone",
      "Email",
      "Country",
    ]);
  }, []);

  useEffect(() => {
    getPaginationData(1);
  }, []);

  const getPaginationData = (page) => {
    setState({
      ...state,
      isWaiting: true,
    });
    const config = { headers: { Authorization: `Bearer ${state.adminToken}` } };
    let data = { currentPage: page, pageSize: state.pageSize };

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/getassessmentforms",
        data,
        config
      )
      .then((res) => {
        setState({
          ...state,
          students: res.data.details.students,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
        setisrotating(false)
      })
      .catch((err) => {
      });
  };

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns;
    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem("selectedColumnsassessmentform", JSON.stringify(updatedColumns));
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem("selectedColumnsassessmentform"));

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Full Name",
        "Phone",
        "Email",
        "Country",
      ]);
      setTempColumnsTable([
        "Sr.",
        "Full Name",
        "Phone",
        "Email",
        "Country",
      ]);
    }
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsassessmentform");
    setSelectedColumnsTable([
      "Sr.",
      "Full Name",
      "Phone",
      "Email",
      "Country",
    ]);
    setTempColumnsTable([
      "Sr.",
      "Full Name",
      "Phone",
      "Email",
      "Country",
    ]);
  };

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem("selectedColumnsassessmentform", JSON.stringify(uniqueSelectedColumns));

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  const handleSearch = () => {
    if (searchName === "") {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
    searchFilter(1, searchName);
  }

  const searchFilter = (page, searchName) => {
    let status = searchParams.get("status") || "";
    let data;
    if (searchName == undefined) {
      data = { currentPage: page, status, searchName: "" };
    } else {
      data = { 
        currentPage: page,
         status, 
         searchName: searchName,
         date: startDate ? startDate?.format("YYYY-MM-DD") : "",
         };
    }
    setState({
      ...state,
      isWaiting: true,
    });
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getAssessmentForms", data)
      .then((res) => {
        setState({
          ...state,
          students: res.data.details.students,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
      })
      .catch((err) => {
      });
  };
  const handlenewStatus = async (_id) => {
    try {
      const body = {
        formId: _id,
        newstatus: false,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/v2/api/changeFormnewStatus`,
        body
      );
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const startLoader = () => {
    setisrotating(true);
    getPaginationData(state.currentPage);
    setSearchName("");
    setStartDate(null)
  };

  const handleClear = () => {
      getPaginationData(state.currentPage);
      setSearchName("");
      setStartDate(null)
  }


  return (
    <>
      <div heading_title={"Total Students"}>
        <>
          <div className="row min-height-vh-100">
            <div className="row mt-10 p-45 mob-padding">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="col-12 w-full inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="card mb-4 mt-4">
                    <div className="flex my-3">
                      <input
                        type="text"
                        className="border border-gray-300 px-4 rounded-md focus:outline-none"
                        placeholder="Name, Email and Phone..."
                        value={searchName}
                        style={{paddingBlock: '8.45px'}}
                        onChange={(e) => {
                          setSearchName(e.target.value);
                        }}
                      />
                      <div className="ms-3">
                      {/* <label htmlFor="dateRange">Date Range</label> */}
                        <div className="assesment-date-calender adsada">
                          <DateRangePicker
                            startDate={startDate ? moment(startDate) : null}
                            startDateId="startDate"
                            onDatesChange={({ startDate }) => {
                              setStartDate(startDate);
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                            isOutsideRange={() => false}
                            id="dateRangePicker"
                            label="Select Dates" 
                          />
                        </div>
                      </div>
                      <button
                        onClick={handleSearch}
                        className="custom_add_button mt-0 bg-[#19632f] hover:bg-[#053815] text-white py-2 px-4 ms-3 rounded-md focus:outline-none"
                      >
                        Find
                      </button>

                      <button
                        onClick={handleClear}
                        className="bg-[#5555] text-[#515151] font-bold h-10 px-4 ms-3 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out"
                      >
                        Clear
                      </button>

                      <div className="flex justify-end w-full items-center">
                        <div className="reload-icon mr-2">
                          <i
                            className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                            onClick={startLoader}
                          />
                        </div>
                        <AiFillSetting
                          className="Bar_filter"
                          onClick={() => setModalCode(!ModalCode)}
                        />
                      </div>

                    </div>



                    <div className="btn_outerxx mb-3 mt-5">
                      {ModalCode ? (
                        <div className="modal_cover">
                          <div className="modal_inner select-col-popup">
                            <div className="header_modal">
                              <h1 className="capitalize font-bold text-lg mb-2">
                                select columns
                              </h1>
                              <p className="border-b pb-2 mb-2">
                                Select the column you want to display or hide.
                              </p>
                              <div
                                className="columns-popup"
                                style={{ justifyContent: "space-between" }}
                              >
                                {tableColumns.map((column, index) => {

                                  if (
                                    selectedColumnsTable.includes(column.value)
                                  ) {
                                    return (
                                      <p className="mb-0 gap-2">
                                        <input
                                          type="checkbox"
                                          id={column.value}
                                          checked={tempColumnsTable.includes(column.value)}
                                          onChange={() => handleColumnCheckboxChange(column.value)}
                                        />
                                        <label htmlFor={column.value}>
                                          {formatLabel(column.label)}
                                        </label>
                                      </p>
                                    );
                                  } else {
                                    return (
                                      <p className="mb-0 gap-2">
                                        <input
                                          type="checkbox"
                                          id={column.value}
                                          onClick={() => {
                                            selectedColumnsTable.push(
                                              column.value
                                            );
                                            setTempColumnsTable(
                                              selectedColumnsTable
                                            );
                                          }}
                                        />
                                        <label htmlFor={column.value}>
                                          {formatLabel(column.label)}
                                        </label>
                                      </p>
                                    );
                                  }
                                })}
                              </div>
                              <div className="button-part flex justify-end items-center gap-4">
                                <button
                                  className="py-2 px-6"
                                  type="btn"
                                  onClick={handleResetAll}
                                >
                                  Reset all
                                </button>
                                <button
                                  className="py-2 px-6"
                                  type="btn"
                                  onClick={handleOk}
                                >
                                  Ok
                                </button>
                              </div>
                              <GrClose
                                className="close"
                                onClick={() => setModalCode(false)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="card-body px-0 pt-0 pb-2 table-border">

                      <div className="overflow-auto card  col-12 px-0 pt-0 table_postioning">
                        {state.isWaiting ? (
                          <div>
                            <center className="w-full my-10">
                              <img src={loader} alt="" />
                            </center>
                          </div>
                        ) : state?.students?.length > 0 ? (
                          <table className="table-auto overflow-scroll w-full agent-table files-table assesment-forms-table">
                            <thead>
                              <tr>
                                {tableColumns.map((col) => {
                                  if (selectedColumnsTable.includes(col.label)) {
                                    return (
                                      <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left w-0">
                                        {formatLabel(col.label)}
                                      </th>
                                    );
                                  }
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {state.students?.map((student, index) => {
                                return (
                                  <tr
                                    onClick={() => handlenewStatus(student._id)}
                                    style={{
                                      background:
                                        student.new === true ? "#b9f5e3" : "#fff",
                                    }}>
                                    {selectedColumnsTable.includes("Sr.") && (
                                      <td className="p-2 text-left w-0">
                                        <p className="text-xs font-weight-bold mb-0">
                                          <div className="flex items-center">
                                            <div className="mr-1">
                                              {((state.currentPage === 0 ? 0 : state.currentPage - 1) * PAGE_SIZE) + index + 1}
                                            </div>
                                            {student.new === true && (
                                              <span className="bg-green-500 text-white text-[10px] p-1 rounded">
                                                New
                                              </span>
                                            )}
                                          </div>
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Full Name") && (
                                      <td className="Capitalize p-2 text-left w-0">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student.full_name}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Phone") && (
                                      <td className="p-2 text-left  w-0">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student.phone}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Email") && (
                                      <td className="p-2 text-left  w-0">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student.email}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Country") && (
                                      <td className="p-2 text-left  w-0">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student.destination_country}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Created") && (
                                      <td className="p-2 text-left  w-0">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {new Date(
                                            parseInt(student.created)
                                          ).toLocaleDateString()}
                                        </p>
                                      </td>
                                    )}


                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            <div>
                              <p className="text-center p-5 text-red-500">
                                No data available.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                    {showPagination && (<div className="card-footer pb-0">
                      <div className="card-footer pb-0">
                        <div className="pagination">
                          <div className="pages">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="next"
                              onPageChange={(event) => {
                                getPaginationData(event.selected + 1);
                              }}
                              pageCount={state.totalPages}
                              previousLabel="prev"
                              renderOnZeroPageCount={null}
                              pageRangeDisplayed={1}
                              marginPagesDisplayed={0}
                              containerClassName="pagination"
                              subContainerClassName="pages"
                            />
                          </div>
                        </div>
                      </div>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default AssessmentForms;
