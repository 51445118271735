/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import { GrClose } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../../images/loader.gif";
import { AiFillSetting } from "react-icons/ai";
import { Switch } from "@mui/material";

import GoogleLogo from '../../../images/google-logo.png';
import GMailLogo from '../../../images/gmail-logo.png';

const StudentList = () => {
  const [ModalCode, setModalCode] = useState(false);
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [toggleModal, setToggleModal] = useState(false);
  const navigate = useNavigate();
  const [ageId, setAgeId] = useState();
  const [blockStatus, setBlockStatus] = useState();
  const [showPagination, setShowPagination] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageAfterBlock, setCurrentPageAfterBlock] = useState(1)
  const [state, setState] = useState({
    isWaiting: true,
    students: [],
    adminToken: getToken("admin"),
    totalPages: 0,
  });

  const defaultColumns = [
    "Sr.",
    "Name",
    "Agent Name",
    "Agent Email",
    "Phone",
    "Email",
    "Block/Unblock",
  ];

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Name", value: "Name" },
    { label: "Agent Name", value: "Agent Name" },
    { label: "Agent Email", value: "Agent Email" },
    { label: "Phone", value: "Phone" },
    { label: "Email", value: "Email" },
    { label: "Block/Unblock", value: "Block/Unblock" },
  ]);

  const confirmTwo = (agentId, blockStatus) => {
    setToggleModal(true);
    setAgeId(agentId);
    setBlockStatus(blockStatus);
  };

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsStudentList")) ||
    defaultColumns
  );

  const [tempColumnsTable, setTempColumnsTable] = useState(defaultColumns);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      getPaginationData(1, searchName, agentName);
    }, 300);
    return () => clearTimeout(delaySearch);
  }, []);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Agent Name",
      "Agent Email",
      "Phone",
      "Email",
      "Block/Unblock",
    ]);
  }, []);

  const getPaginationData = (page, searchName, agentName) => {
    setState({
      ...state,
      isWaiting: true,
    });
    const config = { headers: { Authorization: `Bearer ${state.adminToken}` } };
    let data;
    if (searchName == undefined) {
      data = { currentPage: page, searchName: "" };
    } else if (agentName == undefined) {
      data = { currentPage: page, searchAgentName: "" };
    }
     else {
      data = { currentPage: page, searchName: searchName, searchAgentName: agentName };
    }
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getstudents", data, config)
      .then((res) => {
        // console.log("agentName", res.data.details.students);
        setState({
          ...state,
          students: res.data.details.result,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
        setisrotating(false)
      })
      .catch((err) => { });
  };

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected);
    getPaginationData(selected.selected + 1);
  };
  const itemsPerPage = 10;
  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns;

    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem(
      "selectedColumnsStudentList",
      JSON.stringify(updatedColumns)
    );
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    const storedColumns = JSON.parse(
      localStorage.getItem("selectedColumnsStudentList")
    );

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Name",
        "Agent Name",
        "Agent Email",
        "Phone",
        "Email",
        "Block/Unblock",
      ]);
      setTempColumnsTable([
        "Sr.",
        "Name",
        "Agent Name",
        "Agent Email",
        "Phone",
        "Email",
        "Block/Unblock",
      ]);
    }
  }, []);

  useEffect(() => {
    setTempColumnsTable([
      "Sr.",
      "Name",
      "Agent Name",
      "Agent Email",
      "Phone",
      "Email",
      "Block/Unblock",
    ]);
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsStudentList");
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Agent Name",
      "Agent Email",
      "Phone",
      "Email",
      "Block/Unblock",
    ]);
    setTempColumnsTable([
      "Sr.",
      "Name",
      "Agent Name",
      "Agent Email",
      "Phone",
      "Email",
      "Block/Unblock",
    ]);
    // setModalCode(false);
  };

  const handleOk = () => {
    // Check for duplicate columns before updating state
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumnsStudentList",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };
  const handlenewStatus = async (_id) => {
    try {
      const body = {
        studentId: _id,
        newstatus: false,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/v2/api/changeStudentStatus`,
        body
      );
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const startLoader = () => {
    setisrotating(true);
    getPaginationData(1);
    setSearchName("")
    setAgentName("")
  };

  const handleClear = () => {
    getPaginationData(1);
    setSearchName("")
    setAgentName("")
  };

  const handleBlockUnblock = (studentId, blockStatus) => {
    const confirmationMessage = blockStatus
      ? "Are you sure you want to block this student?"
      : "Are you sure you want to unblock this student?";

    if (confirmationMessage) {
      const data = {
        blockstatus: blockStatus,
        studentId,
      };

      axios
        .post(process.env.REACT_APP_NODE_URL + "/v2/api/blockSudent", data)
        .then((response) => {
          console.log(response.data);
          getPaginationData(  );
          setToggleModal(false);
          toast.success(
            `Student ${blockStatus ? "Blocked" : "Unblocked"} successfully!`
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div heading_title={"Total Students"}>
        <>
          <div className="row min-height-vh-100">
            <div className="row p-45  mob-padding">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="col-12 w-full inline-block min-w-full sm:px-6 lg:px-8">
                  <div className=" setting_icon my-4 flex flex-row gap-3">
                    <div className="flex my-3" style={{ width: '100%', maxWidth: 450 }}>
                      <input
                        type="text"
                        className="border border-gray-300 px-4 rounded-md focus:outline-none"
                        placeholder="Name, Email and Phone..."
                        name="searchName"
                        value={searchName}
                        style={{ width: '100%', paddingBlock: '8.45px' }}
                        onChange={(e) => {
                          setSearchName(e.target.value)
                        }}
                      />

                      <input
                        type="text"
                        className="border border-gray-300 px-4 rounded-md focus:outline-none ms-3"
                        placeholder="Agent Name"
                        name="searchName"
                        value={agentName}
                        style={{ width: '100%', paddingBlock: '8.45px' }}
                        onChange={(e) => {
                          setAgentName(e.target.value)
                        }}
                      />  
                      <button
                        className="bg-[#19632f] hover:bg-[#053815] text-white py-2 px-4 ms-3 rounded-md focus:outline-none"
                        // onClick={handleSearch}
                        onClick={() => getPaginationData(1, searchName, agentName)}
                      >
                        Find
                      </button>
                      <button class="bg-[#5555] text-[#515151] font-bold h-10 px-4 ms-3 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out" onClick={(e) => handleClear(e)}>Clear</button>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="reload-icon mr-2">
                        <i
                          className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                          onClick={startLoader}
                        />
                      </div>
                      <div className="btn_outerxx">

                        <AiFillSetting
                          className="Bar_filter"
                          onClick={() => setModalCode(!ModalCode)}
                        />
                        {ModalCode ? (
                          <div className="modal_cover">
                            <div className="modal_inner select-col-popup">
                              <div className="header_modal">
                                <h1 className="capitalize font-bold text-lg mb-2">
                                  select columns
                                </h1>
                                <p className="border-b pb-2 mb-2">
                                  Select the column you want to display or hide.
                                </p>
                                <div
                                  className="columns-popup"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  {tableColumns.map((column, index) => {
                                    if (
                                      selectedColumnsTable.includes(column.value)
                                    ) {
                                      return (
                                        <p className="mb-0 gap-2">
                                          <input
                                            type="checkbox"
                                            id={column.value}
                                            checked={tempColumnsTable.includes(
                                              column.value
                                            )}
                                            onChange={() =>
                                              handleColumnCheckboxChange(
                                                column.value
                                              )
                                            }
                                          />
                                          <label htmlFor={column.value}>
                                            {formatLabel(column.label)}
                                          </label>
                                        </p>
                                      );
                                    } else {
                                      return (
                                        <p className="mb-0 gap-2">
                                          <input
                                            type="checkbox"
                                            id={column.value}
                                            onClick={() => {
                                              selectedColumnsTable.push(
                                                column.value
                                              );
                                              setTempColumnsTable(
                                                selectedColumnsTable
                                              );
                                            }}
                                          />
                                          <label htmlFor={column.value}>
                                            {formatLabel(column.label)}
                                          </label>
                                        </p>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="button-part flex justify-end items-center gap-4">
                                  <button
                                    className="py-2 px-6"
                                    type="btn"
                                    onClick={handleResetAll}
                                  >
                                    Reset all
                                  </button>
                                  <button
                                    className="py-2 px-6"
                                    type="btn"
                                    onClick={handleOk}
                                  >
                                    Ok
                                  </button>
                                </div>
                                <GrClose
                                  className="close"
                                  onClick={() => setModalCode(false)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {toggleModal ? (
                          <div className="modal_cover">
                            <div className="modal_inner select-col-popup">
                              <div className="header_modal">
                                <h2 className="capitalize font-bold text-lg mb-2">
                                  {blockStatus
                                    ? "Are you sure you want to block this student?"
                                    : "Are you sure you want to unblock this student?"}
                                </h2>

                                <div className="button-part flex justify-end items-center gap-4">
                                  <button
                                    className="py-2 px-6"
                                    type="btn"
                                    onClick={() => setToggleModal(false)}
                                  >
                                    No
                                  </button>
                                  <button
                                    className="py-2 px-6"
                                    type="btn"
                                    onClick={() => handleBlockUnblock(ageId, blockStatus)} //call real function here.
                                  >
                                    Yes
                                  </button>
                                </div>
                                <GrClose
                                  className="close"
                                  onClick={() => setToggleModal(false)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full table-border">
                    <div className="overflow-auto card  col-12 px-0 pt-0 pb-2  table_postioning table-overflow">
                      {state.isWaiting ? (
                        <div>
                          <center className="w-full my-10">
                            <img src={loader} alt="" />
                          </center>
                        </div>
                      ) : state.students && state.students.length > 0 ? (
                        <table className="table-auto overflow-scroll w-full agent-table files-table">
                          <thead>
                            <tr>
                              {tableColumns.map((col) => {
                                if (selectedColumnsTable.includes(col.label)) {
                                  return (
                                    <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                      {formatLabel(col.label)}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {state.students?.map((student, index) => {
                              const actualIndex =
                                currentPage * itemsPerPage + index + 1;

                              return (
                                <tr
                                  onClick={() => handlenewStatus(student._id)}
                                >
                                  {selectedColumnsTable.includes("Sr.") && (
                                    <td className="p-2 capitalize">
                                      <p className="text-xs font-weight-bold mb-0">
                                        <div className="flex items-center">
                                          <div className="mr-1">
                                            {actualIndex}
                                          </div>
                                          {student.new === true && (
                                            <span className="bg-green-500 text-white text-[10px] p-1 rounded">
                                              New
                                            </span>
                                          )}
                                        </div>
                                      </p>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Name") && (
                                    <td className="p-2">
                                      <p className="text-sm font-medium text-gray-900 capitalize">
                                        {student.firstName} {student.lastName}
                                      </p>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes(
                                    "Agent Name"
                                  ) && (
                                      <td className="p-2 capitalize">
                                        {`${student?.agentsDetails[0]?.first_name || "-"} ${student?.agentsDetails[0]?.last_name || "-"
                                          }`}
                                      </td>
                                    )}

                                  {selectedColumnsTable.includes(
                                    "Agent Email"
                                  ) && (
                                      <td className="p-2">
                                        <p className="text-sm text-gray-600">
                                          {student?.agentsDetails[0]?.email || "--"}
                                        </p>
                                      </td>
                                    )}
                                  {selectedColumnsTable.includes("Phone") && (
                                    <td className="p-2 capitalize">
                                      <p className="text-sm text-gray-900">
                                        {student?.phone || "--"}
                                      </p>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Email") && (
                                    <td className="p-2">
                                      <p className="text-sm text-gray-900 flex items-center">
                                        {student?.loginProvider == 'google' ? <img src={GoogleLogo} style={{ width: 16, height: 16, marginRight: 10 }} /> : <img src={GMailLogo} style={{ width: 16, height: 16, marginRight: 10 }} />} {student?.email}
                                      </p>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Address") && (
                                    <td className="p-2 align-middle  capitalize">
                                      <span className="text-secondary text-xs font-weight-bold">
                                        <p className="text-sm capitalize text-gray-900">
                                          {student?.country
                                            ? `${student?.city}, ${student?.state}, ${student?.country}`
                                            : "--"}
                                        </p>
                                      </span>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes(
                                    "Recent Activity"
                                  ) && (
                                      <td className="p-2  align-middle capitalize">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          <p className="text-sm text-gray-900">
                                            {new Date(
                                              student.updatedAt
                                            ).toLocaleDateString()}
                                          </p>
                                        </span>
                                      </td>
                                    )}

                                  {selectedColumnsTable.includes("Block/Unblock") && (
                                    <td className="text-left border-2">
                                      <Switch
                                        color="error"
                                        checked={student.block}
                                        onChange={() =>
                                          confirmTwo(student?._id, !student?.block)
                                        }
                                      />
                                    </td>
                                  )}

                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <div>
                            <p className="text-center p-5 text-red-500">
                              No data available.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="">
                      {showPagination && (
                        <div className="card-footer pb-0">
                          <div className="pagination">
                            <div className="pages">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel="next"
                                previousLabel="previous"
                                onPageChange={handlePageChange}
                                pageCount={state.totalPages}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                renderOnZeroPageCount={null}
                                containerClassName="pagination"
                                activeClassName="selected"
                                subContainerClassName="pages"
                                initialPage={currentPage}
                                disableInitialCallback={true}
                                previousClassName={
                                  currentPage === 0 ? "disabled" : ""
                                }
                                nextClassName={
                                  currentPage === state.totalPages - 1
                                    ? "disabled"
                                    : ""
                                }
                                previousLinkClassName={
                                  currentPage === 0 ? "disabled-link" : ""
                                }
                                nextLinkClassName={
                                  currentPage === state.totalPages - 1
                                    ? "disabled-link"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default StudentList;
