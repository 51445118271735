import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

function EditcollegeNamePopup({
  closeEditPopup,
  startLoader,
  selectedCollege,
}) {
  const [popCountry, setPopCountry] = useState([]);
  const [popState, setPopState] = useState([]);
  const [popCity, setPopCity] = useState([]);
  const [customStateCity, setCustomStateCity] = useState(false);
  const [customCity, setCustomCity] = useState(false);
  const [addState, setAddState] = useState("");
  const [addCity, setAddCity] = useState("");
  const [countryLogoPreview, setCountryLogoPreview] = useState(null);
  const [collegeLogoPreview, setCollegeLogoPreview] = useState(null);
  const [state, setState] = useState({
    collegeName: "",
    collegeLogo: null,
    countryId: null,
    countryLogo: null,
    stateId: null,
    cityId: null,
    list: [],
    countryList: [],
    stateList: [],
    cityList: [],
    submitProcessing: false,
  });
  useEffect(() => {
    if (selectedCollege) {
      setState((prevState) => ({
        ...prevState,
        collegeName: selectedCollege.collegeName,
      }));
    }
  }, [selectedCollege]);

  useEffect(() => {
    fetchCountryData();
  }, []);

  const fetchCountryData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/address/country`
      );
      const data = await response.json();
      setPopCountry(data?.details?.countries);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/address/state/${countryId}`
      );
      const data = await response.json();
      setPopState(data?.details?.state);
    } catch (error) {
      console.error("Error fetching states", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/address/city/${stateId}`
      );
      const data = await response.json();
      setPopCity(data?.details?.city);
    } catch (error) {
      console.error("Error fetching cities", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: selectedOption.value,
    }));

    if (name === "countryId") {
      fetchStates(selectedOption.value);
    } else if (name === "stateId") {
      if (selectedOption.value === "other_state") {
        setCustomStateCity(true);
        setCustomCity(false);
      } else {
        setCustomStateCity(false);
        setCustomCity(false);
        fetchCities(selectedOption.value);
      }
    } else if (name === "cityId") {
      if (selectedOption.value === "other_city") {
        setCustomCity(true);
        setCustomStateCity(false);
      } else {
        setCustomCity(false);
        setCustomStateCity(false);
      }
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setState({
      ...state,
      [name]: files[0],
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      if (name === "collegeLogo") {
        setCollegeLogoPreview(reader.result);
      } else if (name === "countryLogo") {
        setCountryLogoPreview(reader.result);
      }
    };

    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
  };

  const EditData = async () => {
    try {
      const formData = new FormData();

      formData.append("collegeName", state.collegeName);
      formData.append("collegeLogo", state.collegeLogo);
      formData.append("countryLogo", state.countryLogo);
      formData.append("countryId", state.countryId);
      formData.append("stateId", state.stateId);
      formData.append("cityId", state.cityId);
      formData.append("id", selectedCollege._id);
      setState({
        ...state,
        submitProcessing: true,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/admin/updatecollegename`,
        formData
      );

      document.getElementById("countryLogoInput").value = "";
      document.getElementById("collegeLogoInput").value = "";

      if (response.data.status === "1") {
        setState({
          ...state,
          submitProcessing: false,
        });
        toast.success(response.data.message);
        closeEditPopup();
        startLoader();
      } else {
        setState({
          ...state,
          submitProcessing: false,
        });
        toast.error(response.data.message);
      }
    } catch (error) {
      setState({
        ...state,
        submitProcessing: false,
      });
    }
  };

  const handleAddStateCity = () => {
    if (!state.countryId) {
      toast.error("Please select a country first");
      return;
    }

    if (addCity === "" || addState === "") {
      toast.error("Please fill in the fields first");
      return;
    }

    fetch(`${process.env.REACT_APP_NODE_URL}/v2/api/addStateAndCity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        countryId: state.countryId,
        state: addState,
        city: addCity,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          fetchStates(state.countryId);
          setCustomStateCity(false);
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding state and city", error);
      });
  };

  const handleAddCity = () => {
    if (addCity === "") {
      toast.error("Please fill in the fields first");
      return;
    }

    fetch(`${process.env.REACT_APP_NODE_URL}/v2/api/addCity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        countryId: state.countryId,
        stateId: state.stateId,
        city: addCity,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          fetchCities(state.stateId);
          setCustomCity(false);
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding city", error);
      });
  };

  return (
    <>
      <div>
        <div className="p-2">
          <h1 className="mb-3 text-xl font-bold">Update College Details</h1>
          <label>College Name</label>
          <div className="mb-3">
            <input
              placeholder="Enter College Name"
              type="text"
              className="block flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
              name="collegeName"
              value={state?.collegeName}
              onChange={handleInputChange}
            />
          </div>
          <label>Country Name</label>
          <div className="mb-3">
            <Select
              className="block flex-1 w-full"
              options={popCountry?.map((country) => ({
                label: country?.countryName,
                value: country?.countryId,
              }))}
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, { name: "countryId" })
              }
              placeholder="Choose"
            />
          </div>

          <div className="flex">
            <div className="w-6/12 mr-1">
              <label>State</label>
              <div className="mb-3">
                <Select
                  className="block flex-1 w-full"
                  options={[
                    ...popState?.map((state) => ({
                      label: state?.stateName,
                      value: state?.stateId,
                    })),
                    { label: "Other", value: "other_state" },
                  ]}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, { name: "stateId" })
                  }
                  placeholder="Choose"
                />
              </div>
            </div>

            <div className="w-6/12 mr-1">
              <label>City</label>
              <div className="mb-3">
                <Select
                  className="block flex-1 w-full"
                  options={[
                    ...popCity?.map((city) => ({
                      label: city?.cityName,
                      value: city?.cityId,
                    })),
                    { label: "Other", value: "other_city" },
                  ]}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, { name: "cityId" })
                  }
                  placeholder="Choose"
                />
              </div>
            </div>
          </div>
          {customStateCity ? (
            <div className="flex items-center">
              <div className="w-5/12 mr-1">
                <label>State</label>
                <div className="mb-3">
                  <input
                    placeholder="Enter State Name"
                    type="text"
                    value={addState}
                    onChange={(e) => setAddState(e.target.value)}
                    className="block flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-5/12 mr-1">
                <label>City</label>
                <div className="mb-3">
                  <input
                    placeholder="Enter City Name"
                    type="text"
                    value={addCity}
                    onChange={(e) => setAddCity(e.target.value)}
                    className="block flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-2/12">
                <button
                  onClick={handleAddStateCity}
                  className="custom_add_button w-full"
                >
                  Add
                </button>
              </div>
            </div>
          ) : null}
          {/*   */}
          {customCity ? (
            <div className="flex items-center">
              <div className="w-10/12 mr-1">
                <label>City</label>
                <div className="mb-3">
                  <input
                    placeholder="Enter City Name"
                    type="text"
                    value={addCity}
                    onChange={(e) => setAddCity(e.target.value)}
                    className="block flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="w-2/12">
                <button
                  onClick={handleAddCity}
                  className="custom_add_button w-full"
                >
                  Add
                </button>
              </div>
            </div>
          ) : null}
          <div className="flex">
            <div className="w-6/12 mr-1">
              <label>College Logo</label>
              <div className="mb-3">
                <input
                  type="file"
                  className="form-control block w-full"
                  name="collegeLogo"
                  id="collegeLogoInput"
                  onChange={handleFileChange}
                />
                {collegeLogoPreview && (
                  <img
                    src={collegeLogoPreview}
                    alt="College Logo Preview"
                    className="mt-2 h-20"
                  />
                )}
              </div>
            </div>

            <div className="w-6/12 ml-1">
              <label>Country Logo</label>
              <div className="mb-3">
                <input
                  type="file"
                  className="form-control block w-full"
                  name="countryLogo"
                  id="countryLogoInput"
                  onChange={handleFileChange}
                />
                {countryLogoPreview && (
                  <img
                    src={countryLogoPreview}
                    alt="Country Logo Preview"
                    className="mt-2 h-20"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="mt-2">
            <button
              type="button"
              className="btn bg-gradient-primary w-100 ml-0 text-white px-2 py-1 rounded mb-0 text-lg"
              onClick={EditData}
              style={{ border: "1px solid #059669" }}
            >
              {state.submitProcessing ? (
                <div aria-label="Loading..." role="status">
                  <svg class="h-6 w-6 animate-spin" viewBox="3 3 18 18">
                    <path
                      class="fill-gray-200"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path
                      class="fill-gray-800"
                      d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                    ></path>
                  </svg>
                </div>
              ) : (
                <>Update</>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditcollegeNamePopup;
