import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../helper/auth";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import Select from "react-select";
const AgentEditStudent = ({ students, setPopup }) => {
    console.log("Student Edit data: ", students)
    const navigate = useNavigate();

    const [state, setState] = useState({
        agentToken: getToken("agent"),
        countryList: [],
        isWait: true,
    });

    const [selectedCountries, setSelectedCountries] = useState([]);

    const [countryOptions, setCountryOptions] = useState([]);

    const [formValues, setFormValues] = useState({
        firstName: students?.first_name,
        lastName: students?.last_name,
        email: students?.email,
        phone: students?.phone,
        highest_education: students?.highest_education || "",
        gradeAverage: students?.grade_score,
        country_to_go: students?.country_to_go || [],
        examType: students?.exam?.type || "",
        tofel_score: students?.exam?.type === "TOFEL" ? students?.exam?.score : "",
        pte_score: students?.exam?.type === "PTE" ? students?.exam?.score : "",
        speaking: students?.exam?.score[3] || "",
        listening: students?.exam?.score[0] || "",
        writing: students?.exam?.score[1] || "",
        reading: students?.exam?.score[2] || "",
        duolingo_lan: students?.exam?.type === "duolingo_lan" ? students?.exam?.score : "",
    });

    console.log("formValues", formValues)

    const [errors, setErrors] = useState({
        examType: "",
        tofel_score: "",
        pte_score: "",
        speaking: "",
        listening: "",
        writing: "",
        reading: "",
        duolingo_lan: "",
        highest_education: '',
        gradeAverage: '',
    });

    const validateForm = () => {
        const newErrors = {};

        if (!formValues.highest_education) {
            newErrors.highest_education = "This Field is required";
        }

        if (!formValues.gradeAverage) {
            newErrors.gradeAverage = "This Field is required";
        }

        if (!formValues.examType) {
            newErrors.examType = "This Field is required";
        }

        if (formValues.examType === "TOFEL" && !formValues.tofel_score) {
            newErrors.tofel_score = "This Field is required";
        }

        if (formValues.examType === "PTE" && !formValues.pte_score) {
            newErrors.pte_score = "This Field is required";
        }

        if (formValues.examType === "duolingo_lan" && !formValues.duolingo_lan) {
            newErrors.duolingo_lan = "This Field is required";
        }

        if (formValues.examType === "IELTS") {
            ["reading", "listening", "writing", "speaking"].forEach((field) => {
                if (!formValues[field]) {
                    newErrors[field] = "This Field is required";
                }
            });
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_NODE_URL + "/address/country")
            .then((countryResponse) => {
                const countries = countryResponse.data.details.countries.map(country => ({
                    value: country.countryName,
                    label: country.countryName
                }));
                setCountryOptions(countries);

                // Preselect the countries if students prop is available
                if (students?.country_to_go && countries.length > 0) {
                    const preselectedCountries = students.country_to_go.map(countryName => {
                        return countries.find(country => country.value === countryName);
                    }).filter(Boolean); // Filter out undefined values

                    setSelectedCountries(preselectedCountries);
                }
            })
            .catch(error => {
                console.error("Error fetching countries:", error);
            });
    }, [students]);

    // console.log("students outside", students)

    // console.log("Country: Form: ", formValues.country_to_go, " Coutnry student : ", students.country_to_go)

    const searchNow = async () => {
        const isFormValid = validateForm();


        if (isFormValid) {
            var api_data = {
                first_name: students?.first_name,
                last_name: students?.last_name,
                email: students?.email,
                phone: students?.phone,
                highest_education: formValues.highest_education !== "" ? formValues.highest_education : students?.highestEducation,
                country_to_go: formValues.country_to_go !== "" ? formValues.country_to_go : students?.country_to_go,
                exam: {
                    type: formValues.examType,
                    score:
                        formValues.examType === "IELTS"
                            ? [
                                parseFloat(formValues.writing),
                                parseFloat(formValues.reading),
                                parseFloat(formValues.speaking),
                                parseFloat(formValues.listening),
                            ]
                            : formValues.examType === "PTE"
                                ? parseFloat(formValues.pte_score)
                                : formValues.examType === "TOFEL"
                                    ? parseFloat(formValues.tofel_score)
                                    : formValues.examType === "duolingo_lan"
                                        ? parseFloat(formValues.duolingo_lan)
                                        : 0,
                },
                grade_score: formValues.gradeAverage !== "" ? formValues.gradeAverage : parseFloat(students?.gradeScore),
            };
            var jsondata = api_data

            console.log("Updated Data: ", jsondata)
            navigate("/agent/findprograms/search/", { state: { data: jsondata } });
            setPopup(false);

        }
    };

    useEffect(() => {
        if (students?.country_to_go && countryOptions.length > 0) {
            const preselectedCountry = countryOptions.find(
                (country) => country.value === students?.country_to_go
            );

            if (preselectedCountry) {
                setSelectedCountries([preselectedCountry]);
            }
        }
    }, [students, countryOptions]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Reset IELTS scores if the exam type changes
        if (name === "examType" && formValues.examType === "IELTS") {
            setFormValues({
                ...formValues,
                [name]: value,
                speaking: "",
                listening: "",
                writing: "",
                reading: "",
            });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }

        setErrors({ ...errors, [name]: "" });
    };


    const handleInputChangeSelect = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleCountrySelect = (selectedOptions) => {
        setSelectedCountries(selectedOptions);
        const selectedCountryNames = selectedOptions.map((option) => option.value);
        handleInputChangeSelect("country_to_go", selectedCountryNames);
    };

    useEffect(() => {
        if (students?.exam?.type) {
            setFormValues(prevValues => ({
                ...prevValues,
                examType: students.exam.type
            }));
        }
    }, [students]);

    useEffect(() => {
        setFormValues((prevValues) => ({
            ...prevValues,
            highest_education: students?.highest_education || "",
            gradeAverage: students?.grade_score || ""
        }));
    }, [students?.highest_education, students?.grade_score]);

    // console.log("formValuessss", formValues.highest_education)
    // console.log("Selected Country List: ", selectedCountries)


    return (
        <div>
            <div className="row">
                <div className="">
                    <div className="card card-plain studentFindPrograms">
                        <div>
                            {true ? (
                                <div className="row">
                                    <div className="">
                                        <div className="card card-plain studentFindPrograms">
                                            <div>
                                                <div className="mt-5">
                                                    <div className="flex">
                                                        <div className="m-2 w-6/12">
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="First Name"
                                                                name="firstName"
                                                                value={students?.first_name}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="m-2 w-6/12">
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="Last Name"
                                                                name="lastName"
                                                                value={students?.last_name}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="m-2 w-6/12">
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="Phone"
                                                                name="phone"
                                                                value={students?.phone}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="m-2 w-6/12">
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="Email"
                                                                name="email"
                                                                value={students?.email}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="flex">
                                                        <div className="m-2 w-6/12">
                                                            <label>Highest Education</label>
                                                            <select
                                                                value={formValues.highest_education} // Set value attribute to the state value
                                                                onChange={handleInputChange}
                                                                name="highest_education"
                                                                className="block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control">
                                                                <option value="">Select</option>
                                                                <option value="secondary">Grade 12 / High School</option>
                                                                <option value="certificate">1 - Year Post - Secondary Certificate</option>
                                                                <option value="diploma">2 - Year Undergraduate Diploma</option>
                                                                <option value="advance_diploma">3 - Year Undergraduate Advanced Diploma</option>
                                                                <option value="3_year_bachlor">3 - Year Bachelor's Degree</option>
                                                                <option value="4_year_bachlor">4 - Year Bachelor's Degree</option>
                                                                <option value="postgraduate_diploma">Postgraduate Certificate / Diploma</option>
                                                                <option value="master">Master's Degree</option>
                                                                <option value="doctrate">Doctoral Degree(Phd, M.D., ...)</option>
                                                            </select>
                                                            {errors.highest_education && <span className="text-red-600">{errors.highest_education}</span>}
                                                        </div>
                                                        <div className="m-2 w-6/12">
                                                            <label>Grade Average</label>
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="Grade Average"
                                                                name="gradeAverage"
                                                                value={formValues.gradeAverage}
                                                                onChange={(e) => setFormValues({ ...formValues, gradeAverage: e.target.value })}
                                                            />
                                                            {errors.gradeAverage && <span className="text-red-600">{errors.gradeAverage}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="m-2">
                                                        <label>Destination Country</label>
                                                        <Select
                                                            isMulti
                                                            options={countryOptions}
                                                            value={selectedCountries}
                                                            onChange={handleCountrySelect}
                                                            placeholder="--Select--"
                                                        />
                                                    </div>
                                                    <div className="m-2">
                                                        <label>Exam Type</label>
                                                        <select
                                                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                            name="examType"
                                                            onChange={handleInputChange}
                                                            value={formValues.examType} // Bind value to form state
                                                        >
                                                            <option value="">--Select--</option>
                                                            <option value="IELTS">IELTS</option>
                                                            <option value="TOFEL">TOFEL</option>
                                                            <option value="PTE">PTE</option>
                                                            <option value="duolingo_lan">DUOLINGO</option>
                                                        </select>
                                                        {errors.examType && <span className="text-red-600">{errors.examType}</span>}
                                                    </div>

                                                    {formValues.examType === "TOFEL" && (
                                                        <div className="m-2">
                                                            <label>TOFEL Score</label>
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="Tofel Score"
                                                                name="tofel_score"
                                                                value={formValues.tofel_score}
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.tofel_score && <span className="text-red-600">{errors.tofel_score}</span>}
                                                        </div>
                                                    )}

                                                    {formValues.examType === "duolingo_lan" && (
                                                        <div className="m-2">
                                                            <label>Duolingo Score</label>
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="Duolingo Score"
                                                                name="duolingo_lan"
                                                                value={formValues.duolingo_lan}
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.duolingo_lan && <span className="text-red-600">{errors.duolingo_lan}</span>}
                                                        </div>
                                                    )}

                                                    {formValues.examType === "PTE" && (
                                                        <div className="m-2">
                                                            <label>PTE Score</label>
                                                            <input
                                                                type="text"
                                                                className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                placeholder="PTE Score"
                                                                name="pte_score"
                                                                value={formValues.pte_score}
                                                                onChange={handleInputChange}
                                                            />
                                                            {errors.pte_score && <span className="text-red-600">{errors.pte_score}</span>}
                                                        </div>
                                                    )}

                                                    {formValues.examType === "IELTS" && (
                                                        <div className="flex w-full">
                                                            <div className="m-2 w-3/12">
                                                                <label>Listening</label>
                                                                <input
                                                                    type="text"
                                                                    className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                    placeholder="Listening"
                                                                    name="listening"
                                                                    value={formValues.listening}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {errors.listening && <span className="text-red-600">{errors.listening}</span>}
                                                            </div>
                                                            <div className="m-2 w-3/12">
                                                                <label>Reading</label>
                                                                <input
                                                                    type="text"
                                                                    className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                    placeholder="Reading"
                                                                    name="reading"
                                                                    value={formValues.reading}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {errors.reading && <span className="text-red-600">{errors.reading}</span>}
                                                            </div>
                                                            <div className="m-2 w-3/12">
                                                                <label>Writing</label>
                                                                <input
                                                                    type="text"
                                                                    className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                    placeholder="Writing"
                                                                    name="writing"
                                                                    value={formValues.writing}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {errors.writing && <span className="text-red-600">{errors.writing}</span>}
                                                            </div>
                                                            <div className="m-2 w-3/12">
                                                                <label>Speaking</label>
                                                                <input
                                                                    type="text"
                                                                    className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                                                                    placeholder="Speaking"
                                                                    name="speaking"
                                                                    value={formValues.speaking}
                                                                    onChange={handleInputChange}
                                                                />
                                                                {errors.speaking && <span className="text-red-600">{errors.speaking}</span>}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formValues.speaking !== "" &&
                                                        formValues.listening !== "" &&
                                                        formValues.reading !== "" &&
                                                        formValues.writing !== "" && (
                                                            <div className="overall-score mx-2">
                                                                <p>
                                                                    Overall Score -{" "}
                                                                    {(
                                                                        Math.round(
                                                                            ((parseFloat(formValues.speaking) +
                                                                                parseFloat(formValues.listening) +
                                                                                parseFloat(formValues.reading) +
                                                                                parseFloat(formValues.writing)) /
                                                                                4) *
                                                                            2
                                                                        ) / 2
                                                                    ).toFixed(1)}
                                                                </p>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="m-2 justify-end flex mt-4">
                                                        <ButtonPrimary title={"Search"} onclick={searchNow} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentEditStudent;
