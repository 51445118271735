import React, { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HomePageSetting from './HomePageSetting';

const SettingsTab = () => {
    const [key, setKey] = useState('home');
    return (
        <div className='settings-tab'>
            <div className="dash_bord_form agent-dashboard agent-tabs">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="home" title="Home Page Settings">
                        <HomePageSetting />
                    </Tab>
                    <Tab eventKey="profile" title="Contact Page Settings">
                        Contact
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default SettingsTab;
