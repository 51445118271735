import React from "react";
import { Link } from "react-router-dom";

export default function Possibilities() {
  return (
    <div className="begining-part py-16 relative">
      <div className="container mx-auto">
        <div className="px-4">
        <h3 className="text-center text-white relative leading-tight">
          New Beginnings! Endless Possibilities! provide by
          <br /> Learn Global
        </h3>
        <p className="text-center text-white relative text-lg mt-10">
          Learn Global is with you for your service and convenience to complete
          your dream
        </p>
        <button class="text-white mt-12 py-3 px-8 mx-auto block  font-bold py-2 px-4 rounded-full relative">
          <Link to="/eligible">
          Get Started
          </Link>
        </button>
        </div>
      </div>
    </div>
  );
}
