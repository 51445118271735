import React, { useEffect, useState } from 'react';
import axios from 'axios';
import loader from "../../../images/loader.gif";
import { FaEdit } from "react-icons/fa";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

function ViewOlDocuments({ closeViewPopup, startLoader, selectedCountry, educationtype }) {
    console.log(educationtype)
    let token = Cookies.get("admin_token");
    const [state, setState] = useState({
        list: [],
        ID: '',
        showPopup: false,
        iswait: false
    });

    const [editDocument, setEditDocument] = useState({
        _id: '',
        title: '',
        isRequired: false
    });

    const removeCommas = (str) => {
        return str.replace(/,/g, '');
    };

    const handleDocumentUpdate = () => {
        const payload = {
            _id: editDocument._id,
            title: editDocument.title,
            // isRequired: editDocument.isRequired,
            countryName: selectedCountry.countryName,
            educationType: removeCommas(educationtype.toString())
        };

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.post(process.env.REACT_APP_NODE_URL + "/admin/documentupdate", payload, config)
            .then((response) => {
                if (response.data.status === "1") {
                    fetchOlDocuments();
                    toast.success(response.data.message)
                    setEditDocument({
                        _id: '',
                        title: '',
                        isRequired: false
                    });
                } else {
                    toast.error(response.data.error)
                    console.error("Failed to update document");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const fetchOlDocuments = async () => {
        setState({
            ...state,
            iswait: true
        });
        const body = {
            countryName: selectedCountry.countryName,
            educationType: removeCommas(educationtype.toString())
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_NODE_URL}/admin/getDocumentUniqueCountry`, body
            );
            setState({
                ...state,
                list: response.data.detail.docsRequired,
                ID: response.data.detail._id,
                iswait: false
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchOlDocuments();
    }, []);

    const getEducationTypeMessage = (educationtype) => {
        switch (removeCommas(educationtype.toString())) {
            case "secondary":
                return "Grade 12/High School";
            case "certificate":
                return "1-Year Post-Secondary Certificate";
            case "diploma":
                return "2-Year Undergraduate Diploma";
            case "advance_diploma":
                return "3-Year Undergraduate Advanced Diploma";
            case "3_year_bachlor":
                return "3-Year Bachelor's Degree";
            case "4_year_bachlor":
                return "4-Year Bachelor's Degree";
            case "postgraduate_diploma":
                return "Postgraduate Certificate/Diploma";
            case "master":
                return "Master's Degree";
            case "doctrate":
                return "Doctoral Degree (PhD, M.D., ...)";
            default:
                return "";
        }
    };

    const editpopup = (document) => {
        setEditDocument({
            _id: document._id,
            title: document.title,
            isRequired: document.isRequired
        });
    };

    return (
        <>
            <div className="p-2">
                <h1 className="mb-5 text-[16px] font-bold">{selectedCountry.countryName}
                    <span className='text-[#1a7335]'> {getEducationTypeMessage(educationtype)}</span> Ol Documents</h1>
                {state.iswait ? (
                    <div>
                        <center className="w-full">
                            <img src={loader} alt="" />
                        </center>
                    </div>
                ) : state?.list?.length > 0 ? (
                    <div>
                        {state.list?.map((result, index) => (
                            <div className='flex mb-5' key={index}>
                                <h1 className='flex-1'>{result.title}</h1>
                                <div className='flex-1'>
                                    {result.isRequired ? (
                                        <span className='ml-3 text-[#f00]'>Required</span>
                                    ) : (
                                        <span className='ml-3 text-[#000]'>Optionally</span>
                                    )}
                                </div>
                                <FaEdit onClick={() => editpopup(result)} className='cursor-pointer' />
                            </div>

                        ))}
                    </div>
                ) : (
                    <div>
                        <p className="text-center p-5 text-red-500">
                            No data available.
                        </p>
                    </div>
                )}
            </div>

            {editDocument._id && (
                <div className="bg-[#ddd] p-3">
                    <input
                        className='p-2 mr-3 w-[150px] outline-none'
                        type="text"
                        value={editDocument.title}
                        onChange={(e) => setEditDocument({ ...editDocument, title: e.target.value })}
                    />
                    {/* <select
                        className='p-2 mx-3 outline-none'
                        value={editDocument.isRequired}
                        onChange={(e) => setEditDocument({ ...editDocument, isRequired: e.target.value === 'true' })}
                    >
                        <option value={true}>Required</option>
                        <option value={false}>Optionally</option>
                    </select> */}
                    <button className='p-2 border' onClick={() => handleDocumentUpdate()}>Save</button>
                </div>
            )}
        </>
    );
}

export default ViewOlDocuments;
