import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import loader from "../../../images/loader.gif";

const SocialIconSetting = () => {
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState(null);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [editing, setEditing] = useState(false);
  const [socialIcons, setSocialIcons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ShowPopup, setShowPopup] = useState(false);
  const [editingIcon, setEditingIcon] = useState(null);
  const [ShowPopupTwo, setShowPopupTwo] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Check if all fields are filled
    if (!name || !link) {
      toast.error("All fields are required");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("link", link);

    if (image) {
      console.log("checkDDDDD", image);
      const imageFile = new File([image], image.name); // Create a new File object

      formData.append("image", imageFile);
    }

    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_NODE_URL + "/admin/socialicon",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        toast.success("Icon added successfully");
        fetchData();

        // Reset the form fields
        setImage(null);
        setName("");
        setLink("");
        setShowPopup(false); // Close the modal
      } else {
        console.error("Failed to submit data");
        toast.error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error occurred while submitting data:", error);
      toast.error("Error occurred while submitting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_NODE_URL + "/admin/sociallist"
      );
      if (response.ok) {
        const data = await response.json();
        setSocialIcons(data.details.list);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Set initial form field values when editing an icon
  useEffect(() => {
    fetchData();
    if (editingIcon) {
      setName(editingIcon.name);
      setLink(editingIcon.link);
      setSelectedIcon(editingIcon);
    }
  }, [editingIcon]);

  // handle Delete
  const handleDeleteIcon = async (iconId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/admin/deletesocialinfo/${iconId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        toast.success("Icon deleted successfully");

        // Fetch the updated social icon list
        const updatedResponse = await fetch(
          `${process.env.REACT_APP_NODE_URL}/admin/sociallist`
        );
        if (updatedResponse.ok) {
          const updatedData = await updatedResponse.json();
          setSocialIcons(updatedData.details.list);
          setShowPopup(false); // Close the modal
        } else {
          console.error("Failed to fetch updated data");
        }
      } else {
        console.error("Failed to delete icon");
        toast.error("Failed to delete icon");
      }
    } catch (error) {
      console.error("Error occurred while deleting icon:", error);
      toast.error("Error occurred while deleting icon:", error);
    } finally {
      setEditing(false);
    }
  };

  // const updateSocialLinks = () => {
  //   alert("ccccc")
  // }
  const updateSocialLinks = async (event) => {
    event.preventDefault();

    // check if all fields are filled
    if (!name || !link) {
      toast.error("All fields are required");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("link", link);

    if (icon) {
      formData.append("icon", icon);
    }

    try {
      setLoading(true);
      let response;

      if (selectedIcon) {
        // Editing an existing icon
        formData.append("id", selectedIcon._id); // Pass the icon ID to the API
        response = await fetch(
          process.env.REACT_APP_NODE_URL + `/admin/updateSocialInfo`,
          {
            method: "POST",
            body: formData,
          }
        );
      } else {
        // Adding a new icon
        response = await fetch(
          process.env.REACT_APP_NODE_URL + "/admin/socialicon",
          {
            method: "POST",
            body: formData,
          }
        );
      }

      if (response.ok) {
        if (selectedIcon) {
          toast.success("Icon updated successfully");
          setShowPopupTwo(false);
        } else {
          toast.success("Icon added successfully");
        }

        // Fetch the updated social icon list
        const updatedResponse = await fetch(
          process.env.REACT_APP_NODE_URL + "/admin/sociallist"
        );
        if (updatedResponse.ok) {
          const updatedData = await updatedResponse.json();
          setSocialIcons(updatedData.details.list);
          setShowPopup(false); // Close the modal
        } else {
          console.error("Failed to fetch updated data");
        }
      } else {
        console.error("Failed to submit data");
        toast.error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error occurred while submitting data:", error);
      toast.error("Error occurred while submitting data:", error);
    } finally {
      setLoading(false);

      // Reset the form fields
      setImage(null);
      setName("");
      setLink("");
      setSelectedIcon(null);
    }
  };

  return (
    <>
      <div heading_title={"Add college Detail"}>
        <>
          <div className="row addCountryPage flex flex-row">
            <div
              className={`w-5/12 mx-auto my-4 createcollegeNamePopup add_college_popup social ${
                ShowPopup ? "active" : ""
              }`}
            >
              <form onSubmit={handleFormSubmit} enctype="multipart/form-data">
                <label>
                  <div className="flex justify-between align-center relative">
                    <div className="span">
                      <strong>Add New Social Platform.</strong>
                    </div>
                    <GrClose
                      className="close"
                      onClick={() => {
                        setShowPopup(false);
                      }}
                    />
                  </div>
                </label>
                <div className="card-body p-2">
                  <label>Name</label>
                  <div className="mb-3">
                    <input
                      placeholder="Name"
                      className="block w-full flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                      name="name"
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="flex">
                    <div className="w-6/12 mr-1">
                      <label>Link</label>
                      <div className="mb-3">
                        <input
                          placeholder="Add Link"
                          type="text"
                          className="block w-full flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                          name="link"
                          onChange={(event) => setLink(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="w-6/12 ml-1">
                      <label>Choose Image</label>
                      <div className="mb-3">
                        <input
                          type="file"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-1 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          accept="icon/*"
                          onChange={(event) => setImage(event.target.files[0])} // Make sure this line is correct
                          name="image"
                        />
                      </div>
                      {image && (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Selected Icon"
                          width={100}
                        />
                      )}
                    </div>
                  </div>

                  <div className="custom_submit flex cs-btn">
                    <button
                      type="submit"
                      className="btn btn-primary blue-bg relative overflow-hidden rounded-lg  text-white py-2 px-4 hover:bg-green-500"
                      style={{ background: "#1a7335" }}
                    >
                      <span className="relative z-10">Submit</span>
                      <span className="absolute top-0 left-0 h-full w-0 bg-green-500 transition-all duration-300"></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="mx-auto w-full my-4 p-45">
              <div className="flex justify-between items-center addscl-cs my-4">
                <div className="left flex items-center"></div>
                <button
                  className="addNewBtn hover:bg-[#065f46]"
                  onClick={() => setShowPopup(true)}
                >
                  Add New
                </button>
              </div>
              <div class="flex flex-col table_postioning">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="overflow-hidden  border">
                      <div className="table-overflow">
                        <table class="min-w-full agent-table">
                          <thead class="">
                            <tr>
                              <th class="text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold lg:pl-5">
                                Image
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-6 py-4 text-left font-bold">
                                Link
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-6 py-4 text-left font-bold">
                                Name
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-6 py-4 text-left font-bold">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {socialIcons.map((icon) => (
                              <tr key={icon._id} className="border-b">
                                <td className="text-sm text-gray-900 font-light p-2 lg:pl-5 whitespace-nowrap">
                                  <img
                                    src={
                                      icon.icon
                                        ? process.env.REACT_APP_NODE_URL +
                                          "/uploads/agent/" +
                                          icon.icon
                                        : ""
                                    }
                                    alt=""
                                  />
                                </td>
                                <td className="p-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {icon.link}
                                </td>
                                <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                  <p>{icon.name}</p>
                                </td>
                                <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap">
                                  <div className="action-icons-list">
                                    <i
                                      className="action-icon fa-solid fa-pen-to-square cursor-pointer"
                                      onClick={() => {
                                        setEditingIcon(icon);
                                        setShowPopupTwo(true);
                                      }}
                                    ></i>
                                    <i
                                      className="action-icon fa-solid fa-trash cursor-pointer text-[red]"
                                      onClick={() => handleDeleteIcon(icon._id)}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {ShowPopupTwo ? (
                        <>
                          <div
                            className={`w-5/12 mx-auto my-4 createcollegeNamePopup add_college_popup social ${
                              ShowPopupTwo ? "active" : ""
                            }`}
                          >
                            <form onSubmit={updateSocialLinks}>
                              <label>
                                <div className="flex justify-between align-center relative ">
                                  <div className="span">
                                    <strong>Edit Social Platform.</strong>
                                  </div>
                                  <GrClose
                                    className="close"
                                    onClick={() => {
                                      setShowPopupTwo(false);
                                    }}
                                  />
                                </div>
                              </label>
                              <div className="card-body p-2">
                                <label>Name</label>
                                <div className="mb-3">
                                  <input
                                    placeholder="Name"
                                    className="block w-full flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="flex">
                                  <div className="w-6/12 mr-1">
                                    <label>Link</label>
                                    <div className="mb-3">
                                      <input
                                        placeholder="Add Link"
                                        type="text"
                                        className="block w-full flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                        value={link}
                                        name="link"
                                        onChange={(e) =>
                                          setLink(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="w-6/12 ml-1">
                                    <label>Choose Image</label>
                                    <div className="mb-3">
                                      <input
                                        type="file"
                                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-1 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        accept="image/*"
                                        name="icon"
                                        onChange={(event) =>
                                          setIcon(event.target.files[0])
                                        }
                                      />
                                    </div>
                                    {icon && (
                                      <img
                                        src={URL.createObjectURL(icon)}
                                        alt="Selected Icon"
                                        width={100}
                                      />
                                    )}
                                    {/* Removed the image display */}
                                  </div>
                                </div>

                                <div className="custom_submit flex cs-btn">
                                  <button
                                    type="submit"
                                    className="btn btn-primary blue-bg relative overflow-hidden rounded-lg  text-white py-2 px-4 hover:bg-green-500"
                                    style={{ background: "#1a7335" }}
                                  >
                                    <span className="relative z-10">
                                      Submit
                                    </span>
                                    <span className="absolute top-0 left-0 h-full w-0 bg-green-500 transition-all duration-300"></span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {loading && (
                        <div>
                          <center className="w-full my-10">
                            <img src={loader} alt="" />
                          </center>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default SocialIconSetting;
