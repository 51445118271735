import { Switch } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, Navigate, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import AgentDashboard from "../Screens/Dashboard/AgentDashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AgentNotifications = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showCount, setShowCount] = useState(2);
    const navigate = useNavigate()
    const [state, setState] = useState({
        isWaiting: false,
        notifications: [],
        agentToken: getToken("agent"),
    })

    useEffect(() => {
        getPaginationData(1);
    }, [])


    const getPaginationData = (page) => {
        // get all agents
        setState({
            ...state,
            isWaiting: true,
        })
        let data = { currentPage: page }
        const config = { headers: { "Authorization": `Bearer ${state.agentToken}` } }
        axios.post(process.env.REACT_APP_NODE_URL + "/agent/getnotifications", data, config).then(res => {
            // authenticate with token
            // redirect
            setState({
                ...state,
                isWaiting: false,
                notifications: res.data.details.notifications
            })

        }).catch(err => {
            // toast(err.response.data.message)
        })
    }

    // const toggleStatus = (agentId, index) => {
    //     let oldAgents = state.agents
    //     if (oldAgents[index].status == "APPROVED") {
    //         oldAgents[index].status = "UN_APPROVED";
    //     } else {
    //         oldAgents[index].status = "APPROVED";
    //     }
    //     setState({
    //         ...state,
    //         agents: oldAgents
    //     })
    //     let count = parseInt(document.getElementById("totalAgentsUnapproved").innerText)
    //     if (oldAgents[index].status == "UN_APPROVED") {
    //         document.getElementById("totalAgentsUnapproved").innerText = count + 1
    //     } else {
    //         document.getElementById("totalAgentsUnapproved").innerText = count - 1
    //     }
    //     let data = { agentId }
    //     axios.post(process.env.REACT_APP_NODE_URL + "/admin/togglestatus", data).then(res => {
    //         // authenticate with token
    //         // redirect
    //     }).catch(err => {
    //         // toast(err.response.data.message)
    //     })
    // }

    return (
        <>
            <ToastContainer />
            <div heading_title={"Notifications"}>
                <>
                    <div className="row px-4">
                        <div className="w-full">
                            <div className=" mb-4 mt-4">
                                <div className="px-0 pt-0 pb-2">
                                    <div className="top-notification-box">
                                        <button>Read All</button>
                                    </div>
                                    <div className="table-responsive p-0 table-overflow">
                                        {
                                            state.isWaiting ? "Loading..." :
                                                <table className="table w-full mb-0">
                                                    {/* <thead>
                                                        <tr>
                                                            <th className="">Id</th>
                                                            <th className="text-left p-3">Notification</th>
                                                            <th className="text-secondary opacity-7" />
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        {
                                                            state.notifications.slice(0, showCount).map((notification, index) => {
                                                                return <tr>
                                                                    <td>
                                                                        <p className="text-xs text-center font-weight-bold mb-0">{index + 1}</p>
                                                                        {/* <p className="text-xs text-secondary mb-0"><b>ID</b> {agent._id}</p> */}
                                                                    </td>
                                                                    <td className="unread notificationDiv">
                                                                        <div className="unreadBox">
                                                                            New
                                                                        </div>
                                                                        <div className="left">
                                                                            <h2 onClick={() => navigate(notification.redirectUrl)}>{notification.message}</h2>
                                                                            <small><p>{notification.body}</p></small>
                                                                        </div>
                                                                        <div className="right">
                                                                            <small><p>{notification.created}</p></small>
                                                                        </div>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                                <div className="card-footer pb-0">
                                    {/* pagination is here */}
                                    {/* <div className="pagination">
                                            <div className="pages">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel="next"
                                                    onPageChange={(event) => {
                                                        getPaginationData(event.selected + 1)
                                                    }}
                                                    pageCount={state.totalPages}
                                                    previousLabel="prev"
                                                    renderOnZeroPageCount={null}
                                                    pageRangeDisplayed={1}
                                                    marginPagesDisplayed={0}
                                                    containerClassName="pagination"
                                                    subContainerClassName="pages"
                                                />
                                            </div>
                                    </div> */}
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {showCount < state?.notifications.length && (
                                            <button
                                                className="show-btn rounded-full"
                                                style={{
                                                    background: "rgb(51 51 51)",
                                                    padding: "10px 30px",
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                    marginRight: "10px",


                                                }}
                                                onClick={() => setShowCount(showCount + 2)}
                                            >
                                                Show More
                                            </button>
                                        )}
                                        {showCount > 2 && (
                                            <button
                                                className="show-btn rounded-full"
                                                style={{
                                                    background: "rgb(51 51 51)",
                                                    padding: "10px 30px",
                                                    cursor: "pointer",
                                                    color: "#fff",
                                                    marginRight: "10px",
                                                }}
                                                onClick={() => setShowCount(2)}
                                            >
                                                Show Less
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default AgentNotifications;