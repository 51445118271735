import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../helper/auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import Select from 'react-select'

const AgentFindProgram = ({ ShowPopup }) => {
  const navigate = useNavigate();
  const [display, setdisplay] = useState(true);
  const [state, setState] = useState({
    agentToken: getToken("agent"),
    countryList: [],
    isWait: true,
  });

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    highest_education: "",
    gradeAverage: "",
    country_to_go: [],
    examType: "",
    tofel_score: "",
    pte_score: "",
    speaking: "",
    listening: "",
    writing: "",
    reading: "",
    duolingo_lan: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    highest_education: "",
    gradeAverage: "",
    country_to_go: "",
    examType: "",
    tofel_score: "",
    pte_score: "",
    speaking: "",
    listening: "",
    writing: "",
    reading: "",
    duolingo_lan: "",
  });

  const handleInputEdit = (e) => {
    const { name, value } = e.target;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_NODE_URL + "/address/country")
      .then((countryResponse) => {
        setState({
          ...state,
          iswait: false,
          countryList: countryResponse.data.details.countries,
        });
      });
  }, []);
  const validateForm = () => {
    const newErrors = {};
    if (!formValues.firstName) {
      newErrors.firstName = "This Field is required";
    }
    // if (!formValues.lastName) {
    //   newErrors.lastName = "This Field is required";
    // }
    if (!formValues.email) {
      newErrors.email = "This Field is required";
    }
    if (!formValues.phone) {
      newErrors.phone = "This Field is required";
    }
    if (!formValues.highest_education) {
      newErrors.highest_education = "This Field is required";
    }
    if (!formValues.gradeAverage) {
      newErrors.gradeAverage = "This Field is required";
    }
    if (!formValues.country_to_go || formValues.country_to_go.length === 0) {
      newErrors.country_to_go = "This Field is required";
    }
    if (!formValues.examType) {
      newErrors.examType = "This Field is required";
    }

    if (formValues.examType === "TOFEL" && !formValues.tofel_score) {
      newErrors.tofel_score = "This Field is required";
    }

    if (formValues.examType === "PTE" && !formValues.pte_score) {
      newErrors.pte_score = "This Field is required";
    }

    if (formValues.examType === "duolingo_lan" && !formValues.duolingo_lan) {
      newErrors.duolingo_lan = "This Field is required";
    }

    if (formValues.examType === "IELTS") {
      ["reading", "listening", "writing", "speaking"].forEach((field) => {
        if (!formValues[field]) {
          newErrors[field] = "This Field is required";
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const searchNow = async (values) => {
    const isFormValid = validateForm();

    if (isFormValid) {
      var api_data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        highest_education: values.highest_education,
        country_to_go: values.country_to_go,
        exam: {
          type: values.examType,
          score:
            values.examType == "IELTS"
              ? [
                parseFloat(values.writing),
                parseFloat(values.reading),
                parseFloat(values.speaking),
                parseFloat(values.listening),
              ]
              : values.examType == "PTE"
                ? parseFloat(values.pte_score)
                : values.examType == "TOFEL"
                  ? parseFloat(values.tofel_score)
                  : values.examType == "duolingo_lan"
                    ? parseFloat(values.duolingo_lan)
                    : 0,
        },
        grade_score: parseFloat(values.gradeAverage),
      };
      var jsondata = api_data;
      navigate("/agent/findprograms/search/", { state: { data: jsondata } });
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    if (!ShowPopup) {
      setFormValues({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        highest_education: "",
        gradeAverage: "",
        country_to_go: [],
        examType: "",
        tofel_score: "",
        pte_score: "",
        speaking: "",
        listening: "",
        writing: "",
        reading: "",
        duolingo_lan: "",
      })
    }
  }, [ShowPopup])

  useEffect(() => {
    // Reset related fields when examType changes
    if (formValues.examType) {
      setFormValues((prevFormValues) => {
        const resetFields = {
          tofel_score: "",
          pte_score: "",
          speaking: "",
          listening: "",
          writing: "",
          reading: "",
          duolingo_lan: "",
        };

        return {
          ...prevFormValues,
          ...resetFields,
        };
      });
    }
  }, [formValues.examType]);

  return (
    <>
      <div>
        <>
          {display ? (
            <div className="row">
              <div className="">
                <div className="card card-plain studentFindPrograms">
                  <div>
                    <div className="mt-5">
                      <div className="flex">
                        <div className="m-2 w-6/12">
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="First Name"
                            name="firstName"
                            value={formValues.firstName}
                            onChange={handleInputEdit}

                          />
                          {errors.firstName && <span className="text-red-600">{errors.firstName}</span>}

                        </div>
                        <div className="m-2 w-6/12">
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="Last Name"
                            name="lastName"
                            value={formValues.lastName}
                            onChange={handleInputEdit}

                          />
                          {/* {errors.lastName && <span className="text-red-600">{errors.lastName}</span>} */}

                        </div>
                      </div>
                      <div className="flex">
                        <div className="m-2 w-6/12">
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="Phone"
                            name="phone"
                            value={formValues.phone}
                            onChange={handleInputEdit}

                          />
                          {errors.phone && <span className="text-red-600">{errors.phone}</span>}
                        </div>
                        <div className="m-2 w-6/12">
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="Email"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputEdit}

                          />
                          {errors.email && <span className="text-red-600">{errors.email}</span>}

                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="flex">
                        <div className="m-2 w-6/12">
                          <label>Highest Education</label>
                          <select
                            value={formValues.highest_education}
                            onChange={handleInputEdit}
                            name="highest_education"
                            className="block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control">
                            <option >Select</option>
                            <option value="secondary">Grade 12 / High School</option>
                            <option value="certificate">1 - Year Post - Secondary Certificate</option>
                            <option value="diploma">2 - Year Undergraduate Diploma</option>
                            <option value="advance_diploma">3 - Year Undergraduate Advanced Diploma</option>
                            <option value="3_year_bachlor">3 - Year Bachelor's Degree</option>
                            <option value="4_year_bachlor">4 - Year Bachelor's Degree</option>
                            <option value="postgraduate_diploma">Postgraduate Certificate / Diploma</option>
                            <option value="master">Master's Degree</option>
                            <option value="doctrate">Doctoral Degree(Phd, M.D., ...)</option>
                          </select>
                          {errors.highest_education && <span className="text-red-600">{errors.highest_education}</span>}

                        </div>
                        <div className="m-2 w-6/12">
                          <label>Grade Average</label>
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="Grade Average"
                            name="gradeAverage"
                            value={formValues.gradeScore}
                            onChange={handleInputEdit}

                          />
                          {errors.gradeAverage && <span className="text-red-600">{errors.gradeAverage}</span>}

                        </div>
                      </div>
                      <div className="m-2">
                        <label>Destination Country</label>
                        <Select
                          className="w-full"
                          name="country_to_go"
                          id="country_to_go"
                          isMulti
                          value={formValues.country_to_go
                            ? formValues.country_to_go.map((selectedCountry) => ({
                              value: selectedCountry,
                              label: selectedCountry,
                            }))
                            : null
                          }
                          options={state.countryList.map((country) => ({
                            value: country.countryName,
                            label: country.countryName,
                          }))}
                          onChange={(selectedOptions) => {
                            const selectedCountries = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setFormValues({
                              ...formValues,
                              country_to_go: selectedCountries,
                            });
                          }}
                          placeholder="--Select--"
                          labelledBy="College Name"
                          searchable={true}
                          hideSelectedOptions={false}
                        />
                        {errors.country_to_go && <span className="text-red-600">{errors.country_to_go}</span>}
                      </div>
                      <div className="m-2">
                        <label>Exam Type</label>
                        <select
                          className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                          name="examType"
                          value={formValues.examType}
                          onChange={handleInputEdit}
                        >
                          <option value="">--Select--</option>
                          <option value="IELTS">IELTS</option>
                          <option value="TOFEL">TOFEL</option>
                          <option value="PTE">PTE</option>
                          <option value="duolingo_lan">DUOLINGO</option>
                        </select>
                        {errors.examType && <span className="text-red-600">{errors.examType}</span>}
                      </div>

                      {formValues.examType === "TOFEL" && (
                        <div className="m-2">
                          <label>TOFEL Score</label>
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="Tofel Score"
                            name="tofel_score"
                            value={formValues.tofel_score}
                            onChange={handleInputChange}
                          />
                          {errors.tofel_score && <span className="text-red-600">{errors.tofel_score}</span>}
                        </div>
                      )}

                      {formValues.examType === "duolingo_lan" && (
                        <div className="m-2">
                          <label>Duolingo Score</label>
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="Duolingo Score"
                            name="duolingo_lan"
                            value={formValues.duolingo_lan}
                            onChange={handleInputChange}
                          />
                          {errors.duolingo_lan && <span className="text-red-600">{errors.duolingo_lan}</span>}
                        </div>
                      )}

                      {formValues.examType === "PTE" && (
                        <div className="m-2">
                          <label>PTE Score</label>
                          <input
                            type="text"
                            className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                            placeholder="PTE Score"
                            name="pte_score"
                            value={formValues.pte_score}
                            onChange={handleInputChange}
                          />
                          {errors.pte_score && <span className="text-red-600">{errors.pte_score}</span>}
                        </div>
                      )}

                      {formValues.examType === "IELTS" && (
                        <div className="flex w-full">
                          <div className="m-2 w-3/12">
                            <label>Listening</label>
                            <input
                              type="text"
                              className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                              placeholder="Listening"
                              name="listening"
                              value={formValues.listening}
                              onChange={handleInputChange}
                            />
                            {errors.listening && <span className="text-red-600">{errors.listening}</span>}
                          </div>
                          <div className="m-2 w-3/12">
                            <label>Reading</label>
                            <input
                              type="text"
                              className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                              placeholder="Reading"
                              name="reading"
                              value={formValues.reading}
                              onChange={handleInputChange}
                            />
                            {errors.reading && <span className="text-red-600">{errors.reading}</span>}
                          </div>
                          <div className="m-2 w-3/12">
                            <label>Writing</label>
                            <input
                              type="text"
                              className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                              placeholder="Writing"
                              name="writing"
                              value={formValues.writing}
                              onChange={handleInputChange}
                            />
                            {errors.writing && <span className="text-red-600">{errors.writing}</span>}
                          </div>
                          <div className="m-2 w-3/12">
                            <label>Speaking</label>
                            <input
                              type="text"
                              className={`block flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control`}
                              placeholder="Speaking"
                              name="speaking"
                              value={formValues.speaking}
                              onChange={handleInputChange}
                            />
                            {errors.speaking && <span className="text-red-600">{errors.speaking}</span>}
                          </div>
                        </div>
                      )}
                      {formValues.speaking !== "" &&
                        formValues.listening !== "" &&
                        formValues.reading !== "" &&
                        formValues.writing !== "" && (
                          <div className="overall-score mx-2">
                            <p>
                              Overall Score -{" "}
                              {(
                                Math.round(
                                  ((parseFloat(formValues.speaking) +
                                    parseFloat(formValues.listening) +
                                    parseFloat(formValues.reading) +
                                    parseFloat(formValues.writing)) /
                                    4) *
                                  2
                                ) / 2
                              ).toFixed(1)}
                            </p>
                          </div>
                        )
                      }

                      <div className="m-2 justify-end flex mt-4">
                        <ButtonPrimary title={"Search"} onclick={() => { searchNow(formValues) }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default AgentFindProgram;
