import axios from "axios";
import { useEffect, useState } from "react";
import { getToken } from "../../../helper/auth";
import { useParams } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { AiFillSetting } from "react-icons/ai";
import loader from "../../../images/loader.gif";
import { BsArrowLeftCircle } from "react-icons/bs";
import moment from "moment";
import {Navigate,useNavigate} from "react-router-dom";

const EnrolledHistory = () => {
  const { file_Id } = useParams();
  const navigate = useNavigate();
  const [isrotating, setisrotating] = useState(false);
  const [ModalCode, setModalCode] = useState(false);
  const [state, setState] = useState({
    isWaiting: false,
    adminToken: getToken("admin"),
    history: [],
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Role", value: "Role" },
    { label: "Status", value: "Status" },
    { label: "Date", value: "Date" },
  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsassessmentform")) || [
      "Sr.",
      "Role",
      "Status",
      "Date",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Role",
    "Status",
    "Date",
  ]);

  useEffect(() => {
    setSelectedColumnsTable(["Sr.", "Role", "Status", "Date"]);
  }, []);

  useEffect(() => {
    getPaginationData();
  }, []);

  const getPaginationData = () => {
    setState({
      ...state,
      isWaiting: true,
    });
    axios
      .get(process.env.REACT_APP_NODE_URL + `/v2/api/getHistory/${file_Id}`)
      .then((res) => {
        console.log("checkRes", res.data.response.history);
        setState({
          ...state,
          history: res.data.response.history,
          isWaiting: false,
        });

        setisrotating(false);
      })
      .catch((err) => { });
  };

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns;
    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem(
      "selectedColumnsassessmentform",
      JSON.stringify(updatedColumns)
    );
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    const storedColumns = JSON.parse(
      localStorage.getItem("selectedColumnsassessmentform")
    );

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable(["Sr.", "Role", "Status", "Date"]);
      setTempColumnsTable(["Sr.", "Role", "Status", "Date"]);
    }
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsassessmentform");
    setSelectedColumnsTable(["Sr.", "Role", "Status", "Date"]);
    setTempColumnsTable(["Sr.", "Role", "Status", "Date"]);
  };

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];
    localStorage.setItem(
      "selectedColumnsassessmentform",
      JSON.stringify(uniqueSelectedColumns)
    );
    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  const startLoader = () => {
    setisrotating(true);
    getPaginationData();
  };

  const formatDateTime = (dateTimeString) => {
    const formattedDate = moment(dateTimeString).format("YYYY-MMM-DD");
    const formattedTime = moment(dateTimeString).format("HH:mm:ss");

    return (
      <div className="flex flex-col">
        <span className="text-sm">{formattedDate}</span>
        <span className="text-xs text-gray-600">{formattedTime}</span>
      </div>
    );
  };

  const handleBack = () => {
    navigate("/admin/enrolled-files")
  }

  return (
    <>
      <div heading_title={"Total history"}>
        <>
          <div className="row min-height-vh-100">
            <div className="row mt-10 p-45 mob-padding">
              <div className="col-12">
                <div className="card mb-4 mt-4">
                  <div className="flex my-3">
                    <div className="w-[500px] flex justify-start items-center">
                      <span><BsArrowLeftCircle style={{ width: "40px", height: "20px",cursor:"pointer" }} onClick={handleBack} /></span>
                      <h1 className="text-xl">{file_Id} File History</h1>
                    </div>
                    <div className="flex justify-end w-full items-center">
                      <div className="reload-icon mr-2">
                        <i
                          className={`fas fa-sync ${isrotating ? "rotate" : ""
                            }`}
                          onClick={startLoader}
                        />
                      </div>
                      <AiFillSetting
                        className="Bar_filter"
                        onClick={() => setModalCode(!ModalCode)}
                      />
                    </div>
                  </div>
                  <div className="btn_outerxx mb-3 mt-5">
                    {ModalCode ? (
                      <div className="modal_cover">
                        <div className="modal_inner select-col-popup">
                          <div className="header_modal">
                            <h1 className="capitalize font-bold text-lg mb-2">
                              select columns
                            </h1>
                            <p className="border-b pb-2 mb-2">
                              Select the column you want to display or hide.
                            </p>
                            <div
                              className="columns-popup"
                              style={{ justifyContent: "space-between" }}
                            >
                              {tableColumns.map((column, index) => {
                                if (
                                  selectedColumnsTable.includes(column.value)
                                ) {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        checked={tempColumnsTable.includes(
                                          column.value
                                        )}
                                        onChange={() =>
                                          handleColumnCheckboxChange(
                                            column.value
                                          )
                                        }
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                } else {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        onClick={() => {
                                          selectedColumnsTable.push(
                                            column.value
                                          );
                                          setTempColumnsTable(
                                            selectedColumnsTable
                                          );
                                        }}
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                }
                              })}
                            </div>
                            <div className="button-part flex justify-end items-center gap-4">
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleResetAll}
                              >
                                Reset all
                              </button>
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleOk}
                              >
                                Ok
                              </button>
                            </div>
                            <GrClose
                              className="close"
                              onClick={() => setModalCode(false)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-body px-0 pt-0 pb-2 table-border">
                    <div className="overflow-auto card col-12 px-0 pt-0 pb-2 table_postioning">
                      {state.isWaiting ? (
                        <center className="w-full my-10">
                          <img src={loader} alt="" />
                        </center>
                      ) : state?.history?.length > 0 ? (
                        <table className="w-full table-auto bg-white border border-gray-300 shadow-lg">
                          <thead>
                            <tr>
                              {tableColumns.map((col) => {
                                if (selectedColumnsTable.includes(col.label)) {
                                  return (
                                    <th className="px-4 py-2 text-left border-b border-gray-300">
                                      {col.label}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {state.history?.map((history, index) => {
                              return (
                                <tr key={index}>
                                  {selectedColumnsTable.includes("Sr.") && (
                                    <td className="px-4 py-2 border-b border-gray-300">
                                      {index + 1}
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Role") && (
                                    <td className="px-4 py-2 border-b border-gray-300">
                                      {history.userRole}
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Status") && (
                                    <td className="px-4 py-2 border-b border-gray-300">
                                      {history.comment
                                        ? history.comment
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")
                                        : "--"}
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Date") && (
                                    <td className="px-4 py-2 border-b border-gray-300">
                                      {formatDateTime(history.createdAt)}
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <div>
                            <p className="text-center p-5 text-red-500">
                              No history available.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default EnrolledHistory;
