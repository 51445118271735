/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import loader from "../../../images/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrClose } from "react-icons/gr";
import Select from 'react-select';
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { FaEye } from "react-icons/fa";
import ViewOlDocuments from "./ViewOlDocuments";
const { MultiSelect } = require("react-multi-select-component");

const AddRequiredDocuments = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [isrotating, setisrotating] = useState(false);
  const [countrySelected, setCountrySelected] = useState([]);
  const [selectedEducationType, setselectedEducationType] = useState([]);
  const [showViewOlPopup, setshowViewOlPopup] = useState(false);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedEducationTypes, setSelectedEducationTypes] = useState("");
  const [updateIndex, setUpdate] = useState(-1);
  const [state, setState] = useState({
    countryList: [],
    list: [],
    showPopup: false,
    isWait: true,
  });

  const fetchCountryList = async () => {
    setState({
      ...state,
      isWait: true,
    });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/admin/getUniqueCountries`
      );
      setState({
        ...state,
        countryList: response.data.details.countries,
        isWait: false,
      });
      setisrotating(false)
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const updateData = (data) => {
    setIsAdding(true);
    axios
      .post(process.env.REACT_APP_NODE_URL + "/docsrequired/addNewRequiredDocuments", data)
      .then((response) => {
        if (response.data.status == "1") {
          toast.success(response.data.message)
          document.getElementById("isRequired").value = "";
          document.getElementById("newDoc").value = "";
          setselectedEducationType([])
          setCountrySelected([]);
          setState({
            ...state,
            showPopup: false,
            isWait: false,
          });
          setIsAdding(false);
          axios
            .get(process.env.REACT_APP_NODE_URL + "/admin/getUniqueCountries")
            .then(async (response) => {
              setState({
                ...state,
                showPopup: false,
                isWait: false,
                countryList: response.data.details.countries,
              });
            });
        } else {
          toast.error(response.data.message)
          setIsAdding(false);
        }
      });
  };

  const EducationTypes = [
    {
      value: "secondary",
      label: "Grade 12/High School"
    },
    {
      value: "certificate",
      label: "1-Year Post-Secondary Certificate",
    },
    {
      value: "diploma",
      label: "2-Year Undergraduate Diploma"
    },
    {
      value: "advance_diploma",
      label: "3-Year Undergraduate Advanced Diploma",
    },
    {
      value: "3_year_bachlor",
      label: "3-Year Bachelor's Degree",
    },
    {
      value: "4_year_bachlor",
      label: "4-Year Bachelor's Degree",
    },
    {
      value: "postgraduate_diploma",
      label: "Postgraduate Certificate/Diploma",
    },
    {
      value: "master",
      label: "Master's Degree"
    },
    {
      value: "doctrate",
      label: "Doctoral Degree (PhD, M.D., ...)",
    }
  ]

  const handleViewClick = (country, selectedEducationType, rowIndex) => {
    setSelectedEducationTypes((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[rowIndex] = selectedEducationType;
      return newSelected;
    });
    if (!selectedEducationType || selectedEducationType.length === 0) {
      toast.error("Please select Education Type before viewing OL Documents");
      return;
    }

    setselectedCountry(country);
    setshowViewOlPopup(true);
  };


  const closeViewPopup = () => {
    setshowViewOlPopup(false);
  };
  const startLoader = () => {
    setisrotating(true);
    fetchCountryList();
  };

  return (
    <>
      <ToastContainer />
      <div heading_title={"Add college Detail"}>
        <>
          <div className="row addCountryPage flex flex-row">
            {/* view ol documents popup */}
            <div
              className={`w-5/12 mx-auto my-4 createcollegeNamePopup ${showViewOlPopup ? "active" : ""
                } add_college_popup`}
            >
              {showViewOlPopup && (
                <>
                  <ViewOlDocuments
                    closeViewPopup={closeViewPopup}
                    startLoader={startLoader}
                    selectedCountry={selectedCountry}
                    educationtype={selectedEducationTypes}
                  />
                  <GrClose
                    style={{ top: "-10px", right: "-10px" }}
                    className="close"
                    onClick={closeViewPopup}
                  />
                </>
              )}
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setState({
                  ...state,
                });
              }}
            >
              <div
                className={`px-[20px] w-5/12 mx-auto my-4 createcollegeNamePopup documents add_college_popup new_pop ${state.showPopup && "active"
                  }`}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="" className="text-xl font-bold">
                    Add Document
                  </label>
                  <GrClose
                    onClick={() => {
                      setState({
                        ...state,
                        showPopup: false,

                      });
                    }}
                    className="close"
                  />
                </div>
                <ul>
                  <div className="flex flex-col justify-between my-2 relative ">
                    <div>
                      <label>Document Name</label>
                      <input
                        type="text"
                        className=" w-full outline-[#5fa4ff] outline-1 p-2 w-full mt-1 mb-3 rounded-sm"
                        id="newDoc"
                        placeholder="Document Name"
                      />
                    </div>
                    <div className="mt-3">
                      <label>Country</label>
                      <MultiSelect
                        options={state.countryList.map((country) => {
                          return {
                            label: country.countryName,
                            value: country.countryName,
                          };
                        })}
                        value={countrySelected}
                        onChange={setCountrySelected}
                        labelledBy="Country"
                        className="mt-1 mb-3"
                      />
                    </div>
                    <div className="mt-3">
                      <label>Education Type</label>
                      <MultiSelect
                        className="w-full"
                        value={selectedEducationType}
                        onChange={setselectedEducationType}
                        labelledBy="education_type"
                        options={EducationTypes.map((names) => {
                          return {
                            label: names.label,
                            value: names.value,
                          };
                        })}
                      />
                    </div>
                    <div className="mt-3">
                      <label>Status</label>
                      <select
                        name=""
                        id="isRequired"
                        className="block w-full flex-1 border-gray-300 focus:border-black border-2
                       border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm my-3"
                      >
                        <option value="T">Select</option>
                        <option value="T">Required</option>
                        <option value="F">Optionally</option>
                      </select>
                    </div>
                    <div className="flex mt-3">
                      <ButtonPrimary
                        title={isAdding ? "Adding..." : "Add"}
                        onclick={() => {
                          if (!isAdding) {
                            let countriesList = countrySelected.map((i) => i.value);
                            let value = document.getElementById("newDoc").value;
                            let isRequired = document.getElementById("isRequired").value;
                            let education_type = selectedEducationType.map((i) => i.value);

                            if (value === "") {
                              toast.error("Document Name is Required");
                              return;
                            }

                            if (education_type.length === 0) {
                              toast.error("Education Type is Required");
                              return;
                            }

                            if (countriesList.length === 0) {
                              toast.error("Choose At least one country");
                              return;
                            }

                            let data = {
                              countriesList,
                              education_type,
                              title: value,
                              isRequired,
                            };

                            updateData(data);
                            let oldDocs = state.countryList;
                            if (oldDocs[updateIndex] && oldDocs[updateIndex].documents) {
                              oldDocs[updateIndex].documents.push(value);
                              setState({ ...state, countryList: oldDocs });
                            } else {
                              document.getElementById("newDoc").value = "";
                            }
                          }
                        }}
                        disabled={isAdding}
                      />
                    </div>

                  </div>
                </ul>
              </div>
            </OutsideClickHandler>
            <div className="mx-auto w-full my-4 p-45 mob-padding">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="left">
                    <div
                      className="college-list-btn flex items-center gap-4 justify-end"
                      style={{ marginTop: "15px" }}
                    >
                      <div className=" flex items-center "></div>
                      <button
                        className="addNewBtn hover:bg-[#065f46]"
                        onClick={() => {
                          setState({
                            ...state,
                            showPopup: true,
                          });
                        }}
                      >
                        Add New
                      </button>
                      <div className="reload-icon mr-2">
                        <i
                          className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                          onClick={startLoader}
                        />
                      </div>
                    </div>
                  </div>
                  {state.isWait ? (
                    <div>
                      <center className="w-full my-10">
                        <img src={loader} alt="" />
                      </center>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="overflow-x-auto">
                        <div className="py-2 align-middle inline-block min-w-full">
                          <div className="overflow-hidden border table-overflow" style={{ minHeight: '100vh' }}>
                            <table className="min-w-full agent-table">
                              <thead>
                                <tr>
                                  <th className="text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">Sr.</th>
                                  <th className="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">Country Name</th>
                                  <th className="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">Education Type</th>
                                  <th className="text-right capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">OL Documents</th>
                                </tr>
                              </thead>
                              <tbody>
                                {state.countryList?.map((country, index) => (
                                  <tr key={index} className="border-b">
                                    <td className="p-2 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                    <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                      {country.countryName || "--"}
                                    </td>
                                    <td className="p-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <Select
                                        className="w-[400px]"
                                        options={EducationTypes.map((names) => ({
                                          label: names.label,
                                          value: names.value,
                                        }))}
                                        onChange={(selectedOption) => {
                                          setSelectedEducationTypes(selectedOption ? selectedOption.value : null);
                                        }}
                                      />
                                    </td>
                                    <td className="text-right text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                      <div className="cursor-pointer flex justify-end pr-5">
                                        <FaEye
                                          onClick={() =>
                                            handleViewClick(country, selectedEducationTypes[index], index)
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default AddRequiredDocuments;
