import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nz from "../images/new_zln.png";
import loader from "../../../images/loader.gif";
export default function Countryinfo({ id }) {
  const [data, setData] = useState();
  const [dataTwo, setDataTwo] = useState();

  const [wait, setWait] = useState(true);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_NODE_URL + `/student/specifiCountry/${id}`)
      .then((response) => {
        setData(response.data.details);
        console.log(response.data.details)
        // console.log("checkingBackgroundflag",response.data.details)
        setWait(false);
        // console.log(response.data.details.collegesList.map(college => college.countryLogo));
        setDataTwo(
          response.data.details.collegesList.map(
            (college) => college.countryLogo
          )
        );
      });
  }, [id]);

  if (wait) {
    return (
      <>
        <div className="pt-[150px]">
          <center className="w-full my-10">
            <img src={loader} alt="" />
          </center>
        </div>
      </>
    );
  }

  let baseUrl = `${process.env.REACT_APP_NODE_URL}/uploads/agent/`;
  return (
    <>
      <div
        className="country-banr py-0"
        style={{ backgroundImage: `url${baseUrl + dataTwo})` }}
      >
        <div class="container mx-auto">
          <h1 className="text-center text-white text-6xl">
            {data.countryDetailsdata}
          </h1>
        </div>
      </div>
      <div className="main-contry-upr">
        <div className="container mx-auto px-4 lg:px-0 py-16">
          <div className="country-main-content">
            <h1
              className="text-center font-bold mb-8 text-3xl"
            >
              Information of {data.countryDetailsdata}
            </h1>
            <div class="lg:w-full">
              <div className="upr-img">
                <div className="detail-part">
                  {data.collegesList.map((el) => {
                    return (
                      <div className="inner-detail-part pt-3">
                        <div className="cs-info">
                          <img alt="image" src={baseUrl + el.collegeLogo} />
                        </div>
                        <div>
                          <h6 className="text-xl">
                            {el.college_name
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")}
                          </h6>
                          <Link
                            className="flex items-center custom-de-page"
                            to={"/specificcollege/" + el.college_name}
                          >
                            View Detail
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
