/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import WEligible from "../../website/Pages/WEligible";
import Select from "react-select";
import loader from "../../../images/loader.gif";

// firebase
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const provider = new GoogleAuthProvider();

const StudentEnrolled = (props) => {
  const [isrotating, setisrotating] = useState(false);
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryName, setCountryName] = useState([]);
  const [collegeName, setCollegeName] = useState([]);
  const [selectCollegeName, setSelectCollegeName] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [showCount, setShowCount] = useState(10);
  const [showModal, setShowModal] = useState(false);

  const handleApplyClick = () => {
    setShowModal(true);
  };
  const [state, setState] = useState({
    isWaiting: true,
    enrolledPrograms: [],
    student: "",
    baseUrl: "",
    baseUrlStudent: "",
    pendingDocuments: true,

    // new
    documents: [],
    isDocsRequiredPopup: false,
    isDocsRequired: false,
    underVerification: false,
    disabled: false,
    paymentLoading: false,

    popup: false,
    activeFileIndex: -1,
    swiftFile: null,
  });

  const fetchCountryNames = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`
      );
      const countries = response.data.details;
      // console.log("countrycode", countries);
      const countryData = countries.map((item) => ({
        label: item.countryName,
        value: item.countryId,
      }));
      // console.log(countryData);
      setCountryName(countryData);
    } catch (error) {
      console.error(error);
    }
  };

  const getPaginationData = (page, selectedCountry, selectCollegeName) => {
    setState({
      ...state,
      isWaiting: true,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getToken("student")}`,
      },
    };
    const country =
      selectedCountry && selectedCountry.map((country) => country.value);
    const college =
      selectCollegeName && selectCollegeName.map((college) => college.value);
    let data;
    if (searchName === "undefined") {
      data = {
        currentPage: page,
      };
    } else {
      data = {
        currentPage: page,
      };
    }
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/student/getEnrollPrograms",
        {
          currentPage: page,
          country: country,
          collegeName: college,
        },
        config
      )
      .then((res) => {
        console.log(res);
        setState({
          ...state,
          enrolledPrograms: res.data.details.enrolled_list,
          student: res.data.details.student,
          baseUrl: res.data.details.baseUrl,
          baseUrlStudent: res.data.details.baseUrlStudent,
          isWaiting: false,
          documents: res.data.details.documents,
          isDocsRequired: res.data.details.isDocsRequired,
          isDocsRequiredPopup: res.data.details.isDocsRequired,
          underVerification: res.data.details.underVerification,
        });
        const college = res.data.details.enrolled_list.map(
          (college) => college.college_details?.college_name
        );
        console.log(college);
        setCollegeName(college);
        setisrotating(false);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getPaginationData(1);
    fetchCountryNames();
  }, []);

  const handleSearch = () => {
    getPaginationData(state.currentPage, selectedCountry, selectCollegeName);
  };

  const startLoader = async () => {
    setisrotating(true);
    getPaginationData();
    setSelectCollegeName([]);
    setSelectedCountry([]);
  };

  return (
    <>
      <ToastContainer />
      {false ? (
        <>
          <div
            className="overlay active"
            onClick={() =>
              setState({
                ...state,
                isDocsRequiredPopup: false,
                student: {
                  ...state.student,
                  status: "IN_PROCESS_TEMP",
                },
              })
            }
          ></div>
          <div className="pendingDoctoast popup active">
            <div class="pending-documents">
              <i class="fas fa-exclamation-circle"></i>
              <h2>Pending Documents</h2>
              <p>
                Please Uploads required documents to approve your application.
              </p>
              <button
                onClick={() => {
                  setState({
                    ...state,
                    isPendingProgramPopupShow: false,
                  });
                  navigate("/student/documents");
                }}
              >
                View Required Documents
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {state.isWaiting ? (
          <div>
            <center className="w-full my-10">
              <img src={loader} alt="" />
            </center>
          </div>
        )
        :
        (
          <>
          {state.enrolledPrograms.length != 0 && (
        <>
          <div className="row min-h-screen p-45">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-auto card m-4 col-12 agent-table border student-table table-overflow">
                  {/* {!state.isWaiting &&} */}
                  {state.isWaiting ? (
                    <div>
                      <center className="w-full my-10">
                        <img src={loader} alt="" />
                      </center>
                    </div>
                  ) : (
                    <>
                      <div className="reload-icon mb-3  mr-2 text-right">
                        <i
                          className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                          onClick={startLoader}
                        />
                      </div>
                      <table className="table-auto overflow-scroll w-full files-table enrolled-list-table">
                        <thead>
                          <tr className="">
                            <th className="p-4 text-left">Sr. No.</th>
                            <th className="p-4 text-left">Created Date</th>
                            <th className="p-4 text-left">File Id</th>
                            <th className="p-4 text-left">Country</th>
                            <th className="p-4 text-left">College</th>
                            <th className="p-4 text-left">Student Name</th>
                            <th className="p-4 text-left">Program</th>
                            <th className="p-4 text-left">Processing Fees</th>
                            <th className="p-4 text-left">Current Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.isWait ? (
                            <></>
                          ) : (
                            state.enrolledPrograms
                              .slice(0, showCount)
                              .map((enroll, index) => {
                                return (
                                  <tr key={enroll.fileId}>
                                    <td className="p-4 text-left capitalize">
                                      <p className="text-xs font-weight-bold mb-0">
                                        {index + 1}
                                      </p>
                                    </td>
                                    <td className="p-4 text-left capitalize">
                                      <p className="text-xs font-weight-bold mb-0">
                                        {new Date(enroll.updatedAt).toLocaleDateString('en-GB')}
                                      </p>
                                    </td>
                                    <td className="p-4 text-blue-500 hover:text-blue-700 rounded-lg transition-colors duration-300">
                                      <div
                                        onClick={() => {
                                          navigate(
                                            `/student/remarks/${enroll._id}/${enroll.fileId}`
                                          );
                                        }}
                                        className="flex cursor-pointer items-center"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 mr-1 text-blue-300"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M13 4V2H7v2H2v14h16V4h-5zM7 2h6v2H7V2zm7 14H6V8h8v8zm-2-6h2v2h-2v-2zm-3 0h2v2H9v-2zm-3 0h2v2H6v-2zm0-3h8v1H6V7z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                        <div className="overflow-hidden overflow-ellipsis text-blue mt-1">
                                          {enroll.fileId}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-4 border-2 capitalize">
                                      <div className="small-image-container">
                                      <div className="flex items-center">
                                        <img
                                          width={"100px"}
                                          src={
                                            state.baseUrl +
                                            enroll.college_details.college_meta_details
                                              .countryLogo
                                          }
                                          alt="logo"
                                          className="me-2"
                                        />
                                        {enroll.college_details.country}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-4 border-2 capitalize">
                                      <div className="small-image-container">
                                        <div className="flex items-center">
                                          <img
                                            width={"100px"}
                                            src={
                                              state.baseUrl +
                                              enroll.college_details.college_meta_details
                                                .collegeLogo
                                            }
                                            alt="logo"
                                            className="me-2"
                                          />
                                          {enroll.college_details.college_name}
                                      </div>
                                        </div>
                                    </td>
                                    <td className="p-4 text-left capitalize">
                                      <p className="text-xs font-weight-bold mb-0">
                                        {state.student.firstName} {state.student.lastName}
                                      </p>
                                    </td>
                                    <td className="p-4 border-2">
                                      {enroll.college_details?.collegeprograms
                                        ?.program_name || "--"}
                                    </td>
                                    <td className="p-4 border-2 capitalize">
                                      {enroll.college_details?.collegeprograms
                                        ?.application_fee === 0 ? (
                                        <h1 className="text-orange-600 font-bold">
                                          Free
                                        </h1>
                                      ) : (
                                        <>
                                          {
                                            enroll.college_details?.collegeprograms
                                              ?.application_fee
                                          }{" "}
                                          {
                                            enroll.college_details?.collegeprograms
                                              ?.currency
                                          }
                                          {enroll.enroll_status ===
                                            "OL_FEES_AND_DOCUMENTS_PENDING" ||
                                            enroll.enroll_status ===
                                            "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION" ||
                                            enroll.enroll_status ===
                                            "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED" ||
                                            enroll.enroll_status ===
                                            "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED" ? (
                                            <button
                                              onClick={() => {
                                                navigate(
                                                  `/student/remarks/${enroll._id}/${enroll.fileId}`
                                                );
                                              }}
                                              className="bg-blue-500 hover:bg-blue-300 text-white font-bold py-1 px-2 rounded ml-1"
                                            >
                                              Pay
                                            </button>
                                          ) : (
                                            <h1 className="text-green-600 font-bold">
                                              Paid
                                            </h1>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td className="p-4 border-2">
                                      {enroll.enroll_status
                                        ? enroll.enroll_status
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")
                                        : "--"}
                                      {enroll.optionaldocStatus === true ||
                                        enroll.enroll_status === "" ||
                                        enroll.enroll_status === "OL_FEES_AND_DOCUMENTS_PENDING" ||
                                        enroll.enroll_status === "OL_FEES_PAID_AND_DOCUMENTS_PENDING" ||
                                        enroll.enroll_status === "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION" ||
                                        enroll.enroll_status === "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ||
                                        enroll.enroll_status === "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED" ||
                                        enroll.enroll_status === "OL_FEES_PAID_AND_DOCUMENTS_REJECTED" ? (
                                        <button className="bg-blue-500 hover:bg-blue-300 text-white 
                                          font-bold py-1 px-2 rounded"
                                          onClick={() => {
                                            navigate(
                                              `/student/remarks/${enroll._id}/${enroll.fileId}`
                                            );
                                          }}>Documents</button>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })
                          )}
                          {showCount?.length > 0 && (
                            <tr>
                              <td colSpan="9" className="p-4 border-2 text-center">
                                <div
                                  style={{ display: "flex", justifyContent: "center" }}
                                >
                                  {showCount < state.enrolledPrograms.length && (
                                    <button
                                      className="show-btn rounded-lg"
                                      style={{
                                        background: "rgb(51, 51, 51)",
                                        padding: "15px 40px",
                                        cursor: "pointer",
                                        marginRight: "10px",
                                        color: "rgb(255, 255, 255)",
                                      }}
                                      onClick={() => setShowCount(showCount + 10)}
                                    >
                                      Show More
                                    </button>
                                  )}
                                  {showCount > 10 && (
                                    <button
                                      className="show-btn rounded-lg"
                                      style={{
                                        background: "rgb(51, 51, 51)",
                                        padding: "15px 40px",
                                        cursor: "pointer",
                                        marginRight: "10px",
                                        color: "rgb(255, 255, 255)",
                                      }}
                                      onClick={() => setShowCount(10)}
                                    >
                                      Show Less
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {state.enrolledPrograms.length == 0 && !state.isWaiting && (
        <>
          <div className="flex flex-col justify-center items-center p-[20px]">
            <h1 className="font-black">
              You haven't enrolled in any program yet.
            </h1>
            <button
              className="button-33 mt-3"
              role="button"
              onClick={handleApplyClick}
            >
              Apply Here
            </button>
            {showModal && <WEligible />}
          </div>
        </>
      )}
          </>
        )
      }

      
    </>
  );
};

export default StudentEnrolled;
