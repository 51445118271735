import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import StudentPayment from "../../student/Pages/StudentPayment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../../images/loader.gif";
import AgentStudentDocuments from "./AgentStudentDocuments";
import moment from "moment";
import StudentEmbassyDocuments from "../../student/Pages/StudentEmbassyDocuments";
import { button } from "@material-tailwind/react";

const AgentStudentRemarks = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showEmbassyPop, setShowEmbassyPop] = useState(false);
  const [isPayLoading, setIsPayLoading] = useState(false);
  const [studentId, setstudentId] = useState("");
  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [swiftLoading, setSwiftLoading] = useState(false);

  const [state, setState] = useState({
    baseUrl: `${process.env.REACT_APP_NODE_URL}/uploads/agent/`,
    isWaiting: true,
    history: [],
    fileDetails: null,
    fileStatus: "VISA_REFUSED",
    baseUrl: "",
    popup: false,
    swiftFile: "",
  });

  const params = useParams();
  const fileId = params.fileId;
  const mainFileId = params.id;
  const getstudentremarks = () => {
    // get enrolled list data from api using axios
    const config = {
      headers: { Authorization: `Bearer ${getToken("agent")}` },
    };
    let body = {
      fileId,
    };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/student/getRemarks",
        body,
        config
      )
      .then((res) => {
        setstudentId(res.data.details.fileDetails.student_id)
        setState({
          ...state,
          baseUrl: res.data.details.baseUrl,
          fileDetails: res.data.details.fileDetails,
          fileStatus: res.data.details.fileDetails.enroll_status,
          history: res.data.details.history,
          isWaiting: false,
          // baseUrl: res.data.details.baseUrl
        });
      })
      .catch((err) => { });
  };
  useEffect(() => {
    getstudentremarks();
  }, []);

  const handleFile = (e) => {
    setState({
      ...state,
      swiftFile: e.target.files[0],
    });
  };

  const uploadSwiftFile = () => {
    if (!state?.fileDetails?._id) {
      toast("Undefined Enrolled File");
      return;
    }
    setSwiftLoading(true);

    let enrollId = state?.fileDetails?._id;
    const formData = new FormData();
    formData.append("fileId", enrollId);
    formData.append("UploadTutionFeesReceipt", state.swiftFile);

    const config = {
      headers: {
        Authorization: `Bearer ${getToken("student")}`,
      },
    };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/student/uploadTutionFeesReceipt",
        formData,
        config
      )
      .then((res) => {
        setSwiftLoading(false);
        if (res.data.status == "0") {
          toast.error(res.data.message);
          console.log("file res", res);
        } else {
          toast(res.data.message);
          let oldFile = state.fileDetails;
          oldFile.enroll_status = res?.data?.details?.file.enroll_status;
          oldFile.tution_fees_recepit =
            res?.data?.details?.file.tution_fees_recepit;
          document.getElementById("swift-file").value = "";
          setState({
            ...state,
            popup: false,
            fileDetails: oldFile,
            fileStatus: res?.data?.details?.file.enroll_status,
            swiftFile: null,
          });
        }
      })
      .catch((err) => {
        setSwiftLoading(false);
      });
  };

  const getTrackClass = (status) => {
    if (status == "PENDING") {
      if (
        [
          "OL_FEES_AND_DOCUMENTS_PENDING",
          "FEES_PENDING",
          // NEW
          "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
          "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
          "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
        ].includes(state.fileStatus)
      ) {
        return "a active current";
      } else if (
        [
          "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
          "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
          "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
          "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
          "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS",
          "TUTION_FEES_PAID",
          "OL_APPLIED",
          "OL_RECEIVED",
          "OL_REJECTED",
          "TUTION_FEES_PROCESSING",
          "TUTION_FEES_REJECTED",
          "TUTION_FEES_PROCESSING",
          "FILE_LODGED",
          "FILE_LODGED_DOCS_PROCESSING",
          "FILE_LODGED_DOCS_REJECTED",
          "VISA_PROCESSING",
          "REGISTER_AND_TRAVEL",
          "DEFER_REQUESTED",
          "DEFER_INITIATED",
          "REFUND_REQUESTED",
          "REFUND_INITIATED",
          "VISA_AWAITED",
          "VISA_APPROVED",
          "VISA_REFUSED",
          "CLOSED",
          "CUSTOM",
        ].includes(state.fileStatus)
      ) {
        return "a active";
      }
    }

    if (status == "DOCUMENTS") {
      if (
        [
          "OL_FEES_AND_DOCUMENTS_PENDING",
          "FEES_PENDING",
          "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
          "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
          "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
          // NEW
          "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
          "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
          "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
        ].includes(state.fileStatus)
      ) {
        if (
          [
            "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
            "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
          ].includes(state.fileStatus)
        ) {
          return "a active current stop danger";
        }
        return "a active current";
      }

      if (
        [
          "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
          "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
          "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS",
          "OL_APPLIED",
          "TUTION_FEES_PAID",
          "OL_REJECTED",
          "OL_RECEIVED",
          "TUTION_FEES_PROCESSING",
          "TUTION_FEES_REJECTED",
          "FILE_LODGED",
          "FILE_LODGED_DOCS_PROCESSING",
          "FILE_LODGED_DOCS_REJECTED",
          "VISA_PROCESSING",
          "REGISTER_AND_TRAVEL",
          "DEFER_REQUESTED",
          "DEFER_INITIATED",
          "REFUND_REQUESTED",
          "REFUND_INITIATED",
          "VISA_AWAITED",
          "VISA_APPROVED",
          "VISA_REFUSED",
          "CLOSED",
          "CUSTOM",
        ].includes(state.fileStatus)
      ) {
        return "a active";
      }
    }

    if (status == "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS") {
      if (
        ["OL_APPLIED", "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS", "OL_REJECTED",
          "TUTION_FEES_PAID",
        ].includes(
          state.fileStatus
        )
      ) {
        if (state.fileStatus == "OL_REJECTED") {
          return "a active current stop danger";
        } else {
          return "a active current";
        }
      }

      if (
        [
          "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
          "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
          "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
          "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
          "OL_FEES_AND_DOCUMENTS_PENDING",
          "FEES_PENDING",
          "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
          "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
          "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
        ].includes(state.fileStatus)
      ) {
        return "a";
      } else {
        return "a active";
      }
    }

    // if (status == "TUTION_FEES") {
    //   if (
    //     [
    //       "OL_RECEIVED",
    //       "TUTION_FEES_PROCESSING",
    //       "TUTION_FEES_REJECTED",
    //     ].includes(state.fileStatus)
    //   ) {
    //     if (state.fileStatus == "TUTION_FEES_REJECTED") {
    //       return "a active current stop danger";
    //     }
    //     return "a active current";
    //   }
    //   if (
    //     [
    //       "OL_FEES_AND_DOCUMENTS_PENDING",
    //       "FEES_PENDING",
    //       "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
    //       "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
    //       "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
    //       "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
    //       "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
    //       "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
    //       "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS",
    //       "OL_APPLIED",
    //       "OL_REJECTED",
    //     ].includes(state.fileStatus)
    //   ) {
    //     return "a";
    //   } else {
    //     return "a active";
    //   }
    // }

    if (status == "FILE_LODGED") {
      if (
        [
          "FILE_LODGED",
          "FILE_LODGED_DOCS_PROCESSING",
          "FILE_LODGED_DOCS_REJECTED",
          "VISA_PROCESSING",
          "REGISTER_AND_TRAVEL",
          "DEFER_REQUESTED",
          "DEFER_INITIATED",
          "REFUND_REQUESTED",
          "REFUND_INITIATED",
          "VISA_AWAITED",
          "VISA_APPROVED",
          "VISA_REFUSED",
          "CLOSED",
          "CUSTOM",
        ].includes(state.fileStatus)
      ) {
        return "a active";
      } else {
        return "a";
      }
    }

    // if (status == "EMBASSY_DOCUMENTS") {
    //   if (
    //     [
    //       "FILE_LODGED",
    //       "FILE_LODGED_DOCS_PROCESSING",
    //       "FILE_LODGED_DOCS_REJECTED",
    //     ].includes(state.fileStatus)
    //   ) {
    //     if (state.fileStatus == "FILE_LODGED_DOCS_REJECTED") {
    //       return "a active current stop danger";
    //     }
    //     return "a active current";
    //   }
    //   if (
    //     [
    //       "VISA_PROCESSING",
    // "REGISTER_AND_TRAVEL",
    //   "DEFER_REQUESTED",
    //   "DEFER_INITIATED",
    //   "REFUND_REQUESTED",
    //   "REFUND_INITIATED",
    //   "VISA_AWAITED",
    //       "VISA_AWAITED",
    //       "VISA_APPROVED",
    //       "VISA_REFUSED",
    //       "CLOSED",
    //       "CUSTOM",
    //     ].includes(state.fileStatus)
    //   ) {
    //     return "a active";
    //   } else {
    //     return "a";
    //   }
    // }

    if (status == "VISA") {
      if (
        [
          "VISA_PROCESSING",
          "REGISTER_AND_TRAVEL",
          "DEFER_REQUESTED",
          "DEFER_INITIATED",
          "REFUND_REQUESTED",
          "REFUND_INITIATED",
          "VISA_AWAITED",
          "VISA_APPROVED",
          "VISA_REFUSED",
        ].includes(state.fileStatus)
      ) {
        if (state.fileStatus == "VISA_APPROVED") {
          return "a active current stop success";
        }
        if (state.fileStatus == "VISA_REFUSED") {
          return "a active current stop danger";
        }

        return "a active current";
      }
      if (
        [
          "VISA_PROCESSING",
          "REGISTER_AND_TRAVEL",
          "DEFER_REQUESTED",
          "DEFER_INITIATED",
          "REFUND_REQUESTED",
          "REFUND_INITIATED",
          "VISA_AWAITED",
          "VISA_APPROVED",
          "VISA_REFUSED",
          "CLOSED",
          "CUSTOM",
        ].includes(state.fileStatus)
      ) {
        return "a active";
      } else {
        return "a";
      }
    }
  };

  const getProgressLineClass = () => {
    let level = 0;
    if (["OL_FEES_AND_DOCUMENTS_PENDING", "FEES_PENDING"].includes(state.fileStatus)) {
      level = 1;
    }

    if (
      [
        "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
        "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
        "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
        "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
        "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
        "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
        "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
      ].includes(state.fileStatus)
    ) {
      level = 2;
    }

    if (
      [
        "OL_APPLIED",
        "TUTION_FEES_PAID",
        "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS",
        "OL_REJECTED",
        "OL_RECEIVED",
      ].includes(state.fileStatus)
    ) {
      level = 3;
    }

    if (["FILE_LODGED"].includes(state.fileStatus)) {
      level = 4;
    }

    if (
      [
        "VISA_PROCESSING",
        "REGISTER_AND_TRAVEL",
        "DEFER_REQUESTED",
        "DEFER_INITIATED",
        "REFUND_REQUESTED",
        "REFUND_INITIATED",
        "VISA_AWAITED",
        "VISA_APPROVED",
        "VISA_REFUSED",
      ].includes(state.fileStatus)
    ) {
      level = 5;
    }

    return `progress-line h-${level}`;
  };

  function handleClick() {
    setShowPopup(true);
    setShowEmbassyPop(false);
  }
  const AddIntake = () => {
    let selectedIntake = document.getElementById("selectedIntake").value;
    if (selectedIntake === "") {
      toast.error("Please select an intake");
      return;
    }
    const [selectedYear, selectedMonthIndex] = selectedIntake.split("-");
    setIsPayLoading(true);
    let body = {
      id: state.fileDetails._id,
      intake: {
        year: parseInt(selectedYear),
        month: parseInt(selectedMonthIndex),
      },
    };

    axios
      .post(process.env.REACT_APP_NODE_URL + "/v2/api/addIntake", body)
      .then((res) => {
        if (res.data.status === false) {
          toast.error(res.data.message);
          setIsPayLoading(false);
          getstudentremarks();
        } else {
          toast.success("Intake Added Successfully");
          setIsPayLoading(false);
          getstudentremarks();
        }
      })
      .catch((err) => { });
  };
  return (
    <>
      <ToastContainer />
      <div className="flex flex-col md:flex-row w-full">
        <div className="left w-full md:w-8/12">
          <div className="overflow-auto m-4 col-12 px-0 pt-0 pb-2 agent-table">
            <h1 className="text-lg font-bold text-gray-800 bg-gray-200 px-4 py-2 rounded-md mb-6">
              {mainFileId} File History
            </h1>
            <div className="table-overflow">
              <table className="card  table-auto overflow-scroll w-full files-table">
                <thead>
                  <tr>
                    <th
                      className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left"
                      scope="col"
                    >
                      Sr.
                    </th>
                    <th
                      className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left"
                      scope="col"
                    >
                      Description
                    </th>
                    <th
                      className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left"
                      scope="col"
                    >
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.isWaiting ? (
                    <></>
                  ) : (
                    state.history.map((history, index) => {
                      return (
                        <tr key={history._id}>
                          <td className="p-2 border-2">{index + 1}</td>
                          <td className="p-2 border-2">{history.content}</td>
                          <td className="p-2 border-b">
                            {moment(history.createdAt).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            {state.isWaiting ? (
              <center className="w-full my-10">
                <img src={loader} alt="" />
              </center>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="right w-full md:w-4/12 dfs">
          <div id="file-track" className="kjkn">
            <div className={getProgressLineClass()}></div>
            <div className="list">
              <li>
                <div className={getTrackClass("PENDING")}>
                  <span>FEES</span>
                  {[
                    "FEES_PENDING",
                    "OL_FEES_AND_DOCUMENTS_PENDING",
                    "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
                    "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
                    "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
                  ].includes(state.fileStatus) ? (
                    <span>Pending</span>
                  ) : (
                    <span>PAID</span>
                  )}
                  <div className="btns flex items-center">
                    {!state?.fileDetails ? (
                      "Loading..."
                    ) : ![
                      "FEES_PENDING",
                      "OL_FEES_AND_DOCUMENTS_PENDING",
                      "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
                      "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
                      "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
                    ].includes(state?.fileStatus) ? (
                      state.fileDetails.intake ? (
                        `Intake - ${monthsArr[state?.fileDetails?.intake?.month]
                        }, ${state?.fileDetails?.intake?.year}`
                      ) : (
                        <div className="App flex">
                          <select
                            name="intakes"
                            id={`selectedIntake`}
                            className="p-2 m-2 ml-0"
                          >
                            <option value="">Choose Intake</option>
                            {state?.fileDetails?.college_details?.collegeprograms?.intakes_data.map(
                              (intakes, IntakeIndex) => {
                                return intakes.months.map((month, monthIndex) => {
                                  const isClosed =
                                    state?.fileDetails?.college_details?.collegeprograms?.intakes_closed_data &&
                                    state?.fileDetails?.college_details?.collegeprograms?.intakes_closed_data[IntakeIndex]?.months[monthIndex];
                                  if (month) {
                                    const optionValue = `${intakes.year}-${monthIndex}`;
                                    const optionDate = new Date(intakes.year, monthIndex);
                                    return (
                                      <option
                                        key={IntakeIndex + "-" + monthIndex}
                                        value={optionValue}
                                        disabled={
                                          optionDate < new Date() ||
                                          intakes.year < new Date().getFullYear() ||
                                          (intakes.year === new Date().getFullYear() && monthIndex <= new Date().getMonth()) ||
                                          isClosed
                                        }
                                      >
                                        {intakes.year + " " + monthsArr[monthIndex]}
                                      </option>
                                    );
                                  }
                                  return null;
                                });
                              }
                            )}
                          </select>
                          <div className="m-2">
                            <ButtonPrimary
                              theme="tracker"
                              title={"Add"}
                              onclick={AddIntake}
                              loading={isPayLoading}
                            />
                          </div>
                        </div>
                      )
                    ) : (
                      <StudentPayment
                        enrollId={state?.fileDetails?._id}
                        state={state}
                        setState={setState}
                        getstudentremarks={getstudentremarks}
                      />
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div className={getTrackClass("DOCUMENTS")}>
                  <span>OL DOCUMENTS</span>
                  {[
                    "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
                    "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
                  ].includes(state.fileStatus) ? (
                    <span>Under Verification</span>
                  ) : [
                    "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
                    "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
                    "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS",
                    "OL_REJECTED",
                    "OL_RECEIVED",
                    "TUTION_FEES_PROCESSING",
                    "TUTION_FEES_REJECTED",
                    "FILE_LODGED",
                    "FILE_LODGED_DOCS_PROCESSING",
                    "FILE_LODGED_DOCS_REJECTED",
                    "VISA_PROCESSING",
                    "REGISTER_AND_TRAVEL",
                    "DEFER_REQUESTED",
                    "DEFER_INITIATED",
                    "REFUND_REQUESTED",
                    "REFUND_INITIATED",
                    "VISA_AWAITED",
                    "VISA_APPROVED",
                    "VISA_REFUSED",
                    "CLOSED",
                  ].includes(state.fileStatus) ? (
                    <span>Verified</span>
                  ) : [
                    "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
                    "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
                  ].includes(state.fileStatus) ? (
                    <span>Rejected</span>
                  ) : [
                    "OL_FEES_AND_DOCUMENTS_PENDING",
                    "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
                  ].includes(state.fileStatus) ? (
                    <span>Pending</span>
                  ) : (
                    ""
                  )}
                  <div className="btns flex items-center">
                    <button className="mr-2" onClick={handleClick}>
                      Documents
                    </button>
                    {showPopup && (
                      <AgentStudentDocuments
                        studentId={studentId}
                        onClose={() => setShowPopup(false)}
                      />
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div className={getTrackClass("COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS")}>
                  <span>OFFER LETTER</span>
                  {state.fileStatus == "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS" ? (
                    <span>College/University Application In Process
                    </span>
                  ) : state.fileStatus == "OL_APPLIED" ? (
                    <span>Applied and Awaited</span>
                  ) : state.fileStatus == "TUTION_FEES_PAID" ? (
                    <span>Tution Fees Paid</span>
                  ) : state.fileStatus == "OL_REJECTED" ? (
                    <span>Rejected</span>
                  ) : [
                    "OL_RECEIVED",
                    "TUTION_FEES_PROCESSING",
                    "TUTION_FEES_REJECTED",
                    "FILE_LODGED",
                    "FILE_LODGED_DOCS_PROCESSING",
                    "FILE_LODGED_DOCS_REJECTED",
                    "VISA_PROCESSING",
                    "REGISTER_AND_TRAVEL",
                    "DEFER_REQUESTED",
                    "DEFER_INITIATED",
                    "REFUND_REQUESTED",
                    "REFUND_INITIATED",
                    "VISA_AWAITED",
                    "VISA_APPROVED",
                    "VISA_REFUSED",
                    "CLOSED",
                  ].includes(state.fileStatus) ? (
                    <span>Received</span>
                  ) : (
                    ""
                  )}
                </div>
              </li>

              {/* <li>
                <div className={getTrackClass("TUTION_FEES")}>
                  <span>TUTION FEES</span>
                  {state.fileStatus == "OL_RECEIVED" ? (
                    <span> Pending</span>
                  ) : state.fileStatus == "TUTION_FEES_PROCESSING" ? (
                    <span> Under Verification</span>
                  ) : state.fileStatus == "TUTION_FEES_REJECTED" ? (
                    <span>Rejected, Please Uplaod Again</span>
                  ) : (
                    [
                      "FILE_LODGED",
                      "FILE_LODGED_DOCS_PROCESSING",
                      "FILE_LODGED_DOCS_REJECTED",
                      "VISA_PROCESSING",
                       "REGISTER_AND_TRAVEL",
                      "DEFER_REQUESTED",
                      "DEFER_INITIATED",
                      "REFUND_REQUESTED",
                      "REFUND_INITIATED",
                      "VISA_AWAITED",
                      "VISA_AWAITED",
                      "VISA_APPROVED",
                      "VISA_REFUSED",
                      "CLOSED",
                    ].includes(state.fileStatus) && <span>Verified</span>
                  )}
                  {[
                    "OL_RECEIVED",
                    "TUTION_FEES_PROCESSING",
                    "TUTION_FEES_REJECTED",
                    "FILE_LODGED",
                    "FILE_LODGED_DOCS_PROCESSING",
                    "FILE_LODGED_DOCS_REJECTED",
                    "VISA_PROCESSING",
                     "REGISTER_AND_TRAVEL",
                      "DEFER_REQUESTED",
                      "DEFER_INITIATED",
                      "REFUND_REQUESTED",
                      "REFUND_INITIATED",
                      "VISA_AWAITED",
                    "VISA_AWAITED",
                    "VISA_APPROVED",
                    "VISA_REFUSED",
                    "CLOSED",
                  ].includes(state.fileStatus) && (
                    <div className="btns">
                      <button
                        onClick={() =>
                          setState({
                            ...state,
                            popup: true,
                          })
                        }
                      >
                        {[
                          "TUTION_FEES_PROCESSING",
                          "TUTION_FEES_REJECTED",
                          "FILE_LODGED",
                          "FILE_LODGED_DOCS_PROCESSING",
                          "FILE_LODGED_DOCS_REJECTED",
                          "RESULT_AWAITED",
                          "VISA_APPROVED",
                          "VISA_REFUSED",
                          "CLOSE",
                        ].includes(state.fileDetails?.enroll_status) &&
                          "View Receipt"}
                        {![
                          "FILE_LODGED",
                          "FILE_LODGED_DOCS_PROCESSING",
                          "FILE_LODGED_DOCS_REJECTED",
                          "RESULT_AWAITED",
                          "VISA_APPROVED",
                          "VISA_REFUSED",
                          "CLOSE",
                        ].includes(state.fileDetails?.enroll_status) &&
                          "Upload Receipt"}
                      </button>
                      <div
                        className={`overlay ${
                          state.popup == true ? "active" : ""
                        }`}
                        onClick={() =>
                          setState({
                            ...state,
                            popup: false,
                          })
                        }
                      >
                        <div
                          className={`pendingDoctoast popup  ${
                            state.popup == true ? "active" : ""
                          }`}
                          style={{ color: "black" }}
                        >
                          {
                            <div class="w-full p-5">
                              <h1 class="text-2xl font-bold mb-5">
                                <span>
                                  {![
                                    "FILE_LODGED",
                                    "FILE_LODGED_DOCS_PROCESSING",
                                    "FILE_LODGED_DOCS_REJECTED",
                                    "RESULT_AWAITED",
                                    "VISA_APPROVED",
                                    "VISA_REFUSED",
                                    "CLOSE",
                                  ].includes(state?.fileDetails?.enroll_status)
                                    ? state.fileDetails?.enroll_status ==
                                      "TUTION_FEES_PROCESSING"
                                      ? "Change"
                                      : "Upload"
                                    : ""}{" "}
                                  Fees Receipt Swift Copy
                                </span>
                              </h1>
                              <div class="mb-5 flex items-center">
                                {[
                                  "TUTION_FEES_PROCESSING",
                                  "TUTION_FEES_REJECTED",
                                  "FILE_LODGED",
                                  "FILE_LODGED_DOCS_PROCESSING",
                                  "FILE_LODGED_DOCS_REJECTED",
                                  "RESULT_AWAITED",
                                  "VISA_APPROVED",
                                  "VISA_REFUSED",
                                  "CLOSE",
                                ].includes(
                                  state.fileDetails?.enroll_status
                                ) && (
                                  <a
                                    href={
                                      state.baseUrl +
                                      state.fileDetails?.tution_fees_recepit
                                    }
                                    className="inline-block text-blue-500 mx-2 px-4 py-2 border border-blue-500 hover:bg-blue-500 hover:text-white"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View
                                  </a>
                                )}
                                {![
                                  "FILE_LODGED",
                                  "FILE_LODGED_DOCS_PROCESSING",
                                  "FILE_LODGED_DOCS_REJECTED",
                                  "RESULT_AWAITED",
                                  "VISA_APPROVED",
                                  "VISA_REFUSED",
                                  "CLOSE",
                                ].includes(
                                  state.fileDetails?.enroll_status
                                ) && (
                                  <>
                                    <label
                                      for="swift-file"
                                      class="block font-medium mb-2"
                                    >
                                      File:
                                    </label>
                                    <input
                                      type="file"
                                      id="swift-file"
                                      name="swiftFile"
                                      className="border px-4 py-2 w-full"
                                      onChange={handleFile}
                                    />
                                  </>
                                )}
                              </div>
                              {![
                                "FILE_LODGED",
                                "FILE_LODGED_DOCS_PROCESSING",
                                "FILE_LODGED_DOCS_REJECTED",
                                "RESULT_AWAITED",
                                "VISA_APPROVED",
                                "VISA_REFUSED",
                                "CLOSE",
                              ].includes(state.fileDetails?.enroll_status) && (
                                <div className="flex justify-end">
                                  <ButtonPrimary
                                    onclick={uploadSwiftFile}
                                    type="button"
                                    title={
                                      state.fileDetails?.enroll_status ==
                                      "TUTION_FEES_PROCESSING"
                                        ? "Change"
                                        : "Upload"
                                    }
                                    loading={swiftLoading}
                                  />
                                </div>
                              )}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li> */}

              <li>
                <div className={getTrackClass("FILE_LODGED")}>
                  <span>FILE LODGED</span>
                </div>
              </li>
              {/* <li>
                <div className={getTrackClass("EMBASSY_DOCUMENTS")}>
                  <span>EMBASSY DOCUMENTS</span>
                  {state.fileStatus == "FILE_LODGED" ? (
                    <span> Pending</span>
                  ) : state.fileStatus == "FILE_LODGED_DOCS_PROCESSING" ? (
                    <span> Under Verification</span>
                  ) : state.fileStatus == "FILE_LODGED_DOCS_REJECTED" ? (
                    <span>Rejected</span>
                  ) : (
                    [
                      "VISA_PROCESSING",
                       "REGISTER_AND_TRAVEL",
                      "DEFER_REQUESTED",
                      "DEFER_INITIATED",
                      "REFUND_REQUESTED",
                      "REFUND_INITIATED",
                      "VISA_AWAITED",
                      "VISA_AWAITED",
                      "VISA_APPROVED",
                      "VISA_REFUSED",
                      "CLOSED",
                      "CUSTOM",
                    ].includes(state.fileStatus) && <span>Verified</span>
                  )}
                  {[
                    "FILE_LODGED",
                    "FILE_LODGED_DOCS_PROCESSING",
                    "FILE_LODGED_DOCS_REJECTED",
                    "VISA_PROCESSING",
                     "REGISTER_AND_TRAVEL",
                      "DEFER_REQUESTED",
                      "DEFER_INITIATED",
                      "REFUND_REQUESTED",
                      "REFUND_INITIATED",
                      "VISA_AWAITED",
                    "VISA_AWAITED",
                    "VISA_APPROVED",
                    "VISA_REFUSED",
                    "CLOSED",
                    "CUSTOM",
                  ].includes(state.fileStatus) && (
                    <div className="btns">
                      <button onClick={() => setShowEmbassyPop(true)}>
                        Documents
                      </button>
                    </div>
                  )}
                  {showEmbassyPop && (
                    <div className="Modaaal-overlay">
                      <div className="Modaaal">
                        <div
                          class="Modaaal-close"
                          onClick={() => setShowEmbassyPop(false)}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill="none"
                              stroke="#000"
                              stroke-width="2"
                              d="M3,3 L21,21 M3,21 L21,3"
                            ></path>
                          </svg>
                        </div>
                        <h2 class="Modaaal-title">Document Details</h2>
                        <StudentEmbassyDocuments
                          fileId={state?.fileDetails?._id}
                          tokenType="agent"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </li> */}
              <li>
                <div className={getTrackClass("VISA")}>
                  <span>VISA</span>
                  {state.fileStatus === "VISA_PROCESSING" ? (
                    <span>Under Verification</span>
                  ) : state.fileStatus === "VISA_AWAITED" ? (
                    <span>VISA Applied & Awaited</span>
                  ) : state.fileStatus === "VISA_APPROVED" ? (
                    <span>Approved</span>
                  ) : state.fileStatus === "VISA_REFUSED" ? (
                    <span>Rejected</span>
                  ) : state.fileStatus === "REGISTER_AND_TRAVEL" ? (
                    <span>Register and Travel</span>
                  ) : state.fileStatus === "DEFER_REQUESTED" ? (
                    <span>Defer Requested</span>
                  ) : state.fileStatus === "DEFER_INITIATED" ? (
                    <span>Defer Initiated</span>
                  ) : state.fileStatus === "REFUND_REQUESTED" ? (
                    <span>Refund Requested</span>
                  ) : state.fileStatus === "REFUND_INITIATED" && <span>Refund Initiated</span>}
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentStudentRemarks;
