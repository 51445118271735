import { Switch } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { GrClose } from "react-icons/gr";
import {
    Link,
    Navigate,
    redirect,
    useNavigate,
    useSearchParams
} from "react-router-dom";
import { BigLoading } from "../../../common/BigLoading";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { authenticate, getToken } from "../../../helper/auth";
import Dashboard from "../Screens/Dashboard/Dashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminStudentProfile = ({ sId, fId }) => {
    const [delId, setDelId] = useState()
    const [deleteModalCode, setDeleteModalCode] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        isWaiting: true,
        studentProfile: {},
        enrolledList: [],
        adminToken: getToken("admin"),
        reason: "",
        reasonType: -1,
        remarkpopupActive: false,
        remarkpopupActiveDocType: false,
        baseUrl: "",
        popup: false,
        remark: "",
        remarksList: [],
        profileApproveLoading: false,
        fileId: null,
        approveFileLoading: false,
        bigLoading: false,
    });

    const toggleRemarkPopup = (type) => {
        setState({
            ...state,
            remarkpopupActive: !state.remarkpopupActive,
            remarkpopupActiveDocType: type
        });
    };

    const [tab, setTab] = useState(searchParams.get("tab") == "documents" ? 2 : 1);

    var studentId = searchParams.get("id");

    useEffect(() => {
        if (!studentId) {
            studentId = sId;
        }
        if (studentId) {
            const config = {
                headers: {
                    Authorization: `Bearer ${state.adminToken
                        }`
                }
            };
            axios.get(process.env.REACT_APP_NODE_URL + "/student?id=" + studentId, config).then((res) => {
                axios.post(process.env.REACT_APP_NODE_URL + "/student/getRemarks?id=" + studentId, {}, config).then((remarksResponse) => {
                    setState({
                        ...state,
                        studentProfile: res.data.details.student,
                        enrolledList: res.data.details.enrolledList,
                        isWaiting: false,
                        baseUrl: res.data.details.baseUrl,
                        remarksList: remarksResponse.data.details.remarks
                    });
                });
            });
        } else {
            setState({
                ...state,
                isWaiting: false
            });
        }
    }, []);

    // const AsideBarAgentDetails = () => <></>
    // const AsideBarAgentDetails = () =>

    const RemarkPopup = () => {
        const changeReasonType = (e) => {
            setState({
                ...state,
                [e.target.name]: e.target.value
            });
        };

        const submitNow = () => {
            setState({
                ...state,
                bigLoading: true,
            })
            const remarkReason = document.getElementById("remarkReason").value;

            let oldDocs = state.studentProfile.documents;
            oldDocs[parseInt(state.remarkpopupActiveDocType)].document_status = "UN_APPROVED";

            const data = {
                documents: oldDocs,
                reason: state.reasonType == "Other" ? remarkReason : state.reasonType,
                doc_title: oldDocs[parseInt(state.remarkpopupActiveDocType)].document_title
            };

            const config = {
                headers: {
                    Authorization: `Bearer ${state.adminToken
                        }`
                }
            };
            axios.patch(process.env.REACT_APP_NODE_URL + "/student?id=" + studentId, data, config).then((res) => {
                if (res.data.status == "1") {
                    setState({
                        ...state,
                        studentProfile: {
                            ...state.studentProfile,
                            documents: oldDocs
                        },
                        remarkpopupActive: false,
                        remarkpopupActiveDocType: false,
                        bigLoading: false,
                    });
                }
                // setState({
                //     ...state,
                //     agentProfile: res.data.details.agent,
                //     isWaiting: false,
                // })
            });
        };

        return (
            <>
                {
                    state.bigLoading && <BigLoading />
                }
                <div className={
                    `remarkpopup ${state.remarkpopupActive && "active"
                    }`
                }>
                    <div className="w-full">
                        <h1>
                            <span>Select Reason</span>
                            <div className="close"
                                onClick={toggleRemarkPopup}>
                                <svg className="mr-2"
                                    style={
                                        { width: "25px" }
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                                </svg>
                            </div>
                        </h1>
                        <ul>
                            <div className="form-group">
                                {
                                    state.reasonType == "Not Visible" ? (
                                        <input type="radio" name="reasonType" id="1"
                                            value={"Not Visible"}
                                            onChange={changeReasonType}
                                            checked />
                                    ) : (
                                        <input type="radio" name="reasonType" id="1"
                                            value={"Not Visible"}
                                            onChange={changeReasonType} />
                                    )
                                }
                                <label htmlFor="1">Not Visible
                                </label>
                            </div>
                            <div className="form-group">
                                {
                                    state.reasonType == "Invalid Document" ? (
                                        <input type="radio" name="reasonType" id="2"
                                            value={"Invalid Document"}
                                            onChange={changeReasonType}
                                            checked />
                                    ) : (
                                        <input type="radio" name="reasonType" id="2"
                                            value={"Invalid Document"}
                                            onChange={changeReasonType} />
                                    )
                                }
                                <label htmlFor="2">Invalid Document</label>
                            </div>
                            <div className="form-group">
                                {
                                    state.reasonType == "Other" ? (
                                        <input type="radio" name="reasonType" id="3"
                                            value={"Other"}
                                            onChange={changeReasonType}
                                            checked />
                                    ) : (
                                        <input type="radio" name="reasonType" id="3"
                                            value={"Other"}
                                            onChange={changeReasonType} />
                                    )
                                }
                                <label htmlFor="3">Other</label>
                            </div>
                            <div className={
                                `form-group ${state.reasonType == "Other" ? "" : "hidden"
                                }`
                            }>
                                <input type="text" placeholder="Type Reason" className="form-control border-1 border-grey px-2 py-2 mb-3" id="remarkReason" />
                            </div>
                            <div>
                                <button className="py-[4px] px-[12px] rounded-full text-black submitRemarkBtn"
                                    onClick={submitNow}>
                                    Submit
                                </button>
                            </div>
                        </ul>
                    </div>
                </div>
            </>
        );
    };

    const approveConfirm = (index) => {
        setDeleteModalCode(true);
        setDelId(index)
    };

    const approveDocStatus = (index) => {

        // if (window.confirm("Are you sure ?")) { // now update
        setState({
            ...state,
            bigLoading: true,
        })
        const config = {
            headers: {
                Authorization: `Bearer ${state.adminToken
                    }`
            }
        };
        let oldDocs = state.studentProfile.documents;
        oldDocs[index].document_status = "APPROVED";

        const data = {
            documents: oldDocs,
            doc_title: oldDocs[index].document_title
        };

        axios.patch(process.env.REACT_APP_NODE_URL + "/student?id=" + studentId, data, config).then((res) => {
            if (res.data.status == "1") {
                setDeleteModalCode(false)
                toast(res.data.message);
                setState({
                    ...state,
                    bigLoading: false,
                    studentProfile: {
                        ...state.studentProfile,
                        documents: oldDocs
                    },
                });
                return;
            }
            setState({
                ...state,
                bigLoading: false,
            })
            toast.error(res.data.message);

            // setState({
            //     ...state,
            //     agentProfile: res.data.details.agent,
            //     isWaiting: false,
            // })
        });
        // }
    };


    // const approveFileNow = () => {
    //     if (state?.fileId == null) {
    //         toast.error("Please Choose a File")
    //         return;
    //     }

    //     if (window.confirm("Are you sure ?")) { // now update
    //         setState({
    //             ...state,
    //             approveFileLoading: true,
    //         });

    //         const config = {
    //             headers: {
    //                 Authorization: `Bearer ${state.adminToken
    //                     }`
    //             }
    //         };
    //         const data = {
    //             fileId: state.fileId,
    //             status: "FEES_PENDING"
    //         };

    //         axios.patch(process.env.REACT_APP_NODE_URL + "/admin/updateEnrollStatus", data, config).then((res) => {

    //             setState({
    //                 ...state,
    //                 approveFileLoading: false,
    //             });
    //             if (res.data.status == "1") {
    //                 toast(res.data.message);
    //                 navigate("/admin/fees-pending")
    //             } else {
    //                 toast.error(res.data.message);
    //             }
    //             // setState({
    //             //     ...state,
    //             //     agentProfile: res.data.details.agent,
    //             //     isWaiting: false,
    //             // })
    //         }).catch(err => {
    //             toast.error("Something went wrong");
    //             setState({
    //                 ...state,
    //                 approveFileLoading: false,
    //             });
    //         });
    //     }
    // };

    if (state.isWaiting)
        return "Waiting...";



    // const unApproveProfile = () => {
    //     setState({
    //         ...state,
    //         profileApproveLoading: true
    //     });
    //     if (!window.confirm("Are you sure ?"))
    //         return;



    //     const config = {
    //         headers: {
    //             Authorization: `Bearer ${state.adminToken
    //                 }`
    //         }
    //     };
    //     const data = {};
    //     axios.post(process.env.REACT_APP_NODE_URL + "/student/approveProfile?id=" + studentId, data, config).then((res) => {
    //         if (res.data.status == "1") {
    //             toast(res.data.message);
    //             setState({
    //                 ...state,
    //                 studentProfile: {
    //                     ...state.studentProfile,
    //                     status: "IN_PROCESS"
    //                 },
    //                 profileApproveLoading: false
    //             });
    //             return;
    //         }
    //         toast(res.data.message);

    //         // setState({
    //         //    ...state,
    //         //     agentProfile: res.data.details.agent,
    //         //     isWaiting: false,
    //         // })
    //     });
    // };

    // const approveProfile = () => {
    //     setState({
    //         ...state,
    //         profileApproveLoading: true
    //     });
    //     if (!window.confirm("Are you sure ?"))
    //         return;



    //     const config = {
    //         headers: {
    //             Authorization: `Bearer ${state.adminToken
    //                 }`
    //         }
    //     };
    //     const data = {};
    //     axios.post(process.env.REACT_APP_NODE_URL + "/student/approveProfile?id=" + studentId, data, config).then((res) => {
    //         if (res.data.status == "1") {
    //             toast(res.data.message);
    //             setState({
    //                 ...state,
    //                 studentProfile: {
    //                     ...state.studentProfile,
    //                     status: "APPROVED"
    //                 },
    //                 profileApproveLoading: false
    //             });
    //             return;
    //         }
    //         toast(res.data.message);

    //         // setState({
    //         //    ...state,
    //         //     agentProfile: res.data.details.agent,
    //         //     isWaiting: false,
    //         // })
    //     });
    // };

    const handleInput = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    // const handleFile = (event) => {
    //     setState({
    //         ...state,
    //         [event.target.name]: event.target.files[0]
    //     })
    // }

    const addRemark = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${state.adminToken
                    }`
            }
        };
        let body = {
            userId: studentId,
            text: state.remark
        };
        axios.post(process.env.REACT_APP_NODE_URL + "/student/setRemark", body, config).then((res) => {
            setState({
                ...state,
                remark: "",
                popup: false,
                remarksList: [
                    res.data.details.newRemark,
                    ...state.remarksList
                ]
            });
        });
    };

    return (
        <>
            <ToastContainer />
            <div heading_title={"Agent Profile"}>
                <>
                    <div className={`agentDetailsAsideBar active`}>
                        {deleteModalCode ? (
                            <div className="modal_cover">
                                <div className="modal_inner select-col-popup">
                                    <div className="header_modal">
                                        <h1 className="capitalize font-bold text-lg mb-2">
                                            Are You Sure?
                                        </h1>

                                        <div className="button-part flex justify-end items-center gap-4">
                                            <button
                                                className="py-2 px-6"
                                                type="btn"
                                                onClick={() => setDeleteModalCode(false)}
                                            >
                                                No
                                            </button>
                                            <button
                                                className="py-2 px-6"
                                                type="btn"
                                                onClick={() => approveDocStatus(delId)}//call real function here.
                                            >
                                                Yes
                                            </button>
                                        </div>
                                        <GrClose
                                            className="close"
                                            onClick={() => setDeleteModalCode(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        {/* <h1>
                            Agent Details
                            <button onClick={() => {
                                setState({ ...state, activeAgentDetails: null })
                                navigate("/admin/manage?status=unapproved")
                            }}>Close</button>
                        </h1> */}
                        <div className="table-overflow">
                            <table className="table-responsive">
                                <div className="tabs">
                                    <h2 className={
                                        `${tab == 1 && "active"
                                        }`
                                    }
                                        onClick={
                                            () => setTab(1)
                                        }>
                                        Basic
                                    </h2>
                                    <h2 className={
                                        `${tab == 2 && "active"
                                        }`
                                    }
                                        onClick={
                                            () => setTab(2)
                                        }>
                                        Documents
                                    </h2>
                                    {/* <h2 className={
                                        `${tab == 3 && "active"
                                        }`
                                    }
                                        onClick={
                                            () => setTab(3)
                                        }>
                                        Remarks
                                    </h2> */}
                                    {/* <h2 className={`${tab == 3 && "active"}`} onClick={() => setTab(3)}>Additional Details</h2> */} </div>
                                <div className={
                                    `tabDetail ${tab == 1 && "active"
                                    }`
                                }>
                                    <tr className="py-0">
                                        <div className="p-2 flex flex-col">
                                            <th>Email </th>
                                            <td className="lowercase">
                                                {
                                                    state.studentProfile?.email || ""
                                                } </td>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="p-2 flex flex-col">
                                            <th>First Name </th>
                                            <td>{
                                                state.studentProfile?.firstName
                                            }</td>
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <th>Last Name </th>
                                            <td>{
                                                state.studentProfile?.lastName
                                            }</td>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="p-2 flex flex-col">
                                            <th>Phone </th>
                                            <td>{
                                                state.studentProfile?.phone || "---"
                                            }</td>
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <th></th>
                                            <td></td>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="p-2 flex flex-col">
                                            <th>Street </th>
                                            <td>{
                                                state.studentProfile?.street || "---"
                                            }</td>
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <th>City </th>
                                            <td>{
                                                state.studentProfile?.city || "---"
                                            }</td>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="p-2 flex flex-col">
                                            <th>State </th>
                                            <td>{
                                                state.studentProfile?.state || "---"
                                            }</td>
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <th>Country </th>
                                            <td>{
                                                state.studentProfile?.country || "---"
                                            }</td>
                                        </div>
                                    </tr>
                                    <tr>
                                        <div className="p-2 flex flex-col">
                                            <th>Postal Code </th>
                                            <td>{
                                                state.studentProfile?.postal_code || "---"
                                            }</td>
                                        </div>
                                        <div className="p-2 flex flex-col">
                                            <th></th>
                                            <td></td>
                                        </div>
                                    </tr>
                                </div>
                                <div className={
                                    `tabDetail docs ${tab == 2 && "active"
                                    }`
                                }>
                                    {
                                        state.studentProfile.documents.length == 0 ? (
                                            <div className="text-[red] w-full text-center m-3">
                                                No Documents
                                            </div>
                                        ) : (
                                            <div className="text-[red] w-full text-center m-3"></div>
                                        )
                                    }
                                    <div className="table-overflow">
                                        <table className="table-fixed w-full p-2 pt-0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody> {
                                                state.studentProfile.documents.map((document, DocIndex) => {
                                                    return (
                                                        <tr key={DocIndex}
                                                            className="px-2 docRow">
                                                            <td className="flex items-center">
                                                                {
                                                                    document.document_title
                                                                } </td>

                                                            <td className="flex items-center justify-end">
                                                                {
                                                                    document.document_status == "PENDING" ? <>PENDING</> : document.document_status == "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ? (
                                                                        <>
                                                                            <button className="approve"
                                                                                onClick={
                                                                                    () => approveConfirm(DocIndex)
                                                                                }>
                                                                                Approve
                                                                            </button>
                                                                            <button className="decline"
                                                                                onClick={
                                                                                    () => toggleRemarkPopup(DocIndex)
                                                                                }>
                                                                                Decline
                                                                            </button>
                                                                            <span className="flex items-center justify-end downloadSvg" title="Download">
                                                                                <a href={
                                                                                    state.baseUrl + document.document_url
                                                                                }
                                                                                    download="companylogo"
                                                                                    target={"_blank"}>
                                                                                    <svg style={
                                                                                        { width: "20px" }
                                                                                    }
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                                                                </a>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <> {
                                                                            document.document_status == "APPROVED" ? (
                                                                                <div className="approved_status">
                                                                                    Approved
                                                                                </div>
                                                                            ) : (
                                                                                <div className="declined_status">
                                                                                    Declined
                                                                                </div>
                                                                            )
                                                                        }
                                                                            <span className="flex items-center justify-end downloadSvg" title="Download">
                                                                                <a href={
                                                                                    state.baseUrl + document.document_url
                                                                                }
                                                                                    download="companylogo"
                                                                                    target={"_blank"}>
                                                                                    <svg style={
                                                                                        { width: "20px" }
                                                                                    }
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                                                                </a>
                                                                            </span>
                                                                        </>

                                                                    )
                                                                }

                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            } </tbody>
                                        </table>
                                    </div>
                                </div>
                            </table>
                        </div>
                    </div>
                </>
            </div>
            <RemarkPopup />
        </>
    );
};

export default AdminStudentProfile;
