import React from "react";

export default function Rightsideform() {
  return (
    <div>
      <div className="border bg-white right-form">
        <h4 className="p-2 pl-4 bg-black text-white font-light text-lg">
          Interested in studying abroad?
        </h4>
        <div className="p-4">
          <p className="mb-4">
            Learn Global can help – fill in your details and we’ll call you back
          </p>
          <p className="mb-2 w-full">
            <input
              className="w-full px-2 py-3 border outline-none"
              type="text"
              placeholder="First Name"
            />
          </p>
          <p className="mb-2  w-full">
            <input
              className="w-full px-2 py-3 border outline-none"
              type="text"
              placeholder="Last Name"
            />
          </p>
          
          <p className="mb-2  w-full ">
            <input
              className="w-full px-2 py-3 border outline-none"
              type="mail"
              placeholder="Email"
            />
          </p>

          <p className="mb-2 w-full">
            <input
              className="no-spinner w-full px-2 py-3 border outline-none text-[#e5e7eb]"
              style={{borderColor: '#e5e7eb'}}
              type="number"
              placeholder="+91"
            />
          </p>
          
          <p className="">
            <label className="text-slate-300 flex pt-1 pb-2">
              Your preferred study destination
            </label>
            <select className="w-full px-2 py-3 border outline-none text-[#ccc] bg-[#fff]" name="" id="right-select">
              <option value="Nz">--Select----</option>
              <option value="Nz">Nz</option>
              <option value="Uk">Uk</option>
              <option value="Canada">Canada</option>
            </select>
          </p>
          <p className="mt-5 mb-3">
            <input
              type="submit"
              value="Register Now"
              name="submit"
              className="register text-center mx-auto block p-2 w-full text-white"
            />
          </p>
        </div>
      </div>
    </div>
  );
}
