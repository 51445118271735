import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { authenticate, getToken } from "../../../helper/auth";
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary';
import { ToastContainer, toast } from 'react-toastify';
import { FiEye, FiEyeOff } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';

export const Profile = () => {
  const [state, setState] = useState({
    isWaiting: false,
    notifications: [],
    adminToken: getToken("admin"),
    btnLoading: {
      profile: false,
      password: false,
    }
  })

  const [tab, setTab] = useState(1)
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [firstName2, setFirstName2] = useState('');
  const [lastName2, setLastName2] = useState('');

  const [oldPassword, setOldPassword] = useState('')
  const [showOldPass, setShowOldPass] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [showNewPass, setShowNewPass] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showConfirmPass, setShowConfirmPass] = useState(false)

  const [isEdited, setIsEdited] = useState(false);

  const [profilePhoto, setProfilePhoto] = useState("")
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(true)

  const toggleOldPassword = () => {
    setShowOldPass(!showOldPass);
  };

  const toggleNewPassword = () => {
    setShowNewPass(!showNewPass);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };



  const updateData = () => {
    if (!isEdited) {
      return; // Do not proceed if nothing is edited
    }
    setState({
      ...state,
      btnLoading: {
        profile: true,
        password: false,
      }
    })
    const config = { headers: { "Authorization": `Bearer ${state.adminToken}` } }
    let data = { firstName: firstName, lastName: lastName }
    axios.patch(process.env.REACT_APP_NODE_URL + "/admin/updateProfile", data, config).then(res => {
      console.log("checkkk", res.data.message)
      setState({
        ...state,
        btnLoading: {
          profile: false,
          password: false,
        }
      })
      if (res.data.status === "1") {
        setFirstName2(firstName)
        setLastName2(lastName)
        document.getElementById("header-firstname").innerText = firstName
        setIsEdited(false)
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    }).catch(err => {
      setState({
        ...state,
        btnLoading: {
          profile: false,
          password: false,
        }
      })
      // toast(err.response.data.message)
    })
  }

  const updatePassword = () => {
    if (oldPassword != "" && newPassword != "") {
      if (newPassword !== confirmPassword) {
        toast.error("Both password must be same!");
        return;
      }
      setState({
        ...state,
        btnLoading: {
          profile: false,
          password: true,
        }
      })
      const config = { headers: { "Authorization": `Bearer ${state.adminToken}` } }
      let data = { oldPassword: oldPassword, newPassword: newPassword }
      axios.patch(process.env.REACT_APP_NODE_URL + "/admin/changePassword", data, config).then(res => {
        if (res.data.status == "0") {
          toast.error(res.data.message);
        } else {
          toast(res.data.message);
        }

        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')

        setState({
          ...state,
          btnLoading: {
            profile: false,
            password: false,
          }
        })
      }).catch(err => {
        toast.error(err?.response?.data?.message || "Something went wrong");
        setState({
          ...state,
          btnLoading: {
            profile: false,
            password: false,
          }
        })
        // toast(err.response.data.message)
      })
    } else {
      toast.error("All Fields are required");
    }

  }


  useEffect(() => {
    const config = { headers: { "Authorization": `Bearer ${state.adminToken}` } }
    axios
      .get(process.env.REACT_APP_NODE_URL + "/admin/profile", config).then(response => {
        if (response.data.status == "1") {
          setEmail(response.data.details.user.email)
          setFirstName(response.data.details.user.first_name)
          setFirstName2(response.data.details.user.first_name)
          setLastName(response.data.details.user.last_name)
          setLastName2(response.data.details.user.last_name)
          setProfilePhoto(response.data.details.user.profile_photo)
          setProfilePhotoLoading(false)

        }
      }).catch(err => {
      })

  }, [])


  const uploadAvatar = (e) => {
    const config = { headers: { "Authorization": `Bearer ${state.adminToken}` } }

    const fd = new FormData();
    fd.append("photo", e.target.files[0]);

    axios.patch(process.env.REACT_APP_NODE_URL + "/admin/uploadProfilePhoto", fd, config).then(res => {
      if (res.data.status == "0") {
        toast.error(res.data.details.message)
      } else {
        toast(res.data.details.message)
        setProfilePhoto(res.data.details.profile_photo)
      }
      document.getElementById("profilePhoto").src = res.data.details.profile_photo
      setProfilePhotoLoading(false)
    }).catch(err => {
      toast.error("Something went wrong")
      setProfilePhotoLoading(false)
    })
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setIsEdited(true); // Set the flag to indicate edit
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setIsEdited(true); // Set the flag to indicate edit
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEdited(true); // Set the flag to indicate edit
  }

  const LeftProfileBoxFiexed = () => {
    return <>
      <ToastContainer />
      <div className='left-profile py-[30px] w-3/12 flex flex-col items-center'>
        <div className="avatar-edit-profile">
          {
            profilePhotoLoading &&
            <div className="loading">
              <svg class="h-10 w-10 animate-spin" viewBox="3 3 18 18">
                <path
                  class="fill-gray-200"
                  d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                <path
                  class="fill-gray-800"
                  d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
              </svg>
            </div>
          }
          <img src={profilePhoto} alt="" />
          <div className="edit-field">
            <input type="file" id='a' onChange={uploadAvatar} />
            <label htmlFor="a"><i class="fas fa-edit"></i></label>
          </div>
        </div>
        <h2>{firstName2} {lastName2}</h2>
      </div>
    </>
  }


  return (
    <div className='profile-main admin-profile flex flex-col justify-center items-center px-0 py-5'>
      {/* <div className="tabs">
        <h2 className={`${tab == 1 && "active"}`} onClick={() => setTab(1)}>Basic</h2>
        <h2 className={`${tab == 2 && "active"}`} onClick={() => setTab(2)}>Security</h2>
      </div> */}

      <div className="profile-flex">
        <LeftProfileBoxFiexed />
        <div className="right-profile flex items-baseline gap-5 ">
          <div
            class="block flex-none bg-white profile-form">

            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>First Name</label>
              <input
                type="text"
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput90"
                value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
                onChange={handleFirstNameChange}
              />

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>Last Name</label>
              <input
                type="text"
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput90"
                value={lastName}
                // onChange={(e) => setLastName(e.target.value)}
                onChange={handleLastNameChange}
              />

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>Email address</label>
              <input
                type="email"
                className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput91"
                value={email}
                onChange={handleEmailChange}
              />

            </div>

            <button
              className="rounded bg-blue px-6 py-2.5 text-xs border-2 font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover: focus:bg-primary-700 focus: focus:outline-none focus:ring-0 active:bg-primary-800 active:"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={updateData}
              disabled={!isEdited} // Disable the button if nothing is edited
            >
              Update
            </button>

          </div>
          <div
            class="block flex-none bg-white profile-form">
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>Old Password</label>
              <input
                type={showOldPass ? 'text' : 'password'}
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput90"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <button className="eye-btn" onClick={toggleOldPassword}>  
                {showOldPass ? <FiEyeOff /> : <FiEye />}
              </button>

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>New Password</label>
              <input
                type={showNewPass ? 'text' : 'password'}
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput90"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button className="eye-btn" onClick={toggleNewPassword}>
                {showNewPass ? <FiEyeOff /> : <FiEye />}
              </button>

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>Confirm Password</label>
              <input
                type={showConfirmPass ? 'text' : 'password'}
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput91"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button className="eye-btn" onClick={toggleConfirmPassword}>
                {showConfirmPass ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            <ButtonPrimary title={"Change Password"} loading={state.btnLoading.password} onclick={updatePassword} />
            {/* <button
          type="button"
          class="rounded bg-blue px-6 py-2.5 text-xs border-2 font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover: focus:bg-primary-700 focus: focus:outline-none focus:ring-0 active:bg-primary-800 active:"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={updatePassword}
        >
          Change Password
        </button> */}
          </div>

        </div>
      </div>
    </div>
  )
}




