import React, { useEffect } from "react";
import Contact_text from "../Components/Contact_text";
import WebsiteHome from "../Screens/WebsiteHome";

export default function Wcontact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div page={"discover"}>
        <Contact_text />
      </div>
    </div>
  );
}
