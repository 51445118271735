import { Switch } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, Navigate, redirect } from "react-router-dom";
import { getCookie, setCookie } from "../../../helper/auth";
import Papa from "papaparse";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrClose } from "react-icons/gr";
import { useDropzone } from "react-dropzone";
import socketIOClient from "socket.io-client";
const ENDPOINT = process.env.REACT_APP_NODE_URL;
var socket = socketIOClient(ENDPOINT);
const escape_html = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();
  var map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };
  return str.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
};
const Addcolleges = () => {
  const [deleteModalCode, setDeleteModalCode] = useState(false);
  
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      handleFileUpload({ target: { files: [file], name: "csv_attachment" } });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  var visitedcollegePrograms = [];
  var totalErrors;
  const [state, setState] = useState({
    progress: 0,
    csv_attachment: "",
    csvData: [],
    isUploadingStart: false,
    fileUploadedStatus: false,
    requiredColumns: [0, 1, 2, 3, 4, 5, 14, 15, 16],
    countryList: [],
    collegesList: [],
    isWait: true,
    openingFileStatus: false,
  });
  var visitedcolleges = [];
  var errorFieds = 0;

  const uploadData = async () => {
    if (errorFieds != 0) {
      toast(`Failed: Invalid ${errorFieds} Fields.`);
      return;
    }
    if (state.csv_attachment == "") {
      toast("Please upload the file");
      return;
    }
    setState({
      ...state,
      isUploadingStart: true,
    });
    const fd = new FormData();
    fd.append("csv_attachment", state.csv_attachment);

    try {
      let ProgressBarAnimation = document.getElementById(
        "ProgressBarAnimation"
      );
      const config = {
        onUploadProgress: (progressEvent) => {
          let progress = (progressEvent.loaded / progressEvent.total) * 100;
          ProgressBarAnimation.classList.add("bg-blue-600");
        },
      };

      let response = await axios.post(
        process.env.REACT_APP_NODE_URL + "/admin/uploadcsv",
        fd,
        config
      );


      socket.emit("upload_csv_websocket_custom_validation", response.data);
      var complete = 0;
      var total = 0;
      var result = [0, 0, 0];
      socket.on("FromAPI", (data) => {
        if (data.total) {
          total = data.total;
        }
        if (data.index != undefined || data.index != null) {
          let index = data.index;
          complete++;
          let progress = (complete * 100) / total;
          ProgressBarAnimation.style.width = progress + "%";
          if (progress > 45) {
            ProgressBarAnimation.innerText = `(${complete}-${total}) ${parseInt(
              progress,
              10
            )}%`;
          }
          if (progress == 100) {
            ProgressBarAnimation.classList.remove("progress-bar-animated");
            ProgressBarAnimation.classList.add("bg-green-600");
          } else {
            ProgressBarAnimation.classList.add("progress-bar-animated");
          }
          if (data.message == "Uploaded") {
            result[0] = result[0] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText =
              "Uploaded";
          }
          if (data.message == "Updated") {
            result[1] = result[1] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText =
              "Updated";
          }
          if (data.message == "Failed") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText =
              "Failed";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }
          if (data.message == "college not found") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText =
              "College Not Found";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }
          if (data.message == "country not found") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText = "Country Not Found";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }
          if (data.message == "state not found") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText = "State Not Found";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }
          if (data.message == "city not found") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-[#dc2626]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText = "City Not Found";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }
          if (data.message == "Wrong format") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("bg-red-300");
            document
              .getElementById(`wrongformatedStatus_${index}`)
              .classList.add("bg-red-300")
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#16a34a]");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-[#4338ca]");
            document.getElementById(`uploadStatus_${index}`).innerText = "Wrong Format";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }
          if (data.message == "--") {
            result[2] = result[2] + 1;
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.add("text-danger");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-success");
            document
              .getElementById(`uploadStatus_${index}`)
              .classList.remove("text-primary");
            document.getElementById(`uploadStatus_${index}`).innerText = "--";
            document
              .getElementById(`uploadStatus_${index}`)
              .setAttribute("title", data.details);
          }

          document.getElementById(`uploadedCount`).innerText = result[0];
          document.getElementById(`updatedCount`).innerText = result[1];
          document.getElementById(`failedCount`).innerText = result[2];
          document.getElementById(`totalCount`).innerText = complete;

          if (total == index + 1) {
            setState({
              ...state,
              isUploadingStart: false,
            });
          }
        }
      });
    } catch (error) {
    }
  };

  const handleFileUpload = (e) => {
    openFile(e.target.name, e.target.files[0]);
  };

  const openFile = async (key, value) => {
    setState({
      ...state,
      openingFileStatus: true,
    });
    var response1 = await axios.get(
      process.env.REACT_APP_NODE_URL + "/admin/countries"
    );
    var response2 = await axios.get(
      process.env.REACT_APP_NODE_URL + "/admin/collegenames"
    );
    visitedcolleges = [];
    visitedcollegePrograms = [];
    Papa.parse(value, {
      encoding: "ISO-8859-1",
      complete: function (results) {
        setState({
          ...state,
          openingFileStatus: false,
          csvData: [results.data[0], results.data.splice(1)],
          countryList: response1.data.details.list.map(
            (item) => item.countryName
          ),
          collegesList: response2.data.details.list.map(
            (item) => item.collegeName
          ),
          fileUploadedStatus: true,
          [key]: value,
        });
      },
    });
  };

  const closeModal = () => {
    setDeleteModalCode(true);
  };

  const closeIt = () => {
    setDeleteModalCode(false)
    setState({
      ...state,
      csvData: [[], []],
      fileUploadedStatus: false,
      isUploadingStart: false,
    });
  }

  return (
    <>
      <ToastContainer />
      <div heading_title={"Add colleges"}>
        <>
          <div className="">
            {deleteModalCode ? (
              <div className="modal_cover">
                <div className="modal_inner select-col-popup">
                  <div className="header_modal">
                    <h1 className="capitalize font-bold text-lg mb-2">
                      Are You Sure?
                    </h1>

                    <div className="button-part flex justify-end items-center gap-4">
                      <button
                        className="py-2 px-6"
                        type="btn"
                        onClick={() => setDeleteModalCode(false)}
                      >
                        No
                      </button>
                      <button
                        className="py-2 px-6"
                        type="btn"
                        onClick={() => closeIt()}//call real function here.
                      >
                        Yes
                      </button>
                    </div>
                    <GrClose
                      className="close"
                      onClick={() => setDeleteModalCode(false)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {state.fileUploadedStatus ? (
              <></>
            ) : (
              <div className="flex flex-column items-center w-10/12 mx-auto mt-10 mb-3  md:h-[75vh]">
                <div className="mt-7 w-full">
                  <input
                    type="hidden"
                    name="progressInput"
                    id="progressInput"
                    value={0}
                  />
                  <div className="dropZone p-5 text-center " {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop file here, or click to select files</p>
                    <button className=" bg-[#19632f]">Browse File</button>
                  </div>
                </div>
              </div>
            )}

            {state.openingFileStatus && (
              <div className="text-center w-full font-black uppercase flex flex-col items-center justify-center">
                <span>Opening File...</span>
                <div aria-label="Loading..." role="status" className="mt-2">
                  <svg class="h-6 w-6 animate-spin" viewBox="3 3 18 18">
                    <path
                      class="fill-gray-200"
                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                    ></path>
                    <path
                      class="fill-gray-800"
                      d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                    ></path>
                  </svg>
                </div>
              </div>
            )}
            {state.fileUploadedStatus ? (
              <div className="tableData">
                <label className="flex items-center" htmlFor="">
                  <b className="mr-10">
                    <span className="m-1">College Details</span>
                  </b>

                  <div className="flex items-center justify-center">
                    <div className="flex flex-direction-column m-2">
                      <p className="m-0 p-1">
                        <span className="text-secondary">Updated : </span>
                        <b>
                          <span id="updatedCount">0</span>
                        </b>
                      </p>
                      <p className="m-0 p-1">
                        <span className="text-secondary">Uploaded : </span>
                        <b>
                          <span id="uploadedCount">0</span>
                        </b>
                      </p>
                      <p className="m-0 p-1">
                        <span className="text-secondary">Failed : </span>
                        <b>
                          <span id="failedCount">0</span>
                        </b>
                      </p>
                      <p className="m-0 p-1">
                        <span className="text-secondary">Total : </span>
                        <b>
                          <span id="totalCount">0</span>
                        </b>
                      </p>
                    </div>

                    <button
                      className="bg-gradient-primary m-0 px-2 py-1 rounded hover:bg-[#2a276b] text-white"
                      onClick={state.isUploadingStart ? null : uploadData}
                    >
                      {state.isUploadingStart ? (
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>Upload</>
                      )}
                    </button>
                    <button
                      className="px-2 py-1 text-red-600 hover:text-[#7f1d1d] m-2"

                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </label>
                {true ? (
                  <div className="w-8/12 mx-auto bg-gray-200 rounded-full">
                    <div
                      id="ProgressBarAnimation"
                      className="text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: "0%", height: "15px" }}
                    >
                    </div>
                  </div>
                ) : (
                  <> </>
                )}
                <div className="table-overflow">
                  <table className="table collegeList">
                    <thead>
                      <tr>
                        <th className="p-2">#</th>
                        <th className="p-2">Status</th>
                        {state.csvData[0].map((item, index) => {
                          if (state.requiredColumns.includes(index)) {
                            return (
                              <th scope="col" className="p-2">
                                {item ? item : <span className="csv_box_fill">--</span>}
                                <span className="text-red-600">*</span>
                              </th>
                            );
                          }
                          return (
                            <>
                              <th className="p-2" scope="col">
                                {item ? item : <span className="csv_box_fill">--</span>}
                              </th>
                            </>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {state.fileUploadedStatus ? (
                        state.csvData[1].map((collegeDataArr, index) => {
                          if (collegeDataArr.length == 1 && collegeDataArr[0] == "")
                            return '';

                          let isDoubleProgram = -1;
                          if (
                            visitedcollegePrograms.includes(
                              `${collegeDataArr[0]}-${collegeDataArr[14]}-${collegeDataArr[2]}`
                            )
                          ) {
                            isDoubleProgram = visitedcollegePrograms.indexOf(
                              `${collegeDataArr[0]}-${collegeDataArr[14]}-${collegeDataArr[2]}`
                            );

                          } else {
                            visitedcollegePrograms[
                              index
                            ] = `${collegeDataArr[0]}-${collegeDataArr[14]}-${collegeDataArr[2]}`;
                          }
                          return (
                            <tr
                            id={`wrongformatedStatus_${index}`}
                              className={`${isDoubleProgram != -1
                                ? ""
                                : ""
                                }`}
                              title={`${isDoubleProgram != -1
                                ? `Double Program name to row number ${isDoubleProgram + 1
                                }`
                                : ""
                                }`}
                            >
                              <td className="p-2 text-center">{index + 1}</td>
                              <td className="p-2" id={`uploadStatus_${index}`}>
                                {isDoubleProgram != -1 ? "--" : <span className="csv_box_fill">--</span>}
                              </td>
                              {collegeDataArr.map((item, index) => {
                                if (index == 15)
                                  if (
                                    state.requiredColumns.includes(index) &&
                                    item == ""
                                  ) {

                                    return (
                                      <td className="p-2 myCell required_missing">
                                        {item ? item : <span className="csv_box_fill">--</span>}
                                      </td>
                                    );
                                  }
                                if (
                                  !state.collegesList.includes(
                                    item.toLowerCase()
                                  ) &&
                                  index == 0
                                ) {
                                }
                                return (
                                  <td className="p-2" title={escape_html(item)}>
                                    {item ? item : <span className="csv_box_fill">--</span>}

                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Addcolleges;
