import React from 'react'
import { toast } from "react-toastify";
import axios from "axios";

function ShowCollege({ closeshowcollegePopup, startLoader, selectedCollege }) {
    const handleShowCollege = () => {
        axios.post(`${process.env.REACT_APP_NODE_URL}/admin/updateCollegeVisibility`, {
            collegeId: selectedCollege._id,
            isVisible: true
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                if (response.data.status === '1') {
                    toast.success(response.data.message);
                    closeshowcollegePopup();
                    startLoader();
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div>
            <div className="p-2">
                <h1 className="mb-3 text-[16px] font-bold">Are you sure you want to show
                    <span className='text-[green] capitalize'> {selectedCollege.collegeName} ?</span></h1>
                <button onClick={handleShowCollege} className="border p-1">Yes</button>
                <button className="bg-red-600 border p-1 ml-2">No</button>
            </div>
        </div>
    )
}

export default ShowCollege