import axios from 'axios';
import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminSendOtp = ({ onClose, enrollId, agentId }) => {
    const [sentOtp, setSentOtp] = useState(true);
    const [verifyOtp, setVerifyOtp] = useState(false);
    const [otpInput, setOtpInput] = useState('');
    const fileId = enrollId;

    const inputRefs = useRef([]);

    const handlePayment = (enrollId) => {
        const requestBody = {
            fileId: enrollId,
            agentId: agentId
        };

        axios
            .post(process.env.REACT_APP_NODE_URL + "/student/handleCommisionPayment", requestBody)
            .then((response) => {
                if (response.data.status === "1") {
                    toast.success(response.data.message);
                    setSentOtp(false);
                    setVerifyOtp(true);

                }
                else {
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleInputChange = (index, event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            if (value.length === 1 && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
            const otp = inputRefs.current.map(ref => ref.value).join('');
            setOtpInput(otp);
        } else {
            event.target.value = '';
        }
    };

    const handleVerify = () => {
        const requestBody = {
            fileId: fileId,
            otp: otpInput
        };

        axios
            .post(process.env.REACT_APP_NODE_URL + "/student/verifyPament", requestBody)
            .then((response) => {
                if (response.data.status === "1") {
                    toast.success(response.data.message);
                    setVerifyOtp(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
                else {
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                toast.error(error.data.message);
            });
    };

    return (
        <>
            <ToastContainer />
            {sentOtp ? (
                <div className="fixed inset-0 flex items-center justify-center custom-container">
                    <div className="relative z-10 bg-white p-6 rounded-lg w-80 shadow-md">
                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-600"
                            onClick={onClose}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <h2 className="text-xl font-semibold m-0 mb-3">Send OTP to Agent</h2>
                        <h5 className='mt-3'>
                            Please click the button below to send a One-Time Password (OTP) to the agent for verification.
                        </h5>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md mr-2 mt-5"
                            onClick={() => handlePayment(fileId)}
                        >
                            Send OTP
                        </button>
                    </div>
                </div>
            ) : null}
            {verifyOtp ? (
                <div className="fixed inset-0 flex items-center justify-center custom-container">
                    <div className="relative z-10 bg-white p-6 rounded-lg w-80 shadow-md flex flex-col items-center">
                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-600"
                            onClick={() => setVerifyOtp(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <h2 className="text-xl font-semibold m-0">Verify OTP</h2>
                        <div className='grid grid-cols-4 gap-2 my-6'>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <input
                                    key={index}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                    maxLength="1"
                                    className="border border-gray-300 px-1 py-2 rounded-md text-center w-8"
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                            ))}
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md mr-2"
                            onClick={handleVerify}
                        >
                            Verify
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default AdminSendOtp;
