/* eslint-disable no-unused-vars */
import { Switch } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { GrClose } from "react-icons/gr";
import "react-toastify/dist/ReactToastify.css";
import { AiFillSetting } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Manage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../../images/loader.gif";
import Select from "react-select";

const Manage = () => {
  const PAGE_SIZE = 10;
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [ModalCode, setModalCode] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [statData, setStatData] = useState();
  const [isrotating, setisrotating] = useState(false);
  const [catData, setCatData] = useState();
  const [toggleModal, setToggleModal] = useState(false);
  const [buttPop, setButtPop] = useState(false);
  const [buId, setBuId] = useState();
  const [buIndex, setBuIndex] = useState();
  const [ee, setEe] = useState();
  const [ageId, setAgeId] = useState();
  const [blockStatus, setBlockStatus] = useState();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isWaiting: true,
    agents: [],
    students: [],
    totalPages: 0,
    currentPage: 1,
    filterActive: false,
    activeAgentDetails: null,
    onlyDetails: [],
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Name", value: "Name" },
    { label: "Agent Number", value: "Agent Number" },
    { label: "Approve/Reject", value: "Approve/Reject" },
    { label: "Block/Unblock", value: "Block/Unblock" },
  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumns")) || [
      "Sr.",
      "Name",
      "Agent Number",
      "Approve/Reject",
      "Block/Unblock",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Name",
    "Agent Number",
    "Approve/Reject",
    "Block/Unblock",
  ]);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Agent Number",
      "Approve/Reject",
      "Block/Unblock",
    ]);
  }, []);

  useEffect(() => {
    getPaginationData(1);
  }, [searchParams.get("Approve/Reject")]);

  const getPaginationData = (page) => {
    const agentId = searchParams.get("id");
    setState({
      ...state,
      isWaiting: true,
    });
    let status = searchParams.get("Approve/Reject") || "";
    let data = { currentPage: page, status };

    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getagents", data)
      .then((res) => {
        // console.log("cgheckkkk", res.data);
        const sortedAgents = res.data.details.agents.sort((a, b) => {
          if (a.block && !b.block) return 1;
          if (!a.block && b.block) return -1;
          return 0;
        });
        setState({
          ...state,
          isWaiting: false,
          agents: sortedAgents,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
        });
        setisrotating(false);
      })
      .catch((err) => {
        toast(err.response.data.message);
      });
  };

  const toggleStatus = (agentId, index, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    let oldAgents = state.agents;
    oldAgents[index].status = e.target.value;
    setState({
      ...state,
      agents: oldAgents,
    });
    let data = { agentId, status: e.target.value };
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/togglestatus", data)
      .then((res) => {
        toast.success(res.data.message);
        getPaginationData(currentPage);
        setButtPop(false);
        setLoading(false);
      })
      .catch((err) => { });

  };

  const AsideBarAgentDetails = () => <></>;

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns;

    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem("selectedColumns", JSON.stringify(updatedColumns));
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem("selectedColumns"));

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Name",
        "Agent Number",
        "Approve/Reject",
        "Block/Unblock",
      ]);
      setTempColumnsTable(["Sr.", "Name", "Agent Number", "Approve/Reject", "Block/Unblock"]);
    }
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumns");
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Agent Number",
      "Approve/Reject",
      "Block/Unblock",
    ]);
    setTempColumnsTable(["Sr.", "Name", "Agent Number", "Approve/Reject", "Block/Unblock"]);
    // setModalCode(false);
  };

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumns",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };
  const handleBlockUnblock = (agentId, blockStatus) => {
    const confirmationMessage = blockStatus
      ? "Are you sure you want to block this agent?"
      : "Are you sure you want to unblock this agent?";

    if (confirmationMessage) {
      const data = {
        blockstatus: blockStatus,
        agentId,
      };

      axios
        .post(process.env.REACT_APP_NODE_URL + "/v2/api/blockAgent", data)
        .then((response) => {
          console.log(response.data);
          getPaginationData(currentPage);
          setToggleModal(false);
          toast.success(
            `Agent ${blockStatus ? "Blocked" : "Unblocked"} successfully!`
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error);
        });
    }
  };

  const handleSearch = () => {
    getSearchResult(1, searchName);
  };
  console.log("catData", catData?.value)

  const getSearchResult = (page, searchName) => {
    setState({
      ...state,
      isWaiting: true,
    });


    const data = {
      currentPage: page,
      category: catData?.value,
      status: statData?.value,
      searchName: searchName,
    };

    console.log("data", data)

    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getagents", data)
      .then((res) => {
        const sortedAgents = res.data.details.agents.sort((a, b) => {
          if (a.block && !b.block) return 1;
          if (!a.block && b.block) return -1;
          return 0;
        });
        setState({
          ...state,
          isWaiting: false,
          agents: sortedAgents,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
        });
      })
      .catch((err) => {
        // Handle the error
      });
  };


  const confirmOne = (agentId, index, e) => {
    setButtPop(true);
    setBuId(agentId);
    setBuIndex(index);
    setEe(e);
  };

  const confirmTwo = (agentId, blockStatus) => {
    setToggleModal(true);
    setAgeId(agentId);
    setBlockStatus(blockStatus);
  };
  const handlenewStatus = async (_id) => {
    try {
      const body = {
        agentId: _id,
        newstatus: false,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/v2/api/changeAgentNewStatus`,
        body
      );
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  // refresh page code here
  const startLoader = () => {
    setisrotating(true);
    getPaginationData();
    setSearchName("");
    setCatData("")
    setStatData("")
  };

  const handleClear = () => {
    getPaginationData();
    setSearchName("");
    setCatData("")
    setStatData("")
  };
  return (
    <>
      <ToastContainer />
      <div heading_title={"Agents List"}>
        <>
          {/* buttonPopUp */}
          {buttPop ? (
            <div className="modal_cover">
              <div className="modal_inner select-col-popup">
                <div className="header_modal">
                  <h2 className="capitalize font-bold text-lg mb-2">
                    Are You Sure?
                  </h2>

                  <div className="button-part flex justify-end items-center gap-4">
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={() => setButtPop(false)}
                    >
                      No
                    </button>
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={() => toggleStatus(buId, buIndex, ee)} //call real function here.
                    >
                      Yes
                    </button>
                  </div>
                  <GrClose
                    className="close"
                    onClick={() => setButtPop(false)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* toGGlePopUp */}
          {toggleModal ? (
            <div className="modal_cover">
              <div className="modal_inner select-col-popup">
                <div className="header_modal">
                  <h2 className="capitalize font-bold text-lg mb-2">
                    {blockStatus
                      ? "Are you sure you want to block this agent?"
                      : "Are you sure you want to unblock this agent?"}
                  </h2>

                  <div className="button-part flex justify-end items-center gap-4">
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={() => setToggleModal(false)}
                    >
                      No
                    </button>
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={() => handleBlockUnblock(ageId, blockStatus)} //call real function here.
                    >
                      Yes
                    </button>
                  </div>
                  <GrClose
                    className="close"
                    onClick={() => setToggleModal(false)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row p-45  mob-padding manage-table">
            <div className=" sm:-mx-6 lg:-mx-8">
              <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
                <div className="flex justify-between items-center filter my-5">
                  <div className="flex flex-col md:flex-row justify-center items-center gap-2">
                    <input
                      type="text"
                      className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none college-input"
                      placeholder="Name, Email and Phone..."
                      value={searchName}
                      // onChange={(e) => setSearchName(e.target.value)}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                        
                      }}
                    />
                    <Select
                      name="Approve/Reject"
                      value={statData}
                      options={[
                        { value: "APPROVED", label: "Approved" },
                        { value: "REJECT", label: "Rejected" },
                      ]}
                      className="block w-full flex-1 focus:border-black focus:ring-indigo-500 sm:text-sm college-input"
                      onChange={(selectedOptions) => {
                        // Check if any option is selected
                        setStatData(selectedOptions ? selectedOptions : ""); // You can also set it to `null` if needed
                      }}
                      searchable={true}
                      hideSelectedOptions={false}
                    />

                    <Select
                      name="category"
                      value={catData}
                      options={[
                        { value: "true", label: "Block" },
                        { value: "false", label: "UnBlock" },
                      ]}
                      className="block w-full flex-1 focus:border-black focus:ring-indigo-500 sm:text-sm college-input"
                      onChange={(selectedOptions) => {
                        setCatData(selectedOptions);
                      }}
                      searchable={true}
                      hideSelectedOptions={false}
                    />

                    <button
                      onClick={handleSearch}
                      className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                    >
                      Find
                    </button>
                    <button
                      onClick={handleClear}
                      className="bg-[#5555] text-[#515151] font-bold h-10 px-4 ms-3 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out"
                    >
                      Clear
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="reload-icon mr-2">
                      <i
                        className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                        onClick={startLoader}
                      />
                    </div>
                    <AiFillSetting
                      className="Bar_filter"
                      onClick={() => setModalCode(!ModalCode)}
                    />
                  </div>
                </div>

                <div className="agent-table">
                  <div className="btn_outerxx">
                    {ModalCode ? (
                      <div className="modal_cover">
                        <div className="modal_inner select-col-popup">
                          <div className="header_modal">
                            <h1 className="capitalize font-bold text-lg mb-2">
                              select columns
                            </h1>
                            <p className="border-b pb-2 mb-2">
                              Select the column you want to display or hide.
                            </p>
                            <div
                              className="columns-popup"
                              style={{ justifyContent: "space-between" }}
                            >
                              {tableColumns.map((column, index) => {
                                if (selectedColumnsTable.includes(column.value)) {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        checked={tempColumnsTable.includes(
                                          column.value
                                        )}
                                        onChange={() =>
                                          handleColumnCheckboxChange(column.value)
                                        }
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                } else {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        onClick={() => {
                                          selectedColumnsTable.push(column.value);
                                          setTempColumnsTable(
                                            selectedColumnsTable
                                          );
                                        }}
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                }
                              })}
                            </div>
                            <div className="button-part flex justify-end items-center gap-4">
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleResetAll}
                              >
                                Reset all
                              </button>
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleOk}
                              >
                                Ok
                              </button>
                            </div>
                            <GrClose
                              className="close"
                              onClick={() => setModalCode(false)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="px-0 pt-0">
                    <div className="overflow-auto card col-12 px-0 pt-0 pb-2 table_postioning table-overflow m-0">
                      {state.isWaiting ? (
                        <div>
                          <center className="w-full my-10">
                            <img src={loader} alt="" />
                          </center>
                        </div>
                      ) : state?.agents?.length > 0 ? (
                        <table className="table-auto overflow-scroll w-full agent-table files-table">
                          <thead>
                            <tr>
                              {tableColumns.map((col) => {
                                if (selectedColumnsTable.includes(col.label)) {
                                  return (
                                    <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                      {formatLabel(col.label)}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {state.agents?.map((agent, index) => {
                              const startingIndex =
                                (currentPage - 1) * PAGE_SIZE + index + 1;
                              return (
                                <tr
                                  onClick={() => handlenewStatus(agent._id)}
                                >
                                  {selectedColumnsTable.includes("Sr.") && (
                                    <td className="p-2 capitalize">
                                      <p className="text-xs font-weight-bold mb-0">
                                        <div className="flex items-center">
                                          <div className="mr-1">
                                            {startingIndex}
                                          </div>
                                          {agent.new === true && (
                                            <span className="bg-green-500 text-white text-[10px] p-1 rounded">
                                              New
                                            </span>
                                          )}
                                        </div>
                                      </p>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Name") && (
                                    <td className="p-2 border-2 ">
                                      <div className="flex jusify-center px-2 py-0">
                                        <div>
                                          <img
                                            width={"50px"}
                                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
                                            className="avatar avatar-sm mr-2"
                                            alt="user1"
                                          />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                          <h6
                                            onClick={() =>
                                              navigate(
                                                "/admin/agentprofile?id=" +
                                                agent._id
                                              )
                                            }
                                            className="mb-0 text-sm font-bolder text-[#2a276b] capitalize cursor-pointer hover:underline"
                                          >
                                            <b>{agent.first_name}</b>
                                          </h6>
                                          <p className="text-xs text-secondary mb-0 lowercase">
                                            {agent.username}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes(
                                    "Agent Number"
                                  ) && (
                                      <td className="align-middle border-2 p-2">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          {agent.phone}
                                        </span>
                                      </td>
                                    )}
                                  {selectedColumnsTable.includes("Approve/Reject") && (
                                    <td className="align-middle text-sm border-2 p-2">
                                      <select
                                        name=""
                                        id=""
                                        className={`px-4 py-2 border-2 border-gray ${agent.status === "APPROVED"
                                          ? "bg-[green] text-[#fff]"
                                          : agent.status === "REJECT"
                                            ? "bg-[red] text-[#fff]"
                                            : "bg-[#cdcdcd]"
                                          }`}
                                        onChange={(e) =>
                                          confirmOne(agent._id, index, e)
                                        }
                                      >
                                        <option
                                          value=""
                                          className="border-2 border-gray px-4 py-2"
                                        >
                                          {agent.status === "APPROVED"
                                            ? "APPROVED"
                                            : agent.status === "REJECT"
                                              ? "REJECTED"
                                              : "PENDING"}
                                        </option>
                                        <option
                                          value={
                                            agent.status === "APPROVED"
                                              ? "REJECT"
                                              : "APPROVED"
                                          }
                                          className="border-2 border-gray px-4 py-2"
                                        >
                                          {agent.status === "APPROVED"
                                            ? "REJECT"
                                            : "APPROVE"}
                                        </option>
                                      </select>
                                    </td>
                                  )}

                                  {selectedColumnsTable.includes("Block/Unblock") && (
                                    <td className="text-left border-2">
                                      <Switch
                                        color="error"
                                        checked={agent.block}
                                        onChange={() =>
                                          confirmTwo(agent._id, !agent.block)
                                        }
                                      />
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <p className="text-center p-5 text-red-500">
                            No data available.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-footer pb-0">
                    <div className="pagination">
                      <div className="pages">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next"
                          onPageChange={(event) => {
                            setCurrentPage(event.selected + 1);
                            getPaginationData(event.selected + 1);
                          }}
                          pageCount={state.totalPages}
                          previousLabel="prev"
                          renderOnZeroPageCount={null}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={0}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      {<AsideBarAgentDetails />}
    </>
  );
};

export default Manage;
