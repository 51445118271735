/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../../../helper/auth";

import { useSelector } from "react-redux";

export default function Footer() {
  const [socialIcons, setSocialIcons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDropDown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          process.env.REACT_APP_NODE_URL + "/admin/sociallist"
        );
        if (response.ok) {
          const data = await response.json();
          setSocialIcons(data.details.list);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const landingPage = useSelector((state) => state.landingPage);

  return (
    <div>
      <div className="footer-content bg-black py-16 px-4">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-3 gap-4">
            <div>
              <img
                className="logo-norml mb-3"
                src={landingPage.baseUrl + landingPage.main_logo_a}
                alt=""
              />
              <p className="text-white">
                Learn global means learn anywhere in the world and we provide
                you the best platform to study abroad and make your future
                bright.
              </p>
              <h6 className="text-white my-4">SCO 6 , Sector 41 D , First Floor, Chandigarh</h6>
              <ul className="social-list flex gap-4 items-center custom-socail-links">
                {socialIcons.map((socialIcon) => (
                  <li
                    key={socialIcon._id}
                    className="flex items-center space-x-4"
                  >
                    <Link to={socialIcon.link} className="mx-1" target="_blank">
                      <img
                        src={
                          socialIcon.icon
                            ? process.env.REACT_APP_NODE_URL +
                            "/uploads/agent/" +
                            socialIcon.icon
                            : ""
                        }
                        alt=""
                        className="h-6 w-6 rounded-full"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="text-white font-bold text-2xl mb-5 pb-4 border-b inline-block">
                Quick Links
              </h2>
              <ul>
                <li className="mb-3">
                  <Link className="text-white" to={"/eligible"}>
                    <i
                      className="text-white fa fa-caret-right mr-3"
                      aria-hidden="true"
                    ></i>
                    Apply Now
                  </Link>
                </li>
                <li className="mb-3">
                  <Link className="text-white" to={"/discover"}>
                    <i
                      className="text-white fa fa-caret-right mr-3"
                      aria-hidden="true"
                    ></i>
                    Discover College
                  </Link>
                </li>
                {getToken("student") ? (
                  <>
                    <li className="mb-3">
                      <Link className="text-white" to={"/student/enrolled"}>
                        <i
                          className="text-white fa fa-caret-right mr-3"
                          aria-hidden="true"
                        ></i>
                        Student Dashboard
                      </Link>
                    </li>
                  </>
                ) : getToken("agent") ? (
                  <>
                    <li className="mb-3">
                      <Link className="text-white" to={"/agent/dashboard"}>
                        <i
                          className="text-white fa fa-caret-right mr-3"
                          aria-hidden="true"
                        ></i>
                        Agent Dashboard
                      </Link>
                    </li>
                  </>
                ) : getToken("admin") ? (
                  <>
                    <li className="mb-3">
                      <Link className="text-white" to={"/admin/"}>
                        <i
                          className="text-white fa fa-caret-right mr-3"
                          aria-hidden="true"
                        ></i>
                        Admin Dashboard
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    {/* <li className="mb-3 cursor-pointer">
                      <div className={`text-white dropdown ${showDropDown ? 'drop-active' : ''}`} onClick={() => { setShowDropdown(!showDropDown) }}>
                        <i
                          className="text-white fa fa-caret-right mr-3"
                          aria-hidden="true"
                        ></i>
                        Login/Signup
                      </div>
                    </li>
                    <div className={`flex flex-col ps-3 dropdown-content ${showDropDown ? 'drop-content-active' : ''}`}>
                      <Link to='/studentlogin' className="text-white">Student Login</Link>
                      <Link to='/agentlogin' className="text-white">Agent Login</Link>
                    </div> */}
                    <li className="mb-3">
                      <Link className="text-white" to={"/agentlogin"}>
                        <i
                          className="text-white fa fa-caret-right mr-3"
                          aria-hidden="true"
                        ></i>
                        Agent Login
                      </Link>
                    </li>
                  </>
                )}
                <li className="mb-3">
                  <Link className="text-white" to={"/about"}>
                    <i
                      className="text-white fa fa-caret-right mr-3"
                      aria-hidden="true"
                    ></i>
                    About
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to={"/contact"}>
                    <i
                      className="text-white fa fa-caret-right mr-3"
                      aria-hidden="true"
                    ></i>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="text-white font-bold text-2xl mb-5 pb-4 border-b inline-block">
                Top Colleges
              </h2>
              <ul className="">
                {landingPage.topcolleges.map((el) => {
                  return (
                    <li className="mb-5">
                      <Link
                        to={`/specificcollege/${el._id}`}
                        className="flex items-center"
                      >
                        <img
                          className="rounded-full w-14 h-14 mr-3"
                          src={landingPage.baseUrl + el.collegeLogo}
                          alt=""
                        />
                        <p className="mb-0 text-sm text-white capitalize">
                          {el.college_name}
                        </p>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="lower-content py-5 px-4 bg-gray-700">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-2">
            <div>
              <p className="text-white text-sm text-center lg:text-left mb-5 lg:mb-0">
                Copyright © 2023 Learn Global All Right Reserved
              </p>
            </div>

            <ul className="flex gap-4 lg:gap-8 justify-center lg:justify-end">
              <li className="text-center">
                <a className="text-white text-sm text-center" href="#">
                  FAQ
                </a>
              </li>

              <li className="text-center">
                <a className="text-white text-sm" href="#">
                  Privacy Policy
                </a>
              </li>

              <li className="text-center">
                <a className="text-white text-sm" href="#">
                  Terms & Conditions
                </a>
              </li>

              <li className="text-center">
                <a href="#" className="text-white text-sm">
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
