/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { GrClose } from "react-icons/gr";
import loader from "../../../images/loader.gif";
import OutsideClickHandler from "react-outside-click-handler";
import { getToken } from "../../../helper/auth";
import Cookies from "js-cookie";
const countryToCurrency = require("country-to-currency");
const AddCurrency = () => {
  const [isrotating, setisrotating] = useState(false);
  let token = Cookies.get("admin_token");
  const [state, setState] = useState({
    countryList: [],
    countryId: "",
    phone_code: "",
    countryPrice: "",
    plusPrice: "",
    gst: "",
    isWait: false,
    list: [],
    showPopup: false,
    CurrencyObj: {},
  });

  useEffect(() => {
    fetchCurrencyData();
  }, []);

  const fetchCurrencyData = () => {
    setState({
      ...state,
      isWait: true,
      showPopup: false,
    });
    axios
      .get(process.env.REACT_APP_NODE_URL + "/address/country")
      .then((countryResponse) => {
        // setisrotating(false)
        if (countryResponse.data.status == "1") {
          axios
            .get(process.env.REACT_APP_NODE_URL + "/admin/getcurrency")
            .then(async (response) => {
              var CurrencyObj = {};
              if (response.data.status == "1") {
                setState({
                  ...state,
                  list: response.data.details.list,
                  countryList: countryResponse.data.details.countries,
                  isWait: false,
                  CurrencyObj: CurrencyObj,
                });
                setisrotating(false);
              } else {
                setState({
                  ...state,
                  isWait: false,
                });
              }
            });
        }
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const uploadData = async () => {
    setState({
      ...state,
      isWait: true,
    });
    try {
      const fd = new FormData();

      let requiredError = [];
      if (state.countryId === "") {
        requiredError.push("Country");
      }
      if (requiredError.length > 0) {
        toast.error(requiredError.join(",") + " is required");
        return;
      }

      setState({
        ...state,
        submitProcessing: true,
      });

      const config = {
        headers: { Authorization: `Bearer ${getToken("admin")}` },
      };
      let response = await axios.post(
        process.env.REACT_APP_NODE_URL + "/admin/addcurrency",
        {
          countryId: state.countryId,
          phone_code: state.phone_code,
          plusPrice: Number(state.plusPrice),
          gst: Number(state.gst),
        },
        config
      );
      if (response.data.status === "1") {
        console.log("check", response.data.details);
        toast.success(response.data.message);
        setState({
          ...state,
          countryId: "",
          phone_code: "",
          countryPrice: "",
          plusPrice: "",
          list: [...state.list, response.data.details.newCurrency],
          submitProcessing: false,
          showPopup: false,
          isWait: false,
        });
      } else {
        toast.error(response.data.message);
        setState({
          ...state,
          submitProcessing: false,
        });
      }
    } catch (error) {
      toast.error("Something went wrong");
      setState({
        ...state,
        submitProcessing: false,
      });
    }
  };

  const updateData = async () => {
    try {
      let index = state.updatedId;
      const data = {
        gst: Number(state.gst),
        id: state.list[index]._id,
        plusPrice: Number(state.plusPrice),
      };

      setState({
        ...state,
        submitProcessing: true,
      });

      // console.log("checkToken", token);

      let response = await axios.post(
        process.env.REACT_APP_NODE_URL + "/admin/updateCurrency",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setState({
        ...state,
        showPopup: false,
      });

      if (response.data.status == "1") {
        setState({
          ...state,
          showPopup: false,
          submitProcessing: false,
        });
        toast.success("Updated succesfully");
        fetchCurrencyData();
      } else {
        setState({
          ...state,
          submitProcessing: false,
        });
      }
      // fetchCurrencyData();
    } catch (error) {
      toast.error("Something went wrong");
      setState({
        ...state,
        submitProcessing: false,
      });
    }
    state.showPopup = false;
    state.plusPrice = "";
  };

  const setUpdate = (index) => {
    const selectedCollege = state.list[index];
    setState({
      ...state,
      countryId: selectedCollege.countryId,
      phone_code: selectedCollege.phone_code,
      plusPrice: selectedCollege.plusPrice,
      gst: selectedCollege.gst,
      showPopup: true,
      updatedId: index,
    });
  };

  const removeUpdate = (_) => {
    setState({
      ...state,
      countryId: "",
      phone_code: "",
      plusPrice: "",
      gst: "",
      showPopup: false,
      updatedId: null,
    });
  };

  const startLoader = () => {
    setisrotating(true);
    fetchCurrencyData();
  };

  return (
    <>
      <div heading_title={"Add college Detail"}>
        <>
          <div className="row addCountryPage flex flex-row">
            <OutsideClickHandler
              onOutsideClick={() => {
                setState({
                  ...state,
                  showPopup: false,
                  countryId: "",
                  phone_code: "",
                  countryPrice: "",
                  updatedId: null,
                });
              }}
            >
              <div
                className={`w-5/12 mx-auto my-4 createcollegeNamePopup add_college_popup ${
                  state.showPopup && "active"
                }`}
              >
                <label htmlFor="">
                  <div className="flex justify-between align-center relative ">
                    <div className="span">Add Currency Details</div>
                    <GrClose
                      onClick={() => {
                        setState({
                          ...state,
                          showPopup: false,
                          countryId: "",
                          phone_code: "",
                          countryPrice: "",
                          updatedId: null,
                        });
                      }}
                      className="close"
                    />
                  </div>
                </label>
                <div className=" card-body p-2">
                  {state.updatedId == null && (
                    <>
                      <label>Country Name</label>
                      <div className="mb-3">
                        <select
                          className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                          name="countryId"
                          value={state.countryId}
                          onChange={handleChange}
                        >
                          <option value="">Choose</option>
                          {state.countryList.map((country) => {
                            return (
                              <option value={country.countryId}>
                                {country.countryName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </>
                  )}

                  <label>Plus Price </label>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm   "
                      value={state.plusPrice}
                      name="plusPrice"
                      onChange={handleChange}
                    />
                  </div>

                  <label>Tax (%) </label>
                  <div className="mb-3">
                    <input
                      type="number"
                      className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm   "
                      value={state.gst}
                      name="gst"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex mt-3 justify-end">
                    {state.updatedId != null ? (
                      <ButtonPrimary
                        title={"Update"}
                        onclick={updateData}
                        loading={state.submitProcessing}
                      />
                    ) : (
                      <ButtonPrimary
                        title={"Save"}
                        onclick={uploadData}
                        loading={state.submitProcessing}
                      />
                    )}
                    {state.updatedId != null && (
                      <div className="ml-1">
                        <ButtonPrimary
                          title={"Cancel"}
                          onclick={removeUpdate}
                          theme="danger"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </OutsideClickHandler>
            <div className="mx-auto w-full my-4 p-45 mob-padding">
              <div className="left">
                <div
                  className="college-list-btn flex items-center gap-4 justify-end"
                  style={{ marginTop: "15px" }}
                >
                  <div className=" flex items-center "></div>

                  <button
                    className="addNewBtn hover:bg-[#065f46]"
                    onClick={() => {
                      setState({
                        ...state,
                        showPopup: true,
                      });
                    }}
                  >
                    Add New
                  </button>
                  <div className="reload-icon mr-2">
                    <i
                      className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                      onClick={startLoader}
                    />
                  </div>
                </div>
              </div>
              {state.isWait ? (
                <div>
                  <center className="w-full my-10">
                    <img src={loader} alt="" />
                  </center>
                </div>
              ) : (
                <div class="flex flex-col table_postioning">
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <div class="overflow-hidden  border table-overflow">
                        <table class="min-w-full agent-table">
                          <thead class="">
                            <tr>
                              <th class="text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Sr.
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                country name
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Currency
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Live Rate
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Plus Price
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Tax (%)
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Total
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-6 py-4 text-center font-bold">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.list.map((college, index) => {
                              const livePrice = college?.livePriceInr;
                              const plusPrice = college?.plusPrice;
                              const taxPercentage = college?.gst;
                              const mainPrice = livePrice + plusPrice;
                              const taxValue =
                                (taxPercentage / 100) * mainPrice;
                              const total = mainPrice + taxValue;
                              return (
                                <tr
                                  key={index}
                                  className="border-b"
                                >
                                  <td className="p-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {index + 1}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    {college?.countryName || "--"}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    {college?.currency || "--"}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    {livePrice || "--"}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    {plusPrice || "--"}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    {taxPercentage + "%" || "--"}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    {total.toFixed(2)}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap">
                                    <div className="flex items-center justify-center space-x-2">
                                      <i
                                        className="action-icon fa-solid fa-pen-to-square cursor-pointer"
                                        onClick={() => setUpdate(index)}
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default AddCurrency;
