import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getToken } from "../../../helper/auth";
import { ToastContainer, toast } from "react-toastify";
import { GrClose } from "react-icons/gr";
import loader from "../../../images/loader.gif";
import { useNavigate } from "react-router-dom";
import AgentFindProgram from "./AgentFindPrograms";
import AgentFindProgramsEnroll from "./AgentFindProgramsEnroll";
const AgentStudentInner = () => {
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  const [ShowPopup, setShowPopup] = useState(false);
  const [editPop, setEditPop] = useState(false);
  const [loading, setLoading] = useState(true);
  const agentToken = getToken("agent");
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [id, setId] = useState();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    isWaiting: false,
    students: [],
    agentToken: getToken("agent"),
    totalPages: 0,
    currentPage: 1,
  });

  console.log("state.students", state.students)
  

  const getPaginationData = async (page, searchName) => {
    setLoading(true);
    const config = { headers: { Authorization: `Bearer ${state.agentToken}` } };
    // let data = { currentPage: page };
    let data;
    if (searchName === undefined) {
      data = { currentPage: page, searchName: "" };
    } else {
      data = { currentPage: page, searchName: searchName };
    }
    await axios
      .post(process.env.REACT_APP_NODE_URL + "/agent/getstudents", data, config)
      .then((res) => {
        setState({
          ...state,
          students: res.data.details.students,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
        setLoading(false);
        setisrotating(false);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getPaginationData(1);
  }, []);

  const handleSearch = () => {
    getPaginationData(1, searchName);
  };

  const startLoader = async () => {
    setisrotating(true);
    await getPaginationData(1);
    setSearchName("");
  };

  const handleEnroll = (student) => {
    setSelectedStudent(student);
    setEditPop(true);
    setShowPopup(false);
  };
  const handlenewStatus = async (_id) => {
    try {
      const body = {
        studentId: _id,
        newstatus: false,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/v2/api/changeStudentStatus`,
        body
      );
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };


  return (
    <>
      <ToastContainer />
      <div className="p-45">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
            <div className="row addCountryPage flex flex-row mt-4 mb-4">
              <div className="flex flex-col md:flex-row justify-center items-center gap-2">
                <input
                  type="text"
                  className="border border-gray-300 py-2 px-4 rounded-md focus:outline-none college-input"
                  placeholder="Name, Email and Phone..."
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
                <button
                  onClick={handleSearch}
                  className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                >
                  Find
                </button>
              </div>

              <div
                className={`w-5/12 mx-auto m-5 createcollegeNamePopup ${editPop ? "active" : ""
                  }`}
              >
                <label>
                  <div className="flex justify-between align-center">
                    <div className="span">Enroll Student</div>
                    <GrClose
                      className="close"
                      onClick={() => {
                        setEditPop(false);
                      }}
                    />
                  </div>
                </label>
                <div className="card-body p-2">
                  <AgentFindProgramsEnroll students={selectedStudent} />
                </div>
              </div>

              <div
                className={`w-5/12 mx-auto m-5 createcollegeNamePopup ${ShowPopup ? "active" : ""
                  }`}
              >
                <label>
                  <div className="flex justify-between align-center">
                    <div className="span">Add New Student</div>
                    <GrClose
                      className="close"
                      onClick={() => {
                        setShowPopup(false);
                      }}
                    />
                  </div>
                </label>
                <div className="card-body p-2">
                  <AgentFindProgram ShowPopup={ShowPopup} />
                </div>
              </div>
              <div className="mx-auto w-full">
                <div className="flex justify-end mt-4 items-center addscl-cs md:mt-0 gap-2">
                  <div className="left flex items-center"></div>
                  <button
                    className="addNewBtn hover:bg-[#065f46]"
                    onClick={() => {
                      setShowPopup(true);
                      setEditPop(false);
                    }}
                  >
                    Add Student
                  </button>
                  <div className="reload-icon mr-2">
                    <i
                      className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                      onClick={startLoader}
                    />
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div>
                <center className="w-full my-10">
                  <img src={loader} alt="Loading..." />
                </center>
              </div>
            ) : (
              <div className="overflow-auto card  my-4 col-12 px-0 pt-0 pb-2 agent-table border table-overflow">
                <table className="table-auto overflow-scroll w-full files-table enrolled-list-table">
                  <thead>
                    <tr>
                      <th className="p-4 text-left w-1">Sr.</th>
                      <th className="p-4 text-left">Name</th>
                      <th className="p-4 text-left">Email</th>
                      <th className="p-4 text-left">Phone</th>
                      <th className="p-4 text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.students.length > 0 ? (
                      state.students.map((student, index) => {
                        const serialNumber =
                          (state.currentPage - 1) * 10 + index + 1;
                        return (
                          <tr key={index} className="hover-bg-gray-200">
                            <td
                              onClick={() => handlenewStatus(student._id)}
                              className="p-4 max-width">
                              <p className="text-xs font-weight-bold mb-0">
                                <div className="flex items-center">
                                  <div className="mr-1">
                                    {serialNumber}
                                  </div>
                                  {student.new === true && (
                                    <span className="bg-green-500 text-white text-[10px] p-1 rounded">
                                      New
                                    </span>
                                  )}
                                </div>
                              </p>
                            </td>
                            <td className="p-4 text-left">
                              <p className="text-xs font-weight-bold mb-0">
                                {student.firstName + " " + student.lastName ||
                                  "--"}
                              </p>
                            </td>
                            <td className="p-4 text-left">
                              <p className="text-xs font-weight-bold mb-0">
                                {student.email || "--"}
                              </p>
                            </td>
                            <td className="p-4 text-left">
                              <p className="text-xs font-weight-bold mb-0">
                                {student.phone || "--"}
                              </p>
                            </td>
                            <td className="p-4 text-left">
                              <p className="text-xs font-weight-bold mb-0">
                                <button
                                  className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                                  onClick={() => handleEnroll(student)}
                                >
                                  Enroll
                                </button>
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-center">
                          <div>
                            <p className="text-center p-5 text-red-500">
                              No data available.
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <div className="card-footer pb-0">
              <div className="pagination">
                <div className="pages">
                  {state.totalPages >=2 && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={(event) => {
                        getPaginationData(event.selected + 1);
                      }}
                      pageCount={state.totalPages}
                      previousLabel="prev"
                      renderOnZeroPageCount={null}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      containerClassName="pagination"
                      subContainerClassName="pages"
                      initialPage={state.currentPage - 1}
                      forcePage={state.currentPage - 1}
                      activeClassName="selected"
                      previousClassName="previous"
                      nextClassName="next"
                      disabledClassName="disabled"
                      breakClassName="break"
                      pageClassName="page"
                      onPageActive={(page) => {
                        if (page === 0) {
                          setFirstPage(state.currentPage);
                        } else if (page === state.totalPages - 1) {
                          setLastPage(state.currentPage);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentStudentInner;
