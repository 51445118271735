import React, { useState } from "react";
import axios from "axios";
import "./AssessmentForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AssessmentForm = ({ popup, closeForm }) => {
  const [thankssubmission, setthankssubmission] = useState(false);
  const [state, setState] = useState({
    activeIndex: 0,
    btnLoading: false,
  });

  const [formdata, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    destination_country: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    full_name: "",
    email: "",
    phone: "",
    destination_country: "",
  });

  const handleFormData = (e) => {
    setFormData({
      ...formdata,
      [e.target.name]: e.target.value,
    });

    setValidationErrors({
      ...validationErrors,
      [e.target.name]: "",
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formdata.full_name) {
      errors.full_name = "Full Name is required";
    }
    if (!formdata.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formdata.email)) {
      errors.email = "Invalid email format";
    }
    if (!formdata.phone) {
      errors.phone = "Phone is required";
    }
    if (!formdata.destination_country) {
      errors.destination_country = "Please select a destination country";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    return true;
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setState({
        ...state,
        btnLoading: true,
      });

      const newObj = {
        full_name: formdata.full_name,
        email: formdata.email,
        phone: formdata.phone,
        destination_country: formdata.destination_country,
        btnLoading: formdata.btnLoading,
      };

      axios
        .post(
          `${process.env.REACT_APP_NODE_URL}/student/fillassessmentform`,
          newObj
        )
        .then((response) => {
          if (response.data.status == "1") {
            setthankssubmission(true);
            setTimeout(() => {
              closeForm();
            }, 5000);
            setState({
              ...state,
              btnLoading: false,
            });
          } else {
            setState({
              ...state,
              btnLoading: false,
            });
          }
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={`hide_popup_outer-type ${popup ? "overlay-open" : ""}`}>
        <div className={`${popup ? "popup" : ""} custom-body`}>
          {thankssubmission == true ?
            <div className="bg-[#149449] text-white p-4 rounded-md mb-4">
              <div className="flex items-center">
                <svg
                  className="w-6 h-6 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <p className="text-lg font-semibold">
                  Thank you for applying.<br /> Our team will contact you<br /> within 24 hours.
                </p>
              </div>
            </div>
            :
            <div className="pop-form">
              <h3 className="flex items-center justify-between p-[10px]">
                Visa Assessment Form
                <span onClick={closeForm} className="cursor-pointer">
                  <i className="fa fa-xmark"></i>
                </span>
              </h3>
              <div className="flex vissa">
                <p className="mb-2">
                  Learn Global, Please fill the below visa enquiry form to help us
                  assist you better.
                </p>
              </div>
              <form onSubmit={submitForm}>
                <div className="flex vissa  justify-content-center">
                  <input
                    type="text"
                    placeholder="Full Name*"
                    onChange={handleFormData}
                    value={formdata.full_name}
                    id="full_name"
                    name="full_name"
                    className="w-6/12"
                  />
                </div>
                <p className="px-3" style={{ color: "red" }}>
                  {validationErrors.full_name}
                </p>

                <div className="flex vissa">
                  <input
                    type="email"
                    placeholder="Email*"
                    onChange={handleFormData}
                    value={formdata.email}
                    id="email"
                    name="email"
                  />
                </div>
                <p className="px-3" style={{ color: "red" }}>
                  {validationErrors.email}
                </p>

                <div className="flex vissa">
                  <input
                    type="text"
                    placeholder="Phone*"
                    onChange={handleFormData}
                    value={formdata.phone}
                    id="phone"
                    name="phone"
                  />
                </div>
                <p className="px-3" style={{ color: "red" }}>
                  {validationErrors.phone}
                </p>

                <p className="mb-0 prefer">Your preferred study destination</p>
                <div className="flex vissa">
                  <select
                    onChange={handleFormData}
                    value={formdata.destination_country}
                    id="destination_country"
                    name="destination_country"
                  >
                    <option value="">--Select--</option>
                    <option value="Australia">Australia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Canada">Canada</option>
                    <option value="Usa">Usa</option>
                  </select>
                </div>
                <p className="px-3" style={{ color: "red" }}>
                  {validationErrors.destination_country}
                </p>

                <p className="mb-0 mx-[10px]">
                  Learn Global is that institution that removes rough edges from
                  the way of your success.
                </p>

                <div className="flex vissa">
                  <button type="submit">
                    {state.btnLoading ? (
                      <center>
                        <div aria-label="Loading..." role="status">
                          <svg
                            className="h-4 w-4 animate-spin"
                            viewBox="3 3 18 18"
                          >
                            <path
                              className="fill-gray-200"
                              d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                            ></path>
                            <path
                              className="fill-gray-800"
                              d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                            />
                          </svg>
                        </div>
                      </center>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    </>
  );
};
export default AssessmentForm;
