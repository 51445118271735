import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../../helper/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash } from "react-icons/fa";
const HomePageSetting = () => {
  const [state, setState] = useState({
    abt_text: "",
    media_text: "",
    main_email: "",
    scholarship: null,
    total_student: null,
    total_courses: null,
    title: "",
    linkedIn: "",
    insta: "",
    fb: "",
    twitter: "",
    abt_image: "",
    media_image: "",
    cover_image: "",
    mainLogoA: "",
    mainLogoB: "",
    baseUrl: ""
  })
  const [items, setItems] = useState([]);

  const [files, setFiles] = useState({
    abt_image: "",
    media_image: "",
    mainLogoA: "",
    mainLogoB: "",
  })

  const [wait, setWait] = useState(true)
  const config = { headers: { Authorization: `Bearer ${getToken("admin")}` } };

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {

    axios
      .get(process.env.REACT_APP_NODE_URL + "/admin/homePageSettings", config)
      .then((res) => {
        setWait(false)
        setFiles({
          abt_image: "",
          media_image: "",
          mainLogoA: "",
          mainLogoB: "",
          cover_image: "",
        })
        setState({
          ...state,
          baseUrl: res?.data?.details?.baseUrl,
          abt_text: res?.data?.details?.settings?.about_text || "",
          abt_image: res?.data?.details?.settings?.about_image || "",
          media_text: res?.data?.details?.settings?.media_text || "",
          media_image: res?.data?.details?.settings?.media_image || "",
          main_email: res?.data?.details?.settings?.main_email || "",
          mainLogoA: res?.data?.details?.settings?.main_logo_a || "",
          mainLogoB: res?.data?.details?.settings?.main_logo_b || "",
          cover_image: res?.data?.details?.settings?.cover_image || "",
          scholarship: res?.data?.details?.settings?.scholarships || 0,
          total_student: res?.data?.details?.settings?.total_students || 0,
          total_courses: res?.data?.details?.settings?.total_courses || 0,
          title: res?.data?.details?.settings?.title_line || "",
          linkedIn: res?.data?.details?.settings?.social_linkedin || "",
          insta: res?.data?.details?.settings?.social_instagram || "",
          fb: res?.data?.details?.settings?.social_facebook || "",
          twitter: res?.data?.details?.settings?.social_twitter || "",
        })
      })
  }

  function handleInputChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleFiles = (e) => {
    setFiles({
      ...files,
      [e.target.name]: e.target.files[0]
    })
  };

  const handleMultipleFiles = (e) => {
    setFiles({
      ...files,
      [e.target.name]: e.target.files
    })
  };


  const submitNow = () => {
    const fd = new FormData();

    if (files.media_image != "") {
      for (let index = 0; index < files.media_image.length; index++) {
        const element = files.media_image[index];
        fd.append("media_image", element)
      }
    }
    if (files.abt_image != "") {
      fd.append("about_image", files.abt_image)
    }
    if (files.cover_image != "") {
      fd.append("cover_image", files.cover_image)
    }
    if (files.mainLogoA != "") {
      fd.append("main_logo_a", files.mainLogoA)
    }
    if (files.mainLogoB != "") {
      fd.append("main_logo_b", files.mainLogoB)
    }

    fd.append("about_text", state.abt_text)
    fd.append("media_text", state.media_text)
    fd.append("main_email", state.main_email)
    fd.append("scholarships", state.scholarship)
    fd.append("total_student", state.total_student)
    fd.append("total_courses", state.total_courses)
    fd.append("title_line", state.title)
    fd.append("social_linkedin", state.linkedIn)
    fd.append("social_instagram", state.insta)
    fd.append("social_facebook", state.fb)
    fd.append("social_twitter", state.twitter)

    axios
      .patch(process.env.REACT_APP_NODE_URL + "/admin/homePageSettings", fd, config)
      .then((res) => {
        fetchData()

        toast.success(res.data.message)
      })
  }

  if (wait) {
    return "Loading..."
  }

  return (
    <>
      <ToastContainer />
      <div className="w-full p-45 settings-form flex justify-start">
        {/* <div className="container" style={{ marginLeft: 0, paddingLeft: 0 }}> */}
        <div className="sm:-mx-6 lg:-mx-8">
          <div className='inline-block min-w-full sm:px-6 lg:px-8 w-full'>
            <form class="w-full my-5 home-width ">
              <div class="flex flex-wrap mb-6">
                <div class="w-full mb-6 md:mb-0">

                  <div class="w-full">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Image
                    </label>
                    <input onChange={(event) => handleFiles(event)} class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" name="cover_image" type="file" placeholder="Upload Image" />
                    {
                      state.cover_image != "" && state.cover_image &&
                      <img className='home-page-setting-images' src={state.baseUrl + state.cover_image} alt="" />
                    }
                  </div>
                </div>
              </div>

              <hr />
              <br />

              <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2 w-full" for="grid-first-name">
                About
              </label>
              <div class="flex flex-wrap mb-6">
                <div class="w-full md:w-1/2 mb-6 md:mb-0 md:pr-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-first-name">
                    Text
                  </label>
                  <textarea cols="30" rows="5" className="resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-black-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" value={state.abt_text} id="grid-first-name" name="abt_text" placeholder="Enter Text" type="text" onChange={(event) => handleInputChange(event)}></textarea>

                </div>
                <div class="w-full md:w-1/2 md:pl-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-last-name">
                    Image
                  </label>
                  <input onChange={(event) => handleFiles(event)} class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" name="abt_image" type="file" placeholder="Upload Image" />
                  {
                    state.abt_image != "" && state.abt_image &&
                    <img className='home-page-setting-images' src={state.baseUrl + state.abt_image} alt="" />
                  }
                </div>
              </div>
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                Media
              </label>
              <div class="flex flex-wrap mb-6">
                <div class="w-full md:w-1/2 mb-6 md:mb-0 md:pr-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-first-name">
                    Text
                  </label>
                  <textarea cols="30" rows="5" value={state.media_text} className="resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-black-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" name="media_text" placeholder="Enter Text" onChange={(event) => handleInputChange(event)}></textarea>
                </div>
                <div class="w-full md:w-1/2 md:pl-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-last-name">
                    Image
                  </label>
                  <input multiple onChange={(event) => handleMultipleFiles(event)} class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="media_image" id="grid-last-name" type="file" placeholder="Upload image" />
                  <div className="flex">
                    {
                      state.media_image != "" && state.media_image &&
                      <>
                        {
                          state.media_image.map(img => {
                            return <img className='home-page-setting-images' src={state.baseUrl + img} alt="" />
                          })
                        }
                      </>
                    }
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-password">
                    Main Email
                  </label>
                  <input value={state.main_email} onChange={(event) => handleInputChange(event)} name="main_email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Enter Main Email" />
                  {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-password">
                    Title Line
                  </label>
                  <input value={state.title} onChange={(event) => handleInputChange(event)} name="title" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Enter Title Email" />
                  {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                </div>
              </div>
              <label class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-city">
                Logo
              </label>
              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                    Main Logo a
                  </label>
                  <input onChange={(event) => handleFiles(event)} name="mainLogoA" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="file" placeholder="Upload logo" />
                  {
                    state.mainLogoA != "" && state.mainLogoA &&
                    <img className='home-page-setting-images' src={state.baseUrl + state.mainLogoA} alt="" />
                  }
                </div>

                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                    Main Logo b
                  </label>
                  <input onChange={(event) => handleFiles(event)} name="mainLogoB" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="file" placeholder="Upload logo" />
                  {
                    state.mainLogoB != "" && state.mainLogoB &&
                    <img className='home-page-setting-images' src={state.baseUrl + state.mainLogoB} alt="" />
                  }
                </div>
              </div>
              <label class="mt-5 block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" for="grid-city">
                Achievements
              </label>
              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                    Scholarships
                  </label>
                  <input value={state.scholarship} onChange={(event) => handleInputChange(event)} name="scholarship" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Scholarships" />
                </div>
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                    Total Students
                  </label>
                  <input value={state.total_student} onChange={(event) => handleInputChange(event)} name='total_student' class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="Total Students" />
                </div>
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                    Total Courses
                  </label>
                  <input value={state.total_courses} onChange={(event) => handleInputChange(event)} name="total_courses" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="Total Courses" />
                </div>
              </div>
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold  py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={submitNow}>
                Submit
              </button>
            </form> 
          </div>
        </div>
        {/* </div> */}
      </div >
    </>
  )
}


export default HomePageSetting;