import "./App.css";
import "./fileTrack.css";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./common/ProtectedRoute";
import { getToken } from "./helper/auth";
import logoblack from "./images/logoblack.png";
import Addcolleges from "./users/admin/Pages/Addcolleges";
import AdminSubStudents from "./users/admin/Pages/AdminSubStudents";
import AdminSendOtp from "./users/admin/Pages/AdminSendOtp";
import DashboardPage from "./users/admin/Pages/DashboardPage";
import AgentDashboardPage from "./users/agent/Screens/Dashboard/AgentDashboardPage";
import Login from "./users/admin/Pages/Login";
import Manage from "./users/admin/Pages/Manage";
import ProgramsList from "./users/admin/Pages/ProgramsList";
import CollegeList from "./users/admin/Pages/CollegeList";
import StudentList from "./users/admin/Pages/StudentList";
import AgentAddStudent from "./users/agent/Pages/AgentAddStudent";
import AgentGetStudent from "./users/agent/Pages/AgentGetStudent";
import AgentLogin from "./users/agent/Pages/AgentLogin";
import AgentProfile from "./users/agent/Pages/AgentProfile";
import AgentRegister from "./users/agent/Pages/AgentRegister";
import AgentDashboard from "./users/agent/Screens/Dashboard/AgentDashboard";
import AgentStudentInner from "./users/agent/Pages/AgentStudentInner";
import StudentConfirm from "./users/student/common/StudentConfirm";
import StudentLogin from "./users/student/Pages/StudentLogin";
import StudentRegister from "./users/student/Pages/StudentRegister";
import StudentDashboard from "./users/student/Screens/Dashboard/StudentDashboard";
import AddCountry from "./users/admin/Pages/AddCountry";
import AddcollegesName from "./users/admin/Pages/AddcollegeName";
import Notifications from "./users/admin/Pages/Notifications";
import SettingsTab from "./users/admin/Pages/SettingsTab";

// import WebsiteHome from "./users/website/screens/WebsiteMain";
import WHome from "./users/website/Pages/WHome";
import WEligible from "./users/website/Pages/WEligible";
import WSearch from "./users/website/Pages/WSearch";
import axios from "axios";
import AdminAgentProfile from "./users/admin/Pages/AdminAgentProfile";
import AgentNotifications from "./users/agent/Pages/AgentNotifications";
import CreateEmployee from "./users/admin/Pages/CreateEmployee";
import EmployeeList from "./users/admin/Pages/EmployeeList";
import Header from "./users/admin/common/Header/Header";
import Navbar from "./users/admin/common/Header/Navbar";
import Dashboard from "./users/admin/Screens/Dashboard/Dashboard";
import { requestForToken } from "./firebase";
import Notification from "./common/Notifications";
import StudentForgot from "./users/student/common/StudentForgot";
import IntakesManagement from "./users/admin/Pages/IntakesManagement";
import ProgramIntakes from "./users/admin/Pages/ProgramIntakes";
// import DataTable from "./users/admin/Pages/DataTable";
import CollegeUpdate from "./users/admin/Pages/CollegeUpdate";
import AssessmentForms from "./users/admin/Pages/AssessmentForms";
import SearchQueryForms from "./users/admin/Pages/SearchQueryForms";
import StudentEnrolled from "./users/student/Pages/StudentEnrolled";
import StudentPrograms from "./users/student/Pages/StudentPrograms";
import StudentDocuments from "./users/student/Pages/StudentDocuments";
import AdminStudentProfile from "./users/admin/Pages/AdminStudentProfile";
import StudentNotifications from "./users/student/Pages/StudentNotifications";
import StudentHistory from "./users/student/Pages/StudentHistory";
import StudentRemarks from "./users/student/Pages/StudentRemarks";
import FilesList from "./users/admin/Pages/FilesList";
import Login2 from "./users/admin/Pages/Login2";

// import Login3 from "./users/admin/Pages/Login3";

import { Profile } from "./users/admin/Pages/Pofile";
import AgentFindProgram from "./users/agent/Pages/AgentFindPrograms";
import AgentProgramsList from "./users/agent/Pages/AgentProgramsList";
import { StudentProfile } from "./users/student/Pages/StudentProfile";
import { Security } from "./users/admin/Pages/Security";
import Wabout from "./users/website/Pages/Wabout";
import Wdiscover from "./users/website/Pages/Wdiscover";
import Wcontact from "./users/website/Pages/Wcontact";
import AgentSubProgramsList from "./users/agent/Pages/AgentSubProgramsList";
import AgentEnrolledList from "./users/agent/Pages/AgentEnrolledList";
import AgentStudentDocuments from "./users/agent/Pages/AgentStudentDocuments";
import Wcountry1 from "./users/website/Pages/Wcountry1";
import Wviewdetails from "./users/website/Pages/Wviewdetails";
import AgentStudentRemarks from "./users/agent/Pages/AgentStudentRemarks";
import AddCurrency from "./users/admin/Pages/AddCurrency";
import EnglishSpeakingNations from "./users/admin/Pages/EnglishSpeakingNations";
import ProgramUpdate from "./users/admin/Pages/ProgramUpdate";
import AdminStudentRemarks from "./users/admin/Pages/AdminStudentRemarks";
import WebsiteHome from "./users/website/Screens/WebsiteHome";
import loader from "./images/loader.gif";
import AgentLoginForm from "./users/admin/Pages/AgentLoginForm";
import StudentLoginForm from "./users/admin/Pages/StudentLoginForm";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setData } from "./redux/reducers/landingPage";
import AddRequiredDocuments from "./users/admin/Pages/AddRequiredDocuments";
import StudentEmbassyDocuments from "./users/student/Pages/StudentEmbassyDocuments";
import ManageFileStatus from "./users/admin/Pages/ManageFileStatus";
import HomePageSetting from "./users/admin/Pages/HomePageSetting";
import SocialIconSetting from "./users/admin/Pages/SocialIconSetting";
import { BasicInfo } from "./users/admin/Pages/abc";
import AdminCommission from "./users/agent/Pages/AdminCommission";
import AgentCommission from "./users/student/Pages/AgentCommission";
import NotFound from "./Notfound";
import ScrollToTopButton from "./ScrollToTopButton";
import EnrolledHistory from "./users/admin/Pages/EnrolledHistory";
// import AdminCommStudent from "./users/agent/Pages/AdminCommStudent";
// web-socket
// import socketIOClient from "socket.io-client";
// const ENDPOINT = "http://127.0.0.1:3006";
// console.log("COnnecting")
// const socket = socketIOClient(ENDPOINT);

const App = () => {
  const dispatch = useDispatch();
  const [protectedToken, setprotectedToken] = useState("");
  const roleFromUrl = window.location.href.split("/")[4];
  const [state, setState] = useState({
    wait: true,
    tokenAdmin: false,
    tokenAgent: false,
    tokenStudent: false,
    currentPermissions: [],
  });
  const [showPopup, setShowPopup] = useState(true);
  const [password, setPassword] = useState("");
  const [enteredPassword, setEnteredPassword] = useState(
    localStorage.getItem("enteredPassword") || ""
  );
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (enteredPassword === "jas@global") {
      setShowPopup(false);
    }
  }, [enteredPassword]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterButtonClick = () => {
    if (password === "jas@global") {
      setEnteredPassword(password);
      localStorage.setItem("enteredPassword", password);
      setShowPopup(false);
    } else {
      // Handle incorrect password
      alert("Incorrect password. Please try again.");
    }
  };

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${getToken("agent")}` },
    };
    requestForToken().then((token) => {
      axios
        .post(
          process.env.REACT_APP_NODE_URL + "/agent/verifyToken",
          { token },
          config
        )
        .then((res) => {
          setprotectedToken(res.data.message);
        })
        .catch((err) => { });
    });
  }, []);
  // useEffect(() => {
  //   socket.on("FromAPI", data => {
  //     console.log(data)
  //   });
  // }, []);

  useEffect(() => {
    let tokenAdmin = getToken("admin");
    let tokenAgent = getToken("agent");
    let tokenStudent = getToken("student");

    // get Permissions
    var myToken;
    if (roleFromUrl == "admin") {
      myToken = tokenAdmin;
      const config = { headers: { Authorization: `Bearer ${myToken}` } };
      requestForToken().then((token) => {
        axios
          .post(
            process.env.REACT_APP_NODE_URL + "/admin/verifyToken",
            { token },
            config
          )
          .then((res) => {
            if (res.data.status == "0") {
              setState({
                ...state,
                wait: false,
                currentPermissions: "ALLOW",
                tokenAdmin,
                tokenAgent,
                tokenStudent,
              });
              return;
            }
            if (res.data.details.userData.role != "ADMIN") {
              setState({
                ...state,
                currentPermissions: res.data.details.userData.permissions,
                wait: false,
                tokenAdmin,
                tokenAgent,
                tokenStudent,
              });
              return;
            }

            setState({
              ...state,
              currentPermissions: "ALLOW",
              wait: false,
              tokenAdmin,
              tokenAgent,
              tokenStudent,
            });
            return;
          })
          .catch((err) => { });
      });
    } else if (roleFromUrl == "student") {
      myToken = tokenStudent;
      const config = { headers: { Authorization: `Bearer ${myToken}` } };
      requestForToken().then((token) => {
        axios
          .post(
            process.env.REACT_APP_NODE_URL + "/student/verifyToken",
            { token },
            config
          )
          .then((res) => {
            setState({
              ...state,
              currentPermissions: "ALLOW",
              wait: false,
              tokenAdmin,
              tokenAgent,
              tokenStudent,
            });
            return;
          })
          .catch((err) => { });
      });
    } else {
      axios
        .get(process.env.REACT_APP_NODE_URL + "/student/landingPage")
        .then((response) => {
          let data = response.data.details;
          dispatch(setData(data));
          setState({
            ...state,
            currentPermissions: "ALLOW",
            wait: false,
            tokenAdmin,
            tokenAgent,
            tokenStudent,
          });
        });
    }
  }, []);

  if (state.wait) {
    return (
      <center className="bg-white flex h-screen items-center justify-center">
        <img width={"500px"} src={loader} alt="" />
      </center>
    );
  }

  const testing_url = process.env.REACT_APP_NODE_URL
  console.log("testing_url", testing_url)
  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-md w-96">
            <img
              src={logoblack}
              alt="logo"
              className="mx-auto mb-6"
              style={{ width: "150px" }}
            />
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-semibold mb-2 text-gray-800"
              >
                Password:
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="border border-gray-300 p-2 w-full rounded-md"
                />
                <button
                  type="button"
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 text-xl"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? "🙈" : "👁️"}
                </button>
              </div>
            </div>
            <button
              onClick={handleEnterButtonClick}
              className="bg-green-700 text-white px-4 py-2 
              rounded w-full hover:bg-green-600 focus:outline-none focus:shadow-outline-green active:bg-green-800"
            >
              Enter
            </button>
          </div>
        </div>
      )}
      {!showPopup && (
        <>
          <Notification />
          <ScrollToTopButton />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/d123/" element={<BasicInfo />} />
            {/* <Route path="/LoginSignup" element={<Login3 />} /> */}
            <Route path="/agentlogin" element={<AgentLoginForm />} />
            <Route path="/studentlogin" element={<StudentLoginForm />} />
            <Route path="/adminlogin" element={<Login2 />} />
            <Route path="/admin/forgot/:token" element={<Login2 />} />
            {/* <Route path="/LoginSignup" element={<Home isAdmin={false} />} />
          <Route
            path="/adminlogin"
            element={
              <Home isAdmin={true} role="ADMIN" token={state.tokenAdmin} />
            }
          /> */}

            <Route path="/admin" element={<Dashboard />}>
              <Route
                index
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="dashboard"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="home-page-settings"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <HomePageSetting />
                  </ProtectedRoute>
                }
              />

              <Route
                path="website-settings"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <SettingsTab />
                  </ProtectedRoute>
                }
              />

              <Route
                path="social-icon-settings"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <SocialIconSetting />
                  </ProtectedRoute>
                }
              />

              <Route
                path="profile"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="security"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <Security />
                  </ProtectedRoute>
                }
              />
              <Route
                path="students"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"student_list"}
                  >
                    <StudentList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="manage"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"csv_programs"}
                  >
                    <Manage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="agentprofile"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <AdminAgentProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="studentprofile"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <AdminStudentProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="studentprofile"
                element={
                  <ProtectedRoute token={state.tokenAdmin} role={"admin"}>
                    <AdminAgentProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="colleges"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"sp_list"}
                  >
                    <CollegeList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="programs/:id"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"sp_list"}
                  >
                    <ProgramsList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="addcolleges"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"csv_programs"}
                  >
                    <Addcolleges />
                  </ProtectedRoute>
                }
              />
              <Route
                path="agent_students/:agentId"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"csv_programs"}
                  >
                    <AdminSubStudents />
                  </ProtectedRoute>
                }
              />
              <Route
                path="history/:enrolled_Id/:file_Id"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"csv_programs"}
                  >
                    <EnrolledHistory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="adminsendotp"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"csv_programs"}
                  >
                    <AdminSendOtp />
                  </ProtectedRoute>
                }
              />
              <Route
                path="commission"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"adminCommission"}
                  >
                    <AdminCommission />
                  </ProtectedRoute>
                }
              />

              <Route
                path="addcountry"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"sp_country_names"}
                  >
                    <AddCountry />
                  </ProtectedRoute>
                }
              />
              <Route
                path="addcollegename"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"sp_college_names"}
                  >
                    <AddcollegesName />
                  </ProtectedRoute>
                }
              />
              <Route
                path="createemployee"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"employee_create"}
                  >
                    <CreateEmployee />
                  </ProtectedRoute>
                }
              />
              <Route
                path="listemployee"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                    permission_name={"employee_list"}
                  >
                    <EmployeeList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="assessmentforms"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <AssessmentForms />
                  </ProtectedRoute>
                }
              />
              <Route
                path="serachqueries"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <SearchQueryForms />
                  </ProtectedRoute>
                }
              />
              <Route
                path="intakes"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <IntakesManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="programmIntakes/:id"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <ProgramIntakes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="intakes_program"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <IntakesManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="collegeupdate/:collegeId"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <CollegeUpdate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="programupdate/:collegeId/:programId"
                element={
                  <ProtectedRoute
                    token={state.tokenAdmin}
                    role={"admin"}
                    permissions={state.currentPermissions}
                  >
                    <ProgramUpdate />
                  </ProtectedRoute>
                }
              />
              <Route path="enrolled-files" element={<FilesList type="ALL" />} />
              {/* <Route path="pending-files" element={<FilesList type="PENDING" />} /> */}
              <Route path="remarks/:fileId" element={<AdminStudentRemarks />} />
              {/* <Route
              path="under-verification-files"
              element={<FilesList type="OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" />}
            /> */}
              {/* <Route
              path="fees-pending"
              element={<FilesList type="FEES_PENDING" />}
            /> */}
              {/* <Route
              path="in-processing-files"
              element={<FilesList type="COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS" />}
            /> */}
              {/* <Route path="closed-files" element={<FilesList type="CLOSED" />} /> */}
              {/* <Route
              path="documents-rejected-files"
              element={<FilesList type="DOC_REJECTED" />}
            />
            <Route
              path="permanent-rejected-files"
              element={<FilesList type="PERMANENT_REJECTED" />}
            /> */}
              <Route
                path="english-speaking-nations"
                element={<EnglishSpeakingNations />}
              />
              <Route path="currency" element={<AddCurrency />} />
              <Route
                path="required-documents"
                element={<AddRequiredDocuments />}
              />
              <Route path="manage-file-status" element={<ManageFileStatus />} />

              {/* demo */}
              {/* <Route path="demodatatable" element={<ProtectedRoute token={state.tokenAdmin} role={"admin"} permissions={state.currentPermissions}><DataTable /></ProtectedRoute>} /> */}
            </Route>

            {/* website routes */}
            <Route path="/" element={<WebsiteHome />}>
              <Route index element={<WHome />} />
              <Route path="eligible" element={<WEligible />} />
              <Route path="search" element={<WSearch />} />
              <Route path="about" element={<Wabout />} />
              <Route path="discover" element={<Wdiscover />} />
              <Route path="contact" element={<Wcontact />} />
              <Route path="countries/:id" element={<Wcountry1 />} />
              <Route path="specificcollege/:id" element={<Wviewdetails />} />
            </Route>

            {/* <Route path="/" element={<Home />} /> */}

            {/* dashboard Routes */}
            {/* <Route path="/LoginSignup" element={<Home isAdmin={false} />} />
          <Route path="/adminlogin" element={<Home isAdmin={true} role="ADMIN" token={state.tokenAdmin} />} /> */}

            {/* admin routes */}
            {/* <Route path="/admin/login" element={<Login />} /> */}

            {/* agent routes */}
            <Route path="/agent" element={<AgentDashboard />}>
              <Route
                path="dashboard"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentDashboardPage />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="students"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentStudentInner />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="login"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentLogin />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="register"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentRegister />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="addstudent"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentAddStudent />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="getstudents"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentGetStudent />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="enrolled-list"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentEnrolledList />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="findprograms"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentFindProgram />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="documents/:fileId/:studentId"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentStudentDocuments />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="remarks/:fileId/:id"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentStudentRemarks />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="findprograms/search/"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentProgramsList />
                  ) : (
                    <AgentProfile />
                  )
                }
              />
              <Route
                path="findprograms/search/p/:collegeId"
                element={
                  protectedToken == "Agent Verified" ? (
                    <AgentSubProgramsList />
                  ) : (
                    <AgentProfile />
                  )
                }
              />

              <Route
                path="profile"
                element={
                  <ProtectedRoute token={state.tokenAgent} role={"agent"}>
                    <AgentProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <ProtectedRoute token={state.tokenAgent} role={"agent"}>
                    <AgentNotifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="AgentCommission"
                element={
                  protectedToken == "Agent Verified" ? (
                    <ProtectedRoute token={state.tokenAgent} role={"agent"}>
                      <AgentCommission />
                    </ProtectedRoute>
                  ) : (
                    <AgentProfile />
                  )
                }
              />
            </Route>

            {/* student routes */}
            <Route
              path="/student/confirm/:token"
              element={<StudentConfirm />}
            />

            <Route path="/login2" element={<Login2 />} />
            <Route path="/student/forgot/:studenttoken" element={<StudentLoginForm />} />
            <Route path="/agent/forgot/:agenttoken" element={<AgentLoginForm />} />

            <Route path="/student" element={<StudentDashboard />}>
              <Route index element={<StudentEnrolled />} />
              <Route path="profile" element={<StudentProfile />} />
              {/* <Route
              path="dashboard"
              element={
                <ProtectedRoute token={state.tokenStudent} role={"student"}>
                  {
                    <>
                      {" "}
                      <h1 className="text-xl m-3 font-black">Dashboard</h1>{" "}
                    </>
                  }
                </ProtectedRoute>
              }
            /> */}
              {/* <Route path="login" element={<StudentLogin />} />
            <Route path="register" element={<StudentRegister />} /> */}
              <Route path="enrolled" element={<StudentEnrolled />} />
              <Route path="programs" element={<StudentPrograms />} />
              <Route path="documents/:fileId/" element={<StudentDocuments />} />
              <Route
                path="embassy_documents"
                element={<StudentEmbassyDocuments />}
              />
              <Route path="notifications" element={<StudentNotifications />} />
              <Route path="history" element={<StudentHistory />} />
              <Route path="remarks/:fileId/:id" element={<StudentRemarks />} />

              <Route path="login2" element={<Login2 />} />
            </Route>

            {/* <Route path="*" element={<><center className="pt-5 text-danger text-bold text-decoration-underline">404 Not Found</center></>} /> */}
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
