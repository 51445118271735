/* eslint-disable no-unused-vars */
import { Switch } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, Navigate, redirect } from "react-router-dom";
import { getCookie, getToken, setCookie } from "../../../helper/auth";
import Papa from "papaparse";
import Dashboard from "../Screens/Dashboard/Dashboard";
import loader from "../../../images/loader.gif";
import OutsideClickHandler from "react-outside-click-handler";
import { GrClose } from "react-icons/gr";
// import "./Addcollegename.css";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { AiFillSetting } from "react-icons/ai";
import { HexColorPicker } from 'react-colorful';
import { CompactPicker } from 'react-color';
import { SketchPicker } from 'react-color';
import { SwatchesPicker } from 'react-color';
import { PhotoshopPicker } from 'react-color';
import { FaEdit, FaTrash } from "react-icons/fa";
const countryToCurrency = require("country-to-currency");
const { MultiSelect } = require("react-multi-select-component");
// web-socket
// import socketIOClient from "socket.io-client";
// const ENDPOINT = "http://127.0.0.1:3006";
// var socket = socketIOClient(ENDPOINT);

const ManageFileStatus = () => {
  const [isrotating, setisrotating] = useState(false);
  const [ModalCode, setModalCode] = useState(false);
  const [deleteModalCode, setDeleteModalCode] = useState(false);
  const [index, setIndex] = useState();
  const [ModalCodeFilter, setModalCodeFilter] = useState(false);
  const [color, setColor] = useState("#000000");
  const [updateIndex, setUpdate] = useState(-1);
  const [statusColors, setStatusColors] = useState({});
  const [title, setTitle] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editColor, setEditColor] = useState("");
  const [editIndex, setEditIndex] = useState(-1);

  const [state, setState] = useState({
    list: [],

    showPopup: false,
    isWait: true,
  });

  const fetchFileStatus = () => {
    setState({
      ...state,
      isWait: true,
    });
    const config = {
      headers: { Authorization: `Bearer ${getToken("admin")}` },
    };
    axios
      .get(process.env.REACT_APP_NODE_URL + "/admin/fileStatus", config)
      .then(async (response) => {
        setState({
          ...state,
          list: response.data.details.list,
          isWait: false,
          showPopup: false,
        });
        setisrotating(false)
      });
  };

  useEffect(() => {
    fetchFileStatus();
  }, []);

  // if (state.isWait) {
  //   return "Loading...";
  // }

  const removeUpdate = (statusId) => {
    const config = {
      headers: { Authorization: `Bearer ${getToken("admin")}` },
    };
    const data = {
      statusId: statusId,
    };

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/fileStatus/delete",
        data,
        config
      )
      .then((response) => {
        // Filter out the deleted item from the list
        const updatedList = state.list.filter((item) => item._id !== statusId);

        setState({
          ...state,
          showPopup: false,
          list: updatedList,
          isWait: false,
        });

        document.getElementById("newStatus").value = "";
        setDeleteModalCode(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteItem = (index) => {
    const statusId = state.list[index]._id;
    setDeleteModalCode(true);
    setIndex(statusId);
  };
  console.log("index", index);

  const handleColorChange = (statusId, color) => {
    setStatusColors((prevState) => ({
      ...prevState,
      [statusId]: color,
    }));
  };

  const AddData = (data) => {
    const { title } = data;
    const config = {
      headers: { Authorization: `Bearer ${getToken("admin")}` },
    };

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/fileStatus/",
        { title, color },
        config
      )
      .then((response) => {
        let oldList = state.list;
        oldList.push({ title });
        setState({
          ...state,
          list: oldList,
          isWait: false,
          showPopup: false,
        });
        document.getElementById("newStatus").value = "";
        fetchFileStatus();
        setTitle(""); // Reset title to empty string
        setColor("#000000"); // Reset color to default value
        setState({
          ...state,
          showPopup: false, // Close the modal after adding
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const openEditModal = (index) => {
    setEditModalOpen(true);
    const status = state.list[index];
    // setEditModalOpen(true);
    setEditTitle(status.title);
    setEditColor(status.color);
    setEditIndex(index);
  };

  const updateStatus = (event) => {
    event.preventDefault();

    const statusId = state.list[editIndex]._id;
    const updatedStatus = {
      title: editTitle,
      color: editColor,
      statusId: statusId,
    };

    const config = {
      headers: { Authorization: `Bearer ${getToken("admin")}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_NODE_URL}/admin/updatefileStatus`,
        updatedStatus,
        config
      )
      .then((response) => {
        const updatedList = [...state.list];
        updatedList[editIndex] = response?.data?.details?.status;
        setState({
          ...state,
          list: updatedList,
          isWait: false,
        });
        fetchFileStatus();
        closeEditModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditTitle("");
    setEditColor("");
    setEditIndex(-1);
  };

  const startLoader = async () => {
    setisrotating(true);
    fetchFileStatus();
  };

  return (
    <>
      <ToastContainer />
      <div heading_title={"Add college Detail"}>
        <>
          <div className="row addCountryPage flex flex-row">
            {/* popup start */}
            <OutsideClickHandler
              onOutsideClick={() => {
                setState({
                  ...state,
                  showPopup: false,
                });
              }}
            >
              <div
                className={`px-[20px] w-5/12 mx-auto my-4 createcollegeNamePopup add_college_popup new_pop ${state.showPopup && "active"
                  }`}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <label htmlFor="" className="text-xl font-bold">
                    File Status
                  </label>
                  <GrClose
                    onClick={() => {
                      setState({
                        ...state,
                        showPopup: false,
                      });
                    }}
                    className="close"
                  />
                </div>
                <ul>
                  <div className="flex flex-col justify-between my-2 relative">
                    <input
                      type="text"
                      className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm my-2"
                      id="newStatus"
                      placeholder="Status Name"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />

                    <label className="block text-l font-bold">
                      Choose Color
                    </label>
                    <div class="flex items-center my-2">
                      {/* <input
                        type="color"
                        className="ml-2 h-8 w-8 rounded-lg border border-gray-300"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                      /> */}

                      <SketchPicker
                        color={color}
                        onChange={(selectedColor) => setColor(selectedColor.hex)}
                        className="ml-2"
                      />
                      {/* <SwatchesPicker
                            color={color}
                            onChange={(selectedColor) => setColor(selectedColor.hex)}
                            className="ml-2"
                          /> */}
                    </div>
                    <div className="flex justify-end">
                      <ButtonPrimary
                        title={"Add"}
                        onclick={() => {
                          let value =
                            document.getElementById("newStatus").value;

                          if (value === "") {
                            toast("Status is Required");
                            return;
                          }

                          let data = {
                            title: value,
                          };
                          AddData(data);
                          setState({
                            ...state,
                            showPopup: false,
                          });
                          document.getElementById("newStatus").value = "";
                        }}
                      />

                      <div className="ml-2"></div>
                    </div>
                  </div>
                </ul>
              </div>
            </OutsideClickHandler>

            {/* Edit_Modal */}
            {editModalOpen ? (
              <div
                className={`w-5/12 mx-auto my-4 createcollegeNamePopup ${editModalOpen ? "active" : ""
                  }`}
              >
                <form>
                  <label>
                    <div className="flex justify-between align-center">
                      <div className="span">
                        <strong>Edit file status Colour</strong>
                      </div>
                      <GrClose
                        className="close"
                        onClick={() => {
                          setEditModalOpen(false);
                        }}
                      />
                    </div>
                  </label>
                  <div className="card-body p-2">
                    <div className="flex flex-col justify-between my-2 relative">
                      {/* <div className="w-6/12 mr-1">
                        <label>Title</label>
                        <div className="mb-3">
                          <input
                            placeholder="Title"
                            type="text"
                            className="block w-full flex-1 border-gray-300 focus:border-black border-1 border p-2 w-full focus:ring-indigo-500 sm:text-sm"
                            value={editTitle}
                            onChange={(e) => setEditTitle(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div className="w-6/12 ml-1">
                        <label>Color</label>
                        <div className="mb-3">
                          {/* <input
                            type="color"
                            value={editColor}
                            className="ml-2 h-8 w-8 rounded-lg border border-gray-300"
                            onChange={(e) => setEditColor(e.target.value)}
                          /> */}

                          <SketchPicker
                            color={editColor}
                            onChange={(selectedColor) => setEditColor(selectedColor.hex)}
                            className="ml-2"
                          />


                        </div>
                      </div>
                    </div>

                    <div className="custom_submit flex cs-btn">
                      <button
                        onClick={updateStatus}
                        className="btn btn-primary blue-bg relative overflow-hidden rounded-lg  text-white py-2 px-4 hover:bg-green-500"
                        style={{ background: "#1a7335" }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}

            {/* popup end */}
            {deleteModalCode ? (
              <div className="modal_cover">
                <div className="modal_inner select-col-popup">
                  <div className="header_modal">
                    <h1 className="capitalize font-bold text-lg mb-2">
                      Are You Sure?
                    </h1>

                    <div className="button-part flex justify-end items-center gap-4">
                      <button
                        className="py-2 px-6"
                        type="btn"
                        onClick={() => setDeleteModalCode(false)}
                      >
                        No
                      </button>
                      <button
                        className="py-2 px-6"
                        type="btn"
                        // onClick={handleOk}
                        onClick={() => removeUpdate(index)}
                      >
                        Yes
                      </button>
                    </div>
                    <GrClose
                      className="close"
                      onClick={() => setDeleteModalCode(false)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mx-auto w-full my-4 p-45 mob-padding ">
              <div className="left">
                <div
                  className="college-list-btn flex items-center gap-4 justify-end"
                  style={{ marginTop: "15px" }}
                >
                  <div className=" flex items-center "></div>
                  <button
                    className="addNewBtn hover:bg-[#065f46]"
                    onClick={() => {
                      setState({
                        ...state,
                        showPopup: true,
                      });
                    }}
                  >
                    Add New
                  </button>
                  <div className="reload-icon mr-2">
                    <i
                      className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                      onClick={startLoader}
                    />
                  </div>
                </div>
              </div>
              {state.isWait ? (
                <div>
                  <center className="w-full my-10">
                    <img src={loader} alt="" />
                  </center>
                </div>
              ) : (
                <div class="flex flex-col table_postioning">
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <div class="overflow-hidden  border table-overflow">
                        <table class="min-w-full agent-table">
                          <thead class="">
                            <tr>
                              <th class="text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Sr.
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Status
                              </th>
                              <th class="capitalize text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Color
                              </th>
                              <th class="capitalize w-5 text-sm font-medium text-gray-900 px-2 py-4 text-left font-bold">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {state?.list.map((status, index) => {
                              return (
                                <tr class="border-b" style={{ background: status?.color }}>
                                  <td className="p-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {index + 1}
                                  </td>
                                  <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap capitalize font-black">
                                    {status.title
                                       ? status.title
                                        .replace(/_/g, " ")
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")
                                      : "--"} 
                                  </td>
                                  <td style={{padding: 10}}>
                                    <div style={{ background: status?.color, height: 32 }} className="w-full flex"></div>
                                  </td>

                                  <td className="text-sm mr-4 text-center text-gray-900 font-light p-2 whitespace-nowrap capitalize">
                                    <div className="action-icons-list">
                                      <i
                                        className="action-icon fa-solid fa-pen-to-square cursor-pointer"
                                        onClick={() => openEditModal(index)}
                                      ></i>
                                      {/* <i
                                      className="action-icon fa-solid fa-trash cursor-pointer text-[red]"
                                      onClick={() => deleteItem(index)}
                                    ></i> */}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ManageFileStatus;
