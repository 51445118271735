import React from 'react';
import { AiFillSetting } from "react-icons/ai";
import Select from "react-select";

export default function CollegeFilter({ countryOptions, selectedCountryOptions, countryChange, stateOptions, selectedStateOptions, stateChange, cityOptions, selectedCityOptions, cityChange, collegeOptions, selectedCollegeOptions, collegeChange, handleSearch, clearfilter, isrotating, startLoader, modalChange, handlePopup, handleShowCollege, handleHideCollege, handleEditPopup, showButton=true }) {
    return (
        <div className="setting_icon my-4 flex flex gap-3 items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 items-center">
                {/* Country Multi-select */}
                <div className="mb-4 w-[150px]">
                    <label
                        htmlFor="country"
                        className="text-gray-700 block font-semibold"
                    >
                        Country
                    </label>
                    <Select
                        className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                        options={countryOptions}
                        value={selectedCountryOptions}
                        isMulti
                        onChange={countryChange}
                        labelledBy="Country Name"
                        searchable={true}
                        hideSelectedOptions={false}
                    />
                </div>

                {/* State Multi-select */}
                <div className="mb-4 w-[150px]">
                    <label
                        htmlFor="state"
                        className="text-gray-700 block font-semibold"
                    >
                        State
                    </label>
                    <Select
                        className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                        options={stateOptions}
                        value={selectedStateOptions}
                        isMulti
                        onChange={stateChange}
                        labelledBy="State Name"
                        searchable={true}
                        hideSelectedOptions={false}
                    />
                </div>

                {/* City Multi-select */}
                <div className="mb-4 w-[150px]">
                    <label
                        htmlFor="city"
                        className="text-gray-700 block font-semibold"
                    >
                        City
                    </label>
                    <Select
                        className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                        options={cityOptions}
                        value={selectedCityOptions}
                        isMulti
                        onChange={cityChange}
                        labelledBy="City Name"
                        searchable={true}
                        hideSelectedOptions={false}
                    />
                </div>

                {/* College Multi-select */}
                <div className="mb-4 w-[150px]">
                    <label
                        htmlFor="college_name"
                        className="text-gray-700 block font-semibold"
                    >
                        College Name
                    </label>
                    <Select
                        className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                        options={collegeOptions}
                        value={selectedCollegeOptions}
                        isMulti
                        onChange={collegeChange}
                        labelledBy="College Name"
                        searchable={true}
                        hideSelectedOptions={false}
                    />
                </div>

                <div className="mb-4 w-[150px] flex">
                    <button
                        className="mr-3 bg-[#1a7335] text-white font-bold h-10
                        w-full mt-4 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                        onClick={handleSearch}
                    >
                        Find
                    </button>

                        <button
                            className="bg-[#5555] text-[#515151] font-bold h-10
                        w-full mt-4 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out"
                            onClick={clearfilter}
                        >
                            Clear
                        </button>
                </div>
            </div>
            <div className="flex items-center">
                {showButton &&
                <div className="addscl-cs my-4 mr-5">
                    <button
                        className="addNewBtn hover:bg-[#065f46]"
                        onClick={() => {
                            handlePopup();
                            handleShowCollege();
                            handleHideCollege();
                            handleEditPopup();
                        }}
                    >
                        Add New
                    </button>
                </div>
                }
                <div className="reload-icon mr-2">
                    <i
                        className={`fas fa-sync ${isrotating ? "rotate" : ""
                            }`}
                        onClick={startLoader}
                    />
                </div>
                {showButton &&
                <AiFillSetting
                    className="Bar_filter"
                    onClick={modalChange}
                />
                }
            </div>
        </div>
    )
}
