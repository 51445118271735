import axios from "axios";
import { useCallback, useState } from "react";
import useRazorpay from "react-razorpay";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { getToken } from "../../../helper/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

export default function StudentPayment({ enrollId, state, setState, getstudentremarks }) {
  const Razorpay = useRazorpay();
  const [isPayLoading, setIsPayLoading] = useState(false);
  const params = useParams();
  const mainFileId = params.id;

  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const payNow = (enrollId, res) => {
    let { razorpay_payment_id } = res;

    // validation
    let selectedIntake = document.getElementById("selectedIntake").value;
    if (selectedIntake === "") {
      toast.error("Please select an intake");
      return;
    }

    // intakes
    let intakesArr = selectedIntake.split("-");
    let intakeData = {
      year: intakesArr[0],
      month: parseInt(intakesArr[1]),
    };

    let body = {
      fileId: enrollId,
      status: "PENDING",
      intake: intakeData,
      razorpay_payment_id: razorpay_payment_id,
    };

    // handlePayment API
    axios
      .post(process.env.REACT_APP_NODE_URL + "/student/handlePayment", body, {
        headers: {
          Authorization: `Bearer ${getToken("student")}`,
        },
      })
      .then((res) => {
        setIsPayLoading(false);

        toast.success("Payment Successful");
        // add history
        const addHistoryData = {
          userRole: "Student",
          comment: res.data.details.enroll_status,
          fileId: mainFileId,
        };

        axios.post(
          process.env.REACT_APP_NODE_URL + "/v2/api/addHistory",
          addHistoryData
        );

        getstudentremarks()

        let oldFileDetails = state.fileDetails;
        oldFileDetails.enroll_status = res.data.details.enroll_status;
        oldFileDetails.intake = {
          year: parseInt(intakeData.year),
          month: parseInt(intakeData.month),
        };
        oldFileDetails.fees_status = "PAID";

        setState({
          ...state,
          fileDetails: oldFileDetails,
          fileStatus: res.data.details.enroll_status,
        });
      })
      .catch((err) => {
        setIsPayLoading(false);
        toast.error("Payment Failed");
      });
  };

  const createOrder = async (data) => {
    // api
    const config = {
      headers: {
        Authorization: `Bearer ${getToken("student")}`,
      },
    };

    let response = await axios.post(
      process.env.REACT_APP_NODE_URL + "/student/createOrder",
      data,
      config
    );
    return response.data;
  };

  const handlePayment = useCallback(async () => {
    // validation
    // CHANGE
    let selectedIntake = document.getElementById("selectedIntake").value;
    if (selectedIntake === "") {
      toast.error("Please select an intake");
      return;
    }

    setIsPayLoading(true);

    const order = await createOrder({ enrollId });
    let userDetails = order?.userDetails;
    const options = {
      key: "rzp_test_ElUkqLX4SVyVMn",
      amount: parseFloat(order.amount),
      currency: order.currency,
      name: "Learn Global",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.order_id,
      handler: (res) => {
        payNow(enrollId, res);
      },
      prefill: {
        name: userDetails?.firstName,
        email: userDetails?.email,
        contact: userDetails?.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: () => {
          setIsPayLoading(false);
        },
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay, enrollId]);

  return (
    <>
      <ToastContainer />
      <div className="App flex">
        <select
          name="intakes"
          id={`selectedIntake`}
          className="p-2 m-2 ml-0"
        >
          <option value="">Choose Intake</option>
          {state?.fileDetails?.college_details?.collegeprograms?.intakes_data.map(
            (intakes, IntakeIndex) => {
              return intakes.months.map((month, monthIndex) => {
                const isClosed =
                  state?.fileDetails?.college_details?.collegeprograms?.intakes_closed_data &&
                  state?.fileDetails?.college_details?.collegeprograms?.intakes_closed_data[IntakeIndex]?.months[monthIndex];
                if (month) {
                  const optionValue = `${intakes.year}-${monthIndex}`;
                  const optionDate = new Date(intakes.year, monthIndex);
                  return (
                    <option
                      key={IntakeIndex + "-" + monthIndex}
                      value={optionValue}
                      disabled={
                        optionDate < new Date() ||
                        intakes.year < new Date().getFullYear() ||
                        (intakes.year === new Date().getFullYear() && monthIndex <= new Date().getMonth()) ||
                        isClosed
                      }
                    >
                      {intakes.year + " " + monthsArr[monthIndex]}
                    </option>
                  );
                }
                return null;
              });
            }
          )}
        </select>
        <div className="m-2">
          <ButtonPrimary
            theme="tracker"
            title={"Pay"}
            onclick={handlePayment}
            loading={isPayLoading}
          />
        </div>
      </div>
    </>
  );
}
