/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Logo from "../../website/images/logo.png";
import Logoblack from "../../../images/logoblack.png";
import StudentAvatar from "../../../images/StudentAvatar.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrClose } from "react-icons/gr";
import { MdAlternateEmail, MdLock } from "react-icons/md";
import { Icon } from "react-icons-kit";
import { FaEnvelope, FaKey } from "react-icons/fa";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { FiEye, FiEyeOff } from "react-icons/fi";
import axios from "axios";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { authenticate } from "../../../helper/auth";
import AgentAuthScreen from "../../agent/Screens/Authentication/AgentAuthScreen";
import AuthScreen from "../../student/Screens/Authentication/StudentAuthScreen";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// firebase
import ButtonPrimaryCopy from "../../../common/Buttons/ButtonPrimarycopy";
import AdminStudentImage from '../../../images/admin-student-screen.jpg';

const AgentLoginForm = (props) => {
    const navigate = useNavigate();
    const [agentPrevent, setAgentPrevent] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmNewPass, setShowConfirmNewPass] = useState(false);
    // console.log("pid", sid, pid);
    const [state, setState] = useState({
        username: "",
        password: "",
        submitProcessing: false,
        data: "",

        redirect: searchParams.get("redirect"),
        type: searchParams.get("type"),
        college: searchParams.get("college"),
        program: searchParams.get("program"),
        email: "",
        confirmpassword: "",
        newpassword: "",
    });
    const [buttonLoading, setButtonLoading] = useState({
        type: "FALSE",
    });

    // student forgot password screen
    const { agenttoken } = useParams();

    const toggleNewPassword = () => {
        setShowNewPass(!showNewPass);
    };

    const toggleConfirmPassword = () => {
        setShowConfirmNewPass(!showConfirmNewPass);
    };

    const handleInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    // // agent login api
    const LoginNow = async () => {
        if (agentPrevent) {
            return;
        }
        setAgentPrevent(true);
        const { email, password } = state;

        if (email == "" || password == "")
            return toast.error("All fields are required");

        setButtonLoading({ type: "AGENT_LOGIN" });
        const data = { email, password };
        const config = { "content-type": "application/json" };
        axios
            .post(process.env.REACT_APP_NODE_URL + "/agent/login", data)
            .then((res) => {
                setAgentPrevent(false)
                setButtonLoading({ type: "FALSE" });
                if (res.data.status == "0") {
                    toast.error(res.data.message);
                    return;
                }
                authenticate(res, "agent", () => {
                    toast.success(res.data.message);
                    window.location.href = "/agent/dashboard";
                });
                localStorage.setItem("isAuthenticated", true);
                // authenticate with token
                // redirect
            })
            .catch((err) => {
                setButtonLoading({ type: "FALSE" });
                toast.error(err.response.data.message);
            });
    };
    // Agent Register
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        street: Yup.string().required("Street is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
        postalcode: Yup.string().required("Postal code is required"),
        phone: Yup.string().required("Phone number is required"),
    });

    const handleSubmit = (values) => {
        setButtonLoading({ type: "AGENT_REGISTER" });

        const config = { "content-type": "application/json" };
        axios
            .post(process.env.REACT_APP_NODE_URL + "/agent/register", values)
            .then((res) => {
                // redirect
                setButtonLoading({ type: "FALSE" });
                if (res.data.status == "0") {
                    toast.error(res.data.message);
                } else {
                    toast.success(
                        "Registration successful. Please check your email for login details."
                    );
                    setagentRegisterSide(false);
                    setagentLoginSide(true);
                }
            })
            .catch((err) => {
                setButtonLoading({ type: "FALSE" });
            });
    };

    const ForgotAgent = async () => {
        if (state.newpassword !== state.confirmpassword) {
            toast.error("Both passwords should be same");
            return;
        }
        if (state.newpassword === "" || state.confirmpassword === "") {
            toast.error("Both passwords are required");
            return;
        }
        setState({
            ...state,
            submitProcessing: true,
        });
        const { data, password } = state;
        const config = { headers: { Authorization: `Bearer ${agenttoken}` } };
        const data2 = { newPassword: state.confirmpassword };
        axios
            .post(
                process.env.REACT_APP_NODE_URL + "/agent/setnewpassword",
                data2,
                config
            )
            .then((res) => {
                if (res.data.status === "0") {
                    toast.error(res.data.message);
                    return;
                }
                toast.success(res.data.message);
                navigate("/agentlogin");
                // redirect
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    const SendAgentForgotPasswordEmail = async () => {
        if (state.email === "") {
            toast.error("Email is required");
            return;
        }
        setButtonLoading({ type: "FORGOT_EMAIL_SEND" });
        const data = { email: state.email };
        axios
            .post(process.env.REACT_APP_NODE_URL + "/agent/forgotpassword", data)
            .then((res) => {
                setButtonLoading({ type: "FALSE" });
                if (res.data.status === "0") {
                    toast.error(res.data.message);
                    return;
                }
                toast.success(res.data.message);
                setAgentForgotPassword(false);
                setState({
                    ...state,
                    email: "",
                });
            })
            .catch((err) => {
                setButtonLoading({ type: "FALSE" });
                toast.error(err.response.data.message);
            });
    };
    // password eye Toggle for Both Student and Agent Screen
    const [typeConfirm, setTypeConfirm] = useState("password");
    const [iconConfirm, setIconConfirm] = useState(eye);
    const handleToggleConfirm = () => {
        if (typeConfirm === "password") {
            setIconConfirm(eyeOff);
            setTypeConfirm("text");
        } else {
            setIconConfirm(eye);
            setTypeConfirm("password");
        }
    };
    // Modal
    const [AgentForgotPassword, setAgentForgotPassword] = useState(false);
    // Login Method Tabs Active Button Methods
    const [agentLoginSide, setagentLoginSide] = useState(true);
    const [agentRegisterSide, setagentRegisterSide] = useState(false);


    if (agenttoken) {
        return (
            <>
                <ToastContainer />
                <div className="new-main">
                    <div className="lg:flex items-center">
                        <div className="new-right flex items-center w-full justify-center items-center">
                            <div className="rt-inner w-full flex justify-center items-center">

                                <div className="inner-form-data rounded-lg bg-white p-10 px-4 mx-auto relative lg:px-6">
                                    <Link to="/" className="flex w-fit mx-auto">
                                        <img className="w-60 mx-auto" src={Logo} alt="" />
                                    </Link>
                                    {agenttoken && (
                                        <div className="col-12">
                                            <h1 className="text-xl mt-5 mb-3 font-black text-center">
                                                Set New Password
                                            </h1>
                                            <div className="sm:overflow-hidden">
                                                <div className="space-y-6 pb-2">
                                                    <div className="">
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label
                                                                htmlFor="company-website"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                New Password
                                                            </label>
                                                            <div className="mt-1 flex rounded-md pass-input">
                                                                <input
                                                                    type={showNewPass ? "text" : "password"}
                                                                    name="newpassword"
                                                                    value={state.newpassword}
                                                                    onChange={handleInput}
                                                                    id="company-website"
                                                                    className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                                                    placeholder="New Password"
                                                                />
                                                                <button
                                                                    className="eye-btn"
                                                                    onClick={toggleNewPassword}
                                                                >
                                                                    {showNewPass ? <FiEyeOff /> : <FiEye />}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label
                                                            htmlFor="about"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Confirm Password
                                                        </label>
                                                        <div className="mt-1 pass-input">
                                                            <input
                                                                type={showConfirmNewPass ? "text" : "password"}
                                                                name="confirmpassword"
                                                                value={state.confirmpassword}
                                                                onChange={handleInput}
                                                                className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="Confirm Password"
                                                            />
                                                            <button
                                                                className="eye-btn"
                                                                onClick={toggleConfirmPassword}
                                                            >
                                                                {showConfirmNewPass ? <FiEyeOff /> : <FiEye />}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right button mt-5">
                                                    <button
                                                        type="button"
                                                        onClick={ForgotAgent}
                                                        className="bg-gradient-primary inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 sm:py-4 rounded-lg px-4 text-sm font-medium text-white shadow-sm"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-center pt-2 px-lg-2 px-1 mt-4">
                                                <p className="mb-4 text-sm mx-auto flex flex-col">
                                                    Want to login?
                                                    <span
                                                        onClick={() => navigate("/agentlogin")}
                                                        className="pl-2 text-info text-gradient font-bold cursor-pointer underline"
                                                    >
                                                        Login
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <ToastContainer />
            <div className="new-main login-signup">
                <div className="lg:flex items-center signup-login">
                    <div className="lg:w-full new-right flex items-center login-form">
                        <div className="rt-inner w-full">
                            <div className="side-image">
                                <img src={AdminStudentImage} alt="side-image" />
                            </div>
                            <div className="inner-form-data rounded-lg bg-white p-10 px-4 mx-auto relative lg:px-6 register">


                                <div className="admin_logo sign_up flex flex-col justify-center mt-3 mb-5">
                                    <Link to="/">
                                        {" "}
                                        <img className="w-50 lg:w-50 mx-auto" src={Logoblack} />
                                    </Link>

                                    {agentLoginSide && <h2 className="text-center mt-6 xl:mt-87 mb-2 text-lg">Agent Login</h2>}
                                </div>
                                {agentLoginSide ? (
                                    <AgentAuthScreen>
                                        <div className="agent-form log-main">
                                            <div className="input-content rounded-lg">
                                                <div className="custom_inputs flex items-center gap-4 relative">
                                                    <div
                                                        className="icon_for absolute left-3"
                                                        style={{ top: 16, zIndex: 9 }}
                                                    >
                                                        <MdAlternateEmail
                                                            style={{ fill: "#bdbdbd", width: 17, height: 17 }}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <label className="custom-field one w-full">
                                                            {/* <span className="placeholder mb-2">
                                                                User Name
                                                            </span> */}
                                                            <input
                                                                name="email"
                                                                value={state.email}
                                                                onChange={handleInput}
                                                                className="w-full"
                                                                type="email"
                                                                style={{ paddingLeft: 34 }}
                                                                placeholder="Enter your Email"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-content mt-7 rounded-lg">
                                                <div className="custom_inputs flex items-center gap-4 relative">
                                                    <div
                                                        className="icon_for absolute left-3"
                                                        style={{ top: 16, zIndex: 9 }}
                                                    >
                                                        <MdLock
                                                            style={{ fill: "#bdbdbd", width: 17, height: 17 }}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <label className="custom-field one w-full">
                                                            {/* <span className="placeholder">Password</span> */}
                                                            <input
                                                                name="password"
                                                                type={typeConfirm}
                                                                value={state.password}
                                                                onChange={handleInput}
                                                                className="w-full"
                                                                style={{ paddingLeft: 34 }}
                                                                placeholder="Enter Password"
                                                            />
                                                        </label>
                                                        <div className="field_eye_view">
                                                            <div
                                                                className="icon icon-eye"
                                                                onClick={handleToggleConfirm}
                                                            >
                                                                <Icon icon={iconConfirm} size={20} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-2 mt-2 rounded-lg forget-pass">
                                                <div className="flex  gap-2">
                                                    <div className="ml-auto">
                                                        <Link
                                                            to="#"
                                                            onClick={() =>
                                                                setAgentForgotPassword(!AgentForgotPassword)
                                                            }
                                                            className="text-black underline cursor-pointer text-sm"
                                                        >
                                                            Forgot Password?
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                onClick={LoginNow}
                                                className="log-btn w-full py-2 sm:py-3 text-white mt-4 rounded-lg"
                                                disabled={buttonLoading.type === "AGENT_LOGIN"}
                                            >
                                                {buttonLoading.type === "AGENT_LOGIN" ? (
                                                    <div className="flex items-center justify-center">
                                                        <svg
                                                            class="h-5 w-5 animate-spin"
                                                            viewBox="3 3 18 18"
                                                        >
                                                            <path
                                                                class="fill-gray-200"
                                                                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                                            ></path>
                                                            <path
                                                                class="fill-gray-800"
                                                                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                ) : (
                                                    "Login"
                                                )}
                                            </button>

                                            <p className="mt-3 text-center text-sm flex justify-center items-center">
                                                Don’t have an account ?{" "}
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setagentRegisterSide(!agentRegisterSide);
                                                        setagentLoginSide(false);
                                                    }}
                                                    className="text-black underline cursor-pointer text-sm ms-6"
                                                >
                                                    Register
                                                </p>
                                            </p>
                                        </div>
                                    </AgentAuthScreen>
                                ) : (
                                    ""
                                )}
                                {AgentForgotPassword && (
                                    <div className="modal_cover add_college_popup new_ccs sign_up ">
                                        <div className="modal_inner relative">
                                            <div className="header_modal login-part-modal">
                                                <GrClose
                                                    style={{
                                                        position: "absolute",
                                                        fontSize: "18px",
                                                        height: "32px",
                                                        width: "32px",
                                                    }}
                                                    className="close cursor-pointer text-base"
                                                    onClick={() => setAgentForgotPassword(false)}
                                                />
                                                <h4 className="my-2 text-center font-bold text-xl mb-10">
                                                    Forgot Your Password
                                                </h4>
                                            </div>
                                            <AuthScreen>
                                                <div className="flex flex-col justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-2 sm:p-2">
                                                                    <div className="">
                                                                        <div className="col-span-3 sm:col-span-2 relative">
                                                                            <div
                                                                                className="icon_for mt-5 absolute left-3"
                                                                                style={{ top: 19, left: 7, zIndex: 9 }}
                                                                            >
                                                                                <FaEnvelope
                                                                                    style={{
                                                                                        fill: "#bdbdbd",
                                                                                        width: 17,
                                                                                        height: 17,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <label
                                                                                htmlFor="company-website"
                                                                                className="block text-sm font-medium text-gray-700"
                                                                            >
                                                                                Email
                                                                            </label>
                                                                            <div className="mt-1 flex rounded-md shadow-sm">
                                                                                <input
                                                                                    type="email"
                                                                                    name="email"
                                                                                    value={state.email}
                                                                                    onChange={handleInput}
                                                                                    style={{ paddingLeft: 30 }}
                                                                                    id="company-website"
                                                                                    className="block w-full flex-1 border-gray-300 focus:border-black border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                                                                    placeholder="Enter your Email"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-3 text-right sm:px-6 submit_sign flex justify-center">
                                                                    <ButtonPrimaryCopy
                                                                        title={"Submit"}
                                                                        onclick={SendAgentForgotPasswordEmail}
                                                                        loading={
                                                                            buttonLoading.type == "FORGOT_EMAIL_SEND"
                                                                        }
                                                                        className="w-fitcontent"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AuthScreen>
                                        </div>
                                    </div>
                                )}
                                {agentRegisterSide ? (
                                    <AgentAuthScreen>
                                        <>
                                            <div className="part-cont relative text-center my-3">
                                                {agentRegisterSide && <h2 className="text-center mt-3 mb-4 text-lg">Agent Register</h2>}
                                            </div>
                                            <div className="row register-form">
                                                <div className="">
                                                    <div className="mt-5 md:col-start-2 md:mt-0 m-auto w-full lg:w-12/12">
                                                        <div className="">
                                                            <div className="form-login">
                                                                <div className="">
                                                                    <Formik
                                                                        initialValues={{
                                                                            email: "",
                                                                            first_name: "",
                                                                            last_name: "",
                                                                            street: "",
                                                                            city: "",
                                                                            state: "",
                                                                            country: "",
                                                                            postalcode: "",
                                                                            phone: "",
                                                                        }}
                                                                        validationSchema={validationSchema}
                                                                        onSubmit={handleSubmit}
                                                                    >
                                                                        {({
                                                                            touched,
                                                                            errors,
                                                                            isSubmitting,
                                                                            values,
                                                                        }) => (
                                                                            <Form>
                                                                                <div className="row flex">
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="email">Email</label>
                                                                                        <Field
                                                                                            type="email"
                                                                                            name="email"
                                                                                            id="email"
                                                                                            placeholder="Enter your Email"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="email"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="phone">Phone</label>
                                                                                        <Field
                                                                                            type="number"
                                                                                            name="phone"
                                                                                            id="phone"
                                                                                            placeholder="Enter your Number"
                                                                                            className={`no-spinner block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="phone"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="first_name">
                                                                                            First Name
                                                                                        </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="first_name"
                                                                                            id="first_name"
                                                                                            placeholder="Enter First Name"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="first_name"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="last_name">
                                                                                            Last Name
                                                                                        </label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="last_name"
                                                                                            id="last_name"
                                                                                            placeholder="Enter Last Name"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="last_name"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="street">Street</label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="street"
                                                                                            id="street"
                                                                                            placeholder="Enter Street Address"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="street"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="city">City</label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="city"
                                                                                            id="city"
                                                                                            placeholder="Enter your City"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="city"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex">
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="state">State</label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="state"
                                                                                            id="state"
                                                                                            placeholder="Enter your State"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="state"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="country">Country</label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="country"
                                                                                            id="country"
                                                                                            placeholder="Enter your Country"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="country"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="m-2 w-6/12">
                                                                                        <label for="postalcode">
                                                                                            Postalcode
                                                                                        </label>
                                                                                        <Field
                                                                                            type="number"
                                                                                            name="postalcode"
                                                                                            id="postalcode"
                                                                                            placeholder="Enter your Postalcode"
                                                                                            className={`no-spinner block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name="postalcode"
                                                                                            component="div"
                                                                                            className="text-sm text-danger"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="text-center px-2">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="log-btn py-4 text-white mt-4 rounded-lg p-2 sm:p-3 w-full"
                                                                                        disabled={buttonLoading.type === "AGENT_REGISTER"}
                                                                                    >
                                                                                        {buttonLoading.type ==
                                                                                            "AGENT_REGISTER" ? (
                                                                                            <div className="flex items-center justify-center">
                                                                                                <svg
                                                                                                    class="h-5 w-5 animate-spin"
                                                                                                    viewBox="3 3 18 18"
                                                                                                >
                                                                                                    <path
                                                                                                        class="fill-gray-200"
                                                                                                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                                                                                    ></path>
                                                                                                    <path
                                                                                                        class="fill-gray-800"
                                                                                                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                                                                    ></path>
                                                                                                </svg>
                                                                                            </div>
                                                                                        ) : (
                                                                                            "Register"
                                                                                        )}
                                                                                    </button>
                                                                                </div>
                                                                            </Form>
                                                                        )}
                                                                    </Formik>
                                                                </div>
                                                                <div className="text-center pt-2 px-lg-2 px-1">
                                                                    <p className="mb-4 text-sm mx-auto">
                                                                        Already have an account?
                                                                        <span
                                                                            onClick={() => {
                                                                                setagentRegisterSide(false);
                                                                                setagentLoginSide(!agentLoginSide);
                                                                            }}
                                                                            className="cursor-pointer font-bold"
                                                                        >
                                                                            {" "}
                                                                            Login
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </AgentAuthScreen>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AgentLoginForm;