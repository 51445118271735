import axios from "axios";
import Select from "react-select";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../helper/auth";
import { GrClose } from "react-icons/gr";
import loader from "../../../images/loader.gif";
import { AiFillSetting } from "react-icons/ai";

const PAGE_SIZE = 10;
const CollegeList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isrotating, setisrotating] = useState(false);
  const [totalPages, setTotalpages] = useState(0);
  const [ModalCode, setModalCode] = useState(false);
  const [inputValues, setInputValues] = useState({});
  // 
  const [selectedCountryOptions, setselectedCountryOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  // 
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const navigate = useNavigate();

  const [state, setState] = useState({
    isWaiting: true,
    isFilterLoading: false,
    colleges: [],
    collegeNamesList: [],
    adminToken: getToken("admin"),
    country: "",
    searchItem: "",
    baseUrl: "",

    countryNamesList: [],
    stateNamesList: [],
    cityNamesList: [],

    // new filters
    filter_type: "",
    filter_total_students_min: "",
    filter_total_students_max: "",
    filter_international_students_min: "",
    filter_international_students_max: "",
    filter_founded_year_min: "",
    filter_founded_year_max: "",
    filter_accomodation_features: "",
    filter_work_permit_feature: "",
    filter_work_while_study: "",
    filter_program_cooporation: "",
    filter_condition_offer_letter: "",
    // filter_top_status: "",
    filter_library: "",
    countryId: "",
    stateId: "",
    cityId: "",
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Country", value: "Country" },
    { label: "College", value: "College" },
    { label: "State", value: "State" },
    { label: "City", value: "City" },
    { label: "Total Programs", value: "Total Programs" },
    { label: "Type", value: "Type" },
    { label: "Total_student", value: "Total_student" },
    { label: "Founded", value: "Founded" },
    { label: "Actions", value: "Actions" },
  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnscollegelist")) || [
      "Sr.",
      "Country",
      "College",
      "State",
      "City",
      "Total Programs",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Country",
    "College",
    "State",
    "City",
    "Total Programs",
  ]);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Country",
      "College",
      // "college_name",
      // "country",
      "State",
      "City",
      "Total Programs",
    ]);
  }, []);

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns; // Define updatedColumns here

    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem(
      "selectedColumnscollegelist",
      JSON.stringify(updatedColumns)
    );
  };

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumnscollegelist",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  useEffect(() => {
    getAllCollegeaData()
  }, []);
  const getCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`);
      const countries = response?.data?.details;
      const result = countries.map((country) => ({
        label: country.countryName,
        value: country.countryId,
      }));
      setCountryOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${selectedCountryIds.join(",")}`
      );
      const states = response?.data?.details;
      const result = states.map((state) => ({
        label: state.stateName,
        value: state.stateId,
      }));
      setStateOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async () => {
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchCities?stateIds=${selectedStateIds.join(",")}`
      );
      const cities = response?.data?.details;
      const result = cities.map((city) => ({
        label: city.cityName,
        value: city.cityId,
      }));
      setCityOptions(result);
    } catch (error) {
      console.error(error);
    }
  };
  const getColleges = async () => {
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    const selectedCityIds = selectedCityOptions.map((city) => city.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCollegeWithProgram?country=${selectedCountryIds.join(",")}&state=${selectedStateIds.join(",")}&city=${selectedCityIds.join(",")}`
      );
      const colleges = response?.data?.detail;
      const result = colleges.map((college) => ({
        label: college.collegeName,
        value: college.collegeName,
      }));
      setCollegeOptions(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getCountries();
      await getStates();
      await getCities();
      await getColleges();
    };
    fetchData();
  }, [selectedCountryOptions, selectedStateOptions, selectedCityOptions]);

  const getAllCollegeaData = async () => {
    setState({
      ...state,
      isWaiting: true,
    });

    await axios
      .post(
        process.env.REACT_APP_NODE_URL +
        `/admin/getnewAllcolleges?currentPage=${currentPage}`
      )
      .then((res) => {
        setState({
          ...state,
          colleges: res?.data?.details,
          isWaiting: false,
        });
        setTotalpages(res.data.totalPages);
      })
      .catch((error) => {
        setState({
          ...state,
          isWaiting: false,
        });
      });
  };


  // Dynamic Table Functions
  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleSearch = () => {
    const collegeNames = selectedCollegeOptions.map((option) => option.label);
    const countryNames = selectedCountryOptions.map((options) => options.label);
    const stateNames = selectedStateOptions.map((state) => state.label);
    const cityNames = selectedCityOptions.map((city) => city.label);

    // Creating searchData object
    const searchData = { ...inputValues };

    // Removing empty values from searchData object
    for (const key in searchData) {
      if (searchData.hasOwnProperty(key) && !searchData[key]) {
        delete searchData[key];
      }
    }

    // Updating searchData based on non-empty arrays
    if (collegeNames.length > 0) {
      searchData.college_name = collegeNames;
    }
    if (countryNames.length > 0) {
      searchData.countryNames = countryNames;
    }
    if (stateNames.length > 0) {
      searchData.state = stateNames;
    }
    if (cityNames.length > 0) {
      searchData.city = cityNames;
    }

    setState({
      ...state,
      isWaiting: true,
    });

    axios
      .post(
        `${process.env.REACT_APP_NODE_URL}/admin/getnewAllcolleges?currentPage=${currentPage}`,
        searchData
      )
      .then((res) => {
        setState({
          ...state,
          colleges: res?.data?.details,
          isWaiting: false,
        });
        setTotalpages(res.data.totalPages);
      })
      .catch((error) => {
        setState({
          ...state,
          isWaiting: false,
        });
      });
  };

  // pagination Function
  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };
  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  useEffect(() => {
    getAllCollegeaData();
  }, []);

  useEffect(() => {
    handleResetAll();
    // Retrieve selected columns from localStorage
    const storedColumns = JSON.parse(
      localStorage.getItem("selectedColumnscollegelist")
    );

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Country",
        "College",
        "State",
        "City",
        "Total Programs",
      ]);
      setTempColumnsTable([
        "Sr.",
        "Country",
        "College",
        "State",
        "City",
        "Total Programs",
      ]);
    }
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnscollegelist");
    setSelectedColumnsTable([
      "Sr.",
      "Country",
      "College",
      "State",
      "City",
      "Total Programs",
    ]);
    setTempColumnsTable([
      "Sr.",
      "Country",
      "College",
      "State",
      "City",
      "Total Programs",
    ]);
    // setModalCode(false);
  };
  function mapTypes(values) {
    const types = values.split(",").map((value) => {
      switch (value) {
        case "1":
          return "Public";
        case "2":
          return "Private";
        case "3":
          return "Institute";
        case "4":
          return "University";
        case "5":
          return "College";
        case "6":
          return "School";
        default:
          return value;
      }
    });
    return types.join(" ");
  }
  // refresh page code here
  const startLoader = async () => {
    setisrotating(true);
    await getAllCollegeaData();
    setselectedCountryOptions([]);
    setSelectedCollegeOptions([]);
    setSelectedStateOptions([]);
    setSelectedCityOptions([]);
    setisrotating(false);
  };
  // clear page code here
  const clearfilter = async () => {
    await getAllCollegeaData();
    setselectedCountryOptions([]);
    setSelectedCollegeOptions([]);
    setSelectedStateOptions([]);
    setSelectedCityOptions([]);
  };

  console.log("ModalCode", ModalCode)

  return (
    <>
      <div heading_title={"colleges List"}>
        <>
          {ModalCode && (
            <div className="modal_cover">
              <div className="modal_inner select-col-popup">
                <div className="header_modal">
                  <h1 className="capitalize font-bold text-lg mb-2">
                    select columns
                  </h1>
                  <p className="border-b pb-2 mb-2">
                    Select the column you want to display or hide.
                  </p>
                  <div
                    className="columns-popup"
                    style={{ justifyContent: "space-between" }}
                  >
                    {tableColumns.map((column, index) => {
                      if (
                        selectedColumnsTable.includes(column.value)
                      ) {
                        return (
                          <p className="mb-0 gap-2">
                            <input
                              type="checkbox"
                              id={column.value}
                              checked={tempColumnsTable.includes(
                                column.value
                              )}
                              onChange={() =>
                                handleColumnCheckboxChange(
                                  column.value
                                )
                              }
                            />
                            <label htmlFor={column.value}>
                              {formatLabel(column.label)}
                            </label>
                          </p>
                        );
                      } else {
                        return (
                          <p className="mb-0 gap-2">
                            <input
                              type="checkbox"
                              id={column.value}
                              onClick={() => {
                                selectedColumnsTable.push(
                                  column.value
                                );
                                setTempColumnsTable(
                                  selectedColumnsTable
                                );
                              }}
                            />
                            <label htmlFor={column.value}>
                              {formatLabel(column.label)}
                            </label>
                          </p>
                        );
                      }
                    })}
                  </div>
                  <div className="button-part flex justify-end items-center gap-4">
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={handleResetAll}
                    >
                      Reset all
                    </button>
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={handleOk}
                    >
                      Ok
                    </button>
                  </div>
                  <GrClose
                    className="close"
                    onClick={() => setModalCode(false)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row min-height-vh-100">
            <div className="row p-45 mob-padding ">
              <div className="setting_icon my-4 flex flex gap-3 items-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
                lg:grid-cols-5 xl:grid-cols-5 gap-4 items-center">
                  {/* Country Multi-select */}
                  <div className="mb-4 w-[150px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      Country
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={countryOptions}
                      value={selectedCountryOptions}
                      isMulti
                      onChange={(newValue) => {
                        setselectedCountryOptions(newValue); // Update selectedOptions
                      }}
                      labelledBy="Country Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  {/* State Multi-select */}
                  <div className="mb-4 w-[150px]">
                    <label
                      htmlFor="state"
                      className="text-gray-700 block font-semibold"
                    >
                      State
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={stateOptions}
                      value={selectedStateOptions}
                      isMulti
                      onChange={(newValue) => {
                        setSelectedStateOptions(newValue);
                      }}
                      labelledBy="State Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  {/* City Multi-select */}
                  <div className="mb-4 w-[150px]">
                    <label
                      htmlFor="city"
                      className="text-gray-700 block font-semibold"
                    >
                      City
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={cityOptions}
                      value={selectedCityOptions}
                      isMulti
                      onChange={(newValue) => {
                        setSelectedCityOptions(newValue);
                      }}
                      labelledBy="City Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  {/* College Multi-select */}
                  <div className="mb-4 w-[150px]">
                    <label
                      htmlFor="college_name"
                      className="text-gray-700 block font-semibold"
                    >
                      College Name
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={collegeOptions}
                      value={selectedCollegeOptions}
                      isMulti
                      onChange={(newValue) => {
                        setSelectedCollegeOptions(newValue);
                      }}
                      labelledBy="College Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="mb-4 w-[150px] flex">
                    <button
                      className="mr-3 bg-[#1a7335] text-white font-bold h-10
                         w-full mt-4 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                      onClick={handleSearch}
                    >
                      Find
                    </button>
                    <button
                      className="bg-[#5555] text-[#515151] font-bold h-10
                         w-full mt-4 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out"
                      onClick={clearfilter}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="reload-icon mr-2">
                    <i
                      className={`fas fa-sync ${isrotating ? "rotate" : ""
                        }`}
                      onClick={startLoader}
                    />
                  </div>
                  <AiFillSetting
                    className="Bar_filter"
                    onClick={() => setModalCode(!ModalCode)}
                  />
                </div>
              </div>
              <div className="card-body px-0 pt-0 pb-2 table-border">
                <div className="overflow-auto card  col-12 px-0 pt-0 pb-2  table_postioning college-list table-overflow">
                  {state.isWaiting ? (
                    <div>
                      <center className="w-full my-10">
                        <img src={loader} alt="" />
                      </center>
                    </div>
                  ) : state.colleges.length > 0 ? (
                    <table className="table-auto overflow-scroll w-full agent-table files-table table-hover">
                      <thead>
                        <tr>
                          {tableColumns.map((col) => {
                            if (selectedColumnsTable.includes(col.label)) {
                              return (
                                <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                  {formatLabel(col.label)}
                                </th>
                              );
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {state.colleges?.map((college, index) => {
                          return (
                            <tr
                              onClick={() =>
                                navigate("/admin/programs/" + college._id)
                              }
                              className="hover:bg-blue-100 cursor-pointer transition-colors"
                            >
                              {selectedColumnsTable.includes("Sr.") && (
                                <td className="p-2 capitalize w-0">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {(currentPage === 0 ? 0 : currentPage - 1) *
                                      PAGE_SIZE +
                                      index +
                                      1}
                                  </p>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Country") && (
                                <td className="p-2">
                                  <div className="flex items-center">
                                    <div className="small-image-container">
                                      <img
                                        width={60}
                                        src={
                                          process.env.REACT_APP_NODE_URL +
                                          "/uploads/agent/" +
                                          college["countryLogo"][0]
                                        }
                                        alt="user1"
                                      />
                                    </div>
                                    <p className="text-sm text-gray-900 font-light ps-2 pe-2 py-2 whitespace-nowrap capitalize">
                                      {college?.country}
                                    </p>
                                  </div>
                                </td>
                              )}
                              {selectedColumnsTable.includes("College") && (
                                <td className="p-2">
                                  <div className="flex items-center">
                                    <div className="small-image-container">
                                      <img
                                        width={60}
                                        src={
                                          process.env.REACT_APP_NODE_URL +
                                          "/uploads/agent/" +
                                          college["collegeLogo"][0]
                                        }
                                        alt="user1"
                                      />
                                    </div>
                                    <p className="text-sm text-gray-900 font-light ps-2 pe-2 py-2 whitespace-nowrap capitalize">
                                      {college?.college_name}
                                    </p>
                                  </div>
                                </td>
                              )}
                              {selectedColumnsTable.includes("State") && (
                                <td className="p-2 capitalize">
                                  {college?.state}
                                </td>
                              )}
                              {selectedColumnsTable.includes("City") && (
                                <td className="p-2 capitalize">
                                  {college?.city}
                                </td>
                              )}
                              {selectedColumnsTable.includes(
                                "Total Programs"
                              ) && (
                                  <td className="p-2 align-middle capitalize">
                                    <span className="text-secondary text-xs font-weight-bold">
                                      {college?.college_programs}
                                    </span>
                                  </td>
                                )}
                              {selectedColumnsTable.includes("Type") && (
                                <td className="p-2  align-middle capitalize">
                                  <span className="text-secondary text-xs font-weight-bold">
                                    {mapTypes(college.type)}
                                  </span>
                                </td>
                              )}
                              {selectedColumnsTable.includes(
                                "Total_student"
                              ) && (
                                  <td className="p-2  align-middle">
                                    <span className="text-secondary text-xs font-weight-bold">
                                      {college?.total_student}
                                    </span>
                                  </td>
                                )}
                              {selectedColumnsTable.includes("Founded") && (
                                <td className="p-2  align-middle ">
                                  <span className="text-secondary text-xs font-weight-bold">
                                    {college?.founded}
                                  </span>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Actions") && (
                                <td className="p-1 border-2 ">
                                  <div className="action-icons-list">
                                    <svg
                                      className="action-icon"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent the click event from reaching the row
                                        navigate(
                                          "/admin/collegeupdate/" + college?._id
                                        );
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                    </svg>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <p className="text-center p-5 text-red-500">
                        No data available.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* pagination is here */}
              {totalPages >= 2 && (
                <div className="pagination">
                  <div className="pages">
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      breakLabel="..."
                      pageCount={totalPages}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      initialPage={currentPage}
                      marginPagesDisplayed={1}
                      activeClassName="selected"
                      containerClassName="pagination"
                      renderOnZeroPageCount={null}
                      subContainerClassName="pages"
                      disableInitialCallback={true}
                      previousClassName={
                        currentPage === 0 ? "disabled" : ""
                      }
                      nextClassName={
                        currentPage === totalPages - 1 ? "disabled" : ""
                      }
                      previousLinkClassName={
                        currentPage === 0 ? "disabled-link" : ""
                      }
                      nextLinkClassName={
                        currentPage === totalPages - 1
                          ? "disabled-link"
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default CollegeList;
