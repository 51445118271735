/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import loader from "../../../images/loader.gif";
import Select from "react-select";

export default function MultiStepForm() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef(null);
  const navigate = useNavigate();
  const initialFormData = {
    nationality: "",
    highestEducation: "",
    gradingScheme: "",
    gradeAverage: "",
    name: "",
    phoneNumber: "",
    email: "",
    country_to_go: "",
    examType: "",
    tofel_score: "",
    duolingo_lan: "",
    pte_score: "",
    speaking: "",
    listening: "",
    writing: "",
    reading: "",
  };
  const [state, setState] = useState({
    stepNumber: 0,
    iswait: true,
    countryList: [],
  });

  const [formData, setFormData] = useState(initialFormData);

  const [errors, setErrors] = useState({
    nationality: "",
    highestEducation: "",
    gradingScheme: "",
    gradeAverage: "",
    name: "",
    phoneNumber: "",
    email: "",
    country_to_go: "",
    examType: "",
    tofel_score: "",
    duolingo_lan: "",
    pte_score: "",
    speaking: "",
    listening: "",
    writing: "",
    reading: "",
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_NODE_URL + "/address/country")
      .then((countryResponse) => {
        setState({
          ...state,
          iswait: false,
          countryList: countryResponse.data.details.countries,
        });
      });
  }, []);

  const searchNow = async () => {
    const validationErrors = {};

    if (formData.nationality === "") {
      validationErrors.nationality = "Nationality is Required";
    }
    if (formData.highestEducation === "") {
      validationErrors.highestEducation = "Highest Education is Required";
    }
    if (formData.gradingScheme === "") {
      validationErrors.gradingScheme = "Grading Scheme is Required";
    }
    if (formData.gradeAverage === "") {
      validationErrors.gradeAverage = "Grade Average is Required";
    }

    if (formData.name === "") {
      validationErrors.name = "Name is Required";
    }

    if (formData.phoneNumber === "") {
      validationErrors.phoneNumber = "Phone Number is Required";
    }
    if (formData.email === "") {
      validationErrors.email = "Email is Required";
    }

    if (formData.country_to_go.length === 0) {
      validationErrors.country_to_go = "Country to go is Required";
    }

    if (formData.examType === "") {
      validationErrors.examType = "Exam Type is Required";
    }

    if (formData.examType === "IELTS") {
      if (
        formData.speaking === "" ||
        formData.listening === "" ||
        formData.reading === "" ||
        formData.writing === ""
      ) {
        validationErrors.speaking = "IELTS Speaking is Required";
        validationErrors.listening = "IELTS Listening is Required";
        validationErrors.reading = "IELTS Reading is Required";
        validationErrors.writing = "IELTS Writing is Required";
      }
    } else if (formData.examType === "TOFEL") {
      if (formData.tofel_score === "") {
        validationErrors.tofel_score = "TOFEL Score is Required";
      }
    } else if (formData.examType === "PTE") {
      if (formData.pte_score === "") {
        validationErrors.pte_score = "PTE Score is Required";
      }
    } else if (formData.examType === "duolingo_lan") {
      if (formData.duolingo_lan === "") {
        validationErrors.duolingo_lan = "Duolingo Score is Required";
      }
    }

    setErrors(validationErrors);
    if (Object.values(validationErrors).some((error) => error !== "")) {
      return;
    }

    var api_data = {
      highest_education: formData.highestEducation,
      country_to_go: formData.country_to_go,
      exam: {
        type: formData.examType,
        score:
          formData.examType == "IELTS"
            ? [
              parseFloat(formData.writing),
              parseFloat(formData.reading),
              parseFloat(formData.speaking),
              parseFloat(formData.listening),
            ]
            : formData.examType == "PTE"
              ? parseFloat(formData.pte_score)
              : formData.examType == "duolingo_lan"
                ? parseFloat(formData.duolingo_lan)
                : formData.examType == "TOFEL"
                  ? parseFloat(formData.tofel_score)
                  : 0,
      },
      new_stream: formData.new_stream,
      grade_score: parseFloat(formData.gradeAverage),
    };
    navigate("/search", { state: { data: JSON.stringify(api_data) } });
  };

  const handleChangeexam = (event) => {
    const { name, value } = event.target;

    // Clear error when user starts typing
    setErrors({ ...errors, [name]: "" });

    if (
      name === "speaking" ||
      name === "listening" ||
      name === "reading" ||
      name === "writing"
    ) {
      let sanitizedValue = "";
      if (/^\d+(\.5)?$/.test(value)) {
        sanitizedValue = Math.min(parseFloat(value), 10).toString();
      }

      setFormData({ ...formData, [name]: sanitizedValue });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleChange = (selectedOption, { name }) => {
    setErrors({ ...errors, [name]: "" });

    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
  };

  const handleChange2 = (selectedOption, field) => {
    setErrors({ ...errors, [field]: "" });

    setFormData({
      ...formData,
      [field]: selectedOption.value,
    });
  };

  const handleChange3 = (event) => {
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: "" });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleexamChange = (selectedOption, fieldName) => {
    setErrors({ ...errors, [fieldName]: "" });

    setFormData({
      ...formData,
      [fieldName]: selectedOption.value,
    });
  };

  const getLabelForOption = (value) => {
    switch (value) {
      case "secondary":
        return "Grade 12/High School";
      case "certificate":
        return "1-Year Post-Secondary Certificate";
      case "diploma":
        return "2-Year Undergraduate Diploma";
      case "advance_diploma":
        return "3-Year Undergraduate Advanced Diploma";
      case "3_year_bachlor":
        return "3-Year Bachelor's Degree";
      case "4_year_bachlor":
        return "4-Year Bachelor's Degree";
      case "postgraduate_diploma":
        return "Postgraduate Certificate/Diploma";
      case "master":
        return "Master's Degree";
      case "doctrate":
        return "Doctoral Degree (PhD, M.D., ...)";
      default:
        return "";
    }
  };

  if (state.iswait) {
    return (
      <div className="w-full pt-[50px]">
        <center className="w-full my-10">
          <img src={loader} alt="" />
        </center>
      </div>
    );
  }

  const handleSelectMenuClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="mx-auto rounded my-14 multistep">
          <div className="input-container">
            <label>Nationality</label>
            <div className="my-4 md:mt-2 mx-0">
              <Select
                className="w-full"
                name="nationality"
                id="nationality"
                value={
                  formData.nationality
                    ? {
                      value: formData.nationality,
                      label: formData.nationality,
                    }
                    : null
                }
                options={state.countryList.map((country) => ({
                  value: country.countryName,
                  label: country.countryName,
                }))}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, { name: "nationality" })
                }
              />
              <div className="text-[red]">{errors.nationality}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Highest Education</label>
            <div className="my-4 md:mt-2 mx-0">
              <Select
                className="w-full"
                name="highestEducation"
                id="highestEducation"
                value={
                  formData.highestEducation
                    ? {
                      value: formData.highestEducation,
                      label: getLabelForOption(formData.highestEducation),
                    }
                    : null
                }
                options={[
                  { value: "", label: "--Select--" },
                  { value: "secondary", label: "Grade 12/High School" },
                  {
                    value: "certificate",
                    label: "1-Year Post-Secondary Certificate",
                  },
                  { value: "diploma", label: "2-Year Undergraduate Diploma" },
                  {
                    value: "advance_diploma",
                    label: "3-Year Undergraduate Advanced Diploma",
                  },
                  {
                    value: "3_year_bachlor",
                    label: "3-Year Bachelor's Degree",
                  },
                  {
                    value: "4_year_bachlor",
                    label: "4-Year Bachelor's Degree",
                  },
                  {
                    value: "postgraduate_diploma",
                    label: "Postgraduate Certificate/Diploma",
                  },
                  { value: "master", label: "Master's Degree" },
                  {
                    value: "doctrate",
                    label: "Doctoral Degree (PhD, M.D., ...)",
                  },
                ]}
                onChange={(selectedOption) =>
                  handleChange2(selectedOption, "highestEducation")
                }
              />
              <div className="text-[red]">{errors.highestEducation}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Grading Scheme</label>
            <div className="my-4 md:mt-2 mx-0">
              <Select
                className="w-full"
                name="gradingScheme"
                id="gradingScheme"
                value={
                  formData.gradingScheme
                    ? {
                      value: formData.gradingScheme,
                      label: "Secondary Level - Scale: 0-100",
                    }
                    : null
                }
                options={[
                  { value: "", label: "--Select--" },
                  {
                    value: "secondary_level",
                    label: "Secondary Level - Scale: 0-100",
                  },
                ]}
                onChange={(selectedOption) =>
                  handleChange2(selectedOption, "gradingScheme")
                }
              />
              <div className="text-[red]">{errors.gradingScheme}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Grade Average</label>
            <div className="my-4 md:mt-2 mx-0">
              <input
                className="no-spinner w-full p-2 border rounded-lg"
                type="number"
                name="gradeAverage"
                value={
                  formData.gradeAverage !== "" ? formData.gradeAverage : ""
                }
                id="gradeAverage"
                placeholder="Enter Grade Average"
                onChange={handleChange3}
              />
              <div className="text-[red]">{errors.gradeAverage}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Name</label>
            <div className="my-4 md:mt-2 mx-0">
              <input
                className="border w-full rounded-lg p-2"
                type="text"
                name="name"
                key={"4"}
                id="name"
                value={formData.name !== "" ? formData.name : ""}
                placeholder="Enter Name"
                onChange={handleChange3}
              />
              <div className="text-[red]">{errors.name}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Phone Number</label>
            <div className="my-4 md:mt-2 mx-0">
              <input
                key={"5"}
                className="no-spinner border w-full rounded-lg p-2"
                type="number"
                name="phoneNumber"
                id="phoneNumber"
                value={formData.phoneNumber}
                placeholder="Enter Phone"
                onChange={handleChange3}
              />
              <div className="text-[red]">{errors.phoneNumber}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Email</label>
            <div className="my-4 md:mt-2 mx-0">
              <input
                className="border w-full rounded-lg p-2"
                type="email"
                name="email"
                value={formData.email}
                id="email"
                placeholder="Enter Email"
                onChange={handleChange3}
              />
              <div className="text-[red]">{errors.email}</div>
            </div>
          </div>

          <div className="input-container">
            <label>Where You Want To Go?</label>
            <div className="my-4 md:mt-2 mx-0">
              <Select
                ref={selectRef}
                className="w-full"
                name="country_to_go"
                id="country_to_go"
                isMulti
                value={formData.country_to_go
                  ? formData.country_to_go.map((selectedCountry) => ({
                    value: selectedCountry,
                    label: selectedCountry,
                  }))
                  : null
                }
                options={state.countryList.map((country) => ({
                  value: country.countryName,
                  label: country.countryName,
                }))}
                onChange={(selectedOptions) => {
                  const selectedCountries = selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [];
                  setFormData({
                    ...formData,
                    country_to_go: selectedCountries,
                  });
                }}
                placeholder="--Select--"
                labelledBy="College Name"
                searchable={true}
                hideSelectedOptions={false}
                menuIsOpen={isDropdownOpen}
                onFocus={() => setIsDropdownOpen(true)}
                onBlur={() => setIsDropdownOpen(false)}
                menuProps={{ onClick: handleSelectMenuClick }}
              />
              {formData.country_to_go.length == 0 && (
                <div className="text-[red]">{errors.country_to_go}</div>
              )}
            </div>
          </div>

          <div className="input-container">
            <label>Exam</label>
            <div className="my-4 md:mt-2 mx-0">
              <Select
                name="examType"
                className="w-full"
                options={[
                  { value: "", label: "--SELECT--" },
                  { value: "TOFEL", label: "TOFEL" },
                  { value: "IELTS", label: "IELTS" },
                  { value: "PTE", label: "PTE" },
                  { value: "duolingo_lan", label: "DUOLINGO" },
                ]}
                value={
                  formData.examType
                    ? { value: formData.examType, label: formData.examType }
                    : null
                }
                onChange={(selectedOption) =>
                  handleexamChange(selectedOption, "examType")
                }
              />
              <div className="text-[red]">{errors.examType}</div>

              {formData.examType == "IELTS" ? (
                <div className="flex examLabels gap-4 red">
                  <div className="input-container-child">
                    <label>Listening</label>
                    <input
                      type="number"
                      value={formData.listening}
                      name="listening"
                      onChange={handleChangeexam}
                      className="no-spinner listening border border-current  h-9 p-2"
                    />
                    <div className="text-[red]">{errors.listening}</div>
                  </div>
                  <div className="input-container-child">
                    <label>Reading</label>
                    <input
                      type="number"
                      value={formData.reading}
                      name="reading"
                      onChange={handleChangeexam}
                      className="no-spinner reading border border-current  h-9 p-2"
                    />
                    <div className="text-[red]">{errors.reading}</div>
                  </div>
                  <div className="input-container-child">
                    <label>Writing</label>
                    <input
                      type="number"
                      value={formData.writing}
                      name="writing"
                      onChange={handleChangeexam}
                      className="no-spinner writing border border-current  h-9 p-2"
                    />
                    <div className="text-[red]">{errors.writing}</div>
                  </div>
                  <div className="input-container-child">
                    <label className="">Speaking</label>
                    <input
                      type="number"
                      value={formData.speaking}
                      name="speaking"
                      onChange={handleChangeexam}
                      className="no-spinner speaking border border-current  h-9 p-2"
                    />
                    <div className="text-[red]">{errors.speaking}</div>
                  </div>
                </div>
              ) : formData.examType == "TOFEL" ? (
                <div className="examLabels">
                  <label>Tofel Score</label>
                  <input
                    type="number"
                    name="tofel_score"
                    value={formData.tofel_score}
                    onChange={handleChangeexam}
                    className="no-spinner writing border border-current  h-9 p-2 w-full"
                  />
                  <div className="text-[red]">{errors.tofel_score}</div>
                </div>
              ) : formData.examType == "PTE" ? (
                <div className="examLabels">
                  <label>Pte Score</label>
                  <input
                    type="number"
                    name="pte_score"
                    value={formData.pte_score}
                    onChange={handleChangeexam}
                    className="no-spinner writing border border-current  h-9 p-2 w-full"
                  />
                  <div className="text-[red]">{errors.pte_score}</div>
                </div>
              ) : formData.examType == "duolingo_lan" ? (
                <div className="examLabels">
                  <label>Duolingo Score</label>
                  <input
                    type="number"
                    name="duolingo_lan"
                    value={formData.duolingo_lan}
                    onChange={handleChangeexam}
                    className="no-spinner writing border border-current  h-9 p-2 w-full"
                  />
                  <div className="text-[red]">{errors.duolingo_lan}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {formData.speaking !== "" &&
              formData.listening !== "" &&
              formData.reading !== "" &&
              formData.writing !== "" && (
                <div className="overall-score">
                  <p>
                    Overall Score -{" "}
                    {(
                      Math.round(
                        ((parseFloat(formData.speaking) +
                          parseFloat(formData.listening) +
                          parseFloat(formData.reading) +
                          parseFloat(formData.writing)) /
                          4) *
                        2
                      ) / 2
                    ).toFixed(1)}
                  </p>
                </div>
              )}
          </div>

          <button type="submit" onClick={searchNow}>
            Search
          </button>
        </div>
      </div>
    </>
  );
}