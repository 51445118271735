import { Outlet, Route, Routes } from "react-router-dom";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import Navbar from "../../common/Header/Navbar";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";
const Dashboard = ({ children, heading_title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const role = location.pathname;
    if(localStorage.getItem('isAuthenticated') == null){
      navigate('/adminlogin');
    }
  }, []);
  return (
    <>
      <Header />
      <main className="custom_side_bar">
        <Navbar heading_title={heading_title} />
        <div className="innerBox">
          <div style={{ minHeight: "85vh" }}>
            <Outlet />
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
