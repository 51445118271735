import React, { useState } from "react";
import AgentDashboard from "./AgentDashboard";
import StudentDashboard from "./StudentDashboard";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const DashboardPage = () => {
  const [key, setKey] = useState('home');
  return (
    <div>
      <div className="dash_bord_form agent-dashboard agent-tabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="home" title="Agents' Students History">
            <AgentDashboard />
          </Tab>
          <Tab eventKey="profile" title="Students History">
            <StudentDashboard />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default DashboardPage;
