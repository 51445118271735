import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { getToken } from "../../../helper/auth";
import StudentPayment from "../../student/Pages/StudentPayment";
import { ToastContainer, toast } from "react-toastify";
import loader from "../../../images/loader.gif";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const AgentEnrolledList = () => {
  const navigate = useNavigate();
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryName, setCountryName] = useState([]);
  const [collegeName, setCollegeName] = useState([]);
  const [selectCollegeName, setSelectCollegeName] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [state, setState] = useState({
    isWaiting: true,
    enrolledPrograms: [],
    agentToken: getToken("agent"),
    totalPages: 0,
    currentPage: 1,
    baseUrl: "",
  });

  useEffect(() => {
    getPaginationData(1);
  }, []);

  const getPaginationData = (
    page,
    searchName,
    selectedCountry,
    selectCollegeName
  ) => {
    setState({
      ...state,
      isWaiting: true,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${state.agentToken}`,
      },
    };
    const country =
      selectedCountry && selectedCountry.map((country) => country.value);
    // Construct the query parameters manually
    const params = new URLSearchParams();
    params.append("currentPage", page);
    if (searchName) {
      params.append("searchName", searchName);
    }
    if (selectCollegeName) {
      params.append("collegeName", selectCollegeName);
    }
    if (Array.isArray(country) && country.length > 0 && country != undefined) {
      country.forEach((c) => params.append("country", c));
    }

    axios
      .get(
        process.env.REACT_APP_NODE_URL +
        "/agent/getAgentEnrolledList?" +
        params.toString(),
        {
          headers: config.headers,
        }
      )
      .then((res) => {
        // Handle the response
        const collegeName = res?.data?.details?.enrolled_list.map(
          (file) => file.college_details?.college_name
        );
        setCollegeName(collegeName);
        setState({
          ...state,
          enrolledPrograms: res?.data?.details?.enrolled_list,
          isWaiting: false,
          baseUrl: res?.data?.details?.baseUrl,
          totalPages: res?.data?.totalPages,
        });
        setisrotating(false);
      })
      .catch((err) => {
        toast(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchCountryNames();
  }, []);

  const fetchCountryNames = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`
      );
      const countries = response.data.details;
      console.log("countrycode", countries);
      const countryData = countries.map((item) => ({
        label: item.countryName,
        value: item.countryId,
      }));
      console.log(countryData);
      setCountryName(countryData);
    } catch (error) {
      console.error(error);
    }
  };

  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleSearch = () => {
    getPaginationData(
      state.currentPage,
      searchName,
      selectedCountry,
      selectCollegeName
    );
  };

  const startLoader = () => {
    setisrotating(true);
    getPaginationData(state.currentPage);
    setSearchName("");
    setSelectedCountry([]);
    setSelectCollegeName([]);
  };

  return (
    <>
      <ToastContainer />
      <div>
        <>
          <div className="row p-45">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
                <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 items-center mt-4">
                  <div className="mb-4">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      Student Name
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 py-2 px-4 rounded-md focus:outline-none w-full"
                      placeholder="Search By Student Name"
                      value={searchName}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      Country
                    </label>
                    <Select
                      isMulti
                      name="country"
                      className="block w-full flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={countryName}
                      value={selectedCountry}
                      onChange={(newValue) => {
                        setSelectedCountry(newValue);
                      }}
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      College Name
                    </label>
                    <Select
                      isMulti
                      name="collegeName"
                      options={collegeName.map((college) => ({
                        value: college,
                        label: college,
                      }))}
                      className="block w-full flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map(
                          (option) => option.value
                        );
                        setSelectCollegeName(selectedValues.join(","));
                      }}
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="flex query-button justify-between">
                    <button
                      onClick={handleSearch}
                      className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                    >
                      Find
                    </button>
                    <div className="reload-icon mr-2 text-right">
                      <i
                        className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                        onClick={startLoader}
                      />
                    </div>
                  </div>
                </div>
                </div>

                {state.isWaiting ? (
                  <center className="w-full my-10">
                    <img src={loader} alt="" />
                  </center>
                ) : (
                  <div className="overflow-auto card  my-4 col-12 px-0 pt-0 pb-2 agent-table border table-overflow">
                    <table className="table-auto overflow-scroll w-full files-table enrolled-list-table">
                      <thead>
                        <tr className="">
                          <th className="p-4 text-left">Sr. No.</th>
                          <th className="p-4 text-left">Created Date</th>
                          <th className="p-4 text-left">File Id</th>
                          <th className="p-4 text-left">Country</th>
                          <th className="p-4 text-left">College</th>
                          <th className="p-4 text-left">Student Name</th>
                          <th className="p-4 text-left">Program</th>
                          <th className="p-4 text-left">Tution Fees</th>
                          <th className="p-4 text-left">Duration</th>
                          <th className="p-4 text-left">Processing Fees</th>
                          <th className="p-4 text-left">Current Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {state.enrolledPrograms.length > 0 ? (
                          state.enrolledPrograms.map((enrollDetails, index) => {
                            const serialNumber = index + 1;
                            return (
                              <tr>
                                <td className="p-4 text-left capitalize">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {serialNumber}
                                  </p>
                                </td>
                                <td className="p-4 text-left capitalize">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {new Date(enrollDetails.updatedAt).toLocaleDateString('en-GB')}
                                  </p>
                                </td>
                                <td className="p-4 text-blue-500 hover:text-blue-700 transition-colors duration-300">
                                  <div
                                    onClick={() => {
                                      localStorage.setItem(
                                        "userId",
                                        enrollDetails?.student_id
                                      );
                                      navigate(
                                        `/agent/remarks/${enrollDetails._id}/${enrollDetails.fileId}`
                                      );
                                    }}
                                    className="flex cursor-pointer items-center"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6 mr-1 text-blue-300"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13 4V2H7v2H2v14h16V4h-5zM7 2h6v2H7V2zm7 14H6V8h8v8zm-2-6h2v2h-2v-2zm-3 0h2v2H9v-2zm-3 0h2v2H6v-2zm0-3h8v1H6V7z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <div className="overflow-hidden overflow-ellipsis text-blue mt-1">
                                      {enrollDetails?.fileId}
                                    </div>
                                  </div>
                                </td>
                                <td className="p-4 border-2 capitalize">
                                  <div className="flex items-center">
                                    <div className="small-image-container me-2">
                                      <img
                                        width={"100px"}
                                        style={{minWidth: 40}}
                                        src={
                                          state.baseUrl +
                                          enrollDetails.college_details.college_meta_details
                                            .countryLogo
                                        }
                                        alt="logo"
                                      />
                                    </div>
                                    {enrollDetails.college_details.country}
                                  </div>
                                </td>
                                <td className="p-4 border-2 capitalize">
                                <div className="flex items-center">
                                  <div className="small-image-container me-2">
                                    <img
                                      width={"100px"}
                                      style={{minWidth: 40}}
                                      src={
                                        state.baseUrl +
                                        enrollDetails.college_details.college_meta_details
                                          .collegeLogo
                                      }
                                      alt="logo"
                                    />
                                  </div>
                                  {enrollDetails.college_details.college_name}
                                  </div>
                                </td>
                                <td className="p-4 text-left capitalize">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {enrollDetails.student_details.firstName}{" "}
                                    {enrollDetails.student_details.lastName}
                                  </p>
                                </td>
                                <td className="p-4 text-left capitalize">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {
                                      enrollDetails.college_details.college_programs
                                        .program_name
                                    }
                                  </p>
                                </td>
                                <td className="p-4 text-left capitalize">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {
                                      enrollDetails.college_details.college_programs
                                        .max_tution_fee
                                    }
                                    -
                                    {
                                      enrollDetails.college_details.college_programs
                                        .min_tution_fee_per_semester
                                    }{" "}
                                    {
                                      enrollDetails.college_details.college_programs
                                        .currency
                                    }
                                  </p>
                                </td>
                                <td className="p-4 text-left capitalize">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {
                                      enrollDetails.college_details.college_programs
                                        .duration
                                    }{" "}
                                    Years
                                  </p>
                                </td>
                                <td className="p-4 border-2 capitalize">
                                  {enrollDetails?.applicationFee === 0 ? (
                                    <h1 className="text-orange-600 font-bold">
                                      Free
                                    </h1>
                                  ) : (
                                    <>
                                      {
                                        enrollDetails?.applicationFee
                                      }{" "}
                                      {enrollDetails?.college_details?.countryDetails?.currency}
                                      {enrollDetails.enroll_status ===
                                        "OL_FEES_AND_DOCUMENTS_PENDING" ||
                                        enrollDetails.enroll_status ===
                                        "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION" ||
                                        enrollDetails.enroll_status ===
                                        "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED" ||
                                        enrollDetails.enroll_status ===
                                        "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED" ? (
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/agent/remarks/${enrollDetails._id}/${enrollDetails.fileId}`
                                            );
                                          }}
                                          className="bg-blue-500 hover:bg-blue-300 text-white font-bold py-1 px-2 rounded ml-1"
                                        >
                                          Pay
                                        </button>
                                      ) : (
                                        <h1 className="text-green-600 font-bold">
                                          Paid
                                        </h1>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className="p-4 text-left">
                                  {enrollDetails.enroll_status
                                    ? enrollDetails.enroll_status
                                      .replace(/_/g, " ")
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")
                                    : "--"}
                                  {enrollDetails.optionaldocStatus === true ||
                                    enrollDetails.enroll_status === "" ||
                                    enrollDetails.enroll_status === "OL_FEES_AND_DOCUMENTS_PENDING" ||
                                    enrollDetails.enroll_status === "OL_FEES_PAID_AND_DOCUMENTS_PENDING" ||
                                    enrollDetails.enroll_status === "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION" ||
                                    enrollDetails.enroll_status === "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ||
                                    enrollDetails.enroll_status === "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED" ||
                                    enrollDetails.enroll_status === "OL_FEES_PAID_AND_DOCUMENTS_REJECTED" ? (
                                    <button className="bg-blue-500 hover:bg-blue-300 text-white 
                                        font-bold py-1 px-2 rounded"
                                      onClick={() => {
                                        navigate(
                                          `/agent/remarks/${enrollDetails._id}/${enrollDetails.fileId}`
                                        );
                                      }}>Documents</button>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12" className="text-center">
                              <div>
                                <p className="text-center p-5 text-red-500">
                                  No data available.
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="card-footer pb-0">
                  <div className="pagination">
                    <div className="pages">
                      {state?.totalPages >=2 && (
                        <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={(event) => {
                          getPaginationData(event.selected + 1);
                        }}
                        pageCount={state.totalPages}
                        previousLabel="prev"
                        renderOnZeroPageCount={null}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={0}
                        activeClassName="selected"
                        containerClassName="pagination"
                        subContainerClassName="pages"
                        initialPage={state.currentPage}
                        disableInitialCallback={true}
                        previousClassName={
                          state.currentPage === 0 ? "disabled" : ""
                        }
                        nextClassName={
                          state.currentPage === state.totalPages - 1
                            ? "disabled"
                            : ""
                        }
                        previousLinkClassName={
                          state.currentPage === 0 ? "disabled-link" : ""
                        }
                        nextLinkClassName={
                          state.currentPage === state.totalPages - 1
                            ? "disabled-link"
                            : ""
                        }
                      />
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default AgentEnrolledList;
