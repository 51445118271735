/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { GrClose } from "react-icons/gr";
import { Navigate, redirect, useSearchParams } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import Dashboard from "../Screens/Dashboard/Dashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import loader from "../../../images/loader.gif";
import { AiFillSetting } from "react-icons/ai";

const itemsPerPage = 10;


const EmployeeList = () => {
  const [ModalCode, setModalCode] = useState(false);
  const [searchName,setSearchName] = useState('')
  const [showPagination, setShowPagination] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ModalCodeFilter, setModalCodeFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [state, setState] = useState({
    isWaiting: false,
    list: [],
    adminToken: getToken("admin"),
    totalPages: 0,
    currentPage: 1,
    isPermissionPopupActive: false,
    activeUser: false,
    tempPermissions: [],
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Name", value: "Name" },
    { label: "Email", value: "Email" },
    { label: "Created", value: "Created" },
    { label: "Permission", value: "Permission" },

  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsemployeelist")) || [
      "Sr.",
      "Name",
      "Email",
      "Created",
      // "Permission",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Name",
    "Email",
    "Created",
    // "Permission",
  ]);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Email",
      "Created",
      // "Permission",
    ]);
  }, []);

  useEffect(() => {
    getPaginationData(currentPage);
  }, []);

  const getPaginationData = (page) => {
    const config = { headers: { Authorization: `Bearer ${state.adminToken}` } };
    let data = { currentPage: page };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/employeelist",
        data,
        config
      )
      .then((res) => {
        setState({
          ...state,
          list: res.data.details.list,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
      })
      .catch((err) => {
      });
  };

  const getSearchResult = (page, searchName) => {
    const agentId = searchParams.get("id");

    setState({
      ...state,
      isWaiting: true,
    });
    let status = searchParams.get("status") || "";
    let data;
    if (searchName == undefined) {
      data = { currentPage: page, status, searchName: "" };
    } else {
      data = { currentPage: page, status, searchName: searchName };
    }
    setState({
      ...state,
      isWaiting: true,
    });
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/employeelist", data)
      .then((res) => {
        setState({
          ...state,
          list: res.data.details.list,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
      })
      .catch((err) => {
      });
  };

  const togglePermission = (e) => {
    let oldTempPermissions = state.tempPermissions;
    if (oldTempPermissions.includes(e.target.value)) {
      let index = oldTempPermissions.indexOf(e.target.value);
      oldTempPermissions.splice(index, 1);
    } else {
      oldTempPermissions.push(e.target.value);
    }
    setState({
      ...state,
      tempPermissions: oldTempPermissions,
    });
  };

  const savePermissions = () => {
    var data = {
      userId: state.activeUser,
      permissions: state.tempPermissions,
    };
    const config = { headers: { Authorization: `Bearer ${state.adminToken}` } };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/updatepermissions",
        data,
        config
      )
      .then((res) => {
        toast(res.data.message);
      })
      .catch((err) => {
      });
  };

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns; // Define updatedColumns here

    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }
  
    localStorage.setItem("selectedColumnsemployeelist", JSON.stringify(updatedColumns));
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }


  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem("selectedColumnsemployeelist", JSON.stringify(uniqueSelectedColumns));

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem("selectedColumnsemployeelist"));
    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Name",
        "Email",
        "Created",
      ]);
      setTempColumnsTable([
        "Sr.",
        "Name",
        "Email",
        "Created",
      ]);
    }
  }, []);

  useEffect(() => {
    setTempColumnsTable([
      "Sr.",
      "Name",
      "Email",
      "Created",
    ]);
  }, [])


  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsemployeelist");
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Email",
      "Created",
    ]);
    setTempColumnsTable([
      "Sr.",
      "Name",
      "Email",
      "Created",
    ]);
  };

  const handleSearch = () => {
    // if (searchName === "") {
    //   setShowPagination(true);
    //   // getPaginationData(1, "")
    // } else {
    //   setShowPagination(false);
    // }
    getSearchResult(1, searchName);
  }

  return (
    <>
      <ToastContainer />
      <div heading_title={"Sub-Admin List"}>
        <div className="row mt-10 p-45  mob-padding">
          <>
            <div className="flex justify-between items-center">
              <div>
                <div className="flex my-3">
                  <input
                    type="text"
                    className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none"
                    placeholder="Search Agent By Name"
                    value={searchName}
                    // onChange={(e) => setSearchName(e.target.value)}
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                  />
                  <button
                    onClick={handleSearch}
                    className="custom_add_button w-full mt-0 bg-[#19632f] hover:bg-[#053815] text-white py-2 px-4 rounded-r-md focus:outline-none"
                  >
                    Find
                  </button>

                </div>
              </div>
              <AiFillSetting
                className="Bar_filter"
                onClick={() => setModalCode(!ModalCode)}
              />
            </div>
            <div className="btn_outerxx mb-3 mt-5">
              {ModalCode ? (
                <div className="modal_cover">
                  <div className="modal_inner select-col-popup">
                    <div className="header_modal">
                      <h1 className="capitalize font-bold text-lg mb-2">
                        select columns
                      </h1>
                      <p className="border-b pb-2 mb-2">
                      Select the column you want to display or hide.
                      </p>
                      <div
                        className="columns-popup"
                        style={{ justifyContent: "flex-start" }}
                      >
                        {tableColumns.map((column, index) => {

                          if (
                            selectedColumnsTable.includes(column.value)
                          ) {
                            return (
                              <p className="mb-0 gap-2">
                                <input
                                  type="checkbox"
                                  id={column.value}
                                  checked={tempColumnsTable.includes(column.value)}
                                  onChange={() => handleColumnCheckboxChange(column.value)}
                                />
                                <label htmlFor={column.value}>
                                  {formatLabel(column.label)}
                                </label>
                              </p>
                            );
                          } else {
                            return (
                              <p className="mb-0 gap-2">
                                <input
                                  type="checkbox"
                                  id={column.value}
                                  onClick={() => {
                                    selectedColumnsTable.push(
                                      column.value
                                    );
                                    setTempColumnsTable(
                                      selectedColumnsTable
                                    );
                                  }}
                                />
                                <label htmlFor={column.value}>
                                  {formatLabel(column.label)}
                                </label>
                              </p>
                            );
                          }
                        })}
                      </div>
                      <div className="button-part flex justify-end items-center gap-4">
                        <button
                          className="py-2 px-6"
                          type="btn"
                          onClick={handleResetAll}
                        >
                          Reset all
                        </button>
                        <button
                          className="py-2 px-6"
                          type="btn"
                          onClick={handleOk}
                        >
                          Ok
                        </button>
                      </div>
                      <GrClose
                        className="close"
                        onClick={() => setModalCode(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {state?.isPermissionPopupActive && (
              <div className="modal_cover">
                <div className="popup permissions employee-popup">
                  <h1 className="capitalize font-bold text-lg mb-4 border-b">
                    Permissions
                    <GrClose
                      className="close"
                      onClick={() => {
                        setState({
                          ...state,
                          activeUser: false,
                          isPermissionPopupActive: false,
                        });
                      }}
                    />
                  </h1>
                  <ol>
                    <li>
                      <label className="flex items-center" htmlFor="">
                        {state.tempPermissions.includes("csv_programs") ? (
                          <input
                            name="permission"
                            value="csv_programs"
                            onChange={togglePermission}
                            className="mx-2 w-[15px] h-[15px]"
                            type="checkbox"
                            checked
                          />
                        ) : (
                          <input
                            name="permission"
                            value="csv_programs"
                            onChange={togglePermission}
                            className="mx-2 w-[15px] h-[15px]"
                            type="checkbox"
                          />
                        )}
                        <span>CSV programs</span>
                      </label>
                    </li>
                    <li>
                      <label className="flex items-center" htmlFor="">
                        {" "}
                        {state.tempPermissions.includes("college_main") ? (
                          <input
                            name="permission"
                            value="college_main"
                            onChange={togglePermission}
                            className="mx-2 w-[15px] h-[15px]"
                            type="checkbox"
                            checked
                          />
                        ) : (
                          <input
                            name="permission"
                            value="college_main"
                            onChange={togglePermission}
                            className="mx-2 w-[15px] h-[15px]"
                            type="checkbox"
                          />
                        )}
                        Colleges/Programs
                      </label>
                      <ul className="flex flex-wrap justify-between px-3 mt-1">
                        <li className="flex items-center">
                          {state.tempPermissions.includes("sp_country_names") ? (
                            <input
                              name="permission"
                              value="sp_country_names"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                              checked
                            />
                          ) : (
                            <input
                              name="permission"
                              value="sp_country_names"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                            />
                          )}
                          <span>Add Country Names</span>
                        </li>
                        <li className="flex items-center">
                          {state.tempPermissions.includes("sp_college_names") ? (
                            <input
                              name="permission"
                              value="sp_college_names"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                              checked
                            />
                          ) : (
                            <input
                              name="permission"
                              value="sp_college_names"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                            />
                          )}
                          <span>Add College Names</span>
                        </li>
                        <li className="flex items-center">
                          {state.tempPermissions.includes("sp_list") ? (
                            <input
                              name="permission"
                              value="sp_list"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                              checked
                            />
                          ) : (
                            <input
                              name="permission"
                              value="sp_list"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                            />
                          )}
                          <span>College/Program list</span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <label className="flex items-center" htmlFor="">
                        {" "}
                        {state.tempPermissions.includes("subadmin_main") ? (
                          <input
                            name="permission"
                            value="subadmin_main"
                            onChange={togglePermission}
                            className="mx-2 w-[15px] h-[15px]"
                            type="checkbox"
                            checked
                          />
                        ) : (
                          <input
                            name="permission"
                            value="subadmin_main"
                            onChange={togglePermission}
                            className="mx-2 w-[15px] h-[15px]"
                            type="checkbox"
                          />
                        )}
                        Employee
                      </label>
                      <ul className="px-3 mt-1">
                        <li className="flex items-center">
                          {state.tempPermissions.includes("employee_create") ? (
                            <input
                              name="permission"
                              value="subadmin_create"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                              checked
                            />
                          ) : (
                            <input
                              name="permission"
                              value="subadmin_create"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                            />
                          )}
                          <span>Create</span>
                        </li>
                        <li className="flex items-center">
                          {state.tempPermissions.includes("employee_list") ? (
                            <input
                              name="permission"
                              value="subadmin_list"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                              checked
                            />
                          ) : (
                            <input
                              name="permission"
                              value="subadmin_list"
                              onChange={togglePermission}
                              className="mx-2 w-[15px] h-[15px]"
                              type="checkbox"
                            />
                          )}
                          <span>List</span>
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <button
                    className="float-right py-2 px-6 m-2"
                    onClick={savePermissions}
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
            <div className="w-full">
              <div className="">

                <div className="overflow-auto card  col-12 p-0 pr-0 table_postioning employee-table table-overflow">
                  {state.isWait ? (
                    <div>
                      <center className="w-full my-10">
                        <img src={loader} alt="" />
                      </center>
                    </div>
                  ) : state?.list?.length > 0 ? (
                    <table className="table-auto overflow-scroll w-full agent-table files-table">
                      <thead>
                        <tr>
                          {tableColumns.map((col) => {
                            if (selectedColumnsTable.includes(col.label)) {
                              return (
                                <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                  {formatLabel(col.label)}
                                </th>
                              );
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {state.list?.map((student, index) => {
                          return (
                            <tr className="hover:bg-blue-100 cursor-pointer transition-colors">
                              {selectedColumnsTable.includes("Sr.") && (
                                <td className="p-2 capitalize w-0">
                                  <p className="text-xs font-weight-bold mb-0">
                                    {index + 1}
                                  </p>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Name") && (
                                <td className="p-2">
                                  <div className="d-flex px-2 py-1">
                                    <div className="d-flex flex-column justify-content-center">
                                      <h6 className="mb-0">
                                        {student.first_name +
                                          " " +
                                          student.last_name || "SubAdmin"}
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Email") && (
                                <td className="p-2 align-middle">
                                  <span className="badge badge-sm bg-gradient-success">
                                    {student.email}
                                  </span>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Created") && (
                                <td className="p-2 align-middle ">
                                  <span className="text-secondary text-xs font-weight-bold">
                                    {student?.created || ""}
                                  </span>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Permission") && (
                                <td className="p-2 align-middle">
                                  <span
                                    className="text-[#2a276b] font-bolder cursor-pointer permission"
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        activeUser: student._id,
                                        isPermissionPopupActive: true,
                                        tempPermissions:
                                          state.list[index].permissions,
                                      });
                                    }}
                                  >
                                    Permissions
                                  </span>
                                </td>
                              )}


                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <center className="w-full my-10">
                        <img src={loader} alt="" />
                      </center>
                    </div>
                  )}
                </div>

                <div className="pb-0">
                  <div className="pagination">
                    <div className="pages">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        pageCount={state.totalPages}
                        onPageChange={(event) => {
                          getPaginationData(event.selected);
                        }}
                        previousLabel="prev"
                        renderOnZeroPageCount={null}
                        initialPage={state.currentPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        containerClassName="pagination"
                        activeClassName="selected"
                        subContainerClassName="pages"
                        disableInitialCallback={true}
                        previousClassName={currentPage === 0 ? "disabled" : ""}
                        nextClassName={currentPage === state.totalPages - 1 ? "disabled" : ""}
                        previousLinkClassName={currentPage === 0 ? "disabled-link" : ""}
                        nextLinkClassName={
                          currentPage === state.totalPages - 1 ? "disabled-link" : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
