import axios from "axios";
import { GrClose } from "react-icons/gr";
import { useEffect, useRef, useState } from "react";
import loader from "../../../images/loader.gif";
import { AiFillSetting } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import AddcollegeNamePopup from "./AddcollegeNamePopup";
import EditcollegeNamePopup from "./EditcollegeNamePopup";
import { MdHideSource, MdRemoveRedEye } from "react-icons/md";
import HideCollege from "./HideCollege";
import ShowCollege from "./ShowCollege";
import CollegeFilter from "../../website/Components/collegeFilter";

const Addcollegename = () => {
  const selectRef = useRef(null);
  const [isrotating, setisrotating] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [newIsDropdownOpen, setNewIsDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsState, setSelectedOptionsState] = useState([]);
  const [newSelectedOptions, setNewSelectedOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [ModalCode, setModalCode] = useState(false);
  const [totalPage, setTotalpage] = useState(0);
  const [countryName, setCountryName] = useState([]);
  const [newCollegeName, setNewCollegeName] = useState();
  const [stateName, setStateName] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [empty, setEmpty] = useState([]);
  const [showaddpopup, setShowAddPopup] = useState(false);
  const [showeditpopup, setShowEditPopup] = useState(false);
  const [showcollegepopup, setshowcollegepopup] = useState(false);
  const [hidecollegepopup, sethidecollegepopup] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);


  const [totalPages, setTotalpages] = useState(0);
  const [inputValues, setInputValues] = useState({});
  // 
  const [selectedCountryOptions, setselectedCountryOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  // 
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);

  const [state, setState] = useState({
    isWaiting: true,
    isFilterLoading: false,
    collegeNamesList: [],
    list: [],
    country: "",
    searchItem: "",
    baseUrl: "",

    countryNamesList: [],
    stateNamesList: [],
    cityNamesList: [],

    // new filters
    filter_type: "",
    filter_total_students_min: "",
    filter_total_students_max: "",
    filter_international_students_min: "",
    filter_international_students_max: "",
    filter_founded_year_min: "",
    filter_founded_year_max: "",
    filter_accomodation_features: "",
    filter_work_permit_feature: "",
    filter_work_while_study: "",
    filter_program_cooporation: "",
    filter_condition_offer_letter: "",
    // filter_top_status: "",
    filter_library: "",
    countryId: "",
    stateId: "",
    cityId: "",
  });

  const [buttonLoading, setButtonLoading] = useState({
    type: "FALSE",
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "College", value: "College" },
    { label: "Country", value: "Country" },
    { label: "State", value: "State" },
    { label: "City", value: "City" },
    { label: "Actions", value: "Actions" },
  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsaddcollegename")) || [
      "Sr.",
      "College",
      "Country",
      "State",
      "City",
      "Actions",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "College",
    "Country",
    "State",
    "City",
    "Actions",
  ]);

  useEffect(() => {
    setSelectedColumnsTable(["Sr.", "College", "Country", "State", "City"]);
  }, []);

  const initialData = (apiUrl) => {
    setLoaderState(true);
    axios
      .get(process.env.REACT_APP_NODE_URL + "/address/country")
      .then((countryResponse) => {
        if (countryResponse.data.status == "1") {
          axios
            .get(apiUrl)
            .then((response) => {
              setTotalpage(response.data.totalPages);
              setStateName(empty);
              if (response.data.status === "1") {
                setisrotating(false);
                setLoaderState(false);
                setState({
                  ...state,
                  list: response?.data?.details?.list,
                });
              } else {
                setLoaderState(false);
              }
            })
            .catch((error) => {
              console.error("Error fetching initial data:", error);
            });
        }
      });
  };

  useEffect(() => {
    initialData(
      `${process.env.REACT_APP_NODE_URL
      }/admin/collegenames?country=${""}&colleges=${""}&currentPage=${currentPage}`
    );
  }, []);

  useEffect(() => {
    dropDownCountries();
  }, []);

  const dropDownCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`
      );
      const getCountries = response?.data?.details;
      const resultOne = getCountries.map((item) => ({
        label: item.countryName, // Displayed value
        value: item.countryId, // Actual value (countryId)
      }));
      setCountryName(resultOne);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };


  useEffect(() => {
    getStates();
  }, [newSelectedOptions]);

  useEffect(() => {
    getAllCollegeaData()
  }, []);

  const getCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`);
      const countries = response?.data?.details;
      const result = countries.map((country) => ({
        label: country.countryName,
        value: country.countryId,
      }));
      setCountryOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${selectedCountryIds.join(",")}`
      );
      const states = response?.data?.details;
      const result = states.map((state) => ({
        label: state.stateName,
        value: state.stateId,
      }));
      setStateOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async () => {
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchCities?stateIds=${selectedStateIds.join(",")}`
      );
      const cities = response?.data?.details;
      const result = cities.map((city) => ({
        label: city.cityName,
        value: city.cityId,
      }));
      setCityOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getColleges = async () => {
    console.log("TESTTTTTTTTT")
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    const selectedCityIds = selectedCityOptions.map((city) => city.value);

    console.log("selectedCountryIds", selectedCountryIds)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCollegeNameList?country=${selectedCountryIds.join(",")}&state=${selectedStateIds.join(",")}&city=${selectedCityIds.join(",")}`
      );
      const colleges = response?.data?.detail;
      console.log("College Response", response)
      const result = colleges.map((college) => ({
        label: college.collegeName,
        value: college.collegeName,
      }));
      setCollegeOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCountries();
      await getStates();
      await getCities();
      await getColleges();
    };
    fetchData();
  }, [selectedCountryOptions, selectedStateOptions, selectedCityOptions]);

  const getAllCollegeaData = async () => {
    setLoaderState(true);
    setState({
      ...state,
      isWaiting: true,
    });

    await axios.get(process.env.REACT_APP_NODE_URL + `/admin/collegenames?country=${""}&colleges=${""}&currentPage=${currentPage}`)
      .then((res) => {
        console.log("All colleges", res)
        setLoaderState(false);
        setState({
          ...state,
          list: res?.data?.details?.list,
        });
        setTotalpages(res.data.totalPages);
      })
      .catch((error) => {
        setLoaderState(false);
      });
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumnsaddcollegename",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  const handlePageChange = (e) => {
    setLoaderState(true);
    const newPage = e.selected;

    const apiUrl = `${process.env.REACT_APP_NODE_URL
      }/admin/collegenames?country=${""}&colleges=${""}&state=${""}&city=${""}&currentPage=${newPage + 1
      }`;

    axios
      .get(apiUrl)
      .then((response) => {
        setLoaderState(false);

        if (response.data.status === "1") {
          setState({
            ...state,
            list: response.data.details.list,
          });
          setTotalpage(response.data.totalPages);
          setCurrentPage(newPage);
        } else {
          setLoaderState(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching college names:", error);
        setLoaderState(false);
      });
  };

  const handleSearch = () => {
    setLoaderState(true);
    console.log("selectedCollegeOptions", selectedCollegeOptions)
    const collegeNames = selectedCollegeOptions.map((option) => option.value);
    const countryNames = selectedCountryOptions.map((options) => options.value);
    const stateNames = selectedStateOptions.map((state) => state.value);
    const cityNames = selectedCityOptions.map((city) => city.value);

    // Creating searchData object
    const searchData = { ...inputValues };

    // Removing empty values from searchData object
    for (const key in searchData) {
      if (searchData.hasOwnProperty(key) && !searchData[key]) {
        delete searchData[key];
      }
    }

    // Updating searchData based on non-empty arrays
    if (collegeNames.length > 0) {
      searchData.college_name = collegeNames;
    }
    if (countryNames.length > 0) {
      searchData.countryNames = countryNames;
    }
    if (stateNames.length > 0) {
      searchData.state = stateNames;
    }
    if (cityNames.length > 0) {
      searchData.city = cityNames;
    }


    const apiUrl = `${process.env.REACT_APP_NODE_URL
      }/admin/collegenames?country=${countryNames.join(",")}&colleges=${collegeNames.join(",")}&state=${stateNames.join(",")}&city=${cityNames.join(",")}&currentPage=${currentPage}`;

    axios
      .get(apiUrl)
      .then((res) => {
        console.log("Response Data", res)
        setLoaderState(false);
        setState({
          ...state,
          list: res.data.details.list,
        });
        setTotalpages(res.data.totalPages);
      })
      .catch((error) => {
        setLoaderState(false);
        setState({
          ...state,
          isWaiting: false,
        });
      });
  };


  useEffect(() => {
    // Retrieve selected columns from localStorage
    const storedColumns = JSON.parse(
      localStorage.getItem("selectedColumnsaddcollegename")
    );
    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "College",
        "Country",
        "State",
        "City",
        "Actions",
      ]);
      setTempColumnsTable([
        "Sr.",
        "College",
        "Country",
        "State",
        "City",
        "Actions",
      ]);
    }
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsaddcollegename");
    setSelectedColumnsTable([
      "Sr.",
      "College",
      "Country",
      "State",
      "City",
      "Actions",
    ]);
    setTempColumnsTable([
      "Sr.",
      "College",
      "Country",
      "State",
      "City",
      "Actions",
    ]);
    // setModalCode(false);
  };

  useEffect(() => {
    setTempColumnsTable([
      "Sr.",
      "College",
      "Country",
      "State",
      "City",
      "Actions",
    ]);
  }, []);


  const startLoader = async () => {
    setisrotating(true);
    await getAllCollegeaData();
    setselectedCountryOptions([]);
    setSelectedCollegeOptions([]);
    setSelectedStateOptions([]);
    setSelectedCityOptions([]);
    setisrotating(false);
  };
  // clear page code here
  const clearfilter = async () => {
    await getAllCollegeaData();
    setselectedCountryOptions([]);
    setSelectedCollegeOptions([]);
    setSelectedStateOptions([]);
    setSelectedCityOptions([]);
  };



  const closeAddPopup = () => {
    setShowAddPopup(false);
  };
  const closeshowcollegePopup = () => {
    setshowcollegepopup(false);
  };
  const closehidecollegepopup = () => {
    sethidecollegepopup(false);
  };
  const closeEditPopup = () => {
    setShowEditPopup(false);
  };
  const handleEditClick = (college) => {
    setSelectedCollege(college);
    setShowEditPopup(true);
    setShowAddPopup(false);
    setshowcollegepopup(false);
    sethidecollegepopup(false);
  };


  const handleCountryChange = (newValue) => {
    setselectedCountryOptions(newValue);
  }

  const handleCollegeChange = (newValue) => {
    setSelectedCollegeOptions(newValue);
  }

  const handleCityChange = (newValue) => {
    setSelectedCityOptions(newValue);
  }

  const handleStateChange = (newValue) => {
    setSelectedStateOptions(newValue);
  }

  const handleModalChange = () => {
    setModalCode(!ModalCode)
  }

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns; // Define updatedColumns here

    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem(
      "selectedColumnscollegelist",
      JSON.stringify(updatedColumns)
    );
  };


  return (
    <>
      <div heading_title={"Add college Detail"}>
      {ModalCode && (
            <div className="modal_cover">
              <div className="modal_inner select-col-popup">
                <div className="header_modal">
                  <h1 className="capitalize font-bold text-lg mb-2">
                    select columns
                  </h1>
                  <p className="border-b pb-2 mb-2">
                    Select the column you want to display or hide.
                  </p>
                  <div
                    className="columns-popup"
                    style={{ justifyContent: "space-between" }}
                  >
                    {tableColumns.map((column, index) => {
                      if (
                        selectedColumnsTable.includes(column.value)
                      ) {
                        return (
                          <p className="mb-0 gap-2">
                            <input
                              type="checkbox"
                              id={column.value}
                              checked={tempColumnsTable.includes(
                                column.value
                              )}
                              onChange={() =>
                                handleColumnCheckboxChange(
                                  column.value
                                )
                              }
                            />
                            <label htmlFor={column.value}>
                              {formatLabel(column.label)}
                            </label>
                          </p>
                        );
                      } else {
                        return (
                          <p className="mb-0 gap-2">
                            <input
                              type="checkbox"
                              id={column.value}
                              onClick={() => {
                                selectedColumnsTable.push(
                                  column.value
                                );
                                setTempColumnsTable(
                                  selectedColumnsTable
                                );
                              }}
                            />
                            <label htmlFor={column.value}>
                              {formatLabel(column.label)}
                            </label>
                          </p>
                        );
                      }
                    })}
                  </div>
                  <div className="button-part flex justify-end items-center gap-4">
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={handleResetAll}
                    >
                      Reset all
                    </button>
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={handleOk}
                    >
                      Ok
                    </button>
                  </div>
                  <GrClose
                    className="close"
                    onClick={() => setModalCode(false)}
                  />
                </div>
              </div>
            </div>
          )}
        <>
          <div className="row addCountryPage flex flex-row">
            {/* add college popup */}
            <div
              className={`w-5/12 mx-auto my-4 createcollegeNamePopup ${showaddpopup ? "active" : ""
                } add_college_popup`}
            >
              {showaddpopup && (
                <>
                  <AddcollegeNamePopup
                    closeAddPopup={closeAddPopup}
                    startLoader={startLoader}
                  />
                  <GrClose
                    style={{ top: "-10px", right: "-10px" }}
                    className="close"
                    onClick={closeAddPopup}
                  />
                </>
              )}
            </div>
            {/* edit college popup */}
            <div
              className={`w-5/12 mx-auto my-4 createcollegeNamePopup ${showeditpopup ? "active" : ""
                } add_college_popup`}
            >
              {showeditpopup && (
                <>
                  <EditcollegeNamePopup
                    closeEditPopup={closeEditPopup}
                    startLoader={startLoader}
                    selectedCollege={selectedCollege}
                  />
                  <GrClose
                    className="close"
                    style={{ top: "-10px", right: "-10px" }}
                    onClick={closeEditPopup}
                  />
                </>
              )}
            </div>
            {/* show college popup */}
            <div
              className={`w-5/12 mx-auto my-4 createcollegeNamePopup ${showcollegepopup ? "active" : ""
                } add_college_popup`}
            >
              {showcollegepopup && (
                <>
                  <ShowCollege
                    closeshowcollegePopup={closeshowcollegePopup}
                    startLoader={startLoader}
                    selectedCollege={selectedCollege}
                  />
                  <GrClose
                    style={{ top: "-10px", right: "-10px" }}
                    className="close"
                    onClick={closeshowcollegePopup}
                  />
                </>
              )}
            </div>
            {/* show college popup */}
            <div
              className={`w-5/12 mx-auto my-4 createcollegeNamePopup ${hidecollegepopup ? "active" : ""
                } add_college_popup`}
            >
              {hidecollegepopup && (
                <>
                  <HideCollege
                    closehidecollegepopup={closehidecollegepopup}
                    startLoader={startLoader}
                    selectedCollege={selectedCollege}
                  />
                  <GrClose
                    style={{ top: "-10px", right: "-10px" }}
                    className="close"
                    onClick={closehidecollegepopup}
                  />
                </>
              )}
            </div>


            <div className="mx-auto w-full p-45 mob-padding ">
              <CollegeFilter
                countryOptions={countryOptions}
                selectedCountryOptions={selectedCountryOptions}
                stateOptions={stateOptions}
                selectedStateOptions={selectedStateOptions}
                cityOptions={cityOptions}
                selectedCityOptions={selectedCityOptions}
                collegeOptions={collegeOptions}
                selectedCollegeOptions={selectedCollegeOptions}
                isrotating={isrotating}
                startLoader={startLoader}
                countryChange={handleCountryChange}
                collegeChange={handleCollegeChange}
                stateChange={handleStateChange}
                cityChange={handleCityChange}
                handleSearch={handleSearch}
                clearfilter={clearfilter}
                modalChange={handleModalChange}
                // 
                handlePopup={() => setShowAddPopup(true)}
                handleShowCollege={() => setshowcollegepopup(false)}
                handleHideCollege={() => sethidecollegepopup(false)}
                handleEditPopup={() => setShowEditPopup(false)}
              />


              <div class="flex flex-col table_postioning ">
                <div class=" sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="card  col-12 px-0 pt-0 pb-0 table_postioning table-overflow">
                      {loaderState ? (
                        <div>
                          <center className="w-full my-10">
                            <img src={loader} alt="" />
                          </center>
                        </div>
                      ) : state?.list?.length > 0 ? (
                        <table className="table-auto w-full agent-table files-table">
                          <thead>
                            <tr>
                              {tableColumns.map((col) => {
                                if (selectedColumnsTable.includes(col.label)) {
                                  return (
                                    <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                      {formatLabel(col.label)}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {state?.list?.map((college, index) => {
                              const startIndex = currentPage * itemsPerPage;
                              const currentIndex = startIndex + index + 1;
                              return (
                                <tr>
                                  {selectedColumnsTable.includes("Sr.") && (
                                    <td className="px-5 py-2  w-0 capitalize">
                                      {currentIndex}
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("College") && (
                                    <td className="text-sm text-gray-900 font-light ps-0 pe-2 py-2 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="small-image-container">
                                          <img
                                            src={
                                              !college.collegeLogo
                                                ? "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/No_image_available_600_x_200.svg/1200px-No_image_available_600_x_200.svg.png"
                                                : process.env.REACT_APP_NODE_URL +
                                                "/uploads/agent/" +
                                                college.collegeLogo
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <p className="text-sm text-gray-900 font-light ps-2 pe-2 py-2 whitespace-nowrap capitalize">
                                          {college?.collegeName}
                                        </p>
                                      </div>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Country") && (
                                    <td className="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="small-image-container">
                                          <img
                                            src={
                                              !college.countryLogo
                                                ? "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/No_image_available_600_x_200.svg/1200px-No_image_available_600_x_200.svg.png"
                                                : process.env.REACT_APP_NODE_URL +
                                                "/uploads/agent/" +
                                                college.countryLogo
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <p className="text-sm text-gray-900 font-light ps-2 pe-2 py-2 whitespace-nowrap capitalize">
                                          {college?.countryDetails[0]
                                            ?.countryName || "--"}
                                        </p>
                                      </div>
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("State") && (
                                    <td className="text-sm text-gray-900 font-light  p-2 whitespace-nowrap capitalize">
                                      {college?.stateDetails[0]?.stateName ||
                                        "--"}
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("City") && (
                                    <td className="text-sm text-gray-900 font-light p-2  whitespace-nowrap capitalize">
                                      {college?.cityDetails[0]?.cityName ||
                                        "--"}
                                    </td>
                                  )}
                                  {selectedColumnsTable.includes("Actions") && (
                                    <td className="text-sm text-gray-900 font-light p-2 whitespace-nowrap">
                                      <div className="action-icons-list">
                                        <i
                                          onClick={() =>
                                            handleEditClick(college)
                                          }
                                          className="action-icon fa-solid fa-pen-to-square cursor-pointer"
                                        ></i>
                                        {/* {college?.hide == false ?
                                          <MdRemoveRedEye
                                            onClick={() => {
                                              handlehidecollegeclick(college)
                                            }} className="md-action-show cursor-pointer" /> :
                                          <MdHideSource
                                            onClick={() => {
                                              handleshowcollegeclick(college)
                                            }} className="md-action-hide cursor-pointer" />
                                        } */}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <p className="text-center p-5 text-red-500">
                            No data available.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="card-footer pt-5 pb-20 sm:px-6 lg:px-8">
        <div className="pagination sm:px-2 px-0">
          <div className="pages">
            {totalPages >= 2 && (
              <div className="pagination">
                <div className="pages">
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    breakLabel="..."
                    pageCount={totalPages}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    initialPage={currentPage}
                    marginPagesDisplayed={1}
                    activeClassName="selected"
                    containerClassName="pagination"
                    renderOnZeroPageCount={null}
                    subContainerClassName="pages"
                    disableInitialCallback={true}
                    previousClassName={
                      currentPage === 0 ? "disabled" : ""
                    }
                    nextClassName={
                      currentPage === totalPages - 1 ? "disabled" : ""
                    }
                    previousLinkClassName={
                      currentPage === 0 ? "disabled-link" : ""
                    }
                    nextLinkClassName={
                      currentPage === totalPages - 1
                        ? "disabled-link"
                        : ""
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Addcollegename;
