import React from 'react'
import ButtonPrimary from '../../../common/Buttons/ButtonPrimary'

export const BasicInfo = () => {
  return (
    <div class="flex px-16">
      <div class="w-1/3" >
        <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/styles/tailwind.css" />
        {/* <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"/> */}

        <section class="pt-10 bg-blueGray-50">
          <div class="w-full px-4 mx-auto">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
              <div class="px-6">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full px-4 flex justify-center">
                    <div class="relative">
                      <img alt="..." src="https://tecdn.b-cdn.net/img/new/avatars/2.webp" class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px" />
                    </div>
                  </div>

                  <div class="w-full px-4 text-center mt-20">

                    <h3 class="text-xl font-semibold leading-normal mt-5 text-blueGray-700 mb-2">
                      Vel Kumar P
                    </h3>
                    <div class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                      {/* <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i> */}
                      UI/UX Designer
                    </div>
                    <div class="flex justify-center py-4 lg:pt-4 pt-8">

                      <div class="mr-4 p-3 text-center">
                        <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          86
                        </span>
                        <span class="text-sm text-blueGray-400">Posts</span>
                      </div>
                      <div class="mr-4 p-3 text-center">
                        <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          1.5K
                        </span>
                        <span class="text-sm text-blueGray-400">Messages</span>
                      </div>
                      <div class="lg:mr-4 p-3 text-center">
                        <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                          4.5K
                        </span>
                        <span class="text-sm text-blueGray-400">Members</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-12">
                  <div class="mb-2 text-blueGray-600 mt-10">
                    <ButtonPrimary title={"UPLOAD NEW AVATAR"} />
                  </div>
                  <div class="mb-2 text-blueGray-600">
                    <ButtonPrimary title={"DELETE"} />
                  </div>
                </div>
                <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div class="flex flex-wrap justify-center">
                    <h3 class="text-xl font-semibold leading-normal text-blueGray-700 mb-2">
                      BIOGRAPHY
                    </h3>
                    <div class="w-full lg:w-9/12 px-4">
                      <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                        An artist of considerable range, Jenna the name taken
                        by Melbourne-raised, Brooklyn-based Nick Murphy
                        writes, performs and records all of his own music,
                        giving it a warm, intimate feel with a solid groove
                        structure. An artist of considerable range.
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="w-2/3 px-6">
        <form class="w-full ">
          <div class="flex justify-between items-center border-b border-gray-500 py-2">
            {/* <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Jane Doe" aria-label="Full name"> */}
            <h3 class="text-xl font-semibold leading-normal mt-5 text-blueGray-500 mb-2">
              Basic Info
            </h3><div>
              <button class="flex-shrink-0 bg-white-500 ml-10 mr-5 hover:bg-[#065f46] border-gray-500 hover:border-gray-700 text-sm border-2 text-black py-1 px-2 rounded" type="button">
                Cancel
              </button>
              <button class="flex-shrink-0 bg-gray-500 mr-5 hover:bg-[#065f46] border-gray-500 hover:border-gray-700 text-sm border-2 text-white py-1 px-2 rounded" type="button">
                Save
              </button>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                First Name
              </label>
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Enter First Name" />
              {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                Last Name
              </label>
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Enter Last Name" />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                Title
              </label>
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Enter Your Title" />

            </div>
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                Email
              </label>
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Enter Your Email" />

            </div>
            <div className="flex -mx-3 mb-2">
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                  City
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Albuquerque" />
              </div>
              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                  State
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Albuquerque" />
              </div>

              <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                  Zip
                </label>
                <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="90210" />
              </div>
            </div>
          </div>
          <div class="flex justify-between items-center border-b border-gray-500 py-2">
            {/* <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Jane Doe" aria-label="Full name"> */}
            <h3 class="text-xl font-semibold leading-normal mt-5 text-blueGray-500 mb-2">
              About Me
            </h3>

          </div>

          {/* <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">BIOGRAPY</label> */}
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Biography
          </label>
          <textarea id="message" rows="4" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="About Me...">
            An artist of considerable range, Jenna the name taken
            by Melbourne-raised, Brooklyn-based Nick Murphy
            writes, performs and records all of his own music,
            giving it a warm, intimate feel with a solid groove
            structure. An artist of considerable range.
          </textarea>

          <div class="flex justify-between items-center border-b border-gray-500 py-2">
            {/* <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Jane Doe" aria-label="Full name"> */}
            <h3 class="text-xl font-semibold leading-normal mt-5 text-blueGray-500 mb-2">
              External Link
            </h3>

          </div>
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
              Facebook URl:
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Paste Your Link Here" />

          </div>
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
              Linkedin URL:
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Paste Your Link Here" />

          </div>
          <div class="flex justify-between items-center border-b border-gray-500 py-2">
            {/* <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Jane Doe" aria-label="Full name"> */}
            <h3 class="text-xl font-semibold leading-normal mt-5 text-blueGray-500 mb-2">
              Security
            </h3>

          </div>
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
              Password
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="password" placeholder="Password" />
          </div>
          <div class="flex justify-end items-center border-b border-gray-500 py-2">
            {/* <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Jane Doe" aria-label="Full name"> */}
            <div>
              <button class="flex-shrink-0 bg-white-500 ml-10 mr-5 hover:bg-[#065f46] border-gray-500 hover:border-gray-700 text-sm border-2 text-black py-1 px-2 rounded" type="button">
                Cancel
              </button>
              <button class="flex-shrink-0 bg-gray-500 mr-5 hover:bg-[#065f46] border-gray-500 hover:border-gray-700 text-sm border-2 text-white py-1 px-2 rounded" type="button">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

