/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getToken, logoutHelper } from "../../../../helper/auth";
import StudentAvatar from "../../../../images/StudentAvatar.png";
import { requestForToken } from "../../../../firebase";
import axios from "axios";
import log_a from "../../../../images/log_a.png";
import { toast } from "react-toastify";
const StudentNavbar = () => {
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationData, setnotificationData] = useState([])
  const [studentId, setstudentId] = useState('')
  const [showDropDown, setShowDropDown] = useState(false);
  const [block, setblock] = useState()
  const [state, setState] = useState({
    studentToken: false,
    isWaiting: true,
  });
  const toggleNotificationPopup = () => {
    if (showNotificationPopup) {
      setShowNotificationPopup(false);
    } else {
      setShowNotificationPopup(true);
    }
  };
  const logoutbtn = (data) => {
    setState((prevState) => ({
      ...prevState,
      adminToken: "",
      isWaiting: true,
      profile_photo: null,
    }));
    logoutHelper("student");
    window.location.replace("/studentlogin");
  };

  useEffect(() => {

    const config = {
      headers: { Authorization: `Bearer ${getToken("student")}` },
    };
    requestForToken().then((token) => {
      axios
        .post(process.env.REACT_APP_NODE_URL + "/student/verifyToken", {}, config)
        .then((res) => {
          setstudentId(res.data.details.userData.userId)
          setblock(res.data.details.userData.block);
          document.getElementById("notificationCountSpan").innerText =
            res.data.details.userData.notificationsCount > 99
              ? "99+"
              : res.data.details.userData.notificationsCount;
          return;
        })
        .catch((err) => { });
    });
  }, []);

  if (block === true) {
    logoutbtn();
  } else {
  }

  useEffect(() => {
    let token = getToken("student");
    setState({
      ...state,
      studentToken: token,
      isWaiting: false,
    });
  }, []);

  const toggleSidebar = () => {
    document.getElementById("aside").classList.remove("hidebar");
    document.getElementById("overlay").classList.remove("hidebar");
    document.getElementById("aside").classList.toggle("hide-sidebar");
    document.getElementById("aside").classList.toggle("show");
    document.getElementById("overlay").classList.toggle("show");
    document.getElementsByTagName("main")[0].classList.toggle("hide-sidebar");
  };

  const getnotificatinslist = () => {
    axios
      .get(process.env.REACT_APP_NODE_URL + `/v2/api/notificationdata?type=STUDENT&id=${studentId}`)
      .then((response) => {
        if (response.data.status == "1") {
          setnotificationData(response.data.detail)
        }
      });
  }

  const deleteNotification = (notificationId) => {
    axios
      .delete(`${process.env.REACT_APP_NODE_URL}/v2/api/deletenotification/${notificationId}`)
      .then((response) => {
        if (response.data.status === "1") {
          setnotificationData((prevData) =>
            prevData.filter((notification) => notification._id !== notificationId)
          );
        } else {
          console.error("Failed to delete notification:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error deleting notification:", error);
      });
  };
  useEffect(() => {
    getnotificatinslist();
  }, [studentId])

  const deleteAllNotifications = () => {
    axios.delete(process.env.REACT_APP_NODE_URL + `/v2/api/deleteallnotification?type=STUDENT&id=${studentId}`)
    .then((response) => {
      console.log("Delete all notifications", response);
      toast.success("Notifications Deleted!");
      getnotificatinslist()
    })
    .catch((err) => {
      console.log("Error", err)
    })
  }

  return (
    <>
      <nav className="custom_nav_bar">
        <div className="flex p-3 flex-row justify-between items-center bg-[#19632f]">
          <div>
            <Link to="/">
              <img style={{ width: "180px" }} src={log_a} alt="logo" />
            </Link>
            <span className="breadcrumb flex items-center justify-center">
              <i
                className="fas fa-bars text-xl cursor-pointer"
                onClick={toggleSidebar}
              ></i>
            </span>
          </div>
          <div>
            <ul className="flex items-center justify-center">
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a
                  href="javascript:void(0);"
                  className="nav-link text-body p-0"
                  id="iconNavbarSidenav"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </a>
              </li>
              <li className="nav-item notificationCount dropdown relative mx-3 pe-2 group">
                <div className="relative">
                  <i
                    className="fa fa-bell cursor-pointer text-white text-[30px] relative transition duration-300 ease-in-out hover:text-gray-300"
                    onClick={toggleNotificationPopup}
                  />
                  {notificationData.length > 0 && (
                    <div className="absolute top-0 right-0 -mt-3 -mr-3 bg-yellow-500 rounded-full w-6 h-6 flex items-center
                     justify-center text-white text-xs font-bold transition duration-300 ease-in-out">
                      {notificationData.length}
                    </div>
                  )}
                </div>
                {showNotificationPopup && (
                  <>
                    <div className="header_notification_modal absolute top-full right-0 mt-2 
                      w-80 bg-white shadow-lg p-6 z-10 rounded-md border border-gray-200">
                      <div className="flex items-center justify-between mb-3">
                        <h2 className="text-2xl font-bold text-gray-800 mb-0">Notifications</h2>
                        {notificationData?.length > 0 && (
                          <button type="button" className="text-sm text-red-500 hover:underline mt-2 cursor-pointer underline" onClick={(e) => {deleteAllNotifications(e)}}>Clear all</button>
                        )}
                        
                      </div>
                      <div className="divide-y divide-gray-200">
                        {notificationData.length === 0 ? (
                          <div className="py-4">
                            <p className="text-sm font-semibold text-red-500 mb-1">
                              No New Notifications
                            </p>
                          </div>
                        ) : (
                          notificationData.map((notification, index) => (
                            <div key={index} className="py-4">
                              <p className="text-sm font-semibold text-green-700 mb-1">
                                {notification?.title}
                              </p>
                              <p className="text-[13px] text-gray-800">{notification?.message}</p>
                              <small className="block text-xs text-gray-500 mt-2">
                                {new Date(notification?.createdAt).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                              </small>
                              <button
                                className="text-sm text-red-500 hover:underline mt-2 cursor-pointer underline"
                                onClick={() => deleteNotification(notification._id)}
                              >
                                Remove
                              </button>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    <div
                      className="fixed inset-0 bg-black opacity-50 z-9"
                      onClick={toggleNotificationPopup}
                    ></div>
                  </>
                )}
              </li>
              <div className="relative inline-block text-left navbar-profile-dropdown">
                <div
                  className={`overlay ${!showDropDown ? "hidden" : "show"}`}
                  onClick={() => setShowDropDown(!showDropDown)}
                ></div>
                <div>
                  <img
                    onClick={() => setShowDropDown(!showDropDown)}
                    src={StudentAvatar}
                    class="w-12 rounded-full cursor-pointer hover: avatar"
                    alt="Avatar"
                  />
                </div>
                <div
                  className={`absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none ${!showDropDown && "hidden"
                    }`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex={-1}
                >
                  <div
                    onClick={() => setShowDropDown(!showDropDown)}
                    className="py-1"
                    role="none"
                  >
                    <Link
                      to={"/student/profile"}
                      className="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-1"
                    >
                      Settings
                    </Link>
                  </div>

                  <div className="py-1" role="none">
                    <a
                      href="javascript:void(0)"
                      className="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabIndex={-1}
                      onClick={() => logoutbtn("student")}
                      id="menu-item-6"
                    >
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default StudentNavbar;
