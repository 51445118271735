import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { getToken } from "../../../helper/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import loader from "../../../images/loader.gif";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import CollegeFilter from "../../website/Components/collegeFilter";


const PAGE_SIZE = 10;
const IntakesManagement = (props) => {
  const selectRef = useRef(null);
  const navigate = useNavigate();
  const [isrotating, setisrotating] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newSelectedOptions, setNewSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [newIsDropdownOpen, setNewIsDropdownOpen] = useState(false);
  const [countryName, setCountryName] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search);
  const [intakesData, setIntakesData] = useState([]);
  const [countryId, setCountryid] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalpage, settotalpage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_SIZE);

  // 
  const [selectedCountryOptions, setselectedCountryOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  // 
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [clearButtonClick, setClearButtonClick] = useState(false);



  const [state, setState] = useState({
    college_programs: [],
    collegeNamesList: [],
    countryNameList: [],
    adminToken: getToken("admin"),
    activeIndex: null,
    first: true,
    isWait: false,

    // new
    countryId: "",
    collegeId: "",

    collegeName: "",
    programName: "",
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchCountryData = () => {
    axios
      .get(process.env.REACT_APP_NODE_URL + "/v2/api/getCountries")
      .then((res) => {
        const getCountries = res?.data?.details;
        const resultOne = getCountries.map((item) => ({
          label: item.countryName, // Displayed value
          value: item.countryId, // Actual value (countryId)
        }));
        console.log("countryList",resultOne)
        setCountryName(resultOne);
        // setCountryid(res.data.details[0].countryId);
        setState((prevState) => ({
          ...prevState,
          countryNameList: res.data.details,
        }));
      })
      .catch((err) => {
        console.error("Error fetching countries:", err);
      });
  };

useEffect(() => {
  fetchCountryData();
}, [])


  useEffect(() => {
    getcollegeNames();
   
  }, []);

  const getcollegeNames = (countryId) => { 
    const selectedCountryIds = selectedOptions.map((option) => option.value); 
    let data =selectedCountryIds ;
    console.log("data", data);
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getcollegenames", data)
      .then((res) => {
        setState({
          ...state,
          collegeNamesList: res.data.collegeNamesList,
          countryId: data[0]?.countryId,
          collegeId: "",
        });
      })
      .catch((err) => {});
  };


  const findIntakes = async () => {
    setState({
      ...state,
      isWait: true,
    });
    setClearButtonClick(false)
    const collegeNames = selectedCollegeOptions.map((option) => option.value);
    const countryNames = selectedCountryOptions.map((options) => options.value);
    const stateNames = selectedStateOptions.map((state) => state.value);
    const cityNames = selectedCityOptions.map((city) => city.value);

    console.log("countryNames", countryNames)

    try {
      const response = await axios.get(
        process.env.REACT_APP_NODE_URL +
          `/v2/api/findCollegeIntakes?countryId=${countryNames}&stateId=${stateNames}&cityId${cityNames}=&collegeName=${collegeNames}&currentPage=${
            currentPage + 1
          }`
      );

      console.log("State", state)
      setIntakesData([...response.data.details]);
      settotalpage(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching intakes:", error);
    } finally {
      setState({
        ...state,
        isWait: false,
      });
      setisrotating(false);
    }
  };

  useEffect(() => {
    findIntakes();
  }, [currentPage]);

  const handlePageChange = (e) => {
    const newPage = e.selected;

    const apiUrl = `${process.env.REACT_APP_NODE_URL
      }/admin/collegenames?country=${""}&colleges=${""}&state=${""}&city=${""}&currentPage=${newPage + 1
      }`;

    axios
      .get(apiUrl)
      .then((response) => {
        setState({
      ...state,
      isWait: false,
    });

        if (response.data.status === "1") {
          setState({
            ...state,
            list: response.data.details.list,
          });
          settotalpage(response.data.totalPages);
          setCurrentPage(newPage);
        } else {
          setState({
      ...state,
      isWait: false,
    });
        }
      })
      .catch((error) => {
        console.error("Error fetching college names:", error);
        setState({
      ...state,
      isWait: false,
    });
      });

  };

  const startLoader = async () => {
    console.log("Loader")
    setisrotating(true);
    setselectedCountryOptions([]);
    setSelectedCollegeOptions([]);
    setSelectedStateOptions([]);
    setSelectedCityOptions([]);
    setisrotating(false);
    setClearButtonClick(true);
    findIntakes();
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCountries();
      await getStates();
      await getCities();
      await getColleges();
    };
    fetchData();
  }, [selectedCountryOptions, selectedStateOptions, selectedCityOptions]);


  useEffect(() => {
    getStates();
  }, [newSelectedOptions]);

  const getCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`);
      const countries = response?.data?.details;
      const result = countries.map((country) => ({
        label: country.countryName,
        value: country.countryId,
      }));
      setCountryOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${selectedCountryIds.join(",")}`
      );
      const states = response?.data?.details;
      const result = states.map((state) => ({
        label: state.stateName,
        value: state.stateId,
      }));
      setStateOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async () => {
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchCities?stateIds=${selectedStateIds.join(",")}`
      );
      const cities = response?.data?.details;
      const result = cities.map((city) => ({
        label: city.cityName,
        value: city.cityId,
      }));
      setCityOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getColleges = async () => {
    console.log("TESTTTTTTTTT")
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    const selectedCityIds = selectedCityOptions.map((city) => city.value);

    console.log("selectedCountryIds", selectedCountryIds)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCollegeNameList?country=${selectedCountryIds.join(",")}&state=${selectedStateIds.join(",")}&city=${selectedCityIds.join(",")}`
      );
      const colleges = response?.data?.detail;
      console.log("College Response", response)
      const result = colleges.map((college) => ({
        label: college.collegeName,
        value: college.collegeName,
      }));
      setCollegeOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCountryChange = (newValue) => {
    setselectedCountryOptions(newValue);
  }

  const handleCollegeChange = (newValue) => {
    setSelectedCollegeOptions(newValue);
  }

  const handleCityChange = (newValue) => {
    setSelectedCityOptions(newValue);
  }

  const handleStateChange = (newValue) => {
    setSelectedStateOptions(newValue);
  }

  return (
    <>
      <ToastContainer />
      <div heading_title={"Program List"}>
        <>
          <div className="row min-height-vh-100">
            <div className="row p-4 sm:p-8">
              <div className="flex justify-between items-center">
                <div className="md:flex items-center gap-3 w-full">
                  <CollegeFilter
                    countryOptions={countryOptions}
                    selectedCountryOptions={selectedCountryOptions}
                    stateOptions={stateOptions}
                    selectedStateOptions={selectedStateOptions}
                    cityOptions={cityOptions}
                    selectedCityOptions={selectedCityOptions}
                    collegeOptions={collegeOptions}
                    selectedCollegeOptions={selectedCollegeOptions}
                    isrotating={isrotating}
                    startLoader={startLoader}
                    countryChange={handleCountryChange}
                    collegeChange={handleCollegeChange}
                    stateChange={handleStateChange}
                    cityChange={handleCityChange}
                    handleSearch={findIntakes}
                    clearfilter={startLoader}
                    showButton={false}
                    // 
                  />
                  {/* <div className="md:mb-4 mb-2 md:mb-0">
                    <label
                      htmlFor="countryId"
                      className="block text-sm font-medium text-[#fff]"
                    >
                      -
                    </label>
                    <button
                      onClick={() => findIntakes()}
                      className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                    >
                      Find
                    </button>
                  </div> */}
                </div>
                {/* <div className="reload-icon mr-2">
                  <i
                    className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                    onClick={startLoader}
                  />
                </div> */}
              </div>
              <div className="overflow-x-auto table-overflow mt-10">
                {state.isWait ? (
                  <div>
                    <center className="w-full my-10">
                      <img src={loader} alt="" />
                    </center>
                  </div>
                ) : intakesData.length > 0 ? (
                  <table className="w-full border-collapse table-auto">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="px-2 py-3 sm:px-4 sm:py-4">Sr. No</th>
                        <th className="px-6 py-3 sm:px-8 sm:py-4">
                          College Name
                        </th>
                        <th className="px-4 py-2 sm:px-6 sm:py-3">Programms</th>
                        {months.map((month, index) => (
                          <th key={index} className="px-4 py-2 sm:px-6 sm:py-3">
                            {month}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {intakesData.map((result, index) => (
                        <tr
                          onClick={() => {
                            navigate(`/admin/programmIntakes/${result._id}`, {
                              state: {
                                collegeId: result._id,
                                countryId: result.countryId,
                              },
                            });
                          }}
                          className="hover:bg-blue-100 cursor-pointer transition-colors"
                          key={result._id}
                        >
                          <td className="border px-2 py-3 sm:px-4 sm:py-4">
                          {currentPage * itemsPerPage + index + 1}
                          </td>
                          <td className="border px-6 py-3 sm:px-8 sm:py-4 capitalize">
                            {result.college_name}
                          </td>
                          <td className="border px-4 py-2 sm:px-6 sm:py-3">
                            {result.college_programs_count}
                          </td>
                          {months.map((month, monthIndex) => {
                            const hasIntake =
                              result.college_programs_intake.some((intake) =>
                                intake
                                  .split(",")
                                  .includes(String(monthIndex + 1))
                              );
                            return (
                              <td
                                key={monthIndex}
                                className={`border px-4 py-2 sm:px-6 sm:py-3 ${
                                  hasIntake ? "text-green-500 text-xl" : ""
                                }`}
                              >
                                {hasIntake ? "✔" : "-"}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p className="text-center p-5 text-red-500">
                      No data available.
                    </p>
                  </div>
                )}
              </div>
              <div className="card-footer pt-5 pb-20">
                <div className="pagination sm:px-2 px-0">
                  <div className="pages">
                  {totalpage >= 2 && (
                    <div className="pagination">
                      <div className="pages">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          breakLabel="..."
                          pageCount={totalpage}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageChange}
                          initialPage={currentPage}
                          marginPagesDisplayed={1}
                          activeClassName="selected"
                          containerClassName="pagination"
                          renderOnZeroPageCount={null}
                          subContainerClassName="pages"
                          disableInitialCallback={true}
                          previousClassName={
                            currentPage === 0 ? "disabled" : ""
                          }
                          nextClassName={
                            currentPage === totalpage - 1 ? "disabled" : ""
                          }
                          previousLinkClassName={
                            currentPage === 0 ? "disabled-link" : ""
                          }
                          nextLinkClassName={
                            currentPage === totalpage - 1
                              ? "disabled-link"
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default IntakesManagement;
