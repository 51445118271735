import { Switch } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, Navigate, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import Dashboard from "../Screens/Dashboard/Dashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notifications = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showCount, setShowCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const navigate = useNavigate()
    const [state, setState] = useState({
        isWaiting: false,
        notifications: [],
        adminToken: getToken("admin"),
        totalPages: 0,
        currentPage: 1,
    })

    const totalItems = state.notifications.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedNotifications = state.notifications.slice(startIndex, endIndex);

    const [readAllNotification, setReadAllNotification] = useState(false)

    useEffect(() => {
        getPaginationData(1);
    }, [])


    const getPaginationData = (page) => {
        // get all agents
        setState({
            ...state,
            isWaiting: true,
        })
        let data = { currentPage: page }
        const config = { headers: { "Authorization": `Bearer ${state.adminToken}` } }
        axios.post(process.env.REACT_APP_NODE_URL + "/admin/getnotifications", data, config).then(res => {
            // authenticate with token
            // redirect
            setState({
                ...state,
                isWaiting: false,
                notifications: res.data.details.notifications,
                totalPages: res.data.details.totalPages,
                currentPage: res.data.details.currentPage,
                isWaiting: false,
            })

            readNotifications(res.data.details.notifications)

        }).catch(err => {
            // toast(err.response.data.message)
        })
    }



    const readNotifications = async (notification) => {
        const data = {
            type: !notification ? "ALL" : "CURRENT",
            data: !notification ? [] : notification
        }

        setReadAllNotification(true)

        const config = { headers: { "Authorization": `Bearer ${state.adminToken}` } }
        axios.post(process.env.REACT_APP_NODE_URL + "/admin/readNotifications", data, config).then(res => {
            let count = res.data.count;
            document.getElementById("notificationCountSpan").innerText = count > 99 ? "99+" : count;
            if (!notification) {
                toast("All Notifications marked as Read")
            }
            setReadAllNotification(false)

        })
    }



    return (
        <>
            <ToastContainer />
            <div heading_title={"Notifications"}>
                <>
                    <div className="row px-4">
                        <div className="w-full">
                            <div className=" mb-4 mt-4">
                                <div className="px-0 pt-0 pb-2">
                                    <div className="top-notification-box">
                                        <button onClick={() => readNotifications()}>
                                            {
                                                readAllNotification ?
                                                    "Read All" : <>
                                                        <svg class="h-5 w-5 animate-spin" viewBox="3 3 18 18">
                                                            <path
                                                                class="fill-gray-200"
                                                                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                            <path
                                                                class="fill-gray-800"
                                                                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                        </svg></>
                                            }
                                        </button>
                                    </div>
                                    <div className="table-responsive p-0 table-overflow">
                                        {
                                            state.isWaiting ? "Loading..." :
                                                <table className="table w-full mb-0">
                                                    {/* <thead>
                                                        <tr>
                                                            <th className="">Id</th>
                                                            <th className="text-left p-3">Notification</th>
                                                            <th className="text-secondary opacity-7" />
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        {
                                                            displayedNotifications.map((notification, index) => {
                                                                return (
                                                                    <tr key={(currentPage - 1) * itemsPerPage + index}>
                                                                        <td>
                                                                            <p className="text-xs text-center font-weight-bold mb-0">
                                                                                {(currentPage - 1) * itemsPerPage + index + 1}
                                                                            </p>
                                                                        </td>
                                                                        <td className="unread notificationDiv flex">
                                                                            <div className="unreadBox">
                                                                                {notification?.status && "New"}
                                                                            </div>
                                                                            <div className="left">
                                                                                <h2 onClick={() => navigate(notification.redirectUrl)}>{notification?.message}</h2>
                                                                                <small><p>{notification.notifications?.body}</p></small>
                                                                            </div>
                                                                            <div className="right">
                                                                                <small><p className="text-right">{new Date(parseInt(notification.created)).toLocaleString()}</p></small>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                );
                                                            }
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                                <div className="card-footer pb-0">
                                    {/* pagination is here */}
                                    <div className="pagination">
                                        {/* <div className="pages">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="next"
                                                onPageChange={(event) => {
                                                    getPaginationData(event.selected + 1)
                                                }}
                                                pageCount={state.totalPages}
                                                previousLabel="prev"
                                                renderOnZeroPageCount={null}
                                                pageRangeDisplayed={1}
                                                marginPagesDisplayed={0}
                                                containerClassName="pagination"
                                                subContainerClassName="pages"
                                            />
                                        </div> */}
                                        <div className="pagination" >
                                            <button
                                                disabled={currentPage === 1}
                                                onClick={() => setCurrentPage(currentPage - 1)}
                                            >
                                                Previous
                                            </button>

                                            <button
                                                disabled={currentPage === totalPages}
                                                onClick={() => setCurrentPage(currentPage + 1)}
                                            >
                                                Next
                                            </button>

                                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                                <button
                                                    key={page}
                                                    onClick={() => setCurrentPage(page)}
                                                    disabled={currentPage === page}
                                                >
                                                    {page}
                                                </button>   
                                            ))}
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {/* {showCount < state?.notifications.length && (
                                                <button
                                                    className="show-btn rounded-full"
                                                    style={{
                                                        background: "rgb(51 51 51)",
                                                        padding: "10px 30px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        marginRight: "10px",


                                                    }}
                                                    onClick={() => setShowCount(showCount + 10)}
                                                >
                                                    Show More
                                                </button>
                                            )}
                                            {showCount > 10 && (
                                                <button
                                                    className="show-btn rounded-full"
                                                    style={{
                                                        background: "rgb(51 51 51)",
                                                        padding: "10px 30px",
                                                        cursor: "pointer",
                                                        color: "#fff",
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={() => setShowCount(20)}
                                                >
                                                    Show Less
                                                </button>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default Notifications;