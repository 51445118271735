import React, { useEffect, useState, useRef } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import Select from "react-select";
import { getToken } from "../../../helper/auth";
import axios from "axios";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";

const StudentDashboard = () => {
  const initialDetails = {
    applicationCount: 0,
    offer_applied: 0,
    offer_rejected: 0,
    offers: 0,
    payment: 0,
    visas_received: 0,
  };
  const [details, setDetails] = useState(initialDetails);
  const [response, setResponse] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const intakeRef = useRef(null);
  const [selectYear, setSelectYear] = useState(null);
  const yearRef = useRef(null);
  const [filterParams, setFilterParams] = useState({
    dateRange: {
      startDate: moment(),
      endDate: moment(),
    },
    Countries: [],
    Intakes: [],
    Years: [],
  });
  const [state, setState] = useState({
    isWaiting: true,
    country: "",
    countryNamesList: [],
    countryId: "",
  });

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_NODE_URL +
        "/admin/getcollegenameidandcountrieslist",
        {}
      )
      .then((res) => {
        console.log("checkForCountries", res);
        setState((prevState) => ({
          ...prevState,
          countryNamesList: res?.data?.countryNameList,
          isWaiting: false,
        }));
      })
      .catch((err) => {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
      });
  }, []);

  useEffect(() => {
    handleFilterApply();
  }, []);

  const handleFilterApply = () => {
    const config = {
      headers: { Authorization: `Bearer ${getToken("admin")}` },
    };

    const requestBody = {
      fromdt: filterParams.dateRange.startDate
        .startOf("day")
        .isSame(moment().startOf("day"))
        ? ""
        : filterParams.dateRange.startDate.toISOString().split("T")[0],
      todt: filterParams.dateRange.endDate
        .startOf("day")
        .isSame(moment().startOf("day"))
        ? ""
        : filterParams.dateRange.endDate.toISOString().split("T")[0],
      countries: filterParams.Countries,
      intakes: filterParams.Intakes,
      years: filterParams.Years,
    };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/dashboardfilter",
        requestBody,
        config
      )
      .then((response) => {
        setResponse(response.data);
        setDetails(response.data.details);
        // toast.success("Success");
      })
      .catch((error) => {
        toast.error("Something Went Wrong");
      });
  };

  const handleCountryChange = (selectedCountries) => {
    if (selectRef.current) {
      selectRef.current.focus();
      setIsDropdownOpen(true);
    }
    setFilterParams((prevParams) => ({
      ...prevParams,
      Countries: selectedCountries.map((country) => country.value),
    }));
  };

  const handleIntakeChange = (selectedIntakes) => {
    if (intakeRef.current) {
      intakeRef.current.focus();
      setIsOpen(true);
    }
    setFilterParams((prevParams) => ({
      ...prevParams,
      Intakes: selectedIntakes.map((intake) => intake.value),
    }));
  };

  const handleYearChange = (selectedYears) => {
    if (yearRef.current) {
      yearRef.current.focus();
      setSelectYear(true);
    }
    setFilterParams((prevParams) => ({
      ...prevParams,
      Years: selectedYears.map((year) => year.value),
    }));
  };
  const intakeOptions = [
    { label: "January", value: "1" },
    { label: "Feburary", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const yearOptions = [
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },

    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
  ];

  const [focusedInput, setFocusedInput] = useState(null);
  const onDatesChange = ({ startDate, endDate }) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      dateRange: {
        startDate,
        endDate,
      },
    }));
  };
  const handleSelectMenuClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <div className="agent-form pt-3">
        <h2 className="text-3xl mb-3 font-bold">
          Application History of Students
        </h2>
        <div className="md:flex md:gap-6 md:items-end bg-white rounded-lg p-5">
          <div className="w-full md:mb-0 mb-2">
            <label htmlFor="dateRange">Date Range</label>
            <div>
              <DateRangePicker
                startDate={filterParams.dateRange.startDate}
                startDateId="startDate"
                endDate={filterParams.dateRange.endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  setFilterParams((prevParams) => ({
                    ...prevParams,
                    dateRange: {
                      startDate,
                      endDate,
                    },
                  }));
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                isOutsideRange={() => false}
                id="dateRangePicker"
              />
            </div>
          </div>
          <div className="w-full md:mb-0 mb-2">
            <label htmlFor="country">Select Country</label>
            <Select
              ref={selectRef}
              id="country"
              options={state.countryNamesList.map((country) => ({
                label: country.countryName,
                value: country.countryName,
              }))}
              value={filterParams.Countries.map((country) => ({
                label: country,
                value: country,
              }))}
              onChange={handleCountryChange}
              isMulti
              hideSelectedOptions={false}
              menuIsOpen={isDropdownOpen}
              onFocus={() => setIsDropdownOpen(true)}
              onBlur={() => setIsDropdownOpen(false)}
              menuProps={{ onClick: handleSelectMenuClick }}
            />
          </div>
          <div className="w-full md:mb-0 mb-2">
            <label htmlFor="intake">Select Intake</label>
            <Select
              ref={intakeRef}
              id="intake"
              options={intakeOptions}
              value={filterParams.Intakes.map((intake) => ({
                label: intake,
                value: intake,
              }))}
              onChange={handleIntakeChange}
              isMulti
              hideSelectedOptions={false}
              menuIsOpen={isOpen}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
              menuProps={{ onClick: handleSelectMenuClick }}
            />
          </div>
          <div className="w-full md:mb-0 mb-2">
            <label htmlFor="year">Select Year</label>
            <Select
              id="year"
              options={yearOptions}
              value={filterParams.Years.map((year) => ({
                label: year,
                value: year,
              }))}
              onChange={handleYearChange}
              isMulti
              hideSelectedOptions={false}
              menuIsOpen={selectYear}
              onFocus={() => setSelectYear(true)}
              onBlur={() => setSelectYear(false)}
              menuProps={{ onClick: handleSelectMenuClick }}
            />
          </div>
          <div className="w-fit-content md:mt-0 mt-3">
            <button
              className="apply_buttn border hover:bg-[#065f46] rounded-full"
              onClick={handleFilterApply}
            >
              Apply Filter
            </button>
          </div>
        </div>
        <div className="all_applications pt-6 md:pt-8">
          {Object.entries(details).map(([key, value]) => (
            <div key={key} className="apply_box">
              <p>
                {key === "applicationCount"
                  ? "Application Count"
                  : key
                    .split("_")
                    .map(
                      (word) => word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(" ")}
              </p>
              <p className="app_zero">{value.toString()}</p>
            </div>
          ))}
        </div>

        <div className="custom-dashboard-section">
          {/* <div className="grid dashboard-top-section gap-6 mb-5">
            <div className="p-4  rounded-lg" style={{ background: "#1a7335" }}>
              <h2 className="text-white mb-3">Top Visa Counselor</h2>
              <div className="grid grid-cols-3">
                <div className="flex items-center">
                  <div classsName="" style={{ width: "10%" }}>
                    <p className="text-white">1</p>
                  </div>
                  <div className="" style={{ width: "90%" }}>
                    <div classsName="flex flex-column">
                      <div className="text-white">ABCD (AMBALA)</div>
                      <div className="text-white">(Sale:13)</div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div classsName="" style={{ width: "10%" }}>
                    <p className="text-white">2</p>
                  </div>
                  <div className="" style={{ width: "90%" }}>
                    <div classsName="flex flex-column">
                      <div className="text-white">Innerx counselor</div>
                      <div className="text-white">(Sale:7)</div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div classsName="" style={{ width: "10%" }}>
                    <p className="text-white">3</p>
                  </div>
                  <div className="" style={{ width: "90%" }}>
                    <div classsName="flex flex-column">
                      <div className="text-white">FINTECH (KARN...)</div>
                      <div className="text-white">(Sale:2)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="reg-section grid grid-cols-2 gap-6 mb-5">
            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "#1a7335" }}
              >
                Collection By Application Fees
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">RECEIVED</th>
                      <th className="text-left">DUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>8888</td>
                      <td>569214</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                Registration
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">VISA</th>
                      <th className="text-left">IELTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>8888</td>
                      <td>569214</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <div className="offer-letter-section grid gap-6 mb-5">
            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                OFFER LETTER
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">APPLIED</th>
                      <th className="text-left">NOT APPLIED</th>
                      <th className="text-left">RECEIVED</th>
                      <th className="text-left">NOT RECEIVED</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>4</td>
                      <td>3</td>
                      <td>4</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>64</td>
                      <td>17</td>
                      <td>54</td>
                      <td>10</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <div className="fees-section grid grid-cols-2 gap-6 mb-5">
            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                Tution FEES
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">PAID</th>
                      <th className="text-left">NOT PAID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>4</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>49</td>
                      <td>6</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                INSTITUTE FEES RECIPT
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">RECIPT RECEIVED </th>
                      <th className="text-left">NOT RECEIVED</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>0</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>854</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <div className="files-visa-section grid grid-cols-2 gap-6 mb-5">
            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                FILES
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">PAID</th>
                      <th className="text-left">NOT PAID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>4</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>49</td>
                      <td>6</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                VISA
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">RECIPT RECEIVED </th>
                      <th className="text-left">NOT RECEIVED</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>0</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>854</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          {/* <div className="gic-section grid gap-6 mb-5">
            <div className="bg-white" style={{ borderRadius: "0.5rem" }}>
              <h2
                className="text-white p-3 custom-heading-text"
                style={{ background: "rgb(26, 115, 53)" }}
              >
                GIC
              </h2>
              <div className=" p-3 w-100 pt-0">
                <Table striped bordered hover w-full className="w-full">
                  <thead>
                    <tr>
                      <th className="text-left">DATA BY</th>
                      <th className="text-left">APPLIED</th>
                      <th className="text-left">NOT APPLIED</th>
                      <th className="text-left">RECEIVED</th>
                      <th className="text-left">NOT RECEIVED</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-dark fw-bold">Today</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">Month</td>
                      <td>4</td>
                      <td>3</td>
                      <td>4</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-dark fw-bold">last 3 Months</td>
                      <td>64</td>
                      <td>17</td>
                      <td>54</td>
                      <td>10</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
