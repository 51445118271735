import React, { useState, useEffect } from "react";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 p-2 bg-black text-white rounded-full shadow-md z-[999999]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-up"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8.648 0.5a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 1 2.354 7a.5.5 0 1 1-.708-.708l6-6z"
          />
          <path
            fillRule="evenodd"
            d="M8 0a.5.5 0 0 1 .5.5V14a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5z"
          />
        </svg>
      </button>
    )
  );
};

export default ScrollToTopButton;
