import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BigLoading } from "../../../common/BigLoading";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { authenticate, getToken } from "../../../helper/auth";

const StudentEmbassyDocumentsPopup = ({
  fId,
  setEmbassyDocsModal,
  state: prevState,
  setState: prevSetState,
  activeFileIndex,
}) => {
  const [checkbox, setCheckBox] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    isWaiting: true,
    studentProfile: {},
    enrolledList: [],
    adminToken: getToken("admin"),
    reason: "",
    reasonType: -1,
    remarkpopupActive: false,
    remarkpopupActiveDocType: false,
    baseUrl: "",
    popup: false,
    remark: "",
    remarksList: [],
    profileApproveLoading: false,
    fileId: null,
    approveFileLoading: false,
    bigLoading: false,
    documentsList: [],
  });

  const toggleRemarkPopup = (type) => {
    setState({
      ...state,
      remarkpopupActive: !state.remarkpopupActive,
      remarkpopupActiveDocType: type,
    });
  };
  const [tab, setTab] = useState(
    searchParams.get("tab") == "documents" ? 2 : 2
  );
  const fetchData = () => {
    if (fId) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.adminToken}`,
        },
      };
      axios
        .post(
          process.env.REACT_APP_NODE_URL + "/student/getEmbacyDocs",
          { fileId: fId },
          config
        )
        .then((documentsResponse) => {
          setState({
            ...state,
            isWaiting: false,
            remarksList: [],
            documentsList: documentsResponse.data.details.documents,
            enrollStatus: documentsResponse.data.details.enroll_status,
            baseUrl: documentsResponse.data.details.baseUrl,
          });
        });
    } else {
      setState({
        ...state,
        isWaiting: false,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [fId, state.adminToken]);

  const RemarkPopup = () => {
    const changeReasonType = (e) => {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    };

    const submitNow = () => {
      setState({
        ...state,
        bigLoading: true,
      });
      const remarkReason = document.getElementById("remarkReason").value;

      let oldDocs = state.documentsList;
      oldDocs[parseInt(state.remarkpopupActiveDocType)].document_status =
        "UN_APPROVED";
      const data = {
        status: "0",
        reason: remarkReason,
        fileId: fId,
        documents: oldDocs,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${state.adminToken}`,
        },
      };
      axios
        .patch(
          process.env.REACT_APP_NODE_URL + "/admin/updateEmbassyDocument",
          data,
          config
        )
        .then((documentsResponse) => {
          setState({
            ...state,
            isWaiting: false,
            bigLoading: false,
            documentsList: oldDocs,
            remarkpopupActive: false,
          });
        })
        .catch((err) => {});
    };

    return (
      <>
        {state.bigLoading && <BigLoading />}
        <div className={`remarkpopup ${state.remarkpopupActive && "active"}`}>
          <div className="w-full">
            <h1>
              <span>Select Reason</span>
              <div className="close" onClick={toggleRemarkPopup}>
                <svg
                  className="mr-2"
                  style={{ width: "25px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                </svg>
              </div>
            </h1>
            <ul>
              <div className="form-group">
                {state.reasonType == "Not Visible" ? (
                  <input
                    type="radio"
                    name="reasonType"
                    id="1"
                    value={"Not Visible"}
                    onChange={changeReasonType}
                    checked
                  />
                ) : (
                  <input
                    type="radio"
                    name="reasonType"
                    id="1"
                    value={"Not Visible"}
                    onChange={changeReasonType}
                  />
                )}
                <label htmlFor="1">Not Visible</label>
              </div>
              <div className="form-group">
                {state.reasonType == "Invalid Document" ? (
                  <input
                    type="radio"
                    name="reasonType"
                    id="2"
                    value={"Invalid Document"}
                    onChange={changeReasonType}
                    checked
                  />
                ) : (
                  <input
                    type="radio"
                    name="reasonType"
                    id="2"
                    value={"Invalid Document"}
                    onChange={changeReasonType}
                  />
                )}
                <label htmlFor="2">Invalid Document</label>
              </div>
              <div className="form-group">
                {state.reasonType == "Other" ? (
                  <input
                    type="radio"
                    name="reasonType"
                    id="3"
                    value={"Other"}
                    onChange={changeReasonType}
                    checked
                  />
                ) : (
                  <input
                    type="radio"
                    name="reasonType"
                    id="3"
                    value={"Other"}
                    onChange={changeReasonType}
                  />
                )}
                <label htmlFor="3">Other</label>
              </div>
              <div
                className={`form-group ${
                  state.reasonType == "Other" ? "" : "hidden"
                }`}
              >
                <input
                  type="text"
                  placeholder="Type Reason"
                  className="form-control border-1 border-grey px-2 py-2 mb-3"
                  id="remarkReason"
                />
              </div>
              <div>
                <button
                  className="py-[4px] px-[12px] rounded-full text-black submitRemarkBtn"
                  onClick={submitNow}
                >
                  Submit
                </button>
              </div>
            </ul>
          </div>
        </div>
      </>
    );
  };

  const approveDocStatus = (index) => {
    if (window.confirm("Are you sure ?")) {
      setState({
        ...state,
        bigLoading: true,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${state.adminToken}`,
        },
      };
      let oldDocs = state.documentsList;
      oldDocs[index].document_status = "APPROVED";

      const data = {
        reason: "",
        fileId: fId,
        documents: oldDocs,
        status: "1",
      };

      axios
        .patch(
          process.env.REACT_APP_NODE_URL + "/admin/updateEmbassyDocument",
          data,
          config
        )
        .then((documentsResponse) => {
          setState({
            ...state,
            isWaiting: false,
            bigLoading: false,
            documentsList: oldDocs,
          });
        })
        .catch((err) => {});
    }
  };
  const handleBulkApprove = () => {
    if (window.confirm("Approve all documents?")) {
      setState({
        ...state,
        bigLoading: true,
      });
      axios
        .get(
          process.env.REACT_APP_NODE_URL +
            `/v2/api/verifyallEmbassydocs/${fId}/APPROVED`
        )
        .then((res) => {
          if (res.data.status === true) {
            fetchData();
            toast.success(res.data.message);
            setState({
              ...state,
              bigLoading: false,
            });
          } else {
            toast.error(res.data.message);
          }
          setState({
            ...state,
            bigLoading: false,
          });
        });
    }
  };
  
  const handleBulkDecline = () => {
    if (window.confirm("Decline all documents?")) {
      setState({
        ...state,
        bigLoading: true,
      });
      axios
        .get(
          process.env.REACT_APP_NODE_URL +
            `/v2/api/verifyallEmbassydocs/${fId}/UN_APPROVED`
        )
        .then((res) => {
          if (res.data.status === true) {
            fetchData();
            toast.success(res.data.message);
            setState({
              ...state,
              bigLoading: false,
            });
          } else {
            toast.error(res.data.message);
          }
          setState({
            ...state,
            bigLoading: false,
          });
        });
    }
  };
  if (state.isWaiting) return "Waiting...";

  return (
    <>
      <div
        className="student-table"
      >
        <>
          <div className={`agentDetailsAsideBar active`}>
            <div className="table-overflow">
              <table className="table-responsive">
                <div className="tabs">
                  <h2
                    className={`${tab == 2 && "active"}`}
                    onClick={() => setTab(2)}
                  >
                    Embassy Documents
                  </h2>
                </div>
                <div
                  className={`tabDetail table-overflow docs ${
                    tab == 2 && "active"
                  }`}
                >
                  {state.documentsList.length == 0 ? (
                    <div className="text-[red] w-full text-center m-3">
                      No Documents
                    </div>
                  ) : (
                    <div className="text-[red] w-full text-center m-3"></div>
                  )}
                  <table className="table-fixed w-full p-2 pt-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {state.documentsList.map((document, DocIndex) => {
                        return (
                          <tr key={DocIndex} className="px-2 docRow">
                            <td className="flex items-center">
                              {document.document_title}{" "}
                            </td>

                            <td className="flex items-center justify-end">
                              {document.document_status == "PENDING" ||
                              document.document_status == "UPLOADED" ? (
                                <>PENDING</>
                              ) : document.document_status ==
                                "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ? (
                                <>
                                  <button
                                    className="approve"
                                    onClick={() => approveDocStatus(DocIndex)}
                                  >
                                    Approve
                                  </button>
                                  <button
                                    className="decline"
                                    onClick={() => toggleRemarkPopup(DocIndex)}
                                  >
                                    Decline
                                  </button>
                                  <span
                                    className="flex items-center justify-end downloadSvg"
                                    title="Download"
                                  >
                                    <a
                                      href={
                                        state.baseUrl + document.document_url
                                      }
                                      download="companylogo"
                                      target={"_blank"}
                                    >
                                      <svg
                                        style={{ width: "20px" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                      </svg>
                                    </a>
                                  </span>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {document.document_status == "APPROVED" ? (
                                    <div className="approved_status">
                                      Approved
                                    </div>
                                  ) : (
                                    <div className="declined_status">
                                      Declined
                                    </div>
                                  )}
                                  <span
                                    className="flex items-center justify-end downloadSvg"
                                    title="Download"
                                  >
                                    <a
                                      href={
                                        state.baseUrl + document.document_url
                                      }
                                      download="companylogo"
                                      target={"_blank"}
                                    >
                                      <svg
                                        style={{ width: "20px" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                      </svg>
                                    </a>
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}{" "}
                    </tbody>
                    {state?.documentsList?.some(
                      (document) =>
                        document.document_status === "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION"
                    ) && (
                      <div className="bulk_btns">
                        <button
                            onClick={handleBulkApprove}
                          className="bulkapprove"
                        >
                          Approve All
                        </button>
                        <button
                            onClick={handleBulkDecline}
                          className="bulkdecline"
                        >
                          Decline All
                        </button>
                      </div>
                    )}
                  </table>
                </div>
              </table>
            </div>
          </div>
        </>
      </div>
      <RemarkPopup />
    </>
  );
};

export default StudentEmbassyDocumentsPopup;
