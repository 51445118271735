import axios from "axios";
import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import Slider from "react-slider";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

const GlobalFilterBar = (props) => {
  const navigate = useNavigate();

  const initialFilters = typeof props.filters === 'object' ? props.filters : JSON.parse(props.filters || '{}');
  const [filters, setFilters] = useState(initialFilters);
  // Inside the component function
  const [ModalCodeFilter, setModalCodeFilter] = useState(false);
  const location = useLocation();
  const stateData = location.state?.data;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalpages] = useState(0);

  // 
  const [selectedCountryOptions, setselectedCountryOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [selectedCountryIds, setSelectedCountryIds] = useState([]);
  const [prePopulatedCountryOptions, setPrePopulatedCountryOptions] = useState([]);
  // 
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  let matchingCountry = [];

  console.log("stateData:::::", stateData)
  console.log("initialFilters", initialFilters)

  const [state, setState] = useState({
    iletsScore: [initialFilters?.exam?.score[0] > 0 ? initialFilters?.exam?.score[0] : 0, initialFilters?.exam?.score[1] > 0 ? initialFilters?.exam?.score[1] : 0, initialFilters?.exam?.score[2] > 0 ? initialFilters?.exam?.score[2]: 0, initialFilters?.exam?.score[3] > 0 ? initialFilters?.exam?.score[3]: 0],
    pteScore: initialFilters?.exam?.type === "PTE" ? initialFilters?.exam?.score : 0,
    tofelScore: initialFilters?.exam?.type === "TOFEL" ? initialFilters?.exam?.score : 0,
    duolingo_lan: initialFilters?.exam?.type === "duolingo_lan" ? initialFilters?.exam?.score : 0,
    newStreams: initialFilters?.new_stream?.length > 0 ? initialFilters?.new_stream?.map((option) => option) : [],
    countryList: [],
    data: {},
    datacolleges: [],
    iswait: true,
    buttonLoading: true,
    currentPage: 1,
    noMore: false,
    baseUrl: "",
  }); 

  console.log("state in agentfilter", state)

  useEffect(() => {
    const fetchData = async () => {
      await getCountries();
      await getStates();
      await getCities();
      await getColleges();
    };
    fetchData();
  }, [selectedCountryOptions, selectedStateOptions, selectedCityOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const countryResponse = await axios.get(process.env.REACT_APP_NODE_URL + "/address/country");
      console.log("countryResponse", countryResponse);
  
      setCountryOptions(
        countryResponse.data.details.countries.map((country) => ({
          label: country.countryName,
          value: country.countryId,
        }))
      );
      const countryToGo = filters?.country_to_go || [];
      if (filters?.exam?.type == "IELTS") {
        setState((prevState) => ({
          ...prevState,
          iletsScore: filters?.exam?.score || [0, 0, 0, 0],
          newStreams: filters?.new_stream,
          iswait: false,
          countryList: countryResponse.data.details.countries,
        }));
        console.log("Stattteeee", state)
      }
      if (filters?.exam?.type === "TOFEL") {
        setState({
          ...state,
          tofelScore: filters?.exam?.score,
          newStreams: filters?.new_stream,
          iswait: false,
          countryList: countryResponse.data.details.countries,
        });
      }
      if (filters?.exam?.type === "PTE") {
        setState({
          ...state,
          pteScore: filters?.exam?.score,
          newStreams: filters?.new_stream,
          iswait: false,
          countryList: countryResponse.data.details.countries,
        });
      }
    };
  
    fetchData();
  }, [filters?.country_to_go]); // Empty dependency array to run only once on mount
  
  const collgeTypes = [
    {value: '1,3', label: 'Public Institute'},
    {value: '2,3', label: 'Private Institute'},
    {value: '1,4', label: 'Public University'},
    {value: '2,4', label: 'Private University'},
    {value: '1,5', label: 'Public College'},
    {value: '2,5', label: 'Private College'},
    {value: '1,6', label: 'Public School'},
    {value: '2,6', label: 'Private School'},
  ]


  const streams = [
    "Engineering",
    "Technology",
    "Skill Trades",
    "Transportation",
    "Science",
    "Arts",
    "Media",
    "Design",
    "Law",
    "Politics",
    "Community Service",
    "Education",
    "Business",
    "Management",
    "Economics",
    "Administration",
    "Accounting",
    "English For Academic Studies",
    "Health Sciences",
    "Medicine",
    "Nursing",
    "Paramedic",
  ];


  const handleChangeExam = (e) => {
    if (e.target.name == "tofel_score") {
      setState({
        ...state,
        tofelScore: e.target.value,
      });
      applyFilter("exam", { type: "TOFEL", score: e.target.value });
    }
    if (e.target.name == "duolingo_lan") {
      setState({
        ...state,
        duolingo_lan: e.target.value,
      });
      applyFilter("exam", { type: "duolingo_lan", score: e.target.value });
    }
    if (e.target.name == "pte_score") {
      setState({
        ...state,
        pteScore: e.target.value,
      });
      applyFilter("exam", { type: "PTE", score: e.target.value });
    }
    if (e.target.name == "writing") {
      setState({
        ...state,
        iletsScore: [
          parseFloat(e.target.value) || 0,
          state.iletsScore[1],
          state.iletsScore[2],
          state.iletsScore[3],
        ],
      });
      applyFilter("exam", {
        type: "IELTS",
        score: [
          parseFloat(e.target.value) || 0,
          state.iletsScore[1],
          state.iletsScore[2],
          state.iletsScore[3],
        ],
      });
    }
    if (e.target.name == "reading") {
      setState({
        ...state,
        iletsScore: [
          state.iletsScore[0],
          parseFloat(e.target.value) || 0,
          state.iletsScore[2],
          state.iletsScore[3],
        ],
      });
      applyFilter("exam", {
        type: "IELTS",
        score: [
          state.iletsScore[0],
          parseFloat(e.target.value) || 0,
          state.iletsScore[2],
          state.iletsScore[3],
        ],
      });
    }
    if (e.target.name == "speaking") {
      setState({
        ...state,
        iletsScore: [
          state.iletsScore[0],
          state.iletsScore[1],
          parseFloat(e.target.value) || 0,
          state.iletsScore[3],
        ],
      });
      applyFilter("exam", {
        type: "IELTS",
        score: [
          state.iletsScore[0],
          state.iletsScore[1],
          parseFloat(e.target.value) || 0,
          state.iletsScore[3],
        ],
      });
    }
    if (e.target.name == "listening") {
      setState({
        ...state,
        iletsScore: [
          state.iletsScore[0],
          state.iletsScore[1],
          state.iletsScore[2],
          parseFloat(e.target.value) || 0,
        ],
      });
      applyFilter("exam", {
        type: "IELTS",
        score: [
          state.iletsScore[0],
          state.iletsScore[1],
          state.iletsScore[2],
          parseFloat(e.target.value) || 0,
        ],
      });
    }
  };

  const changeExamType = (e) => {
    setFilters({
      ...filters,
      exam: {
        ...filters.exam,
        type: e.target.value,
      },
    });
    applyFilter("exam", {
      type: e.target.value,
      score:
        e.target.value == "TOFEL"
          ? state.tofelScore
          : e.target.value == "PTE"
            ? state.pteScore
            : state.iletsScore,
    });
  };

  const handleNewStream = (list) => {
    let old_newstream = state.newStreams || [];
    list.map((item) => {
      if (old_newstream.includes(item)) {
        var index = old_newstream.indexOf(item);
        if (index !== -1) {
          old_newstream.splice(index, 1);
        }
      } else {
        old_newstream.push(item);
      }
    });
    setState({
      ...state,
      newStreams: old_newstream,
    });
    applyFilter("new_stream", old_newstream);
  };



const applyFilter = (newKey, newValue) => {
  var oldFilters = filters;
  oldFilters[newKey] = newValue;
  console.log("Filterss", filters)
};

const handlesearchfilter = () => {
  navigate("/search", { state: { dataTwo: filters } });
  props.handleStudentSearch();
  props.handleClose();
}

useEffect(() => {
  getAllCollegeaData()
}, []);

const getCountries = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`);
    const countries = response?.data?.details;
    const result = countries.map((country) => ({
      label: country.countryName,
      value: country.countryId,
    }));
    setCountryOptions(result);
  } catch (error) {
    console.error(error);
  }
};

useEffect(() => {
  if(selectedCountryOptions.length == 0) {
    getStates()
  }
}, [filters.country_to_go]) 

useEffect(() => {
  const fetchData = async () => {
    try {
      let matchingCountries = []; // Initialize matchingCountries as an array

      await Promise.all(
        (filters?.country_to_go || []).map(async (option) => {
          const country = countryOptions.find((country) => country.label === option);
          console.log("matchingCountry", country);
          if (country) {
            matchingCountries.push(country.value); // Push the country value to the array
          }
        })
      );

      if (matchingCountries.length > 0) {
        console.log("TesTttttttttttt");
        const response = await axios.get(
          `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${matchingCountries.join(",")}`
        );
        const states = response?.data?.details;
        const result = states.map((state) => ({
          label: state.stateName,
          value: state.stateId,
        }));
        setStateOptions(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [filters.country_to_go, countryOptions]);



const handleCountrySelect = async (selectedOptions) => {
  const selectedValues = selectedOptions.map((option) => option.value);

  // Update state and other logic as needed
  setselectedCountryOptions(selectedOptions);
  setFilters({ ...filters, country_to_go: selectedOptions.map((option) => option.label) });
  applyFilter("country_to_go", selectedOptions.map((option) => option.label));

  try {
    // Fetch states for the newly selected country
    const newCountryResponse = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${selectedValues.join(",")}`);
    const newStates = newCountryResponse?.data?.details;
    const newResult = newStates.map((state) => ({
      label: state.stateName,
      value: state.stateId,
    }));

    // Fetch states for pre-populated countries
    const prePopulatedStates = await Promise.all(prePopulatedCountryOptions.map(async (prePopulatedCountry) => {
      const response = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${prePopulatedCountry.value}`);
      return response?.data?.details.map((state) => ({
        label: state.stateName,
        value: state.stateId,
      }));
    }));

    // Flatten the array of arrays
    const flattenedPrePopulatedStates = prePopulatedStates.flat();

    setStateOptions([...newResult, ...flattenedPrePopulatedStates]);
  } catch (error) {
    console.error("Error fetching states:", error);
  }
};




console.log("filters.country_to_go", filters.country_to_go)

const getStates = async () => {
  const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
  console.log("selectedCountryIds", selectedCountryIds);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${selectedCountryIds.join(",")}`
    );
    const states = response?.data?.details;
    const result = states.map((state) => ({
      label: state.stateName,
      value: state.stateId,
    }));
    setStateOptions(result);
  } catch (error) {
    console.error(error);
  }
};



const getCities = async () => {
  const selectedStateIds = selectedStateOptions.map((state) => state.value);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchCities?stateIds=${selectedStateIds.join(",")}`
    );
    const cities = response?.data?.details;
    const result = cities.map((city) => ({
      label: city.cityName,
      value: city.cityId,
    }));
    setCityOptions(result);
  } catch (error) {
    console.error(error);
  }
};
const getColleges = async () => {
  const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
  const selectedStateIds = selectedStateOptions.map((state) => state.value);
  const selectedCityIds = selectedCityOptions.map((city) => city.value);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_NODE_URL}/v2/api/getCollegeWithProgram?country=${selectedCountryIds.join(",")}&state=${selectedStateIds.join(",")}&city=${selectedCityIds.join(",")}`
    );
    const colleges = response?.data?.detail;
    const result = colleges.map((college) => ({
      label: college.collegeName,
      value: college.collegeName,
    }));
    setCollegeOptions(result);
  } catch (error) {
    console.error(error);
  }
};
useEffect(() => {
  const fetchData = async () => {
    await getCountries();
    await getStates();
    await getCities();
    await getColleges();
  };
  fetchData();
}, [selectedCountryOptions, selectedStateOptions, selectedCityOptions]);

const getAllCollegeaData = async (isFilterClose) => {
  setState({
    ...state,
    isWaiting: true,
  });

  await axios
    .post(
      process.env.REACT_APP_NODE_URL +
      `/admin/getnewAllcolleges?currentPage=${currentPage}`
    )
    .then((res) => {
      const colleges = res?.data?.details;
      setTotalpages(res.data.totalPages);
      if (isFilterClose == true) {
        setModalCodeFilter(false);
      }
      state.first
        ? setState({
          ...state,
          colleges: res?.data?.details,
          isWaiting: false,
          first: false,
        })
        : setState({
          ...state,
          colleges: res?.data?.details,
          isWaiting: false,
        });
    })
    .catch((err) => { });
};

console.log("Filters", filters);
console.log("selectedCountryOptions", selectedCountryOptions)
  return (
    <div className="flex flex-wrap">
      <button className="absolute right-2 top-2" onClick={() => { props.handleClose() }}>
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="close" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" stroke-width="2" d="M3,3 L21,21 M3,21 L21,3"></path></svg>
      </button>
      <div className="w-full md:w-1/2 md:px-3 md:mb-5">
        <div>
          <div className="lg:flex gap-4">
            <div className="w-full">
              <div>
                <label className="font-bold ">Select Exam</label>
                <br />
                <select
                  className="p-2 w-full"
                  onChange={(e) => changeExamType(e)}
                >
                  {filters?.exam?.type == "IELTS" ? (
                    <option value="IELTS" selected>
                      IELTS
                    </option>
                  ) : (
                    <option value="IELTS">IELTS</option>
                  )}
                  {filters?.exam?.type == "PTE" ? (
                    <option value="PTE" selected>
                      PTE
                    </option>
                  ) : (
                    <option value="PTE">PTE</option>
                  )}
                  {filters?.exam?.type == "TOFEL" ? (
                    <option value="TOFEL" selected>
                      TOFEL
                    </option>
                  ) : (
                    <option value="TOFEL">TOFEL</option>
                  )}
                  {filters?.exam?.type == "duolingo_lan" ? (
                    <option value="DUOLINGO" selected>
                      DUOLINGO
                    </option>
                  ) : (
                    <option value="duolingo_lan">DUOLINGO</option>
                  )}
                </select>
                {filters?.exam?.type == "IELTS" ? (
                  <div
                    className="lg:flex flex-wrap justify-between gap-2 inner-course"
                    style={{}}
                  >
                    <div className="flex flex-col justify-between items-center md:w-28 md:mb-1">
                      <label className="h-fit mb-1 w-full">Listening</label>
                      <input
                        type="number"
                        name="listening"
                        value={state.iletsScore[3]}
                        onChange={(e) => handleChangeExam(e)}
                        className="listening border border-current  h-9 p-2"
                      />
                    </div>
                    <div className="flex flex-col justify-between items-center md:w-28 md:mb-1">
                      <label className="h-fit mb-1 w-full">Reading</label>
                      <input
                        type="number"
                        name="reading"
                        value={state.iletsScore[1]}
                        onChange={(e) => handleChangeExam(e)}
                        className="reading border border-current  h-9 p-2"
                      />
                    </div>
                    <div className="flex flex-col justify-between items-center md:w-28 md:mb-1">
                      <label className="h-fit mb-1 w-full">Writing</label>
                      <input
                        type="number"
                        name="writing"
                        value={state.iletsScore[0]}
                        onChange={(e) => handleChangeExam(e)}
                        className="writing border border-current  h-9 p-2"
                      />
                    </div>
                    <div className="flex flex-col justify-between items-center md:w-28 md:mb-1">
                      <label className="h-fit mb-1 w-full">Speaking</label>
                      <input
                        type="number"
                        name="speaking"
                        value={state.iletsScore[2]}
                        onChange={(e) => handleChangeExam(e)}
                        className="speaking border border-current h-9 p-2"
                      />
                    </div>
                    {state.iletsScore[2] !== "" &&
                      state.iletsScore[3] !== "" &&
                      state.iletsScore[1] !== "" &&
                      state.iletsScore[0] !== "" && (
                        <div className="overall-score">
                          <p>
                            Overall Score -{" "}
                            {(
                              Math.round(
                                ((parseFloat(state.iletsScore[2]) +
                                  parseFloat(state.iletsScore[3]) +
                                  parseFloat(state.iletsScore[1]) +
                                  parseFloat(state.iletsScore[0])) /
                                  4) *
                                2
                              ) / 2
                            ).toFixed(1)}
                          </p>
                        </div>
                      )}
                  </div>
                ) : filters?.exam?.type == "TOFEL" ? (
                  <div>
                    <label className="h-fit">Tofel Score</label>
                    <input
                      type="text"
                      name="tofel_score"
                      value={state.tofelScore}
                      onChange={(e) => handleChangeExam(e)}
                      className="writing border border-current  h-9 p-2 w-full"
                    />
                    <div className="error-field-tofel_score text-danger"></div>
                  </div>
                ) : filters?.exam?.type == "duolingo_lan" ? (
                  <div>
                    <label className="h-fit">Duolingo Score</label>
                    <input
                      type="text"
                      name="duolingo_lan"
                      value={state.duolingo_lan}
                      onChange={(e) => handleChangeExam(e)}
                      className="writing border border-current  h-9 p-2 w-full"
                    />
                    <div className="error-field-tofel_score text-danger"></div>
                  </div>
                ) : filters?.exam?.type == "PTE" ? (
                  <div>
                    <label className="h-fit">Pte Score</label>
                    <input
                      type="text"
                      name="pte_score"
                      value={state.pteScore}
                      onChange={(e) => handleChangeExam(e)}
                      className="writing border border-current  h-9 p-2 w-full"
                    />
                    <div className="error-field-pte_score text-danger"></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 md:px-3 md:mb-5">
        <div className="w-full flex flex-row flex-wrap justify-between">
          <div className="w-full">
            <label className="font-bold">Choose your country</label>
            <br />
            <Select
                className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm mb-[15px]"
                options={countryOptions}
                value={selectedCountryOptions?.length > 0
                  ? selectedCountryOptions
                  : Array.isArray(filters?.country_to_go) && filters.country_to_go.map((option) => {
                      const matchingCountry = countryOptions.find((country) => country.label === option);
                      return { label: option, value: matchingCountry ? matchingCountry.value : null };
                    })
                }
                isMulti
                onChange={(newValue) => {
                  setselectedCountryOptions(newValue); // Update selectedOptions
                  setFilters({ ...filters, country_to_go: newValue.map((option) => option.label) });
                  applyFilter("country_to_go", newValue.map((option) => option.label));
                }}
                labelledBy="Country Name"
                searchable={true}
                hideSelectedOptions={false}
              />

          </div>

          <div className="mb-4 w-[150px]">
            <label
              htmlFor="state"
              className="mb-1 flex"
            >
              State
            </label>
            <Select
              className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
              options={stateOptions}
              value={filters?.stateName ? filters?.stateName.map(option => ({ label: option, value: option })) : selectedStateOptions}
              isMulti
              onChange={(newValue) => {
                setSelectedStateOptions(newValue);
                setFilters({ ...filters, stateName: newValue.map(option => option.label) });
                applyFilter("stateName", newValue.map(option => option.label));
              }}
              labelledBy="State Name"
              searchable={true}
              hideSelectedOptions={false}
            />
          </div>

          {/* City Multi-select */}
          <div className="mb-4 w-[150px]">
            <label
              htmlFor="city"
              className="mb-1 flex"
            >
              City
            </label>
            <Select
              className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
              options={cityOptions}
              value={filters?.cityName ? filters?.cityName.map(option => ({ label: option, value: option })) : selectedCityOptions}
              isMulti
              onChange={(newValue) => {
                setSelectedCityOptions(newValue);
                setFilters({ ...filters, cityName: newValue.map(option => option.label) });
                applyFilter("cityName", newValue.map(option => option.label));
              }}
              labelledBy="City Name"
              searchable={true}
              hideSelectedOptions={false}
            />
          </div>

          {/* College Multi-select */}
          <div className="mb-4 w-[150px]">
            <label
              htmlFor="college_name"
              className="mb-1 flex"
            >
              College Name
            </label>
            <Select
              className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
              options={collegeOptions}
              value={filters?.collegeName ? filters?.collegeName.map(option => ({ label: option, value: option })) : selectedCollegeOptions}
              isMulti
              onChange={(newValue) => {
                setSelectedCollegeOptions(newValue);
                setFilters({ ...filters, collegeName: newValue.map(option => option.label) });
                applyFilter("collegeName", newValue.map(option => option.label));
              }}
              labelledBy="College Name"
              searchable={true}
              hideSelectedOptions={false}
            />
          </div>
        </div>

      </div>
      
      <div className="w-full md:w-1/4 md:px-3 md:mb-5">
        <div>
          <div className="w-full">
            <div>
              <label className="font-bold ">Choose your grade score</label>
              <br />
              <input
                className="w-full bg-white h-9"
                type="text"
                name="grade_score"
                value={filters.grade_score}
                onChange={(e) => {
                  setFilters({ ...filters, grade_score: e.target.value });
                  applyFilter("grade_score", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/4 md:px-3 md:mb-5">
        <div>
          <div className="w-full">
            <div>
              <label className="font-bold">Highest Education</label>
              <br />
              <select
                className="p-2 w-full"
                value={filters?.highest_education}
                onChange={(e) => {
                  setFilters({ ...filters, highest_education: e.target.value });
                  applyFilter("highest_education", e.target.value);
                }}
              >
                <option value="">--Select--</option>
                {filters.highest_education == "secondary" ? (
                  <option value="secondary" selected>
                    Grade 12/High School
                  </option>
                ) : (
                  <option value="secondary">Grade 12/High School</option>
                )}
                {filters.highest_education == "certificate" ? (
                  <option value="certificate" selected>
                    1-Year Post-Secondary Certificate
                  </option>
                ) : (
                  <option value="certificate">
                    1-Year Post-Secondary Certificate
                  </option>
                )}
                {filters.highest_education == "diploma" ? (
                  <option value="diploma" selected>
                    2-Year Undergraduate Diploma
                  </option>
                ) : (
                  <option value="diploma">2-Year Undergraduate Diploma</option>
                )}
                {filters.highest_education == "advance_diploma" ? (
                  <option value="advance_diploma" selected>
                    3-Year Undergraduate Advanced Diploma
                  </option>
                ) : (
                  <option value="advance_diploma">
                    3-Year Undergraduate Advanced Diploma
                  </option>
                )}
                {filters.highest_education == "3_year_bachlor" ? (
                  <option value="3_year_bachlor" selected>
                    3-Year Bachelor's Degree
                  </option>
                ) : (
                  <option value="3_year_bachlor">
                    3-Year Bachelor's Degree
                  </option>
                )}
                {filters.highest_education == "4_year_bachlor" ? (
                  <option value="4_year_bachlor" selected>
                    4-Year Bachelor's Degree
                  </option>
                ) : (
                  <option value="4_year_bachlor">
                    4-Year Bachelor's Degree
                  </option>
                )}
                {filters.highest_education == "postgraduate_diploma" ? (
                  <option value="postgraduate_diploma" selected>
                    Postgraduate Certificate/Diploma
                  </option>
                ) : (
                  <option value="postgraduate_diploma">
                    Postgraduate Certificate/Diploma
                  </option>
                )}
                {filters.highest_education == "master" ? (
                  <option value="master" selected>
                    Master's Degree
                  </option>
                ) : (
                  <option value="master">Master's Degree</option>
                )}
                {filters.highest_education == "doctrate" ? (
                  <option value="doctrate" selected>
                    Doctoral Degree (Phd, M.D., ...)
                  </option>
                ) : (
                  <option value="doctrate">
                    Doctoral Degree (Phd, M.D., ...)
                  </option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/4 md:px-3 md:mb-5 college_type">
        <label className="font-bold ">College Type</label>
        <br />
        <div className="flex flex-col items-center">
          <Select
            className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
            options={collgeTypes}
            style={{ minHeight: 'unset' }}
            value={collgeTypes.filter(option => (filters.college_type || []).includes(option.label) || (filters.college_type || []).includes(option.value))}
            isMulti
            onChange={(selectedOptions) => {
              const selectedValues = selectedOptions.map((option) => option.label);
              setFilters({ ...filters, college_type: selectedOptions.map((option) => option.value) });
              applyFilter('college_type', selectedValues);
            }}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            labelledBy="College Type"
            searchable={true}
            hideSelectedOptions={false}
          />
        </div>
      </div>

      <div className="w-full md:w-1/4 md:px-3 md:mb-5">
        <div>
          <div>
            <label className="font-bold ">Fees in dollars</label>
            <br />
            <div className="flex flex-col items-center">
              <div className="w-full flex justify-between items-center">
                <span className="ps-4">${parseInt(filters?.fees?.min !== undefined || 0 ? filters?.fees?.min : 0)}</span> <span className="pe-4">${parseInt(filters?.fees?.max !== undefined ? filters?.fees?.max : 100000)}</span>
              </div>
              <Slider
                className="w-full mb-5"
                value={[
                  parseInt(filters?.fees?.min !== null ? filters?.fees?.min : 100),
                  parseInt(filters?.fees?.max !== null ? filters?.fees?.max : 0),
                ].map(value => isNaN(value) ? 0 : value)}
                min={0}
                max={100000}
                step={100}
                onChange={(newValues) => {
                  setFilters({
                    ...filters,
                    fees: { min: newValues[0], max: newValues[1] },
                  });
                  applyFilter("fees", { min: newValues[0], max: newValues[1] });
                }}
              />
              <div className="w-full flex justify-between items-center">
                <span>$0</span> <span>$100000</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-full md:px-3 lg:w-full">
        <div className={`flex items-center pb-0 filter-tab-title`}>
          <Icon name="dropdown" />
          <h2 className="px-4 py-2 text-lg  m-0 w-full md:pl-0">
            What Disciplines Do You Want To Study?
          </h2>
        </div>
        <div className="md:flex md:flex-wrap filter-check-list">
          {streams.map((item) => {
            return (
              <div className="flex items-center mb-0">
                <p className="flex items-center mr-3">
                  {state.newStreams && state.newStreams.includes(item) ? (
                    <input
                      value={item}
                      name="stream"
                      className="mr-2"
                      type="checkbox"
                      onChange={(e) => handleNewStream([item])}
                      checked
                    />
                  ) : (
                    <input
                      value={item}
                      name="stream"
                      className="mr-1"
                      type="checkbox"
                      onChange={(e) => handleNewStream([item])}
                    />
                  )}
                  {item}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <button className="view-program  bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-3 px-8 rounded-full mt-5 text-md mx-auto" onClick={handlesearchfilter}>Search</button>
    </div>
  );
};

export default GlobalFilterBar;
