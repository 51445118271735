import React from "react";

export default function Whocontent() {
  return (
    <div>
      <div className="whopart pt-20 px-4 lg:px-0">
        <div className="container mx-auto">
          <h2 className="text-center text-4xl mb-8">Who we are ?</h2>
          <p className="text-center lg:px-32 lg:text-base mb-10">
            Learn Global is one of the best website inimitably planned for students
             to apply to the paramount Colleges in all over the world.
              Our assorted team is fueled by a passion for mores and modernism, 
              and we know that education is very vital for students.
               We place ourselves as a global company. We better comprehend the desires of every student,
                and also deem each country’s barriers. Today, we are conceited partners to a large number of 
                institutions and have assisted thousands of students with their educational journey.
          </p>
          <button class="capitalize bg-[#059669] hover:bg-black text-white  py-3 px-6 rounded mx-auto block text-base">
            our story
          </button>
        </div>
      </div>
    </div>
  );
}
