import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Au from "../../website/images/au.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import loader from "../../../images/loader.gif";

export default function College() {
  const [val, setVal] = useState("");
  const [data, setData] = useState([]);
  const [wait, setWait] = useState(true);
  const [query, setQuery] = useState("-1");
  const [filteredData, setFilteredData] = useState([]);
  const [displayCount, setDisplayCount] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);

  const location = useLocation();
  const loadMoreThreshold = 0.1;
  const containerRef = useRef(null);

  useEffect(() => {
    setWait(true);
    axios
      .get(process.env.REACT_APP_NODE_URL + `/v2/api/discovercollege/${query}`)
      .then((response) => {
        console.log("Colleged", response)
        setData(response.data.details.collegesList);
        setWait(false);
      });
  }, [query]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlWithoutSearch = currentUrl.split("?")[0];
    window.history.replaceState(null, "", urlWithoutSearch);
  }, []);

  useEffect(() => {
    const search = new URLSearchParams(location.search).get("search");
    setVal(search || ""); // Set the search query parameter to val state
  }, [location.search]);

  useEffect(() => {
    const filteredColleges = data?.filter((el) => {
        const name = el.collegeName?.toLowerCase();
        const country = el.country?.[0]?.toLowerCase();
        const searchTerm = val?.toLowerCase() || "";
        return name?.includes(searchTerm) || country?.includes(searchTerm);
    });

    setFilteredData(filteredColleges || []);
  }, [val, data]);

  const loadMore = () => {
    if (displayCount < filteredData.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setDisplayCount(displayCount + 10);
        setLoadingMore(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadMore();
          }
        });
      },
      { threshold: loadMoreThreshold }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [displayCount, filteredData]);

  return (
    <div className="lg:w-100% coverleft">
      <div className="searchbar-top cs-search">
        <div className="flex items-center dc-search">
          <input
            type="text"
            className="p-4"
            value={val}
            placeholder="Find college, University or Country"
            onChange={(e) => setVal(e.target.value)}
          />
        </div>
      </div>
      <div>
        <div className="innercollege mt-5">
          <div className="lg:w-100% ">
            {wait && (
              <div className="w-full pt-[50px]">
                <center className="w-full my-10">
                  <img src={loader} alt="" />
                </center>
              </div>
            )}
            {!wait && filteredData.length === 0 && (
              <center style={{ color: "red" }}>No Data</center>
            )}
            <div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6"
              ref={containerRef}
            >
              {!wait &&
                filteredData.slice(0, displayCount).map((el) => (
                  <div className="border-2 rounded grid grid-cols-2 flex-row gap-3 p-4 items-end bg-white">
                    <div className="basis-2/4 ">
                      <div className="small-image-container">
                        <img
                          src={
                            process.env.REACT_APP_NODE_URL +
                            "/uploads/agent/" +
                            el.collegeLogo
                          }
                          alt=""
                        />
                      </div>
                      <h2 className="font-bold text-1xl mt-2 mb-2">
                        {el.collegeName
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </h2>
                      <div className="flex gap-2 mb-3 items-center">
                        <img
                          className="count-flag"
                          src={
                            process.env.REACT_APP_NODE_URL +
                            "/uploads/agent/" +
                            el.countryLogo
                          }
                          alt=""
                        />
                        {el.country}
                      </div>
                      <Link
                        to={"/specificcollege/" + el._id}
                        className="rounded-full elig-btn vw-colg text-center p-2 mt-0 bg-[#fff] text-[#059669]"
                      >
                        View College
                      </Link>
                    </div>
                    <div className="basis-2/4">
                      <div className="flex mb-2">
                        <p className="text-lg custom-text-con font-bold">
                          Total Student
                        </p>
                        <p className="ml-auto text-lg custom-text-con custom-text-right">
                          {el.allStudents}
                        </p>
                      </div>
                      <div className="flex mb-2">
                        <p className="text-lg custom-text-con font-bold">
                          State
                        </p>
                        <p className="ml-auto text-lg custom-text-con custom-text-right">
                          {Array.from(new Set(el.state)).join(", ")}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 mb-2">
                        <p className="text-lg custom-text-con font-bold">
                          City
                        </p>
                        <p className="ml-auto text-lg custom-text-con custom-text-right">
                          {el.city.join(",")}
                        </p>
                      </div>
                      <button
                        className="custon-el-btn bg-[#181739] text-white"
                        onClick={() => (window.location.href = "/eligible")}
                      >
                        Check Eligibility
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            {loadingMore && (
              <center className="my-4">
                <img src={loader} alt="Loading more..." />
              </center>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
