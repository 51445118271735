import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from 'react-redux'
import canada from '../../../images/canada.jpg'
import uk from '../../../images/uk.jpg'
import singapore from '../../../images/singapore.png'
import usa from '../../../images/usa.png'
import australia from '../../../images/australia.png'

export default function Heroslider(props) {
  const landingPage = useSelector((state) => state.landingPage)

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider className="mt-14 md:mt-0 xl:mt-8" {...settings}>
      {/* {
      landingPage.countryList.map((el)=>{
        console.log('llll', el)
          return ( <div className="inner-content">
          <img src={landingPage.baseUrl+el.countryLogo} />  
          <p className="text-center w-full mt-1">{el.countryDetails.countryName}</p>
          <p className="text-green mt-0">college/Universities</p>
        </div>)
        })
      } */}
      <div className="inner-content">
        <img src={usa} />
        <p className="text-center w-full mt-1">USA</p>
        <p className="text-green mt-0">College/Universities</p>
      </div>
      <div className="inner-content">
        <img src={australia} />
        <p className="text-center w-full mt-1">Australia</p>
        <p className="text-green mt-0">College/Universities</p>
      </div>
      <div className="inner-content">
        <img src={singapore} />
        <p className="text-center w-full mt-1">Singapore</p>
        <p className="text-green mt-0">College/Universities</p>
      </div>
      <div className="inner-content">
        <img src={canada} />
        <p className="text-center w-full mt-1">Canada</p>
        <p className="text-green mt-0">College/Universities</p>
      </div>
      <div className="inner-content">
        <img src={uk} />
        <p className="text-center w-full mt-1">UK</p>
        <p className="text-green mt-0">College/Universities</p>
      </div>
    </Slider>
  );
}
