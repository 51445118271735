import React, { useEffect } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../../../../helper/auth";
import axios from "axios";
import Cross from "../../../website/images/cross.png";
import logo2 from '../../../../images/logo2.png'

const StudentHeader = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("Dashboard");
  const [permissions, setPermissions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [firstName, setFirstName] = useState("User");
  const [role, setRole] = useState("");
  const [isWait, setIsWait] = useState(true);
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (path) => {
    setActiveItem(path);
  };

  useEffect(() => {
    setActive(window.location.pathname.split("/")[3]);
    const config = {
      headers: { Authorization: `Bearer ${getToken("student")}` },
    };
    axios
      .post(process.env.REACT_APP_NODE_URL + "/student/verifyToken", {}, config)
      .then((res) => {
        document.getElementById("notificationCountSpan").innerText =
          res.data.details.userData.notificationsCount > 99
            ? "99+"
            : res.data.details.userData.notificationsCount;
        setFirstName(res.data.details.userData.firstName);
        setIsWait(false);
        return;
      })
      .catch((err) => {
        setIsWait(false);
      });
  }, [window.location.href]);

  const [state, setState] = useState({
    menu: -1,
  });

  const sidebarItems = [
    {
      label: "Enrolled Programs",
      icon: <i class="fas fa-book"></i>,
      path: "/student/enrolled",
    },
    // {
    //   label: "Enroll Now",
    //   icon: <i class="fas fa-arrow-circle-right"></i>,
    //   path: "/student/programs",
    // },
  ];

  const openMenu = (menuNumber) => {
    setState({
      ...state,
      menu: state.menu == menuNumber ? -1 : menuNumber,
    });
  };

  return (
    <>
      {/* overlay */}
      <div
        className="overlay"
        id="overlay"
        onClick={() => {
          document.getElementById("aside").classList.toggle("show");
          document.getElementById("overlay").classList.toggle("show");
        }}
      ></div>
      <div
        id="aside"
        className={`sidebar_style xl:fixed h-screen transform ease-in-out transition duration-500 flex justify-start items-start h-full  w-full sm:w-64 flex-col`}
      >
        <img
          className="closebar-btn"
          src={Cross}
          onClick={() => {
            document.getElementById("aside").classList.add("hidebar");
            document.getElementById("overlay").classList.add("hidebar");
          }}
          alt=''
        />
        {state.isWait ? (
          <></>
        ) : (
          sidebarItems.map((singleItem, index) => {
            if (singleItem?.items) {
              if (!permissions.includes(singleItem.permissions) && !isAdmin)
                return;

              return (
                <div className="flex flex-col justify-start items-center w-full relative">
                  <div
                    className="focus:outline-none text-left text-white flex justify-center items-center w-full px-5 py-3"
                  >
                    <div className={`flex justify-between fgf ${activeItem === singleItem.path ? "activeee" : ""}`} onClick={() => handleItemClick(singleItem.path)}>
                      <span
                        onClick={() => openMenu(index)}
                        className="text-xl" title={singleItem.label}>{singleItem.icon}</span>
                    </div>
                  </div>
                  <div
                    className={`subGroupMenuItems ${state.menu != index ? "hidden-now" : "show-now"
                      } flex justify-end flex-col w-full items-end pb-1 `}
                  >
                    {singleItem.items.map((subItem) => {
                      if (
                        !permissions.includes(subItem.permissions) &&
                        !isAdmin
                      )
                        return;
                      return (
                        <button
                          onClick={() => { navigate(subItem.path); handleItemClick(singleItem.path) }}
                          className={`flex justify-start items-center space-x-6 hover:text-white my-1 text-white rounded-l w-11/12 py-3 px-4 ${activeItem === singleItem.path ? "activeee" : ""}`}
                        >
                          <div className="w-4 subItemIconDot">
                            {/* {subItem.icon} */}
                          </div>
                          <p
                            className="sidebaritem text-left leading-4"
                            style={{ marginLeft: "15px", fontSize: '12px' }}
                          >
                            {subItem.label}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                </div>
              );
            } else {
              // single sidebar
              if (
                !permissions.includes(singleItem.permissions) &&
                !isAdmin &&
                singleItem.permissions
              )
                return;
              return (
                <>
                  <div
                    className="flex flex-col justify-start items-center w-full"
                  >
                    <div
                      className="focus:outline-none text-left text-white flex justify-center items-center w-full px-5 py-3"
                    >
                      <div className={`flex justify-between ${activeItem === singleItem.path ? "activeee" : "fdfdfd"}`} onClick={() => handleItemClick(singleItem.path)}>
                        <span
                          onClick={() => navigate(singleItem.path)}
                          className="text-xl" title={singleItem.label}>{singleItem.icon}</span>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })
        )}
      </div>
    </>
  );
};

export default StudentHeader;
