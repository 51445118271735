import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../helper/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClickHandler from "react-outside-click-handler";
import loader from "../../../images/loader.gif";
import GlobalFilterBar from "../Components/globalFilterBar";
import { SlCalender } from "react-icons/sl";

export default function WSearch() {
  const navigate = useNavigate();
  const [enrollLoading, setEnrollLoading] = useState(false);
  const [showFilterBar, setShowFilterbar] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const loadMoreThreshold = 0.1;
  const containerRef = useRef(null);
  const [displayCount, setDisplayCount] = useState(10);
  const modalRef = useRef(null);
  const location = useLocation();
  const [highestEducation, setHighestEducation] = useState(null);
  const stateData = location.state?.data;
  const stateDataTwo = location.state?.dataTwo;
  const [state, setState] = useState({
    data: {},
    datacolleges: [],
    wait: true,
    currentPage: 1,
    noMore: false,
    buttonLoading: true,
    filterLoading: false,
    baseUrl: "",
  });
  console.log('wwwwwwwwwwww', stateData)
  const handleStudentSearch = (apiData) => {
    setState({
      data: {},
      datacolleges: [],
      wait: true,
      currentPage: 1,
      noMore: false,
      buttonLoading: true,
      filterLoading: false,
      baseUrl: "",
    });
    axios.post(`${process.env.REACT_APP_NODE_URL}/student/search`, apiData)
      .then((response) => {
        console.log("Search result", response);
        setState({
          ...state,
          data: {
            totalPrograms: response.data.details.totalPrograms,
            totalcolleges: response.data.details.totalcolleges,
          },
          datacolleges: [
            ...state.datacolleges,
            ...response.data.details.colleges,
          ],
          wait: false,
          noMore: response.data.details.noMore,
          buttonLoading: false,
          currentPage: 1,
          baseUrl: response.data.details.baseUrl,
        });
      })
      .catch((error) => {
        // Handle error if needed
      });
  };
  useEffect(() => {
    if (stateDataTwo) {
      const apiDataTwo = typeof stateDataTwo === 'object' ? stateDataTwo : JSON.parse(stateDataTwo);
      handleStudentSearch(apiDataTwo);
      const education = apiDataTwo.highest_education;
      setHighestEducation(education);
    } else {
      const apiData = typeof stateData === 'object' ? stateData : JSON.parse(stateData);
      handleStudentSearch(apiData);
      const education = apiData.highest_education;
      setHighestEducation(education);
    }
  }, [stateData, stateDataTwo]);


  const handleToggle = (index) => {
    var element = document.getElementById("toggleEligibleBox_" + index);
    element.classList.toggle("open-data");
  };
  const outsideclick = (index) => {
    var element = document.getElementById("toggleEligibleBox_" + index);
    element.classList.remove("open-data");
  };

  const enrollNow = (sid, pid, program) => {
    setEnrollLoading(true);
    document.getElementById(`program_${pid}`).classList.add("loadingEnrollBtn");

    let studentToken = getToken("student");
    if (studentToken) {
      // enroll API
      let api_data = {
        educationType: highestEducation,
        college_id: sid,
        program_id: pid,
        program_fees: program.application_fee,
      };
      const config = { headers: { Authorization: `Bearer ${studentToken}` } };
      axios
        .post(
          `${process.env.REACT_APP_NODE_URL}/student/enroll`,
          api_data,
          config
        )
        .then((response) => {
          if (response.data.status == "1") {
            toast.success(response.data.message)
            setTimeout(() => {
              document
                .getElementById(`program_${pid}`)
                .classList.remove("loadingEnrollBtn");
              setEnrollLoading(false);
              navigate("/student/enrolled");
            }, 1000);
          }
          else {
            toast.error(response.data.message)
            document
              .getElementById(`program_${pid}`)
              .classList.remove("loadingEnrollBtn")
            setEnrollLoading(false);
          }
        })
    } else {
      toast.error("Login First");
      setTimeout(() => {
        document
          .getElementById(`program_${pid}`)
          .classList.remove("loadingEnrollBtn");
        setEnrollLoading(false);
        navigate(`/studentlogin?pid=${pid}&sid=${sid}&eType=${highestEducation}`);
      }, 1000);
    }
  };

  const loadMore = () => {
    if (displayCount < state.datacolleges.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setDisplayCount(displayCount + 10);
        setLoadingMore(false);
      }, 3000);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadMore();
          }
        });
      },
      { threshold: loadMoreThreshold }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [displayCount, state.datacolleges]);

  const formatIntakeId = (intakeId) => {
    if (!intakeId) {
      return [];
    }
    const yearsMonths = intakeId.split(' : ');

    return yearsMonths.map((yearMonth) => {
      const [year, months] = yearMonth.split('=');
      if (!months) {
        return (
          <div className="text-red-500" key={yearMonth}>
            {`${year} - No months specified`}
          </div>
        );
      }
      const monthNames = months.split(',').map((month) => getMonthName(month));

      return (
        <div key={yearMonth}>
          {`${year} - ${monthNames.join(', ')}`}
        </div>
      );
    });
  };
  const getMonthName = (month) => {
    const monthMap = {
      '1': 'January',
      '2': 'February',
      '3': 'March',
      '4': 'April',
      '5': 'May',
      '6': 'June',
      '7': 'July',
      '8': 'August',
      '9': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December',
    };

    return monthMap[month];
  };

  function mapTypes(values) {
    const types = values.split(",").map((value) => {
      switch (value) {
        case "1":
          return "Public";
        case "2":
          return "Private";
        case "3":
          return "Institute";
        case "4":
          return "University";
        case "5":
          return "College";
        case "6":
          return "School";
        default:
          return value;
      }
    });
    return types.join(" ");
  }

  const handleClose = () => {
    setShowFilterbar(false);
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div className="search-content py-10 relative">
          <div
            className={`hide-filter ${showFilterBar && "show-filter"}`}
          ></div>
          <div className="container mx-auto">

            <div className="filter-heading flex flex-col md:flex-row md:items-center mb-4 justify-between w-100">
              {state.datacolleges?.length == 0 ? (
                ""
              ) : (
                <div className="apply-heading">
                  <h2>
                    You've found{" "}
                    <strong className="text-black">
                      {state?.data?.totalPrograms}
                    </strong>{" "}
                    matching programs offered by{" "}
                    <strong className="text-black">
                      {state?.data?.totalcolleges}
                    </strong>{" "}
                    colleges
                  </h2>
                </div>

              )}
              <div className="flex justify-end mt-5 md:mt-0 h-fit md:pl-4 ms-auto">
                <button
                  className="view-program  bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-3 px-8 rounded-full mt-0 text-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterbar(true);
                  }}
                  disabled={state.wait}
                >
                  Filter
                </button>
              </div>
            </div>
            {state.wait ? (
              <div className="w-full pt-[50px]">
                <center className="w-full my-10">
                  <img src={loader} alt="" />
                </center>
              </div>
            ) : (
              state.datacolleges.length > 0 ? (
                <>

                  <div
                    className="left-part"
                    style={{
                      boxShadow:
                        " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                      padding: "10px",
                    }}
                  >
                    {state.filterLoading ? (
                      <div className="w-full pt-[50px]">
                        <center className="w-full my-10">
                          <img src={loader} alt="" />
                        </center>
                      </div>
                    ) : (
                      <>
                        <div ref={containerRef}>
                          {state.datacolleges
                            .slice(0, displayCount)
                            .map((college, index) => {
                              return (
                                <div className=" border border-[#f4f5f6] shadow p-8 rounded-sm mb-6">
                                  <div className="flex search-main">
                                    <div className="image-container">
                                      <img
                                        src={
                                          state.baseUrl + college.collegeLogo
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="pl-3 edu-content">
                                      <h2 className="text-left text-black text-xl  font-bold capitalize">
                                        {college.collegeName}
                                      </h2>

                                      <p className="my-5 flex items-center country-text font-bold capitalize">
                                        <img
                                          className="w-10 mr-2"
                                          src={
                                            state.baseUrl + college.countryLogo
                                          }
                                          alt=""
                                        />
                                        {college.countryName}
                                      </p>
                                      <div className="flex">
                                        <div className="flex items-center">
                                          <b className="mr-1 text-sm">State:</b>
                                          <div>{college.state.join(", ")}</div>
                                        </div>
                                        <div className="flex items-center ml-4">
                                          <b className="mx-1 text-sm">City:</b>
                                          <div>{college.city.join(", ")}</div>
                                        </div>
                                      </div>
                                      <button
                                        onClick={() => handleToggle(index)}
                                        class="view-program  bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-4 px-10 rounded-full mt-5 text-md"
                                      >
                                        View Eligible Programs{" "}
                                        {college.totalData.length}
                                      </button>
                                    </div>

                                    <div className="ml-auto">
                                      <div className="info-text">
                                        <p className="text-lg flex items-center text-black font-bold mb-6">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#149449"
                                            className="mr-1 w-7 h-7"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                          </svg>
                                          Total Students :{" "}
                                          <b>
                                            &nbsp;
                                            {college.allStudents}
                                          </b>
                                        </p>

                                        <p className="flex items-center text-lg text-black font-bold mb-6">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#149449"
                                            className="mr-1 w-7 h-7"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                                            />
                                          </svg>
                                          Type :{" "}
                                          <b>
                                            &nbsp;
                                            {[...new Set(college.collegeType)]
                                              .map((type) => mapTypes(type))
                                              .join(" ")}
                                          </b>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      outsideclick(index);
                                    }}
                                  >
                                    <div
                                      id={`toggleEligibleBox_${index}`}
                                      className="info-data programsList mt-3"
                                    >
                                      <div
                                        className="custom-dd"
                                        style={{
                                          background: "#059669",
                                          padding: "15px",
                                          position: "sticky",
                                          top: "0px",
                                          borderTopLeftRadius: "30px",
                                        }}
                                      >
                                        <h1
                                          className="font-bold text-2xl"
                                          style={{ color: "#fff" }}
                                        >
                                          You Have{" "}
                                          <span style={{ color: "#000" }}>
                                            {college.totalData.length}{" "}
                                          </span>
                                          Matching Programms from{" "}
                                          <span style={{ color: "#000" }}>
                                            {college.collegeName
                                              .charAt(0)
                                              .toUpperCase() +
                                              college.collegeName.slice(1)}
                                          </span>
                                        </h1>
                                      </div>
                                      <div
                                        className="custom-popcs"
                                        style={{ paddingTop: 20 }}
                                      >
                                        <ul className="">
                                          {college.totalData.map((program) => {
                                            return (
                                              <div>
                                                <li className="mb-5">
                                                  <p className="text-left px-5 py-0">
                                                    <h1 className="font-bold text-xl mb-2">
                                                      {program.program_name}
                                                    </h1>
                                                    <p className="text-lg mb-2">
                                                      Credentials :{" "}
                                                      <span className="font-bold">
                                                        {program.credentials}
                                                      </span>
                                                    </p>
                                                    <div className="table-overflow">
                                                      <table className="programTable">
                                                        <tr className="m-1">
                                                          <th className="pe-3 m-1 mr-3">
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="#149449"
                                                                class="w-6 h-6"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <span className="text-[16px]">
                                                              $ Tuition Fee
                                                            </span>
                                                          </th>
                                                          <th className="pl-10 m-1 mr-3">
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="#149449"
                                                                class="w-6 h-6"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <span className="text-[16px]">
                                                              $ Application Fee
                                                            </span>
                                                          </th>
                                                          <th className="pl-10 m-1 mr-3">
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="#149449"
                                                                class="w-6 h-6"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <span className="text-[16px]">
                                                              Duration
                                                            </span>
                                                          </th>
                                                          <th className="pl-10 m-1 mr-3">
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="#149449"
                                                                class="w-6 h-6"
                                                              >
                                                                <path
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                  d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                                                                />
                                                              </svg>
                                                            </span>
                                                            <span className="text-[16px]">
                                                              Percentage
                                                              Required
                                                            </span>
                                                          </th>
                                                        </tr>
                                                        <tr>
                                                          <td className="pe-3 m-1 mr-2">
                                                            {
                                                              program.min_tution_fee_per_semester
                                                            }
                                                            -
                                                            {parseInt(
                                                              program.max_tution_fee
                                                            ) + 1000}
                                                          </td>
                                                          <td className="pl-10 m-1 mr-2">
                                                            {program.application_fee ==
                                                              0
                                                              ? "0"
                                                              : program.application_fee}
                                                          </td>
                                                          <td className="pl-10 m-1 mr-2">
                                                            {program[
                                                              "duration"
                                                            ] + " Year" ||
                                                              program?.duration}
                                                          </td>
                                                          <td className="pl-10 m-1 mr-2">
                                                            {
                                                              program.grade_score
                                                            }
                                                            %
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </div>
                                                  </p>
                                                  <div className="flex items-center gap-2 p-3 rounded mx-3 my-5 bg-[#eee]">
                                                    <div className="flex items-center">
                                                      <SlCalender
                                                        className="me-2"
                                                        style={{ width: 15, height: 15, color: "#7b7b7b" }}
                                                      />
                                                      <p>Program Intake:</p>
                                                    </div>
                                                    {program?.intake_id && formatIntakeId(program.intake_id).map((formattedIntake, index) => (
                                                      <>
                                                        <span className="w-3 h-3 rounded-full bg-[#000]"></span>
                                                        <div key={index}>{formattedIntake}</div>
                                                      </>
                                                    ))}
                                                  </div>
                                                  <div className="info-ftr flex items-center gap-2 px-5">
                                                    <div>
                                                      <b className="mr-2">
                                                        State:
                                                      </b>
                                                      {program.state}
                                                    </div>
                                                    <div>
                                                      <b className="mr-2">
                                                        City:
                                                      </b>
                                                      {program.city}
                                                    </div>
                                                  </div>
                                                  <div className="info-ftr flex items-center gap-2 px-5">
                                                    <button
                                                      id={`program_${program.program_id}`}
                                                      className="bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-2 px-8 rounded-full capitalize"
                                                      onClick={() =>
                                                        enrollNow(
                                                          program.collegeId,
                                                          program.program_id,
                                                          program
                                                        )
                                                      }
                                                    >
                                                      <span>enroll now</span>
                                                      <span className="hidden">
                                                        Loading...
                                                      </span>
                                                    </button>
                                                    <div>
                                                      {program.program_url ? (
                                                        <Link
                                                          to={
                                                            program.program_url
                                                          }
                                                          className="bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-2 px-8 rounded-full capitalize"
                                                          target="_blank"
                                                        >
                                                          Program Link
                                                        </Link>
                                                      ) : (
                                                        <> </>
                                                      )}
                                                    </div>
                                                    {program.few_seats_status && (
                                                      <span className="mx-4 my-2 fewSeatsLeft text-danger">
                                                        Few seats left
                                                      </span>
                                                    )}
                                                  </div>
                                                </li>
                                              </div>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  </OutsideClickHandler>
                                </div>
                              );
                            })}
                        </div>
                        {loadingMore && (
                          <center className="my-4">
                            <img src={loader} alt="Loading more..." />
                          </center>
                        )}
                      </>
                    )}
                  </div>
                </>
              )
                :
                <center className="w-full my-10">
                  <p className="p-5 text-red-500">No data available.</p>
                </center>
            )}
          </div>

          {showFilterBar &&
            <div
              className={`filter-part w-full lg:flex gap-8 ${showFilterBar ? "right-show" : ""
                }`}
            >
              <div className="right-part">
                <div
                  className="right-part-content relative h-[fit-content] border border-[#f4f5f6] rounded pb-5 bg-white"
                  style={{
                    boxShadow: "0px 3px 6px #00000029",
                    padding: "10px",
                  }}
                >
                  <div className="right-main relative" ref={modalRef}>
                    <h2 className="filter-heading mb-4">Filter Colleges and Programs</h2>
                    <GlobalFilterBar filters={stateData !== undefined ? stateData : stateDataTwo} handleClose={handleClose} handleStudentSearch={handleStudentSearch} />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}
