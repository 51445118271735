import axios from "axios";
import { useState, useRef } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation
} from "react-router-dom";
import { SlCalender } from "react-icons/sl";
import { authenticate, getToken } from "../../../helper/auth";
import { BigLoading } from "../../../common/BigLoading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import AgentFilterBar from '../../website/Components/agentFilterbar';
import loader from '../../../images/loader.gif'
import { GrClose } from "react-icons/gr";
import AgentEditStudent from "./AgentEditStudent";

const AgentProgramsList = () => {
  const navigate = useNavigate();
  const [collegeName, setCollegeName] = useState();
  const [destinationcountry, setdestinationcountry] = useState();
  const [firstData, setFirstData] = useState(true);
  const [eligibleProgramList, setEligibleProgramList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [nextData, setNextData] = useState(false);
  const [collegeId, setCollegeId] = useState();
  const programsListRef = useRef(null);
  const [showFilterBar, setShowFilterbar] = useState(false);
  const modalRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonDiable, setButtonDisable] = useState(false);
  const location = useLocation();
  const stateData = location.state?.data;
  const stateDataTwo = location.state?.dataTwo;

  const [showPopup, setShowPopup] = useState(false);

  console.log("stateDataList", stateData)
  console.log("stateDataTwoList", stateDataTwo)

  const [state, setState] = useState({
    agentToken: getToken("agent"),
    data: {},
    datacolleges: [],
    wait: true,
    currentPage: 1,
    noMore: false,
    buttonLoading: true,
    filterLoading: false,
    baseUrl: "",
    student: searchParams.get("student" || null),
    api_data: stateData,
  });



  // console.log("state", state.api_data)


  const handleStudentSearch = (apiData) => {
    // console.log("Api data inside handle: ", apiData, " State Data: ", stateData)
    setState({
      agentToken: getToken("agent"),
      data: {},
      datacolleges: [],
      wait: true,
      currentPage: 1,
      noMore: false,
      buttonLoading: true,
      filterLoading: false,
      baseUrl: "",
      student: searchParams.get("student" || null),
      api_data: apiData //stateData,
    });

    // console.log("updated state inside handle: ", state.api_data)

    axios.post(`${process.env.REACT_APP_NODE_URL}/student/search`, apiData)
      .then((response) => {
        console.log("Search result", response);
        // setting data College to empty array if no data is found
        const collegesData = response.data.details.colleges || [];
        setState((state) => ({
          ...state,
          data: {
            totalPrograms: response.data.details.totalPrograms,
            totalcolleges: response.data.details.totalcolleges,
          },
          // datacolleges: [
          //   ...state.datacolleges,
          //   ...response.data.details.colleges,
          // ],
          datacolleges: collegesData,
          wait: false,
          noMore: response.data.details.noMore,
          buttonLoading: false,
          currentPage: 1,
          baseUrl: response.data.details.baseUrl,
        }));
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  useEffect(() => {
    if (stateDataTwo) {
      const apiDataTwo = typeof stateDataTwo === 'object' ? stateDataTwo : JSON.parse(stateDataTwo);
      handleStudentSearch(apiDataTwo);
    } else {
      const apiData = typeof stateData === 'object' ? stateData : JSON.parse(stateData);
      handleStudentSearch(apiData);

      // console.log("Api Data Check : ", apiData)
    }
  }, [stateData, stateDataTwo]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        nextData &&
        programsListRef.current &&
        !programsListRef.current.contains(event.target)
      ) {
        setNextData(!nextData);
      }
    };
    // Add event listener to the document body
    document.body.addEventListener("click", handleClickOutside);
    // Clean up the event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [nextData]);

  const showEligibleProgram = (college, cId, event, name, country) => {
    setCollegeName(name);
    event.stopPropagation();
    setCollegeId(cId);
    setNextData(true);
    setEligibleProgramList(college);
    setdestinationcountry(country)
  };

  console.log("eligibleProgramList", eligibleProgramList)

  const enrollNow = (program, pid) => {
    setState({
      ...state,
    });
    setButtonDisable(true);
    console.log('ddddddddddddddddd', state)
    document.getElementById(`program_${pid}`).classList.add("loadingEnrollBtn");

    const config = { headers: { Authorization: `Bearer ${state.agentToken}` } };
    const data = {
      agentStudent: {
        firstName: state.api_data != undefined ? state.api_data.first_name : stateDataTwo?.first_name,
        lastName: state.api_data != undefined ? state.api_data.last_name : stateDataTwo?.last_name,
        phone: state.api_data != undefined ? state.api_data.phone : stateDataTwo?.phone,
        email: state.api_data != undefined ? state.api_data.email : stateDataTwo.email,
        highestEducation: state.api_data != undefined ? state.api_data.highest_education : stateDataTwo?.highest_education,
        gradeScore: state.api_data != undefined ? state.api_data.grade_score : stateDataTwo.grade_score,
        destinationcountry: [destinationcountry],
        examType: state?.api_data?.exam?.type != undefined ? state?.api_data?.exam?.type : stateDataTwo?.exam?.type,
        readingScore: state.api_data != undefined ? state.api_data.exam.score[1] : stateDataTwo?.exam?.score[1],
        listeningScore: state.api_data != undefined ? state.api_data.exam.score[0] : stateDataTwo?.exam?.score[0],
        writingScore: state.api_data != undefined ? state.api_data.exam.score[2] : stateDataTwo?.exam?.score[2],
        speakingScore: state.api_data != undefined ? state.api_data.exam.score[3] : stateDataTwo?.exam?.score[3],
      },
      college_id: collegeId,
      program_id: program.program_id,
      program_fees: program.application_fee,
      educationType: state.api_data != undefined ? state.api_data.highest_education : stateDataTwo.highest_education
    };
    console.log('gggggggg', data)

    axios
      .post(process.env.REACT_APP_NODE_URL + "/student/enroll", data, config)
      .then((res) => {
        setButtonDisable(false);
        if (res.data.status === "1") {
          navigate("/agent/enrolled-list");
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setState({
          ...state,
          buttonLoading: false,
        });
      })
      .catch((err) => {
        setButtonDisable(false)
        toast(err.response.data.message);
        setState({
          ...state,
          buttonLoading: false,
        });
      });
  };
  function mapTypes(values) {
    const types = values.split(",").map((value) => {
      switch (value) {
        case "1":
          return "Public";
        case "2":
          return "Private";
        case "3":
          return "Institute";
        case "4":
          return "University";
        case "5":
          return "College";
        case "6":
          return "School";
        default:
          return value;
      }
    });
    return types.join(" ");
  }

  const formatIntakeId = (intakeId) => {
    if (!intakeId) {
      return [];
    }
    const yearsMonths = intakeId.split(' : ');

    return yearsMonths.map((yearMonth) => {
      const [year, months] = yearMonth.split('=');
      if (!months) {
        return (
          <div className="text-red-500" key={yearMonth}>
            {`${year} - No months specified`}
          </div>
        );
      }
      const monthNames = months.split(',').map((month) => getMonthName(month));

      return (
        <div key={yearMonth}>
          {`${year} - ${monthNames.join(', ')}`}
        </div>
      );
    });
  };

  const getMonthName = (month) => {
    const monthMap = {
      '1': 'January',
      '2': 'February',
      '3': 'March',
      '4': 'April',
      '5': 'May',
      '6': 'June',
      '7': 'July',
      '8': 'August',
      '9': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December',
    };

    return monthMap[month];
  };


  const handleClose = () => {
    setShowFilterbar(false);
  }

  // console.log("Student Data : ", state.api_data)

  console.log("Colleges Data : ", state.datacolleges)

  return (
    <>

      {console.log("inside return ", state.api_data)}
      <div
        className={`w-5/12 mx-auto m-5 createcollegeNamePopup ${showPopup ? "active" : ""
          }`}
      >
        <label>
          <div className="flex justify-between align-center">
            <div className="span">Enroll Student</div>
            <GrClose
              className="close"
              onClick={() => {
                setShowPopup(false);
              }}
            />
          </div>
        </label>
        <div className="card-body p-2">
          <AgentEditStudent students={state?.api_data} setPopup={setShowPopup} />
        </div>
      </div>

      {firstData ? (
        <div className="agent agent-program-list">
          <div className="filter-heading flex flex-col md:flex-row md:items-center mb-4 justify-between w-100">
            {state.datacolleges?.length == 0 ? (
              ""
            ) : (
              <div className="apply-heading">
                <h2>
                  You've found{" "}
                  <strong className="text-black">
                    {state?.data?.totalPrograms}
                  </strong>{" "}
                  matching programs offered by{" "}
                  <strong className="text-black">
                    {state?.data?.totalcolleges}
                  </strong>{" "}
                  colleges
                </h2>
              </div>
            )}


            <div className="flex justify-end gap-2 mt-5 md:mt-0 h-fit md:pl-4 mb-5 ms-auto ">
              <button
                className={`${state.datacolleges?.length == 0 ? 'z-10' : ""} view-program bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-3 px-8 rounded-full mt-0 text-md`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPopup(true);
                }}
                disabled={state.buttonLoading}
              >
                Edit Student
              </button>

              <button
                className={`${state.datacolleges?.length == 0 ? 'z-10 ' : ""}view-program  bg-[#1c3479] hover:bg-[#1c3479e0] text-white font-bold py-3 px-8 rounded-full mt-0 text-md`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilterbar(true);
                }}
                disabled={state.buttonLoading}
              >
                Filter
              </button>
            </div>

          </div>
          {state.buttonLoading ? (
            <BigLoading />
          ) : state.datacolleges.length > 0 ? (
            <div className="programs-list-container">

              <div className="programs-list">
                {state.datacolleges.map((college) => (
                  <div className="program" key={college.collegeId}>
                    <div className="top">
                      <img style={{ width: "30px" }} src={state.baseUrl + college.countryLogo} alt="" />
                      <span className="uppercase">{college.country}</span>
                    </div>

                    <div className="body">
                      <div className="image-container">
                        <img src={state.baseUrl + college.collegeLogo} alt="" />
                      </div>
                      <span className="capitalize mt-4">{college.collegeName}</span>
                    </div>

                    <div className="td flex justify-between">
                      <div>Total Students</div>
                      <div>{college.allStudents}</div>
                    </div>

                    <div className="td flex justify-between">
                      <div>Type</div>
                      <div>
                        &nbsp;
                        {[...new Set(college.collegeType)]
                          .map((type) => mapTypes(type))
                          .join(" ")}
                      </div>
                    </div>

                    <button
                      onClick={(event) =>
                        showEligibleProgram(
                          college?.totalData,
                          college?.totalData[0]?.collegeId,
                          event,
                          college.collegeName,
                          college.countryName,
                        )
                      }
                    >
                      Eligible Programs {college?.totalData?.length}
                    </button>
                  </div>
                ))}
              </div>

            </div>
          ) : (
            <center className="w-full my-10">
              <p className="p-5 text-red-500">No data available.</p>
            </center>
          )}

          {showLoader && (
            <div className="bigLoading">
              <img src={loader} alt="loader" />
            </div>
          )}

          {showFilterBar && (
            <div
              className={`filter-part w-full lg:flex gap-8 ${showFilterBar ? "right-show" : ""
                }`}
            >
              <div className="right-part">
                <div
                  className="right-part-content relative h-[fit-content] border border-[#f4f5f6] rounded pb-5 bg-white"
                  style={{
                    boxShadow: "0px 3px 6px #00000029",
                    padding: "10px",
                  }}
                >
                  <div className="right-main relative" ref={modalRef}>
                    <h2 className="filter-heading mb-4">Filter Colleges and Programs</h2>
                    <AgentFilterBar filters={stateData !== undefined ? stateData : stateDataTwo} handleClose={handleClose} handleStudentSearch={handleStudentSearch} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      <div
        className={`programs-list side-program ${nextData ? "show-sidebar" : ""
          }`}
        ref={programsListRef}
      >
        <div className="program-heading">
          <h2 className="font-bold text-lg text-md-2xl text-white">
            You have{" "}
            <strong className="text-black">{eligibleProgramList.length}</strong>{" "}
            Matching Programs from{" "}
            <strong className="text-black">{collegeName}</strong>
          </h2>
        </div>
        {eligibleProgramList.length === 0
          ? null
          : eligibleProgramList.map((program) => {
            return (
              <div className="program">
                <div className="body pt-5 px-5">
                  <h1 className="font-bold text-[15px] text-md-xl mb-2">
                    {program.program_name}
                  </h1>
                  <p className="max-two-line text-start text-[14px] mb-2">
                    {program.description}
                  </p>
                  <p className="text-lg mb-1">
                    Credentials :
                    <span className="font-bold"> {program?.credentials}</span>
                  </p>

                  <div className="duration-list flex flex-wrap justify-between lg:justify-start mt-3">
                    <p className="text-[14px] lg:text-[16px] flex flex-col  w-32 md:w-1/4 lg:w-fit md:pr-4 lg:pr-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#149449"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                        ></path>
                      </svg>
                      <strong className="mt-1">$ Tuition fee</strong>
                      <span className="text-[10px] lg:text-[12px]">
                        {" "}
                        {program?.min_tution_fee_per_semester}-
                        {program?.max_tution_fee}
                      </span>
                    </p>

                    <p className="text-[14px] lg:text-[16px] flex flex-col  w-32 md:w-1/4 lg:w-fit md:pr-4 lg:pr-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#149449"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                        ></path>
                      </svg>
                      <strong className="mt-1">$ Application fee</strong>
                      <span className="text-[10px] lg:text-[12px]">
                        {" "}
                        {program?.application_fee}
                      </span>
                    </p>

                    <p className="text-[14px] lg:text-[16px] flex flex-col  w-32 md:w-1/4 lg:w-fit md:pr-4 lg:pr-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#149449"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <strong className="mt-1">Duration</strong>
                      <span className="text-[10px] lg:text-[12px]">
                        {" "}
                        {`${program?.duration} Year
                        
                          `}

                        {/* ${program?.duration_sem_per_year
                          ? "(" + program?.duration_sem_per_year + " Sem)"
                          : ""
                          } */}
                      </span>
                    </p>

                    <p className="text-[14px] lg:text-[16px] flex flex-col  w-32 md:w-1/4 lg:w-fit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#149449"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                        ></path>
                      </svg>
                      <strong className="mt-1">Percentage Required</strong>
                      <span className="text-[10px] lg:text-[12px]">
                        {" "}
                        {program?.grade_score} <strong>%</strong>{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2 p-3 rounded mx-3 my-5 bg-[#eee]">
                  <div className="flex items-center">
                    <SlCalender
                      className="me-2"
                      style={{ width: 15, height: 15, color: "#7b7b7b" }}
                    />
                    <p>Program Intake:</p>
                  </div>
                  {program?.intake_id && formatIntakeId(program.intake_id).map((formattedIntake, index) => (
                    <>
                      <span className="w-3 h-3 rounded-full bg-[#000]"></span>
                      <div key={index}>{formattedIntake}</div>
                    </>
                  ))}
                </div>
                <div className="flex items-center gap-2 px-5 mb-3">
                  <div>
                    <b className="mr-2">State:</b>
                    {program.state}
                  </div>
                  <div>
                    <b className="mr-2">City:</b>
                    {program.city}
                  </div>
                </div>
                <p className="px-5">
                  <button
                    className="bg-[#19632f] hover:bg-[#065f46] text-white font-bold py-2 px-8 rounded-full capitalize"
                    onClick={() => enrollNow(program, program.program_id)}
                    id={`program_${program.program_id}`}
                    disabled={buttonDiable}
                  >
                    <span>Enroll Now</span>
                    <span className="hidden">
                      Loading...
                    </span>
                  </button>
                </p>
              </div>
            );
          })}

      </div>
    </>
  );
};

export default AgentProgramsList;
