import React from "react";
import Eligibleform from "../Components/Eligibleform";
import MultiStepForm from "../Components/MultiStepForm";
import WebsiteHome from "../Screens/WebsiteHome";

export default function WEligible() {
  return (
    <div className="w-full">
      <MultiStepForm />
      {/* <Eligibleform /> */}
    </div>
  );
}
