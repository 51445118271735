import Header from "../../common/Header/AgentHeader";
import Navbar from "../../common/Header/AgentNavbar";
import { useEffect } from "react";
import { useState } from "react";
import { getToken } from "../../../../helper/auth";
import axios from "axios";
import { requestForToken } from "../../../../firebase";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";


const AgentDashboard = ({ children, heading_title }) => {
  const [state, setState] = useState({
    isWait: true,
    agentPending: true,
    token: getToken("agent"),
  });
  const role = useLocation().pathname.split('/')[1]
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated') == null) {
      navigate('/agentlogin');
    }
    const config = { headers: { Authorization: `Bearer ${state.token}` } };
    requestForToken().then((token) => {
      axios
        .post(
          process.env.REACT_APP_NODE_URL + "/agent/verifyToken",
          { token },
          config
        )
        .then((res) => {
          if (res.data.details.agent.status != res.data.details.agent.status == "PENDING") {
            setState({
              ...state,
              isWait: false,
              agentPending: true,
            });
          } else {
            setState({
              ...state,
              isWait: false,
              agentPending: false,
            });
          }
        })
        .catch((err) => {
          setState({
            ...state,
            isWait: false,
            agentPending: true,
          });
        });
    });
  }, []);

  return (
    <>
      <Header agentPending={state.agentPending} />
      <main className="custom_side_bar">
        <Navbar heading_title={heading_title} />
        <div className="innerBox">
          <div style={{ minHeight: "85vh" }}>
            <Outlet />
          </div>
        </div>
        <ToastContainer />
      </main>
    </>
  );
};

export default AgentDashboard;
