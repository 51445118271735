import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import loader from "../../../images/loader.gif";
import ReactPaginate from "react-paginate";
const PAGE_SIZE = 10;

const ProgramIntakes = () => {
  const location = useLocation();
  const { collegeId } = location.state;
  const { countryId } = location.state;
  const [intakes, setIntakes] = useState([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [totalpage, settotalpage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(PAGE_SIZE);
  const [state, setState] = useState({
    isWaiting: false,
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const findIntakes = () => {
    setState({
      ...state,
      isWaiting: true,
    });

    axios
      .get(
        `${
          process.env.REACT_APP_NODE_URL
        }/v2/api/findIntakes/${countryId}/${collegeId}/${currentPage + 1}`
      )
      .then((response) => {
        setIntakes(response.data.details);
        settotalpage(response.data.totalPages);
        setState({
          ...state,
          isWaiting: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    findIntakes();
  }, []);

  const handlePageChange = (selectedPage) => {
    setcurrentPage(selectedPage.selected);
    findIntakes();
  };
  return (
    <>
      <div className="row min-height-vh-100">
        <div className="row p-4 sm:p-8">
          <div className="overflow-x-auto table-overflow mt-10">
            {state.isWaiting ? (
              <div>
                <center className="w-full my-10">
                  <img src={loader} alt="" />
                </center>
              </div>
            ) : intakes && intakes.length > 0 ? (
              <table className="w-full border-collapse table-auto">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-2 py-3 sm:px-4 sm:py-4">Program Name</th>
                    <th className="px-2 py-3 sm:px-4 sm:py-4">Year</th>
                    {months.map((month, index) => (
                      <th key={index} className="px-4 py-2 sm:px-6 sm:py-3">
                        {month}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {intakes.map((program, index) => (
                    <React.Fragment key={index}>
                      {program.intakes_data.map((intake, intakeIndex) => (
                        <tr key={`${index}-${intakeIndex}`}>
                          {intakeIndex === 0 && (
                            <td
                              className=" border px-6 py-3 sm:px-8 sm:py-4 capitalize"
                              rowSpan={program.intakes_data.length}
                            >
                              <div className="flex justify-between">
                                <h1 className="mr-5 font-bold text-l">
                                  {currentPage * itemsPerPage + index + 1}
                                </h1>
                                <div>{program.program_name}</div>
                              </div>
                            </td>
                          )}
                          <td className="border px-6 py-3 sm:px-8 sm:py-4">
                            {intake.year}
                          </td>
                          {months.map((month, monthIndex) => (
                            <td
                              key={monthIndex}
                              className="px-4 py-2 sm:px-6 sm:py-3"
                            >
                              {intake.months[monthIndex] ? (
                                <span className="text-xl text-green-500">
                                  ✔
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <div>
                  <p className="text-center p-5 text-red-500">
                    No data available.
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer pt-5 pb-20">
            <div className="pagination">
              <div className="pages">
              <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      breakLabel="..."
                      pageCount={totalpage}
                      onPageChange={handlePageChange}
                      initialPage={currentPage}
                      containerClassName="pagination"
                      activeClassName="selected"
                      renderOnZeroPageCount={null}
                      subContainerClassName="pages"
                      disableInitialCallback={true}
                      previousClassName={currentPage === 0 ? "disabled" : ""}
                      nextClassName={
                        currentPage === totalpage - 1 ? "disabled" : ""
                      }
                      previousLinkClassName={
                        currentPage === 0 ? "disabled-link" : ""
                      }
                      nextLinkClassName={
                        currentPage === totalpage - 1 ? "disabled-link" : ""
                      }
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramIntakes;
