/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { GrClose } from "react-icons/gr";
import {
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
//  
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loader from '../../../images/loader.gif';
import { AiFillSetting } from "react-icons/ai";

const AdminCommission = () => {
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPagination, setShowPagination] = useState(true);
  const [ModalCode, setModalCode] = useState(false);
  const [ModalCodeFilter, setModalCodeFilter] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    isWaiting: true,
    agents: [],
    students: [],
    totalPages: 0,
    currentPage: 1,
    filterActive: false,
    activeAgentDetails: null,
    onlyDetails: [],
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Agent Name", value: "Agent Name" },
    { label: "Agent Number", value: "Agent Number" },
    // { label: "Status", value: "Status" },
    { label: "City", value: "City" },
    { label: "State", value: "State" },
    { label: "Action", value: "Action" },


  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsAdminCommision")) || [
      "Sr.",
      "Agent Name",
      "Agent Number",
      // "Status",
      "Action",
      "City",
      "State"
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Agent Name",
    "Agent Number",
    // "Status",
    "Action",
    "City",
    "State",
  ]);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Agent Name",
      "Agent Number",
      // "Status",
      "Action",
      "City",
      "State",
    ]);
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      getPaginationData(state.currentPage, searchName);
    }, 300);

    return () => clearTimeout(delaySearch);
  }, []);

  const getPaginationData = (page, searchName) => {
    const agentId = searchParams.get("id");

    setState({
      ...state,
      isWaiting: true,
    });
    let status = searchParams.get("status") || "";
    let data;
    if (searchName == undefined) {
      data = { currentPage: page, status, searchName: "" };
    } else {
      data = { currentPage: page, status, searchName: searchName };
    }
    setState({
      ...state,
      isWaiting: true,
    });
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getagents", data)
      .then((res) => {
        setState({
          ...state,
          isWaiting: false,
          agents: res.data.details.agents,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
          isWaiting: false,
        });
        setisrotating(false)
      })
      .catch((err) => {
      });
  };


  const AsideBarAgentDetails = () => <></>;

  const handleSearch = () => {
    if (searchName === "") {
      setShowPagination(true);
      // getPaginationData(1, "")
    } else {
      setShowPagination(false);
    }
    getPaginationData(1, searchName);
  }

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns;

    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem("selectedColumnsAdminCommision", JSON.stringify(updatedColumns));
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem("selectedColumnsAdminCommision"));

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Agent Name",
        "Agent Number",
        // "Status",
        "Action",
        "City",
        "State"
      ]);
      setTempColumnsTable([
        "Sr.",
        "Agent Name",
        "Agent Number",
        // "Status",
        "Action",
        "City",
        "State"
      ]);
    }
  }, []);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Agent Name",
      "Agent Number",
      // "Status",
      "Action",
      "City",
      "State"
    ]);
  }, [])


  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsAdminCommision");
    setSelectedColumnsTable([
      "Sr.",
      "Agent Name",
      "Agent Number",
      // "Status",
      "Action",
      "City",
      "State"
    ]);
    setTempColumnsTable([
      "Sr.",
      "Agent Name",
      "Agent Number",
      // "Status",
      "Action",
      "City",
      "State"
    ]);
    // setModalCode(false);
  };

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem("selectedColumnsAdminCommision", JSON.stringify(uniqueSelectedColumns));

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  const startLoader = () => {
    setisrotating(true);
    getPaginationData();
    setSearchName("")
  };


  return (
    <>
      <ToastContainer />
      <div heading_title={"Agents List"}>
        <>
          <div className="row p-45 mob-padding commission">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
                <div className="setting_icon my-4 flex flex-row gap-3">
                  <div className="flex my-3">
                    <input
                      type="text"
                      className="border border-gray-300 px-4 rounded-md focus:outline-none"
                      placeholder="Search Agent By Name"
                      value={searchName}
                      style={{paddingBlock: '8.45px'}}
                      // onChange={(e) => setSearchName(e.target.value)}
                      onChange={(e) => {
                        setSearchName(e.target.value)
                      }}
                    />
                    <button
                      onClick={handleSearch}
                      className="custom_add_button w-full mt-0 bg-[#19632f] hover:bg-[#053815] text-white py-2 px-4 ms-3 rounded-md focus:outline-none"
                    >
                      Find
                    </button>

                  </div>
                  <div className="btn_outerxx mb-3 mt-5 flex justify-between">
                    <div className="reload-icon mr-2">
                      <i
                        className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                        onClick={startLoader}
                      />
                    </div>
                    <AiFillSetting
                      className="Bar_filter"
                      onClick={() => setModalCode(!ModalCode)}
                    />
                    {ModalCode ? (
                      <div className="modal_cover">
                        <div className="modal_inner select-col-popup">
                          <div className="header_modal">
                            <h1 className="capitalize font-bold text-lg mb-2">
                              select columns
                            </h1>
                            <p className="border-b pb-2 mb-2">
                              Select the column you want to display or hide.
                            </p>
                            <div
                              className="columns-popup"
                              style={{ justifyContent: "space-between" }}
                            >
                              {tableColumns.map((column, index) => {

                                if (
                                  selectedColumnsTable.includes(column.value)
                                ) {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        checked={tempColumnsTable.includes(column.value)}
                                        onChange={() => handleColumnCheckboxChange(column.value)}
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                } else {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        onClick={() => {
                                          selectedColumnsTable.push(
                                            column.value
                                          );
                                          setTempColumnsTable(
                                            selectedColumnsTable
                                          );
                                        }}
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                }
                              })}
                            </div>
                            <div className="button-part flex justify-end items-center gap-4">
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleResetAll}
                              >
                                Reset all
                              </button>
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleOk}
                              >
                                Ok
                              </button>
                            </div>
                            <GrClose
                              className="close"
                              onClick={() => setModalCode(false)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className=" mb-4 mt-4 agent-table table-border">
                  <div className="overflow-auto card  col-12 px-0 pt-0 pb-2  table_postioning table-overflow">
                    {state.isWaiting ? (
                      <div>
                        <center className="w-full my-10">
                          <img src={loader} alt="" />
                        </center>
                      </div>
                    ) : state.agents.length > 0 ? (
                      <table className="table-auto overflow-scroll w-full agent-table files-table">
                        <thead>
                          <tr>
                            {tableColumns.map((col) => {
                              if (selectedColumnsTable.includes(col.label)) {
                                return (
                                  <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                    {formatLabel(col.label)}
                                  </th>
                                );
                              }
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {state.agents?.map((agent, index) => {
                            return (
                              <tr>
                                {selectedColumnsTable.includes("Sr.") && (
                                  <td className="p-2 capitalize">
                                    <p className="text-xs font-weight-bold mb-0">
                                      {index + 1}
                                    </p>
                                  </td>
                                )}
                                {selectedColumnsTable.includes("Agent Name") && (
                                  <td className="p-1 border-2 ">
                                    <div className="flex jusify-center px-2 py-1">
                                      <div>
                                        <img
                                          width={"50px"}
                                          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
                                          className="avatar avatar-sm mr-2"
                                          alt="user1"
                                        />
                                      </div>
                                      <div className="d-flex flex-column justify-content-center">
                                        <h6
                                          className="mb-0 text-sm font-bolder capitalize"
                                        >
                                          <b>{agent.first_name}</b>
                                        </h6>
                                        <p className="text-xs text-secondary mb-0 lowercase">
                                          {agent.username}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                )}
                                {selectedColumnsTable.includes("Agent Number") && (
                                  <td className="p-2 capitalize">
                                    <span className="text-secondary text-xs font-weight-bold">
                                      {agent.phone}
                                    </span>
                                  </td>
                                )}

                                {selectedColumnsTable.includes("Phone") && (
                                  <td className="p-2 capitalize">
                                    <p className="text-sm text-gray-900">{agent?.phone || "--"}</p>
                                  </td>
                                )}

                                {selectedColumnsTable.includes("City") && (
                                  <td className="p-2 capitalize">
                                    <p className="text-sm text-gray-900">{agent?.city || "--"}</p>
                                  </td>
                                )}

                                {selectedColumnsTable.includes("State") && (
                                  <td className="p-2 capitalize">
                                    <p className="text-sm text-gray-900">{agent?.state || "--"}</p>
                                  </td>
                                )}

                                {selectedColumnsTable.includes("Action") && (
                                  <td className="align-middle  border-2">
                                    {agent?.StudentsDetailCount == 0 ?
                                      <Link
                                        className={`bg-[#6f6f6f] text-white capitalize text-secondary fw-bold text-xs p-2 rounded btn-cs cursor-not-allowed`}
                                        data-toggle="tooltip"
                                      >
                                        View Students
                                      </Link>
                                      :
                                      <Link
                                        to={"/admin/agent_students/" + agent._id}
                                        className={`bg-[#008000] text-white capitalize text-secondary fw-bold text-xs p-2 rounded btn-cs`}
                                        data-toggle="tooltip"
                                      >
                                        View Students
                                      </Link>
                                    }
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div>
                        <div>
                          <p className="text-center p-5 text-red-500">No data available.</p>
                        </div>
                        {/* <center className="w-full my-10">
                              <img src={loader} alt="" />
                            </center> */}
                      </div>
                    )}
                  </div>

                  {showPagination && (<div className="card-footer pb-0">
                    {/* pagination is here */}
                    <div className="pagination">
                      <div className="pages">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next"
                          onPageChange={(event) => {
                            getPaginationData(event.selected + 1);
                          }}
                          pageCount={state.totalPages}
                          previousLabel="prev"
                          renderOnZeroPageCount={null}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={0}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                        />
                      </div>
                    </div>
                  </div>)}

                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      {<AsideBarAgentDetails />}
    </>
  );
};

export default AdminCommission;
