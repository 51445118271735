/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../website/images/logo.png";
import Logoblack from "../../../images/logoblack.png";
import StudentAvatar from "../../../images/StudentAvatar.png";
import Gogl from "../../website/images/gogl.png";
import { MdAlternateEmail, MdLock } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrClose } from "react-icons/gr";
// Password Eye
import { Icon } from "react-icons-kit";
import { FaEnvelope, FaKey } from "react-icons/fa";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { FiEye, FiEyeOff } from "react-icons/fi";
import axios from "axios";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { authenticate } from "../../../helper/auth";
import AuthScreen from "../../student/Screens/Authentication/StudentAuthScreen";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// firebase
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseAuth } from "../../../firebase";
import ButtonPrimaryCopy from "../../../common/Buttons/ButtonPrimarycopy";
import AdminStudentImage from '../../../images/admin-student-screen.jpg';
const provider = new GoogleAuthProvider();

const StudentLoginForm = (props) => {
    const navigate = useNavigate();
    const [studentPrevent, setStudentPrevent] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pid = queryParams.get("pid");
    const sid = queryParams.get("sid");
    const etype = queryParams.get("eType");
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmNewPass, setShowConfirmNewPass] = useState(false);
    // console.log("pid", sid, pid);
    const [state, setState] = useState({
        username: "",
        password: "",
        submitProcessing: false,
        data: "",

        redirect: searchParams.get("redirect"),
        type: searchParams.get("type"),
        college: searchParams.get("college"),
        program: searchParams.get("program"),
        email: "",
        confirmpassword: "",
        newpassword: "",
    });
    const [buttonLoading, setButtonLoading] = useState({
        type: "FALSE",
    });

    // student forgot password screen
    const { studenttoken } = useParams();

    const toggleNewPassword = () => {
        setShowNewPass(!showNewPass);
    };

    const toggleConfirmPassword = () => {
        setShowConfirmNewPass(!showConfirmNewPass);
    };

    const handleInput = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    // student login api
    const LoginStudent = async () => {
        if (studentPrevent) {
            return;
        }
        setStudentPrevent(true);
        const { data, password } = state;
        if (data == "" || password == "")
            return toast.error("All fields are required");

        setButtonLoading({ type: "STUDENT_LOGIN" });
        const data2 = { data, password };
        const config = { "content-type": "application/json" };
        axios
            .post(process.env.REACT_APP_NODE_URL + "/student/login", data2)
            .then((res) => {
                setStudentPrevent(false);
                if (res.data.status == "0") {
                    toast.error(res.data.message);
                    setButtonLoading({ type: "FALSE" });
                    return;
                }
                authenticate(res, "student", () => {
                    // if (state.redirect == "true") {
                    // enroll API
                    let api_data = {
                        educationType: etype,
                        college_id: sid,
                        program_id: pid,
                    };
                    const config = {
                        headers: { Authorization: `Bearer ${res.data.details.token}` },
                    };

                    axios
                        .post(
                            `${process.env.REACT_APP_NODE_URL}/student/enroll`,
                            api_data,
                            config
                        )
                        .then((response) => {
                            // setStudentPrevent(false);
                            setButtonLoading({ type: "FALSE" });
                            window.location.href = "/student/enrolled";
                            localStorage.setItem("isAuthenticated", true);
                        })
                        .catch((err) => {
                            setState({
                                ...state,
                                submitProcessing: false,
                            });
                        });
                    // } else {
                    //   setButtonLoading({ type: "FALSE" })
                    //   window.location.href = "/student/";
                    // }
                });
                // authenticate with token
                // redirectLogin3
            })
            .catch((err) => { });
    };

    const signin = () => {
        setButtonLoading({ type: "STUDENT_GOOGEL_LOGIN" });
        signInWithPopup(firebaseAuth, provider)
            .then((res) => {
                const userDtl = res.user.providerData[0];
                const data = {
                    email: userDtl.email,
                    firstName: userDtl.displayName.split(" ")[0],
                    lastName: userDtl.displayName.split(" ")[1],
                    phone: userDtl.phoneNumber,
                    loginProvider: "google",
                    uid: userDtl.uid,
                    tokens: {
                        idToken: res._tokenResponse.oauthIdToken,
                    },
                };
                const config = { "content-type": "application/json" };
                axios
                    .post(
                        process.env.REACT_APP_NODE_URL + "/student/student_google_login",
                        data
                    )
                    .then((res) => {
                        if (res.data.status == "0") {
                            setButtonLoading({ type: "FALSE" });
                            toast.error(res.data.message);
                            return;
                        }
                        authenticate(res, "student", () => {
                            let api_data = {
                                // student_id: "anything",
                                college_id: sid,
                                program_id: pid,
                                educationType: etype,
                            };
                            const config = {
                                headers: {
                                    Authorization: `Bearer ${res.data.details.token}`,
                                },
                            };
                            axios
                                .post(
                                    `${process.env.REACT_APP_NODE_URL}/student/enroll`,
                                    api_data,
                                    config
                                )
                                .then((response) => {
                                    setButtonLoading({ type: "FALSE" });
                                    if (response.data.status == "0") {
                                        toast.error(response.data.message);
                                    } else {
                                        toast.success(response.data.message);
                                    }
                                    window.location.href = "/student/enrolled";
                                    localStorage.setItem("isAuthenticated", true);
                                });
                            // } else {
                            //   setButtonLoading({ type: "FALSE" })
                            //   window.location.href = "/student/";
                            //   localStorage.setItem('isAuthenticated', true);
                            // }
                        });
                        // window.location.href = "/student/login"
                    })
                    .catch((err) => {
                        setButtonLoading({ type: "FALSE" });
                        if (err.response.data.name == "ValidationError") {
                            let errors = err.response.data.details.error;
                            let msg = "";
                            for (const key in errors) {
                                msg += errors[key] += "\n";
                            }
                            toast.error(msg);
                            setState({
                                ...state,
                                submitProcessing: false,
                            });
                            return;
                        }
                        setState({
                            ...state,
                            submitProcessing: false,
                        });
                        toast.error(err.response.data.message);
                    });
            })
            .catch((err) => {
                setButtonLoading({ type: "FALSE" });
                toast.error("Login Unsuccessful");
                setState({
                    ...state,
                    submitProcessing: false,
                });
            });
    };

    // Student Register
    const RegisterNow = async (values) => {
        const { email, password, confirmPassword, firstName, lastName, phone } =
            values;
        if (password !== confirmPassword)
            return toast.error("Both passwords should be same");

        setButtonLoading({ type: "STUDENT_REGISTER" });
        const data = { email, password, firstName, lastName, phone };
        const config = { "content-type": "application/json" };

        axios
            .post(process.env.REACT_APP_NODE_URL + "/student/register", data)
            .then((res) => {
                if (res.data.status === "0") {
                    // console.log("checkkkkkk111",res.data.message)
                    toast.error(res.data.message);
                    setButtonLoading({ type: "FALSE" });
                    return;
                }
                if (state.redirect === "true") {
                    window.location.href =
                        "/" + state.college + "&program=" + state.program;
                    toast.success(res.data.message);
                } else {
                    toast.success(
                        "Registration successful.. Please check your email and verify your account. "
                    );
                    setstudentRegisterSide(false);
                    setagentLoginSide(true);
                }
            })
            .catch((err) => {
                setButtonLoading({ type: "FALSE" });
                if (err.response.data.name === "ValidationError") {
                    let errors = err.response.data.details.error;
                    let msg = "";
                    for (const key in errors) {
                        msg += errors[key] += "\n";
                    }
                    toast.error(msg);
                    return;
                }
                toast.error(err.response.data.message);
            });
    };
    const ForgotStudent = async () => {
        if (state.newpassword !== state.confirmpassword) {
            toast.error("Both passwords should be same");
            return;
        }
        if (state.newpassword === "" || state.confirmpassword === "") {
            toast.error("Both passwords are required");
            return;
        }
        setState({
            ...state,
            submitProcessing: true,
        });
        const { data, password } = state;
        const config = { headers: { Authorization: `Bearer ${studenttoken}` } };
        const data2 = { newPassword: state.confirmpassword };
        axios
            .post(
                process.env.REACT_APP_NODE_URL + "/student/setnewpassword",
                data2,
                config
            )
            .then((res) => {
                if (res.data.status === "0") {
                    toast.error(res.data.message);

                    return;
                }
                toast.success(res.data.message);
                navigate("/studentlogin");
                // redirect
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    const SendStudentForgotPasswordEmail = async () => {
        if (state.email === "") {
            toast.error("Email is required");
            return;
        }
        setButtonLoading({ type: "FORGOT_EMAIL_SEND" });
        const data = { email: state.email };
        axios
            .post(process.env.REACT_APP_NODE_URL + "/student/forgotpassword", data)
            .then((res) => {
                setButtonLoading({ type: "FALSE" });
                if (res.data.status === "0") {
                    toast.error(res.data.message);
                    return;
                }
                toast.success(res.data.message);
                setStudentForgotPassword(false);
                setState({
                    ...state,
                    email: "",
                });
                // authenticate with token
                // redirect
            })
            .catch((err) => {
                setButtonLoading({ type: "FALSE" });
                toast.error(err.response.data.message);
            });
    };
    const ValidationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        phone: Yup.string()
            .min(4, "Phone number is required")
            .required("Phone number is required"),
        email: Yup.string()
            .email("Invalid email address format")
            .required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be 6 characters at minimum")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .min(6, "Confirm Password must be 6 characters at minimum")
            .required("Confirm Password is required"),
    });
    // password eye Toggle for Both Student and Agent Screen
    const [typeConfirm, setTypeConfirm] = useState("password");
    const [iconConfirm, setIconConfirm] = useState(eye);
    const [typeConfirmNew, setTypeConfirmNew] = useState("password");
    const [iconConfirmNew, setIconConfirmNew] = useState(eye);
    const handleToggleConfirm = () => {
        if (typeConfirm === "password") {
            setIconConfirm(eyeOff);
            setTypeConfirm("text");
        } else {
            setIconConfirm(eye);
            setTypeConfirm("password");
        }
    };
    const handleToggleConfirmTwo = () => {
        if (typeConfirmNew === "password") {
            setIconConfirmNew(eyeOff);
            setTypeConfirmNew("text");
        } else {
            setIconConfirmNew(eye);
            setTypeConfirmNew("password");
        }
    };
    // Modal
    const [StudentForgotPassword, setStudentForgotPassword] = useState(false);
    // Login Method Tabs Active Button Methods
    const [agentLoginSide, setagentLoginSide] = useState(false);
    const [studentLoginSide, setstudentLoginSide] = useState(true);
    const [studentRegisterSide, setstudentRegisterSide] = useState(false);
    const [click1, setclick1] = useState();
    const [click2, setclick2] = useState("click1");

    useEffect(() => {
        if (searchParams.get("user") == "student") {
            setstudentLoginSide(true);
            setagentLoginSide(false);
            setclick1("");
            setclick2("click1");
        }
    }, []);

    const handleLoginClick = () => {
        setstudentRegisterSide(false);
        (setstudentLoginSide(!studentLoginSide))

    };

    const handleRegisterClick = () => {
        setstudentRegisterSide(!studentRegisterSide);
        setstudentLoginSide(false);
        // setStudentRegisterSide(true);
        // setStudentLoginSide(false);
    };

    if (studenttoken) {
        return (
            <>
                <ToastContainer />
                <div className="new-main">
                    <div className="lg:flex items-center">
                        <div className="new-right flex items-center w-full justify-center items-center">
                            <div className="rt-inner w-full flex justify-center items-center">

                                <div className="inner-form-data rounded-lg bg-white p-10 px-4 mx-auto relative lg:px-6">
                                    <Link to="/" className="flex w-fit mx-auto">
                                        <img className="w-60 mx-auto" src={Logo} alt="" />
                                    </Link>
                                    {studenttoken && (
                                        <div className="col-12">
                                            <h1 className="text-xl mt-5 mb-3 font-black text-center">
                                                Set New Password
                                            </h1>
                                            <div className="sm:overflow-hidden">
                                                <div className="space-y-6 pb-2">
                                                    <div className="">
                                                        <div className="col-span-3 sm:col-span-2">
                                                            <label
                                                                htmlFor="company-website"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                New Password
                                                            </label>
                                                            <div className="mt-1 flex rounded-md pass-input">
                                                                <input
                                                                    type={showNewPass ? "text" : "password"}
                                                                    name="newpassword"
                                                                    value={state.newpassword}
                                                                    onChange={handleInput}
                                                                    id="company-website"
                                                                    className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                                                    placeholder="New Password"
                                                                />
                                                                <button
                                                                    className="eye-btn"
                                                                    onClick={toggleNewPassword}
                                                                >
                                                                    {showNewPass ? <FiEyeOff /> : <FiEye />}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label
                                                            htmlFor="about"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Confirm Password
                                                        </label>
                                                        <div className="mt-1 pass-input">
                                                            <input
                                                                type={showConfirmNewPass ? "text" : "password"}
                                                                name="confirmpassword"
                                                                value={state.confirmpassword}
                                                                onChange={handleInput}
                                                                className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="Confirm Password"
                                                            />
                                                            <button
                                                                className="eye-btn"
                                                                onClick={toggleConfirmPassword}
                                                            >
                                                                {showConfirmNewPass ? <FiEyeOff /> : <FiEye />}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right button mt-5">
                                                    <button
                                                        type="button"
                                                        onClick={ForgotStudent}
                                                        className="bg-gradient-primary inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 sm:py-4 rounded-lg px-4 text-sm font-medium text-white shadow-sm"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-center pt-2 px-lg-2 px-1 mt-4">
                                                <p className="mb-4 text-sm mx-auto flex flex-col">
                                                    Want to login?
                                                    <span
                                                        onClick={() => navigate("/studentlogin")}
                                                        className="pl-2 text-info text-gradient font-bold cursor-pointer underline"
                                                    >
                                                        Login
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <ToastContainer />
            <div className="new-main login-signup">
                <div className="lg:flex items-center signup-login">
                    <div className="lg:w-full p-0 new-right flex items-center login-form">
                        <div className="rt-inner w-full ">
                            <div className="side-image">
                                <img src={AdminStudentImage} alt="side-image" />
                            </div>
                            <div className="inner-form-data rounded-lg bg-white p-10 px-4 mx-auto relative lg:px-6 register">

                                <div className="admin_logo sign_up flex flex-col justify-center mt-3 mb-5">
                                    <Link to="/">
                                        {" "}
                                        <img className="w-50 lg:w-50 mx-auto" src={Logoblack} />
                                    </Link>
                                    {studentLoginSide && <h2 className="text-center mt-6 xl:mt-8 mb-2 text-lg">Student Login</h2>}
                                </div>

                                {studentLoginSide ? (
                                    <AuthScreen>
                                        <div className="log-main">
                                            <div className="input-content rounded-lg">
                                                <div className="custom_inputs flex items-center gap-4 relative">
                                                    <div
                                                        className="icon_for absolute left-3"
                                                        style={{ top: 16, zIndex: 9 }}
                                                    >
                                                        <MdAlternateEmail
                                                            style={{ fill: "#bdbdbd", width: 17, height: 17 }}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <label className="custom-field one w-full">
                                                            {/* <span className="placeholder">Email</span> */}
                                                            <input
                                                                type="text"
                                                                name="data"
                                                                value={state.data}
                                                                onChange={handleInput}
                                                                style={{ paddingLeft: 34 }}
                                                                className="w-full"
                                                                placeholder="Enter Your Email"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-content mt-7 rounded-lg">
                                                <div className="custom_inputs flex items-center gap-4 relative">
                                                    <div
                                                        className="icon_for absolute left-3"
                                                        style={{ top: 16, zIndex: 9 }}
                                                    >
                                                        <MdLock
                                                            style={{ fill: "#bdbdbd", width: 17, height: 17 }}
                                                        />
                                                    </div>
                                                    <div className="w-full">
                                                        <label className="custom-field one w-full">
                                                            {/* <span className="placeholder">Password</span> */}
                                                            <input
                                                                type={typeConfirmNew}
                                                                name="password"
                                                                value={state.password}
                                                                onChange={handleInput}
                                                                style={{ paddingLeft: 34 }}
                                                                className="w-full"
                                                                placeholder="Enter Password"
                                                            />
                                                        </label>
                                                        <div className="field_eye_view">
                                                            <div
                                                                className="icon icon-eye"
                                                                onClick={handleToggleConfirmTwo}
                                                            >
                                                                <Icon icon={iconConfirmNew} size={20} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-2  mt-2 rounded-lg">
                                                <div className="flex  gap-2">
                                                    <div className="ml-auto">
                                                        <Link
                                                            to=""
                                                            onClick={() =>
                                                                setStudentForgotPassword(!StudentForgotPassword)
                                                            }
                                                            className="text-black underline cursor-pointer text-sm"
                                                        >
                                                            Forgot Password?
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <ButtonPrimaryCopy
                                                id="bt"
                                                title={"Login"}
                                                onclick={LoginStudent}
                                                loading={buttonLoading.type == "STUDENT_LOGIN"}
                                                className="mt-2"
                                            />
                                            <button
                                                onClick={signin}
                                                id="google_login_a"
                                                className="shadow w-full justify-center p-2 rounded flex items-center gap-2 mt-5"
                                                disabled={buttonLoading.type === "STUDENT_GOOGEL_LOGIN"}
                                            >
                                                {buttonLoading.type == "STUDENT_GOOGEL_LOGIN" ? (
                                                    <div className="flex items-center justify-center">
                                                        <svg
                                                            class="h-5 w-5 animate-spin"
                                                            viewBox="3 3 18 18"
                                                        >
                                                            <path
                                                                class="fill-gray-200"
                                                                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                                            ></path>
                                                            <path
                                                                class="fill-gray-800"
                                                                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <img src={Gogl} alt="" />
                                                        Login with Google
                                                    </>
                                                )}
                                            </button>
                                            <p className="mt-3 text-center text-sm flex justify-center items-center">
                                                Don’t have an account ?{" "}
                                                <p
                                                    style={{ cursor: "pointer" }}
                                                    // onClick={() => {
                                                    // setstudentRegisterSide(!studentRegisterSide);
                                                    // setstudentLoginSide(false);
                                                    // }}
                                                    onClick={handleRegisterClick}
                                                    className="text-black underline cursor-pointer text-sm ms-6"
                                                >
                                                    Register
                                                </p>
                                            </p>
                                        </div>
                                    </AuthScreen>
                                ) : (
                                    ""
                                )}
                                {StudentForgotPassword && (
                                    <div className="modal_cover add_college_popup new_ccs sign_up ">
                                        <div className="modal_inner relative">
                                            <div className="header_modal login-part-modal">
                                                <GrClose
                                                    style={{
                                                        position: "absolute",
                                                        fontSize: "18px",
                                                        height: "32px",
                                                        width: "32px",
                                                    }}
                                                    className="close cursor-pointer text-base"
                                                    onClick={() => setStudentForgotPassword(false)}
                                                />
                                                <h4 className="my-2 text-center font-bold text-xl mb-10">
                                                    Forgot Your Password
                                                </h4>
                                            </div>
                                            <AuthScreen>
                                                <div className="flex flex-col justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                                                <div className="space-y-6 bg-white px-4 py-2 sm:p-2">
                                                                    <div className="">
                                                                        <div className="col-span-3 sm:col-span-2 relative">
                                                                            <div
                                                                                className="icon_for mt-5 absolute left-3"
                                                                                style={{ top: 19, left: 7, zIndex: 9 }}
                                                                            >
                                                                                <FaEnvelope
                                                                                    style={{
                                                                                        fill: "#bdbdbd",
                                                                                        width: 17,
                                                                                        height: 17,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <label
                                                                                htmlFor="company-website"
                                                                                className="block text-sm font-medium text-gray-700"
                                                                            >
                                                                                Email
                                                                            </label>
                                                                            <div className="mt-1 flex rounded-md shadow-sm">
                                                                                <input
                                                                                    type="email"
                                                                                    name="email"
                                                                                    value={state.email}
                                                                                    onChange={handleInput}
                                                                                    style={{ paddingLeft: 30 }}
                                                                                    id="company-website"
                                                                                    className="block w-full flex-1 border-gray-300 focus:border-black border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                                                                    placeholder="Enter your Email"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-3 text-right sm:px-6 submit_sign flex justify-center">
                                                                    <ButtonPrimaryCopy
                                                                        title={"Submit"}
                                                                        onclick={SendStudentForgotPasswordEmail}
                                                                        loading={
                                                                            buttonLoading.type == "FORGOT_EMAIL_SEND"
                                                                        }
                                                                        className="w-fitcontent"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AuthScreen>
                                        </div>
                                    </div>
                                )}
                                {studentRegisterSide ? (
                                    <AuthScreen>
                                        <>
                                            <div className="part-cont relative text-center my-3">
                                                <h2 className="text-center mt-3 mb-4 text-lg">Student Register</h2>
                                            </div>
                                            <div className="row">
                                                <div className="">
                                                    <div className="mt-5 md:col-start-2 md:mt-0 m-auto w-full lg:w-12/12">
                                                        <div className="">
                                                            <div className="form-login">
                                                                <div className="">
                                                                    <Formik
                                                                        initialValues={{
                                                                            email: "",
                                                                            password: "",
                                                                            firstName: "",
                                                                            lastName: "",
                                                                            phone: "",
                                                                            confirmPassword: "",
                                                                        }}
                                                                        validationSchema={ValidationSchema}
                                                                        onSubmit={(values) => {
                                                                            RegisterNow(values);
                                                                        }}
                                                                    >
                                                                        {({
                                                                            touched,
                                                                            errors,
                                                                            isSubmitting,
                                                                            values,
                                                                        }) => {
                                                                            return (
                                                                                <Form>
                                                                                    <div className="flex">
                                                                                        <div className="m-2 w-6/12">
                                                                                            <label>First Name</label>
                                                                                            <Field
                                                                                                type="text"
                                                                                                className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.firstName &&
                                                                                                    errors.firstName
                                                                                                    ? "is-invalid"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                placeholder="firstName"
                                                                                                aria-label="firstName"
                                                                                                aria-describedby="firstName-addon"
                                                                                                name="firstName"
                                                                                            />
                                                                                            <ErrorMessage
                                                                                                component="div"
                                                                                                name="firstName"
                                                                                                className="invalid-feedback"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="m-2 w-6/12">
                                                                                            <label>Last Name</label>
                                                                                            <Field
                                                                                                type="text"
                                                                                                className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.lastName &&
                                                                                                    errors.lastName
                                                                                                    ? "is-invalid"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                placeholder="lastName"
                                                                                                aria-label="lastName"
                                                                                                aria-describedby="lastName-addon"
                                                                                                name="lastName"
                                                                                            />
                                                                                            <ErrorMessage
                                                                                                component="div"
                                                                                                name="lastName"
                                                                                                className="invalid-feedback"
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="m-2">
                                                                                        <label>Phone</label>
                                                                                        <Field
                                                                                            type="text"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.phone && errors.phone
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                            placeholder="Phone"
                                                                                            aria-label="Phone"
                                                                                            aria-describedby="phone-addon"
                                                                                            name="phone"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            component="div"
                                                                                            name="phone"
                                                                                            className="invalid-feedback"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="m-2">
                                                                                        <label>Email</label>
                                                                                        <Field
                                                                                            type="email"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.email && errors.email
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                            placeholder="Email"
                                                                                            aria-label="Email"
                                                                                            aria-describedby="email-addon"
                                                                                            name="email"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            component="div"
                                                                                            name="email"
                                                                                            className="invalid-feedback"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="m-2">
                                                                                        <label>Password</label>
                                                                                        <Field
                                                                                            type="password"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.password &&
                                                                                                errors.password
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                            placeholder="Password"
                                                                                            aria-label="Password"
                                                                                            aria-describedby="password-addon"
                                                                                            name="password"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            component="div"
                                                                                            name="password"
                                                                                            className="invalid-feedback"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="m-2">
                                                                                        <label>Confirm Password</label>
                                                                                        <Field
                                                                                            type="password"
                                                                                            className={`block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm form-control ${touched.confirmPassword &&
                                                                                                errors.confirmPassword
                                                                                                ? "is-invalid"
                                                                                                : ""
                                                                                                }`}
                                                                                            placeholder="Confirm Password"
                                                                                            aria-label="confirmPassword"
                                                                                            aria-describedby="confirmPassword-addon"
                                                                                            name="confirmPassword"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            component="div"
                                                                                            name="confirmPassword"
                                                                                            className="invalid-feedback"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="text-center mt-5 px-2">
                                                                                        <ButtonPrimaryCopy
                                                                                            title={"Register"}
                                                                                            loading={
                                                                                                buttonLoading.type ==
                                                                                                "STUDENT_REGISTER"
                                                                                            }
                                                                                            type="submit"
                                                                                            className="log-btn w-full py-2 sm:py-3 text-white mt-2 rounded-lg"
                                                                                        />
                                                                                        {/* <button type="submit" className="bg-gradient-primary text-white px-4 py-1 mt-4 mb-0 text-white rounded-full">Register</button> */}
                                                                                    </div>
                                                                                </Form>
                                                                            );
                                                                        }}
                                                                    </Formik>
                                                                </div>
                                                                <div className="text-center pt-2 px-lg-2 px-1">
                                                                    <p className="mb-4 text-sm mx-auto">
                                                                        Already have an account?
                                                                        <span
                                                                            // onClick={() =>
                                                                            //   setstudentRegisterSide(false)(setstudentLoginSide(!studentLoginSide))}
                                                                            onClick={handleLoginClick}
                                                                            className="cursor-pointer font-bold text-base"
                                                                        >
                                                                            {" "}
                                                                            Login
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </AuthScreen>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default StudentLoginForm;