import axios from "axios";
import React, { useEffect, useState } from "react";
import { getToken } from "../../../helper/auth";
import { GrClose } from "react-icons/gr";
import loader from "../../../images/loader.gif";
import { FaEye } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StudentDocumentsPopup = ({
  sId,
  fId,
  fileID,
  country,
  closeModalCode,
  getPaginationData,
  paginationState
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDocumentTitles, setSelectedDocumentTitles] = useState([]);
  const [approveMOdal, setapproveMOdal] = useState(false);
  const [declineModal, setdeclineModal] = useState(false);
  const [viewUrl, setviewUrl] = useState();
  const [state, setState] = useState({
    isWaiting: true,
    adminToken: getToken("admin"),
    baseUrl: "",
    popup: false,
    fileId: null,
    documentsList: [],
  });

  const fetchData = () => {
    if (sId) {
      const config = {
        headers: {
          Authorization: `Bearer ${state.adminToken}`,
        },
      };
      axios
        .get(process.env.REACT_APP_NODE_URL + "/student?id=" + sId, config)
        .then((res) => {
          axios
            .post(
              process.env.REACT_APP_NODE_URL + "/student/getdocuments",
              { fileId: fId, student_id: sId },
              config
            )
            .then((documentsResponse) => {
              setState({
                ...state,
                isWaiting: false,
                baseUrl: res.data.details.baseUrl,
                documentsList: documentsResponse.data.details.documents,
              });
            });
        });
    } else {
      setState({
        ...state,
        isWaiting: false,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [sId, fId, state.adminToken]);


  const hasDocumentsUnderVerification = state.documentsList.some(
    (document) => document.document_status === "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION"
  );

  const handleCheckAll = () => {
    if (hasDocumentsUnderVerification) {
      if (selectAll) {
        setSelectedDocumentTitles([]);
      } else {
        const allDocumentTitles = state.documentsList
          .filter((document) => document.document_status === "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION")
          .map((document) => document.document_title);
        setSelectedDocumentTitles(allDocumentTitles);
      }
      setSelectAll(!selectAll);
    } else {
      console.log("No documents with status OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION");
    }
  };

  const handleCheckboxChange = (documentTitle) => {
    if (selectedDocumentTitles.includes(documentTitle)) {
      setSelectedDocumentTitles((prevTitles) =>
        prevTitles.filter((title) => title !== documentTitle)
      );
    } else {
      setSelectedDocumentTitles((prevTitles) => [...prevTitles, documentTitle]);
    }
  };

  const approveall = () => {
    setState({
      ...state,
      isWaiting: true,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${state.adminToken}`,
      },
    };
    const data = {
      documentTitle: selectedDocumentTitles,
      studentId: sId,
      enrollid: fId,
      status: "APPROVED",
      country: country,
    };

    setapproveMOdal(false)

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/v2/api/changeDocumentStatus",
        data,
        config
      )
      .then((res) => {
        setapproveMOdal(false);
        if (res.data.status === "1") {
          setSelectedDocumentTitles([]);
          fetchData();
          closeModalCode();
          // add history
          const addHistoryData = {
            userRole: "Admin",
            comment: "Documments Approved Successfully",
            fileId: fileID,
          };

          axios.post(
            process.env.REACT_APP_NODE_URL + "/v2/api/addHistory",
            addHistoryData
          );
          return;
        }
        toast.error(res.data.msg);
        setState({
          ...state,
          isWaiting: false,
        });
      });
    getPaginationData(paginationState);
    // }
  };

  const rejectall = () => {
    setState({
      ...state,
      isWaiting: true,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${state.adminToken}`,
      },
    };
    const data = {
      documentTitle: selectedDocumentTitles,
      studentId: sId,
      enrollid: fId,
      status: "REJECTED",
      country: country,
    };

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/v2/api/changeDocumentStatus",
        data,
        config
      )
      .then((res) => {
        setdeclineModal(false);
        if (res.data.status === "1") {
          fetchData();
          closeModalCode();
          setSelectedDocumentTitles([]);
          // add history
          const addHistoryData = {
            userRole: "Admin",
            comment: "Documents Rejected Successfully",
            fileId: fileID,
          };

          axios.post(
            process.env.REACT_APP_NODE_URL + "/v2/api/addHistory",
            addHistoryData
          );
          return;
        }
        toast.error(res.data.msg);
        setState({
          ...state,
          isWaiting: false,
        });
      });
    getPaginationData(paginationState);
    setdeclineModal(false);
  };

  const approvePop = () => {
    setapproveMOdal(true);
  };

  const rejectPop = () => {
    setdeclineModal(true);

  };

  const isPdf = (url) => {
    return url.toLowerCase().endsWith(".pdf");
  };

  const ViewDocument = (e, viewUrl) => {
    e.preventDefault();
    setviewUrl(viewUrl);
    setModalOpen(true);
  };

  const downloadSelectedDocuments = async () => {
    if (selectedDocumentTitles.length > 0) {
      for (const documentTitle of selectedDocumentTitles) {
        const selectedDocument = state.documentsList.find(
          (document) => document.document_title === documentTitle
        );
        if (selectedDocument) {
          const downloadLink = state.baseUrl + selectedDocument.document_url;
          try {
            const response = await fetch(downloadLink);
            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = selectedDocument.document_title;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            toast.error("Error downloading document. Please try again.");
          }
        }
      }
    } else {
      toast.info("Please select documents to download.");
    }
  };

  return (
    <>
      {isModalOpen ? (
        <div className="z-[9999] fixed top-0 left-0 h-full w-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative p-4 m-5 bg-white h-[80vh] w-[80vw] overflow-y-auto">
            <GrClose
              className="close absolute top-0 right-0 text-gray-600 cursor-pointer"
              onClick={() => setModalOpen(false)}
            />
            <div className="flex items-center justify-center">
              {viewUrl && (
                <>
                  {isPdf(viewUrl) ? (
                    <iframe
                      src={viewUrl}
                      title="PDF Preview"
                      style={{ height: "75vh", width: "75vw" }}
                    />
                  ) : (
                    <img src={viewUrl} alt="Document Preview" />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {approveMOdal ? (
        <div className="z-[9999] fixed top-0 left-0 h-full w-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative bg-white p-4 rounded-lg max-h-[80vh] max-w-[80vw] overflow-y-auto">
            <GrClose
              className="close absolute top-0 right-0 text-gray-600 cursor-pointer"
              onClick={() => setapproveMOdal(false)}
            />
            <h1 className="capitalize font-bold text-lg my-5">
              Are you sure you want to Approve?
            </h1>
            <div className="button-part flex justify-end items-center gap-4">
              <button
                className="py-2 px-6"
                type="btn"
                onClick={() => setapproveMOdal(false)}
              >
                No
              </button>
              <button
                className="py-2 px-6"
                type="btn"
                onClick={approveall}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {declineModal ? (
        <div className="z-[9999] fixed top-0 left-0 h-full w-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative bg-white p-4 rounded-lg max-h-[80vh] max-w-[80vw] overflow-y-auto">
            <GrClose
              className="close absolute top-0 right-0 text-gray-600 cursor-pointer"
              onClick={() => setdeclineModal(false)}
            />
            <h1 className="capitalize font-bold text-lg my-5">
              Are you sure you want to Decline?
            </h1>

            <div className="button-part flex justify-end items-center gap-4">
              <button
                className="py-2 px-6"
                type="btn"
                onClick={() => setdeclineModal(false)}
              >
                No
              </button>
              <button
                className="py-2 px-6"
                type="btn"
                onClick={() => rejectall()}
              >
                Yes
              </button>
            </div>
          </div>
        </div >
      ) : (
        ""
      )}
      <div className="modal_cover">
        <div className="modal_inner">
          <div className="header_modal">
            <GrClose
              className="close"
              onClick={() => {
                closeModalCode();
              }}
            />
            {state.isWaiting ?
              <img src={loader} alt="" /> :
              <div className="student-table">
                <>
                  <div className={`agentDetailsAsideBar enrolled active`}>
                    <table className="table-responsive">
                      <h2 className="text-2xl font-bold">Documents</h2>
                      <div className="tabDetail table-overflow docs">
                        <table className="table-fixed w-full p-2 pt-0">
                          <div className="flex justify-end align-center">
                            {hasDocumentsUnderVerification && (
                              <>
                                <span className="text-gray-800 mr-2">Select All</span>
                                <input
                                  type="checkbox"
                                  id="selectAllCheckbox"
                                  className="mr-4 form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                                  onChange={handleCheckAll}
                                  checked={selectAll}
                                />
                              </>
                            )}
                          </div>
                          <tbody>
                            {state?.documentsList?.map((document, DocIndex) => {
                              return (
                                <tr key={DocIndex} className="flex px-2 docRow">
                                  <td className="flex-1 mr-8 items-center">
                                    <span className="text-gray-800 text-[13px]">
                                      {document.document_title}
                                    </span>
                                  </td>
                                  <td className="flex-1 items-center">
                                    {document.document_isRequired == true ?
                                      <span className="text-red-500 text-[13px]">
                                        Required
                                      </span>
                                      :
                                      <span className="text-gray-500 text-[13px]">
                                        Optionally
                                      </span>
                                    }
                                  </td>
                                  <td className="flex items-center justify-end">
                                    {document.document_status === "PENDING" ||
                                      document.document_status === "UPLOADED" ? (
                                      <span className="text-orange-500 text-[13px]">PENDING</span>
                                    ) : document.document_status ===
                                      "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ? (
                                      <>
                                        <div className="Verify_status text-green text-[13px]">
                                          Verify
                                        </div>
                                        <div className="flex items-center">
                                          <input
                                            type="checkbox"
                                            id={`myCheckbox${DocIndex}`}
                                            className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                                            onChange={() =>
                                              handleCheckboxChange(document.document_title)
                                            }
                                            checked={selectedDocumentTitles.includes(
                                              document.document_title
                                            )}
                                          />
                                        </div>
                                        <span
                                          className="flex items-center justify-end downloadSvg"
                                          title="Download"
                                          onClick={(e) =>
                                            ViewDocument(
                                              e,
                                              state.baseUrl + document.document_url
                                            )
                                          }
                                        >
                                          <FaEye className="text-[#9a3412]" />
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {document.document_status === "APPROVED" ? (
                                          <div className="approved_status text-green-500 text-[13px]">
                                            Approved
                                          </div>
                                        ) : (
                                          <div className="declined_status text-red-500 text-[13px]">
                                            Rejected
                                          </div>
                                        )}
                                        <span
                                          className="flex items-center justify-end downloadSvg"
                                          title="Download"
                                          onClick={(e) =>
                                            ViewDocument(
                                              e,
                                              state.baseUrl + document.document_url
                                            )
                                          }
                                        >
                                          <FaEye className="text-[#9a3412]" />
                                        </span>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <div className="flex items-center justify-center mt-4">
                            {selectedDocumentTitles.length > 0 && (
                              <>
                                <button
                                  onClick={approvePop}
                                  className="bg-[#1a7335] text-white px-4 py-2 rounded-md mr-2"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={rejectPop}
                                  className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
                                >
                                  Decline
                                </button>
                                <button
                                  className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                                  onClick={downloadSelectedDocuments}
                                >
                                  Download
                                </button>
                              </>
                            )}
                          </div>
                        </table>
                      </div>
                    </table>
                  </div>
                </>
              </div >
            }
          </div>
        </div>
      </div >
    </>
  );
};

export default StudentDocumentsPopup;
