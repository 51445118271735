import axios from "axios";
import { useEffect, useState } from "react";
import {
  Link,
  redirect,
  useParams,
} from "react-router-dom";
import { authenticate, getToken } from "../../../helper/auth";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import loader from "../../../images/loader.gif";
import { GrClose } from "react-icons/gr";

// firebase
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AgentStudentDocuments = (props) => {
  const { onClose } = props;
  const { studentId } = props;
  const { fileId } = useParams();
  const [temp, setTemp] = useState(true);
  const [addDocLoading, setAddDocLoading] = useState(false);
  const params = useParams();
  const [deleteModalCode, setDeleteModalCode] = useState(false);
  const [isAllApproved, setisAllApproved] = useState();
  const mainfileId = params.id;
  console.log("sss", fileId, "aaaa", mainfileId);
  const [state, setState] = useState({
    isWait: true,
    documentsList: [],
    document: "",
    title: "",
    baseUrl: "",
    popup: false,
    studentDetails: null,
    activeDocIndex: -1,
    documentStatus: false,
    submitDocsLoading: false,
  });
  function handleOverlayClick(event) {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }
  useEffect(() => {
    // get docs list
    const config = {
      headers: {
        Authorization: `Bearer ${getToken("agent")}`,
      },
    };
    setState({
      ...state,
      isWait: true,
    });
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/student/getdocuments",
        { fileId: fileId },
        config
      )
      .then((res) => {
        setisAllApproved(res.data.details.isAllApproved);
        setState({
          ...state,
          documentsList: res.data.details.documents,
          baseUrl: res.data.details.baseUrl,
          documentStatus: res.data.details.isSubmitStatus,
          isWait: false,
          submitDocsLoading: false,
        });
      })
      .catch((err) => { });
  }, [window.location.href, temp]);

  // add document to database with axios and form data, get doc detail from state.documents array
  const addDocument = async (e) => {
    if (state.document === "") {
      toast.error("Please select a file");
      return;
    }
    const MAX_FILE_SIZE_MB = 4;

    if (state.document.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error(`File size exceeds the limit of ${MAX_FILE_SIZE_MB} MB`);
      return;
    }
    setAddDocLoading(true);

    e.preventDefault();
    const formData = new FormData();
    formData.append("document", state.document);
    formData.append("title", state.title);
    formData.append("fileId", fileId);
    formData.append("studentId", studentId);

    const config = {
      headers: {
        Authorization: `Bearer ${getToken("agent")}`,
      },
    };

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/student/uploaddocument",
        formData,
        config
      )
      .then((res) => {
        setAddDocLoading(false);
        if (res.data.details.status === "0") {
          toast.error(res.data.details.message);
          return;
        }
        let oldDocs = state.documentsList;
        oldDocs[state.activeDocIndex] = res.data.details.document;
        setState({
          ...state,
          documentsList: oldDocs,
          popup: false,
          activeDocIndex: -1,
          document: "",
          title: "",
          documentStatus: res.data.details.isSubmitStatus,
        });
        document.getElementById("document-file").value = "";
      })
      .catch((err) => {
        setAddDocLoading(false);
        document.getElementById("document-file").value = "";
      });
  };

  const handleFile = (e) => {
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    const selectedFile = e.target.files[0];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setState({
        ...state,
        document: selectedFile,
      });
    } else {
      toast.error('Please select a valid file (JPG, PNG, or PDF)');
      e.target.value = '';
    }
  };

  const submitConfirm = (Id) => {
    setDeleteModalCode(true);
  };

  const submitAllDocs = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken("agent")}`,
      },
    };
    setDeleteModalCode(false)
    setState({
      ...state,
      submitDocsLoading: true,
    });

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/student/submitAllDocs",
        { fileId: fileId },
        config
      )
      .then((res) => {
        if (res.data.status === "1") {
          toast.success(res.data.message);
          onClose();
          // add history
          const addHistoryData = {
            userRole: "Agent",
            comment: res.data.message,
            fileId: mainfileId,
          };

          axios.post(
            process.env.REACT_APP_NODE_URL + "/v2/api/addHistory",
            addHistoryData,
            config
          );
        } else {
          toast.error(res.data.message);
        }
        setTemp(!temp);
      })
  };

  return (
    <>
      <ToastContainer />
      <div className="Modaaal-overlay" onClick={handleOverlayClick}>
        {deleteModalCode ? (
          <div className="modal_cover">
            <div className="modal_inner select-col-popup">
              <div className="header_modal">
                <h1 className="capitalize font-bold text-lg mb-2">
                  Are You Sure?
                </h1>
                <div className="button-part flex justify-end items-center gap-4">
                  <button
                    className="py-2 px-6"
                    type="btn"
                    onClick={() => setDeleteModalCode(false)}
                  >
                    No
                  </button>
                  <button
                    className="py-2 px-6"
                    type="btn"
                    onClick={() => submitAllDocs()}//call real function here.
                  >
                    Yes
                  </button>
                </div>
                <GrClose
                  className="close"
                  onClick={() => setDeleteModalCode(false)}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="Modaaal">
          <div className="Modaaal-close" onClick={onClose}>
            <GrClose />
          </div>
          <h2 className="Modaaal-title">Document Details</h2>
          <>
            {" "}
            {state.isWait ? (
              <center className="w-full mt-20">
                <img src={loader} alt="" />
              </center>
            ) : (
              <>
                <div
                  className={`overlay ${state.popup == true ? "active" : ""}`}
                  onClick={() =>
                    setState({
                      ...state,
                      popup: false,
                      activeDocIndex: -1,
                    })
                  }
                ></div>
                {state.enroll_status == "FEES_PENDING" && (
                  <div className="text-[white] m-[20px] bg-[#e5a11f] rounded p-2 text-xl font-bold capitalize">
                    Your Documents are submitted, Please pay Application fees
                    for further Processing!
                  </div>
                )}
                <div
                  className={`pendingDocAlert popup  ${state.popup == true ? "active" : ""
                    }`}
                >
                  <div class="w-full">
                    <h1 class="text-3xl font-bold mb-5">
                      Add Student Document
                    </h1>
                    <div class="mb-5">
                      <label
                        for="document-type"
                        class="block font-medium mb-2 text-black"
                      >
                        Document Name:
                      </label>
                      <input
                        className="border px-4 py-2 w-full"
                        type="text"
                        name="title"
                        placeholder="Document Name text-black"
                        value={state.title}
                        disabled
                      />
                    </div>
                    <div class="mb-5">
                      <label
                        for="document-file"
                        class="block font-medium mb-2 text-black"
                      >
                        Upload Document: (Accepted formats: JPG, PNG, or PDF | Maximum size: 4MB)
                      </label>
                      <input
                        type="file"
                        id="document-file"
                        name="document"
                        className="border px-4 py-2 w-full text-black"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={handleFile}
                      />
                    </div>
                    <div className="flex justify-end">
                      <ButtonPrimary
                        onclick={addDocument}
                        type="submit"
                        loading={addDocLoading}
                        title="Add"
                      />
                    </div>
                  </div>
                </div>
                <div id="uploadStudentDocuments" className="flex">
                  <div class="w-full dashbord-table">
                    {/* create table with document name, view button and status */}

                    <table className="w-full border">
                      <thead>
                        <tr>
                          <th className="uppercase font-bold border-b border-gray-200 px-4 py-2 text-left">
                            Document Name
                          </th>
                          <th className="uppercase font-bold border-b border-gray-200 px-4 py-2 text-left">
                            Type
                          </th>
                          <th className="uppercase font-bold border-b border-gray-200 px-4 py-2 text-left">
                            Status
                          </th>
                          <th className="uppercase font-bold border-b border-gray-200 px-4 py-2 text-left">
                            View
                          </th>
                          <th className="uppercase font-bold border-b border-gray-200 px-4 py-2 text-left">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {" "}
                        {state?.documentsList?.map((document, index) => {
                          return (
                            document != undefined && (
                              <tr key={index}>
                                <td className="border-b border-gray-200 px-4 py-2 font-bold text-black">
                                  {document.document_title}{" "}
                                </td>
                                <td className={`border-b border-gray-200 px-4 py-2 font-bold 
                                  ${document.document_isRequired ? 'text-red-500' : 'text-gray-500'}`}>
                                  {document.document_isRequired ? "Required" : "Optionally"}
                                </td>
                                <td className="border-b border-gray-200 px-4 py-2">
                                  {document.document_status ==
                                    "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION" ? (
                                    <span className="font-bold text-[blue]">
                                      UNDER_VERIFICATION
                                    </span>
                                  ) : document.document_status ==
                                    "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ? (
                                    <span className="font-bold text-[blue]">
                                      UNDER_VERIFICATION
                                    </span>
                                  ) : document.document_status ==
                                    "PENDING" ? (
                                    <span className="font-bold text-[red]">
                                      {document.document_status}
                                    </span>
                                  ) : document.document_status ==
                                    "REJECTED" ? (
                                    <span className="font-bold text-[red]">
                                      {document.document_status}
                                    </span>
                                  ) : document.document_status ==
                                    "APPROVED" ? (
                                    <span className="font-bold text-[green]">
                                      {document.document_status}
                                    </span>
                                  ) : document.document_status ==
                                    "UPLOADED" ? (
                                    <span className="font-bold text-[green]">
                                      Document Added
                                    </span>
                                  ) : (
                                    <span>{document.document_status}</span>
                                  )}{" "}
                                </td>
                                <td className="border-b border-gray-200 px-4 py-2">
                                  {document.document_status == "PENDING" ? (
                                    "--"
                                  ) : (
                                    <Link
                                      target={"_blank"}
                                      to={`${state.baseUrl}${document.document_url}`}
                                      className="text-blue-500 hover:text-blue-700"
                                    >
                                      <svg
                                        width={"15px"}
                                        className="mt-[3px]"
                                        fill="brown"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512"
                                      >
                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                      </svg>
                                    </Link>
                                  )}{" "}
                                </td>
                                <td className="border-b border-gray-200 px-4 py-2">
                                  {document.document_status == "APPROVED" ||
                                    document.document_status ==
                                    "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION" ? (
                                    <>--</>
                                  ) : (
                                    <ButtonPrimary
                                      loading={false}
                                      title={
                                        document.document_status == "PENDING"
                                          ? "Add"
                                          : document.document_status ==
                                            "REJECTED"
                                            ? "Change"
                                            : "Added"
                                      }
                                      onclick={() => {
                                        if (
                                          state.enroll_status ==
                                          "FEES_PENDING"
                                        ) {
                                          toast.error(
                                            "Please pay the fees before upload the documents"
                                          );
                                          return;
                                        }
                                        setState({
                                          ...state,
                                          title: document.document_title,
                                          popup: true,
                                          activeDocIndex: index,
                                        });
                                      }}
                                    />
                                  )}{" "}
                                </td>
                              </tr>
                            )
                          );
                        })}{" "}
                      </tbody>
                    </table>
                    {isAllApproved == false ? (
                      <div style={{ textAlign: "center", marginTop: "30px" }}>
                        {
                          fileId &&
                          state?.documentStatus && (
                            <ButtonPrimary
                              title="Submit Documents"
                              loading={state.submitDocsLoading}
                              onclick={submitConfirm}
                            />
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                    {state?.documentsList?.length == 0 && (
                      <center>
                        <div className="text-[red] p-[30px]">No Documents</div>
                      </center>
                    )}
                  </div>
                </div>
              </>
            )}{" "}
          </>
        </div>
      </div>
    </>
  );
};

export default AgentStudentDocuments;
