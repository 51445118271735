import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import loader from "../../../images/loader.gif";
import { toast } from "react-toastify";
import AdminSendOtp from "./AdminSendOtp";
const AdminSubStudents = () => {
  const [isrotating, setisrotating] = useState(false);
  const { agentId } = useParams();
  const [tableData, setTableData] = useState([]);
  const [feesInputs, setFeesInputs] = useState({});
  const [commissionInputs, setCommissionInputs] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedenrollIds, setSelectedenrollIds] = useState([]);
  const [tuitionButton, setTuitionButton] = useState(false);
  const [commisionButton, setCommisionButton] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [enabledButtons, setEnabledButtons] = useState(tableData.map(() => false));
  const [tuitionButtonEnabled, setTuitionButtonEnabled] = useState({});
  const [commissionButtonEnabled, setCommissionButtonEnabled] = useState({});
  const [showCheck, setShowCheck] = useState(false)

  const [state, setState] = useState({
    isWaiting: true,
    students: [],
    totalPages: 0,
    currentPage: 1,
  });

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      getPaginationData(state.currentPage, searchName);
    }, 300);
    return () => clearTimeout(delaySearch);
  }, []);

  const getPaginationData = (page, searchName) => {
    setState({
      ...state,
      isWaiting: true,
    });
    let data;
    if (searchName == undefined) {
      data = { currentPage: page, userId: agentId, searchName: "" };
    } else {
      data = { currentPage: page, userId: agentId, searchName: searchName };
    }
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/getAgentPaidStudent", data)
      .then((res) => {
        setTableData(res?.data?.details?.students);
        setState({
          ...state,
          isWaiting: false,
          students: res.data.details.students,
          totalPages: res.data.details.totalPages,
          currentPage: res.data.details.currentPage,
        });
        setisrotating(false)
      })
      .catch((err) => { });
  };

  // Function to add commission
  const addCommission = (index, enrollId) => {
    const data = {
      enrollId: enrollId,
      agentId: agentId,
      commissionPrice: commissionInputs[index],
    };
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/createCommision", data)
      .then(() => {
        setShowCheck(true)
        toast.success("Commission added successfully");
        setCommisionButton(false);
      })
      .catch((error) => {
        toast.error("Failed to add commission");
      });
  };

  const handleCommissionChange = (index, value) => {
    if (value !== "") {
      setCommissionInputs((prevInputs) => ({
        ...prevInputs,
        [index]: value,
      }));
      setCommissionButtonEnabled((prevEnabled) => ({
        ...prevEnabled,
        [index]: true,
      }));
    } else {
      setCommissionInputs((prevInputs) => ({
        ...prevInputs,
        [index]: "",
      }));
      setCommissionButtonEnabled((prevEnabled) => ({
        ...prevEnabled,
        [index]: false,
      }));
    }
  };

  // Function to add fees
  const addFees = (index, enrollId) => {
    const feeData = {
      enrollId: enrollId,
      agentId: agentId,
      tuitionFees: feesInputs[index],
    };

    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/createTuitionFeeByAdmin",
        feeData
      )
      .then(() => {
        toast.success("Fees added successfully");
        setTuitionButton(false);
      })
      .catch(() => {
        toast.error("Failed to add fees");
      });
  };
  const handleFeesChange = (index, value) => {
    if (value !== "") {
      setFeesInputs({
        ...feesInputs,
        [index]: value,
      });
      setTuitionButtonEnabled({
        ...tuitionButtonEnabled,
        [index]: true,
      });
    } else {
      setFeesInputs({
        ...feesInputs,
        [index]: "",
      });
      setTuitionButtonEnabled({
        ...tuitionButtonEnabled,
        [index]: false,
      });
      // addFees(index, "");
    }
  };


  const closePaymentPopup = () => {
    setIsPopupOpen(false);
  };
  const handleStudentCheckboxChange = (enrollId) => {
    let updatedSelectedIds;
    if (selectedenrollIds.includes(enrollId)) {
      updatedSelectedIds = selectedenrollIds.filter((id) => id !== enrollId);
    } else {
      updatedSelectedIds = [...selectedenrollIds, enrollId];
    }
    setSelectedenrollIds(updatedSelectedIds);


  };




  const handlePayCommissionClick = () => {
    console.log("Selected Student IDs:", selectedenrollIds);
    setSelectedUserId(selectedenrollIds);
    setIsPopupOpen(true);
    // setSelectedenrollIds([]);
  };

  const handleSearch = () => {
    getPaginationData(state.currentPage, searchName);
  }

  const startLoader = () => {
    setisrotating(true);
    getPaginationData();
    setSearchName()
  };

  return (
    <>
      <div heading_title={"Agent's Students (" + agentId + ")"}>
        <>
          <div className="row min-height-vh-100 p-45">
            <div className=" my-8 flex justify-between">
              <div>
                <input
                  type="text"
                  className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none"
                  placeholder="Search Student By Name"
                  value={searchName}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
                <button
                  onClick={handleSearch}
                  className="border border-green-600 border-solid border-1 !border-opacity-100 mt-0 bg-[#19632f] hover:bg-[#053815] text-white py-2 px-4 rounded-r-md focus:outline-none"
                >
                  Find
                </button>
              </div>
              <div className="reload-icon mr-2">
                <i
                  className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                  onClick={startLoader}
                />
              </div>
            </div>
            <h1 className=" pb-3 font-semibold text-[20px]">
              Add Commisssion
            </h1>
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="card-body px-0 pt-0 pb-2 table-border">
                    <div className="overflow-auto card  m-0 col-12 px-0 pt-0 pb-2  border">
                      <div className="table-responsive">
                        {state.isWaiting ? (
                          <div>
                            <center className="w-full my-10">
                              <img src={loader} alt="" />
                            </center>
                          </div>
                        ) : tableData && tableData.length > 0 ? (
                          <>
                            <table className="table mb-0 w-full">
                              <thead>
                                <tr
                                  className=""
                                  style={{ background: "#ced4da" }}
                                >
                                  <th className="p-2 text-left">Sr. No.</th>
                                  <th className="p-2 text-left">
                                    Student Name
                                  </th>
                                  <th className="p-2 text-left">
                                    College Name
                                  </th>
                                  <th className="p-2 text-left">Program Id</th>
                                  <th className="p-2 text-left">
                                    Application Fees
                                  </th>
                                  <th className="p-2 text-left">
                                    Tuition Fees
                                  </th>
                                  <th className="p-2 align-middle text-center">
                                    Commission
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData &&
                                  tableData.map((student, index) => (
                                    <tr
                                      className="py-4 custom-tr"
                                      key={index}
                                      style={{
                                        background: "#f4f5f6",
                                        borderBottom: "10px solid #fff",
                                      }}
                                    >
                                      <td className="p-2 max-width mb-5">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          {index + 1}
                                        </span>
                                      </td>
                                      <td className="p-2 max-width">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          {student?.firstName +
                                            " " +
                                            student?.studentLastName}
                                        </span>
                                      </td>
                                      <td className="p-2 max-width">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          {student?.college_name}
                                        </span>
                                      </td>
                                      <td className="p-2 max-width">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          {student?.program_id}
                                        </span>
                                      </td>
                                      <td className="p-2 max-width">
                                        <span className="text-secondary text-xs font-weight-bold">
                                          {student?.applicationFee} CAD
                                        </span>
                                      </td>
                                      {student?.isOtpverify === false ? (
                                        <td className="align-middle text-center">
                                          <div className="flex items-center">
                                            <input
                                              className="no-spinner rounded px-2"
                                              style={{
                                                height: "30px",
                                                width: "100px",
                                                border: "1px solid #9f9f9f",
                                              }}
                                              type="number"
                                              value={feesInputs[index] || student?.tutionfee}
                                              onChange={(e) => handleFeesChange(index, e.target.value)}
                                            />
                                            <button
                                              className={`ml-2 px-2 py-1 rounded ${tuitionButtonEnabled[index] ? "enabled-button" : "disabled-button"}`}
                                              style={{
                                                background: tuitionButtonEnabled[index] ? "#008000" : "#ccc",
                                                color: "#fff",
                                              }}
                                              onClick={() => addFees(index, student?._id)}
                                              disabled={!tuitionButtonEnabled[index]}
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </td>
                                      ) : (
                                        <td className="align-middle text-center">
                                          <div className="flex items-center">
                                            <span className="text-secondary text-xs font-weight-bold mr-5">
                                              {student?.tutionfee}
                                            </span>
                                          </div>
                                        </td>
                                      )}
                                      {student?.isOtpverify === false ? (
                                        <td className="align-middle text-center">
                                          <div className="flex items-center">
                                            <input
                                              className="no-spinner rounded px-2 ml-2"
                                              style={{
                                                height: "30px",
                                                width: "100px",
                                                border: "1px solid #9f9f9f",
                                              }}
                                              type="number"
                                              value={commissionInputs[index] || student?.commision}
                                              onChange={(e) => handleCommissionChange(index, e.target.value)}
                                            />
                                            <button
                                              className={`ml-2 px-2 py-1 rounded ${commissionButtonEnabled[index]
                                                ? "enabled-button"
                                                : "disabled-button"
                                                }`}
                                              style={{
                                                background: commissionButtonEnabled[index] ? "#008000" : "#ccc",
                                                color: "#fff",
                                              }}
                                              onClick={() => addCommission(index, student?._id)}
                                              disabled={!commissionButtonEnabled[index]}
                                            >
                                              Add
                                            </button>
                                            <input
                                              className="h-8 w-5 rounded border-gray-300 border ml-2"
                                              type="checkbox"
                                              checked={selectedenrollIds.includes(student?._id)}
                                              onChange={() => handleStudentCheckboxChange(student?._id)} />

                                          </div>
                                        </td>
                                      ) : (
                                        <td className="align-middle text-center">
                                          <div className="flex items-center justify-center">
                                            <span className="text-secondary text-xs font-weight-bold mr-5">
                                              {student?.commision}
                                            </span>
                                            <b style={{ color: "#0045e1" }}>
                                              Commission Paid!
                                            </b>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            <div className="flex items-center justify-center mt-5">
                              <h1 className="text-lg font-semibold">
                                Pay Commission
                              </h1>
                              {selectedenrollIds.length > 0 ? (
                                <button
                                  className="ml-3 px-3 py-1 rounded bg-blue-500 text-white font-medium shadow-md hover:bg-blue-600 transition-colors"
                                  onClick={handlePayCommissionClick}
                                >
                                  Pay
                                </button>
                              ) : (
                                <p className="ml-3 text-red-500 text-sm">
                                  Select at least one Checkbox to pay
                                  commission.
                                </p>
                              )}
                            </div>
                          </>
                        ) : (
                          <div>
                            <p className="text-center p-5 text-red-500">
                              No data available.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pb-0">
                    <div className="pagination">
                      <div className="pages">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next"
                          onPageChange={(event) => {
                            getPaginationData(event.selected + 1);
                          }}
                          pageCount={state.totalPages}
                          previousLabel="prev"
                          renderOnZeroPageCount={null}
                          pageRangeDisplayed={1}
                          marginPagesDisplayed={0}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isPopupOpen && (
            <AdminSendOtp
              enrollId={selectedenrollIds}
              agentId={agentId}
              onClose={closePaymentPopup}
            />
          )}
        </>
      </div>
    </>
  );
};

export default AdminSubStudents;
