/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import { getToken } from "../../../helper/auth";
import OutsideClickHandler from "react-outside-click-handler";
import axios from "axios";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Header({ }) {
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState(
    "Search Colleges and Universities"
  );
  const [isFocused, setIsFocused] = useState(false);
  const [finalList, setFinalList] = useState([]);
  const [dropPop, setdropPop] = useState(false);
  const [data, setData] = useState([]);
  const [socialIcons, setSocialIcons] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_NODE_URL + `/v2/api/getCollegeNameList`)
      .then((response) => {
        console.log("checkkkk", response.data);
        setData(response?.data?.detail);
      });
  }, []);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    setdropPop(true);

    if (inputValue === "") {
      setdropPop(false);
    } else {
      setdropPop(true);

      // Filter the finalList based on the 'collegeName' property of each object
      const filteredSuggestions = data?.filter((suggestion) =>
        suggestion.collegeName.toLowerCase().includes(inputValue.toLowerCase())
      );

      setFinalList(filteredSuggestions);
    }
  };

  const handleFocus = () => {
    setPlaceholder("");
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (value === "") {
      setPlaceholder("Search Colleges and Universities");
    }
    setIsFocused(false);
  };

  const handleSearchClick = (event, suggestion) => {
    setValue(suggestion.collegeName); // Set the collegeName as the value
    setIsFocused(true);
    event.preventDefault();
    // Other code remains the same
    navigate(`/discover?search=${encodeURIComponent(suggestion.collegeName)}`);
    setdropPop(false);
    setFinalList([]);
    setPlaceholder("Search Colleges and Universities");
  };

  const landingPage = useSelector((state) => state.landingPage);

  const location = useLocation();

  const [headerClassName, setHeaderClassName] = useState("");
  const [isDark, setIsDark] = useState(true);
  const [Show, setShow] = useState(false);
  const handleTogle = () => {
    setShow(!Show);
  };

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset >= 100) {
      setHeaderClassName("menuscroll");
      setIsDark(false);
    } else if (headerClassName === "menuscroll" && window.pageYOffset < 100) {
      setHeaderClassName("");
      setIsDark(true);
    }
  };

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  useEffect(() => {
    if (window.location.pathname != "/") {
      document.getElementById("header_menu")?.classList?.add("not-home");
    }
    // window.scrollTo(0, 0)
  }, [location]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          process.env.REACT_APP_NODE_URL + "/admin/sociallist"
        );
        if (response.ok) {
          const data = await response.json();
          setSocialIcons(data.details.list);
          // console.log(data?.details?.list);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div
      className={
        window.location.pathname != "/" && true ? "header-bg-dark" : ""
      }
    >
      <div
        className={
          headerClassName
            ? window.location.pathname == "/"
              ? `${headerClassName}` + "header-part "
              : `${headerClassName}` + "header-part not-home"
            : window.location.pathname == "/"
              ? "header-part"
              : "not-home"
        }
        id="header_menu"
      >
        <div className="top-bar flex py-2 px-4 lg:px-0   border-b">
          <div className="container mx-auto">
            <div className="grid md:grid-col-3 lg:grid-cols-3 items-center">
              <div className="lg:my-3 lg:my-0">
                <a
                  className="mail-link text-center lg:text-left text-black  block text-sm tracking-widest"
                  href="mailto:info@learnglobal.com"
                >
                  <i class="fa fa-envelope mr-2" aria-hidden="true"></i>
                  {landingPage.main_email}
                </a>
              </div>
              <div className="flex items-center justify-center  gap-4 pl-4 mb-5 lg:mb-0 md:mb-0">
                {socialIcons.map((socialIcon) => (
                  <li
                    key={socialIcon._id}
                    className="flex items-center space-x-4"
                  >
                    <Link to={socialIcon.link} className="mx-1" target="_blank">
                      <img
                        src={
                          process.env.REACT_APP_NODE_URL +
                          "/uploads/agent/" +
                          socialIcon.icon
                        }
                        alt=""
                        className="h-6 w-6 rounded-full xl:h-8 xl:w-8"
                      />
                    </Link>
                  </li>
                ))}
              </div>
              <div>
                <div class="flex justify-center lg:justify-end ">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setdropPop(false);
                    }}
                  >
                    <div class="w-full lg:w-[100%] relative">
                      <input
                        type="text"
                        className="form-control text-left block w-100 px-8
                        py-1.5 text-sm font-normal bg-white bg-clip-padding
                        border border-solid border-gray-300 rounded-sm
                        transition ease-in-out m-0 lg:ml-auto focus:text-gray-700 
                        focus:bg-white  focus:outline-none pr-10"
                        id="exampleSearch"
                        value={value}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      <i
                        className="cs_search fas fa-search absolute top-1/2 right-6 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        style={{
                          display: isFocused || value ? "block" : "none",
                          position: "absolute",
                          top: "18px",
                        }}
                        onClick={(event) =>
                          handleSearchClick(event, finalList[0])
                        }
                      ></i>
                      {dropPop && finalList?.length > 0 ? (
                        <div className="suggestion_dropdown">
                          {finalList?.map((suggestion) => (
                            <div key={suggestion._id}>
                              <div
                                onClick={(event) =>
                                  handleSearchClick(event, suggestion)
                                }
                                className="sug_value"
                              >
                                <Link
                                  className="sug_a"
                                  to={`/search?q=${suggestion.collegeName}`}
                                >
                                  {suggestion.collegeName}
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : dropPop && finalList.length === 0 ? (
                        <div
                          style={{ color: "#f00" }}
                          className="suggestion_dropdown"
                        >
                          No Programs Found
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </OutsideClickHandler>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="border-gray-200 px-2 sm:px-4 py-2.5 rounded light:bg-gray-800">
          <div class="container flex flex-wrap justify-between items-center mx-auto">
            <a href="/" className="logo flex items-center">
              <img
                className="logo-norml"
                src={landingPage.baseUrl + landingPage.main_logo_a}
                alt=""
              />
              <img
                className={"logo-scroll"}
                src={landingPage.baseUrl + landingPage.main_logo_b}
                alt="Logo"
                width={"200px"}
              />
            </a>
            <button
              data-collapse-toggle="mobile-menu"
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-[#065f46] dark:focus:ring-gray-600"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => handleTogle()}
            >
              <span class="sr-only">Open Main Menu</span>
              <svg
                class="w-8 h-8"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                class="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <div
              class={
                Show
                  ? "hidden w-full md:block md:w-auto showmenu "
                  : "hidden w-full md:block md:w-auto "
              }
              id="mobile-menu"
            >
              <ul class="header-menus flex items-center flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/"
                    class="block text-sm py-2 pr-4 pl-3   rounded md:bg-transparent text-gray-700 md:p-0 dark:text-dark"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/discover"
                    class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Discover College
                  </NavLink>
                </li>
                <li className="relative">
                  {getToken("student") ? (
                    <>
                      <NavLink
                        activeClassName="active"
                        to="/student/enrolled"
                        class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        Student Dashboard
                      </NavLink>
                    </>
                  ) : getToken("agent") ? (
                    <>
                      <NavLink
                        activeClassName="active"
                        to="/agent/"
                        class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        Agent Dashboard
                      </NavLink>
                    </>
                  ) : getToken("admin") ? (
                    <>
                      <NavLink
                        activeClassName="active"
                        to="/admin/"
                        class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        Admin Dashboard
                      </NavLink>
                    </>
                  ) : (
                    <NavLink
                      activeClassName="active"
                      to={"/studentlogin"}
                      class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    >
                      Student Login
                    </NavLink>
                    // <NavLink
                    //   activeClassName="active"
                    //   to={""}
                    //   class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                    // >
                    //   Login{" "}
                    //   <svg
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     fill="none"
                    //     viewBox="0 0 24 24"
                    //     stroke-width="1.5"
                    //     stroke="currentColor"
                    //     class="w-6 h-6"
                    //   >
                    //     <path
                    //       stroke-linecap="round"
                    //       stroke-linejoin="round"
                    //       d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    //     />
                    //   </svg>

                    //   <ul className="head-sub-menus absolute">
                    //     <li>
                    //       <Link className="flex items-center" to={"/agentlogin"}>Agent Login</Link>
                    //     </li>
                    //     <li>
                    //       <Link className="flex items-center" to={"/studentlogin"}>Student Login</Link>
                    //     </li>
                    //   </ul>
                    // </NavLink>
                  )}
                </li>
                <li>
                  <NavLink
                    activeClassName="active"
                    to="/contact"
                    class="block text-sm py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Contact
                  </NavLink>
                </li>
                <li className="relative">
                  <a
                    href="#"
                    class=" cs-country block text-sm py-2 pr-4 pl-3 hover:text-black  hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-[#065f46] dark:hover:text-white md:dark:hover:bg-transparent"
                  >
                    Country{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                    <ul className="head-sub-menus absolute">
                      {landingPage.countryList.map((el) => {
                        return (
                          <li>
                            <Link
                              className="flex items-center"
                              to={"/countries/" + el.countryDetails.countryId}
                            >
                              {/* {/ <img src={landingPage.baseUrl + el.countryLogo} alt='' /> /} */}
                              {el.countryDetails.countryName}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </a>
                </li>
                <li>
                  <Link
                    to={"/eligible"}
                    style={{ padding: "5px 10px" }}
                    className="text-white hover:text-white bg-[#059669] hover:bg-[#065f46] text-white rounded-full"
                  >
                    Apply Now
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
