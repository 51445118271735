/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authenticate, getToken } from "../../../helper/auth";

export const StudentProfile = () => {
  const [state, setState] = useState({
    isWaiting: false,
    notifications: [],
    adminToken: getToken("student"),
  })
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [disabled, setDisabled] = useState(true);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    }

    // Check if both firstName and lastName have values
    if (firstName && lastName) {
      setDisabled(false); // enable the button
    } else {
      setDisabled(true); // disable the button
    }
  };
  const updateData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken("student")
          }`
      }
    };
    let data = { firstName: firstName, lastName: lastName }
    axios.patch(process.env.REACT_APP_NODE_URL + "/student/", data, config).then(res => {
      toast(res.data.message)
    }).catch(err => {
      // toast(err.response.data.message) 
    })
  }

  const updatePassword = () => {
    if (oldPassword !== "" && newPassword !== "") {
      if (newPassword !== confirmPassword) {
        toast("Both password must be same!")
        return;
      }
      const config = {
        headers: {
          Authorization: `Bearer ${getToken("student")
            }`
        }
      };
      let data = { oldPassword: oldPassword, newPassword: newPassword }
      axios.patch(process.env.REACT_APP_NODE_URL + "/admin/changePassword", data, config).then(res => {
        toast(res.data.message)
      }).catch(err => {
        toast(err.response.data.message)
      })
    } else {
      toast('kindly check password field again');
    }

  }


  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken("student")
          }`
      }
    };
    axios
      .get(process.env.REACT_APP_NODE_URL + "/student/", config).then(response => {
        if (response.data.status == "1") {
          setEmail(response.data.details.student.email)
          setFirstName(response.data.details.student.firstName)
          setLastName(response.data.details.student.lastName)
        }
      })

  }, [])



  return (
    <>
      <ToastContainer />
      <div className='flex profile-main justify-center items-center py-5 px-3'>
        <div
          class="block max-w-sm flex-none w-64 rounded-lg bg-white p-6 lg:m-10  profile-form">

          <div class="relative mb-6" data-te-input-wrapper-init>
            <label>First Name</label>
            <input
              type="text"
              name='firstName'
              class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
              id="exampleInput90"
              value={firstName}
              onChange={handleInputChange}
            />

          </div>
          <div class="relative mb-6" data-te-input-wrapper-init>
            <label>Last Name</label>
            <input
              type="text"
              class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
              id="exampleInput90"
              value={lastName}
              name='lastName'
              onChange={handleInputChange}
            />
          </div>
          <div class="relative mb-6" data-te-input-wrapper-init>
            <label>Email address</label>
            <input
              type="email"
              name='email'
              class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
              id="exampleInput91"
              value={email} />
          </div>
          {isAuthenticated ? (
            <></>
          ) : (
            <button
              type="button"
              class="rounded bg-blue px-6 py-2.5 text-xs border-2 font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover: focus:bg-primary-700 focus: focus:outline-none focus:ring-0 active:bg-primary-800 active:"
              data-te-ripple-init
              data-te-ripple-color="light"
              disabled={disabled}
              onClick={updateData}>
              Update
            </button>
          )}
        </div>
        {isAuthenticated ? (
          <></>
        ) : (
          <div
            class="block max-w-sm flex-none w-64  rounded-lg bg-white p-6 lg:m-10  profile-form">
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>Old Password</label>
              <input
                type="text"
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput90"
                placeholder="Old Password"
                onChange={(e) => setOldPassword(e.target.value)}
              />

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>New Password</label>
              <input
                type="text"
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput90"
                placeholder="New Password"
                onChange={(e) => setNewPassword(e.target.value)}
              />

            </div>
            <div class="relative mb-6" data-te-input-wrapper-init>
              <label>Confirm Password</label>
              <input
                type="text"
                class="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-[black]"
                id="exampleInput91"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button
              type="button"
              class="rounded bg-blue px-6 py-2.5 text-xs border-2 font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover: focus:bg-primary-700 focus: focus:outline-none focus:ring-0 active:bg-primary-800 active:"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={updatePassword}
            >
              Change Password
            </button>
          </div>
        )}
      </div >
    </>
  )
}

