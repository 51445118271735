import React from 'react'
import { toast } from "react-toastify";
import axios from "axios";

function HideCollege({ closehidecollegepopup, startLoader, selectedCollege }) {

  const handleHideCollege = () => {
    axios.post(`${process.env.REACT_APP_NODE_URL}/admin/updateCollegeVisibility`, {
      collegeId: selectedCollege._id,
      isVisible: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.data.status === '1') {
          toast.success(response.data.message);
          closehidecollegepopup();
          startLoader();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className="p-5">
        <h1 className="mb-5 text-[16px] font-bold">Are you sure you want to hide
          <span className='text-[green] capitalize'> {selectedCollege.collegeName} ?</span></h1>
        <button onClick={handleHideCollege} className="border p-1">Yes</button>
        <button className="bg-red-600 border p-1 ml-2">No</button>
      </div>
    </div>
  )
}

export default HideCollege