import axios from "axios";
import Papa from "papaparse";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getToken } from "../../../helper/auth";
import { GrClose } from "react-icons/gr";
import loader from "../../../images/loader.gif";
import { AiFillSetting } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiEdit } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
const ProgramsList = (props) => {
  const selectRef = useRef(null);
  const params = useParams();
  let sId = params.id;
  let _id = params.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isaboutModalOpen, setaboutIsModalOpen] = useState(false);
  const [editedDescription, setEditedDescription] = useState();
  const [editedprogramName, setEditedprogramName] = useState();
  const [isaprogramNameModalOpen, setisaprogramNameModalOpen] = useState();
  const [editedAbout, setEditedAbout] = useState();
  const [proId, setProId] = useState('')
  const [aboutID, setaboutId] = useState('')
  const [programNameID, setprogramNameID] = useState('')
  const [view, setView] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false);
  const [isrotating, setisrotating] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [ModalCode, setModalCode] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [apiData, setApiData] = useState([]);
  const [programNames, setProgramNames] = useState([]);
  const [resultSuggestion, setResultSuggestion] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [state, setState] = useState({
    isWaiting: true,
    collegePrograms: [],
    collegeNamesList: [],
    countryNamesList: [],
    adminToken: getToken("admin"),
    wait: true,
    activeIndex: null,
    first: true,
    searchItem: "",
    totalPages: 0,
    currentPage: 1,
  });

  const { id } = params;
  const fetchData = async () => {
    setState({
      ...state,
      isWaiting: true,
    });
    try {
      const requestData = {
        collegeId: id,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/admin/getnewPrograms`,
        requestData
      );

      let data = response.data.details;
      setState({
        ...state,

        isWaiting: false,
      });
      setApiData(data);
      let result = data.collegePrograms.map((item) => item.program_name);
      setProgramNames(result);
      setState((prevState) => ({ ...prevState, isWaiting: false })); // Update isWaiting to false
      setisrotating(false)
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableColumns = [
    { label: "Sr.", value: "Sr." },
    { label: "Program_name", value: "Program_name" },
    { label: "College_name", value: "College_name" },
    { label: "College_about", value: "College_about" },
    { label: "Duration", value: "Duration" },
    { label: "Description", value: "Description" },
    { label: "Visa_processing_days", value: "Visa_processing_days" },
    { label: "Acceptance_letter", value: "Acceptance_letter" },
    { label: "Acceptable_band", value: "Acceptable_band" },
    { label: "Module", value: "Module" },
    { label: "Application_fee", value: "Application_fee" },
    { label: "Credentials", value: "Credentials" },
    { label: "Stream_id", value: "Stream_id" },
    { label: "Program_level", value: "Program_level" },
    { label: "Cost_of_living", value: "Cost_of_living" },
    { label: "Currency", value: "Currency" },
    { label: "Grade_score", value: "Grade_score" },
    { label: "Overall_band", value: "Overall_band" },
    { label: "Pte_score", value: "Pte_score" },
    { label: "Duolingo_score", value: "Duolingo_score" },
    { label: "Tofel_point", value: "Tofel_point" },
    { label: "English_Language", value: "English_Language" },
    { label: "Foundation_Fee", value: "Foundation_Fee" },
    { label: "Tuition Fees", value: "Tuition Fees" },
    { label: "Other_Fees", value: "Other_Fees" },
    { label: "Other_comment", value: "Other_comment" },
    // { label: "top_status", value: "top_status" },
  ];
  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    // Load selected columns from local storage or use the default columns
    JSON.parse(localStorage.getItem("selectedColumnstwo")) || [
      "Sr.",
      "Program_name",
      "College_name",
      "Duration",
      "Duolingo_score"
    ]
  );

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Program_name",
      "College_name",
      "Duration",
    ]);
  }, []);

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Program_name",
    "College_name",
    "College_about",
    "Duration",
    "Description",
    "Visa_processing_days",
    "Acceptance_letter",
    "Acceptable_band",
    "Module",
    "Application_fee",
    "Credentials",
    "Stream_id",
    "Program_level",
    "Cost_of_living",
    "Currency",
    "Grade_score",
    "Overall_band",
    "Pte_score",
    "Tofel_point",
    "English_Language",
    "Foundation_Fee",
    "Tuition Fees",
    "Other_Fees",
    "Other_comment",
    "Duolingo_score",
  ]);

  function titleCase(str) {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const handleOk = () => {
    // Check for duplicate columns before updating state
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumnsPrograms",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  const resetAllCheckboxes = () => {
    setTempColumnsTable([...selectedColumnsTable]);
  };

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsPrograms");
    resetAllCheckboxes();

    setSelectedColumnsTable([
      "Sr.",
      "Program_name",
      "College_name",
      "Duration",
    ]);
    setTempColumnsTable(["Sr.", "Program_name", "College_name", "Duration"]);
  };

  const toggleTopStatus = (pId) => {
    const config = { headers: { Authorization: `Bearer ${state.adminToken}` } };
    let data = { pId, sId };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/toggletopstatus",
        data,
        config
      )
      .then((res) => { })
      .catch((err) => { });
  };

  const handleSearch = () => {
    const programNames = selectedOptions.map((option) => option.label);
    const collegeId = _id;
    const searchData = { ...inputValues, collegeId };

    // Add program_names to searchData only if it's not empty
    if (programNames.length > 0) {
      searchData.program_name = programNames;
    }

    // Remove empty values from the searchData object
    for (const key in searchData) {
      if (searchData.hasOwnProperty(key) && !searchData[key]) {
        delete searchData[key];
      }
    }

    const isEmpty = Object.values(searchData).every((value) => value === "");

    if (isEmpty) {
      fetchData();
    } else {
      setState((prevState) => ({ ...prevState, isWaiting: true }));
      axios
        .post(
          process.env.REACT_APP_NODE_URL + "/admin/getnewPrograms",
          searchData
        )
        .then((response) => {
          setApiData(response.data.details);
          setState((prevState) => ({ ...prevState, isWaiting: false }));
        })
        .catch((error) => {
          setState((prevState) => ({ ...prevState, isWaiting: false }));
        });
    }
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const downloadCSVdata = () => {
    setIsDownloading(true);
    axios
      .get(process.env.REACT_APP_NODE_URL + "/v2/api/getDataForCsv/" + id)
      .then((response) => {
        const collegeData = response.data.details;
        const csvData = collegeData.map((college) => {
          const { _id, ...rest } = college;
          return rest;
        });
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        const collegeName = collegeData[0]?.college_name || "college_data";
        a.href = url;
        a.download = `${collegeName}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success('CSV Downloaded Successfully.');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleSelectMenuClick = (event) => {
    event.stopPropagation();
  };

  const startLoader = () => {
    setisrotating(true);
    fetchData();
    setSelectedOptions([]);
  };

  const handleEdit = (description, id) => {
    setEditedDescription(description);
    setProId(id)
    setIsModalOpen(true);
    setaboutIsModalOpen(false);
  };
  const handleEditAbout = (about, id) => {
    setEditedAbout(about);
    setaboutId(id)
    setaboutIsModalOpen(true);
    setIsModalOpen(false);
  };
  const handleEditprogramName = (programnName, id) => {
    setEditedprogramName(programnName);
    setprogramNameID(id)
    setisaprogramNameModalOpen(true);
  };

  const handleUpdate = (proId, editedDescription) => {
    let data = { programId: proId, description: editedDescription };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/v2/api/updateProgram",
        data,
      )
      .then((res) => {
        if (res.data.status == "1") {
          toast.success("Description updated sucessfully")
          setIsModalOpen(false);
          fetchData();
          setView(false)
        } else {
          toast.error("Error")
        }
      })
      .catch((err) => { });
  };
  const handleUpdateprogramName = (programNameID, editedprogramName) => {
    let data = { programId: programNameID, program_name: editedprogramName };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/v2/api/updateProgram",
        data,
      )
      .then((res) => {
        if (res.data.status == "1") {
          toast.success("Program name updated sucessfully")
          setisaprogramNameModalOpen(false);
          fetchData();
          setView(false)
        } else {
          toast.error("Error")
        }
      })
      .catch((err) => { });
  };
  const handleUpdateAbout = (aboutID, editedAbout) => {
    let data = { collegeId: aboutID, college_about: editedAbout };
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/v2/api/updateCollegeAbout",
        data,
      )
      .then((res) => {
        if (res.data.status == "1") {
          toast.success("About updated sucessfully")
          setaboutIsModalOpen(false);
          fetchData();
          setView(false)
        } else {
          toast.error("Error")
        }
      })
      .catch((err) => { });
  };

  const handleDescriptionChange = (e) => {
    setEditedDescription(e.target.value);
    setView(true)
  }
  const handleprogramnamechange = (e) => {
    setEditedprogramName(e.target.value);
    setView(true)
  }
  const handleaboutchange = (e) => {
    setEditedAbout(e.target.value);
    setView(true)
  }

  return (
    <>
      <ToastContainer />
      <div heading_title={"Program List"}>
        <>
          <div className="row min-height-vh-100">
            <div className="row p-45 mob-padding ">
              <div className="col-12">
                <div className="setting_icon my-4 flex flex-row gap-3">

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 gap-4 items-center">
                    <div className="mb-4" style={{ width: "200px" }}>
                      <label
                        htmlFor="program_name"
                        className="text-gray-700 block font-semibold"
                      >
                        Program Name
                      </label>
                      <Select
                        className="capitalize"
                        ref={selectRef}
                        options={programNames?.map((country) => {
                          return { label: country, value: country };
                        })}
                        value={selectedOptions}
                        isMulti
                        onChange={(newValue) => {
                          if (!newValue || !newValue.length) {
                            fetchData();
                          }
                          setSelectedOptions(newValue); // Update selectedOptions
                        }}
                        labelledBy="program_name"
                        searchable={true} // Enable search functionality
                        searchValue={searchValue} // Set the search input value
                        onSearch={setSearchValue} // Handle the search input change
                        hideSelectedOptions={false}
                        menuIsOpen={isDropdownOpen}
                        onFocus={() => setIsDropdownOpen(true)}
                        onBlur={() => setIsDropdownOpen(false)}
                        menuProps={{ onClick: handleSelectMenuClick }}
                      />
                      {resultSuggestion?.length > 0 && (
                        <ul className="dropdown-options">
                          {resultSuggestion.map((option) => (
                            <li key={option}>{option}</li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <button
                      onClick={handleSearch}
                      className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out ml-3"
                    >
                      Find
                    </button>
                  </div>

                  <div className="btn_outerxx mb-3 mt-5">
                    <div className="flex">
                      <button
                        onClick={downloadCSVdata}
                        className={`bg-[#1a7335] text-white py-2 px-5 rounded-lg hover:bg-[#065f46] mr-3 ${isDownloading ? "cursor-not-allowed" : ""
                          }`}
                        disabled={isDownloading}
                      >
                        {isDownloading ?
                          "Downloading..." :
                          <div className="flex gap-2 items-center">
                            <FiDownload className="text-[20px]" /> CSV</div>
                        }
                      </button>
                      <div className="reload-icon mr-2">
                        <i
                          className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                          onClick={startLoader}
                        />
                      </div>
                      <AiFillSetting
                        className="Bar_filter"
                        onClick={() => setModalCode(!ModalCode)}
                      />
                    </div>
                    {ModalCode ? (
                      <div className="modal_cover">
                        <div className="modal_inner select-col-popup">
                          <div className="header_modal">
                            <h1 className="capitalize font-bold text-lg mb-2">
                              select columns
                            </h1>
                            <p className="border-b pb-2 mb-2">
                              Select the column you want to display or hide.
                            </p>
                            <div
                              className="columns-popup"
                              style={{ justifyContent: "space-between" }}
                            >
                              {tableColumns.map((column, index) => {
                                if (
                                  selectedColumnsTable.includes(column.value)
                                ) {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        defaultChecked
                                        onClick={() => {
                                          let index =
                                            selectedColumnsTable.indexOf(
                                              column.value
                                            );
                                          selectedColumnsTable.splice(index, 1);
                                          setTempColumnsTable([
                                            ...selectedColumnsTable,
                                          ]);
                                        }}
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                } else {
                                  return (
                                    <p className="mb-0 gap-2">
                                      <input
                                        type="checkbox"
                                        id={column.value}
                                        onClick={() => {
                                          selectedColumnsTable.push(
                                            column.value
                                          );
                                          setTempColumnsTable(
                                            selectedColumnsTable
                                          );
                                        }}
                                      />
                                      <label htmlFor={column.value}>
                                        {formatLabel(column.label)}
                                      </label>
                                    </p>
                                  );
                                }
                              })}
                            </div>
                            <div className="button-part flex justify-end items-center gap-4">
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleResetAll}
                              >
                                Reset all
                              </button>
                              <button
                                className="py-2 px-6"
                                type="btn"
                                onClick={handleOk}
                              >
                                Ok
                              </button>
                            </div>
                            <GrClose
                              className="close"
                              onClick={() => setModalCode(false)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {isModalOpen && (
                  <div className="modal_cover">
                    <div className="modal_inner select-col-popup">
                      <div className="header_modal">
                        <h2 className="list_heading text-lg font-bold ">Edit Description:</h2>
                        <textarea
                          id="editedDescription"
                          value={editedDescription}
                          // onChange={(e) => setEditedDescription(e.target.value)}
                          onChange={handleDescriptionChange}
                          rows="15"
                          cols="30"
                          className="border p-2 outline-none my-3"
                        />
                        <div class="button-part">
                          <div>
                            {view && (
                              <button className={`py-2 px-6 ${!view && 'cursor-not-allowed opacity-50'}`}
                                onClick={() => handleUpdate(proId, editedDescription)}
                                disabled={!view}>
                                Update
                              </button>
                            )}
                          </div>
                          <div>
                          </div>
                        </div>
                        <GrClose
                          className="close"
                          onClick={() => setIsModalOpen(false)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isaboutModalOpen && (
                  <div className="modal_cover">
                    <div className="modal_inner select-col-popup">
                      <div className="header_modal">
                        <h2 className="list_heading text-lg font-bold ">Edit About:</h2>
                        <textarea
                          id="editedAbout"
                          value={editedAbout}
                          // onChange={(e) => setEditedDescription(e.target.value)}
                          onChange={handleaboutchange}
                          rows="15"
                          cols="30"
                          className="border p-2 outline-none my-3"
                        />
                        <div class="button-part">
                          <div>
                            {view && (
                              <button className={`py-2 px-6 ${!view && 'cursor-not-allowed opacity-50'}`}
                                onClick={() => handleUpdateAbout(aboutID, editedAbout)}
                                disabled={!view}>
                                Update
                              </button>
                            )}
                          </div>
                          <div>
                          </div>
                        </div>
                        <GrClose
                          className="close"
                          onClick={() => setaboutIsModalOpen(false)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {isaprogramNameModalOpen && (
                  <div className="modal_cover">
                    <div className="modal_inner select-col-popup">
                      <div className="header_modal">
                        <h2 className="list_heading text-lg font-bold ">Edit Programm Name:</h2>
                        <textarea
                          id="editedprogramName"
                          value={editedprogramName}
                          // onChange={(e) => setEditedDescription(e.target.value)}
                          onChange={handleprogramnamechange}
                          rows="5"
                          cols="30"
                          className="border p-2 outline-none my-3"
                        />
                        <div class="button-part">
                          <div>
                            {view && (
                              <button className={`py-2 px-6 ${!view && 'cursor-not-allowed opacity-50'}`}
                                onClick={() => handleUpdateprogramName(programNameID, editedprogramName)}
                                disabled={!view}>
                                Update
                              </button>
                            )}
                          </div>
                          <div>
                          </div>
                        </div>
                        <GrClose
                          className="close"
                          onClick={() => setisaprogramNameModalOpen(false)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="card mb-4 mt-2 table_postioning">
                  <div className="card-body px-0 pt-0 pb-2 table-border">
                    <div className="overflow-auto card  m-0 col-12 px-0 pt-0 pb-2 table-overflow border">
                      {state.isWaiting ? (
                        <div>
                          <center className="w-full my-10">
                            <img src={loader} alt="" />
                          </center>
                        </div>
                      ) : apiData &&
                        apiData.collegePrograms &&
                        apiData.collegePrograms.length > 0 ? (
                        <table className="table-auto overflow-scroll w-full agent-table files-table">
                          <thead>
                            <tr>
                              {tableColumns.map((col) => {
                                if (selectedColumnsTable.includes(col.label)) {
                                  return (
                                    <th className="p-2 max-width text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                      {formatLabel(col.label)}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {apiData &&
                              apiData.collegePrograms &&
                              apiData.collegePrograms.map((program, index) => {
                                return (
                                  <tr key={program._id}>
                                    {selectedColumnsTable.includes("Sr.") && (
                                      <td className="border-2 p-2 max-width">
                                        {10 * (state.currentPage - 1) + index + 1}
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes(
                                      "Program_name"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.program_name}
                                          <button onClick={() => handleEditprogramName(program?.program_name, program._id)}>
                                            <BiEdit style={{ width: "20px", height: "20px", marginBottom: "-6px", color: "#19632f" }} />
                                          </button>
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "College_name"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {apiData?.college?.college_name &&
                                            titleCase(
                                              apiData?.college.college_name
                                            )}
                                          <br></br>
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "College_about"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {apiData?.college?.college_about &&
                                            titleCase(
                                              apiData?.college.college_about
                                            )}
                                          <button onClick={() => handleEditAbout(apiData?.college?.college_about, apiData?.college?._id)}>
                                            <BiEdit style={{ width: "20px", height: "20px", marginBottom: "-6px", color: "#19632f" }} />
                                          </button>
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Duration"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.duration} Years
                                        </td>
                                      )}

                                    {selectedColumnsTable.includes(
                                      "Description"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.description}
                                          <button onClick={() => handleEdit(program?.description, program._id)}>
                                            <BiEdit style={{ width: "20px", height: "20px", marginBottom: "-6px", color: "#19632f" }} />
                                          </button>
                                        </td>
                                      )}

                                    {selectedColumnsTable.includes(
                                      "Visa_processing_days"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.visa_processing_days}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Acceptance_letter"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.acceptance_letter}
                                        </td>
                                      )}

                                    {selectedColumnsTable.includes("Acceptable_band") && (
                                      <td className="border-2 p-2 max-width">
                                        {program?.acceptable_band
                                          ? program?.acceptable_band
                                          : "-"}
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes(
                                      "Module"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.module
                                            ? program?.module
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Application_fee"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.application_fee
                                            ? program?.application_fee
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Credentials"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.credentials
                                            ? program?.credentials
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Stream_id"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.stream_id
                                            ? program?.stream_id
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Program_level"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.program_level
                                            ? program?.program_level
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Cost_of_living"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.cost_of_living
                                            ? program?.cost_of_living
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Currency"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.currency
                                            ? program?.currency
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Grade_score"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.grade_score
                                            ? program?.grade_score
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Overall_band"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.overall_band
                                            ? program?.overall_band
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Pte_score"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.pte_score
                                            ? program?.pte_score
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Duolingo_score"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.duolingo_lan
                                            ? program?.duolingo_lan
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Tofel_point"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.tofel_point
                                            ? program?.tofel_point
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "English_Language"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.english_Language
                                            ? program?.english_Language
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Foundation_Fee"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.foundation_Fee
                                            ? program?.foundation_Fee
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Tuition Fees"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.min_tution_fee_per_semester}$-
                                          {program?.max_tution_fee}$
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Other_Fees"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.other_Fees
                                            ? program?.other_Fees
                                            : "-"}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Other_comment"
                                    ) && (
                                        <td className="border-2 p-2 max-width">
                                          {program?.other_comment
                                            ? program?.other_comment
                                            : "-"}
                                        </td>
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <center className="w-full my-10">
                            <img src={loader} alt="" />
                          </center>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ProgramsList;
