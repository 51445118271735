import React, { useEffect } from "react";
import H_about from "../images/h_about.jpg";
import Rect from "../images/rect.png";
import { useSelector, useDispatch } from "react-redux";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const landingPage = useSelector((state) => state.landingPage);
  return (
    <div>
      <div className="about-part abt-upr relative mt-16 px-4 lg:px-0">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-2  items-center">
            <div>
              <div className="img-part relative">
                <img
                  className="w-full main-up-img"
                  src={landingPage.baseUrl + landingPage.about.image}
                  alt="about"
                />
                <img className="bm-rect absolute" src={Rect} alt="" />
              </div>
            </div>
            <div className="lg:px-10">
              <h2 className="text-leftfont-bold text-6xl pb-5 mt-16 relative text-center">
                <span>
                  <strong>About</strong>
                </span>
                Us
              </h2>
              <p className="text-black tracking-widest leading-relaxed text-center text-justify ">
                {landingPage.about.text}
              </p>
              <a
                href="/about"
                class="hover:bg-[#065f46] text-white font-bold py-2 px-3 rounded-full mt-8 lg:mt-14 ml-auto flex items-center"
              >
                Learn More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
