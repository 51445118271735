import axios from "axios";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import ReactPaginate from "react-paginate";
import ButtonPrimary from "../../../common/Buttons/ButtonPrimary";
import { getToken } from "../../../helper/auth";
import StudentDocumentsPopup from "./StudentDocumentsPopup";
import StudentEmbassyDocumentsPopup from "./StudentEmbassyDocumentsPopup";
import { ToastContainer, toast } from "react-toastify";
import { AiFillSetting } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../../images/loader.gif";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment';
import { FiDownload } from "react-icons/fi";
import Papa from 'papaparse';

const itemsPerPage = 10;

const FilesList = ({ type }) => {
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const [statusModal, setstatusModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [fileId, setfileId] = useState("");
  const [isrotating, setisrotating] = useState(false);
  const [collegeName, setCollegeName] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  //
  const [selectedCountryOptions, setselectedCountryOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  // 
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null)

  const [selectType, setSelectType] = useState([]);
  const [selectfile, setSelectfile] = useState([]);
  const [selectCollegeName, setSelectCollegeName] = useState([]);
  const [ModalCode, setModalCode] = useState(false);
  const [ModalCodeMain, setModalCodeMain] = useState(false);
  const [EmbassyDocsModal, setEmbassyDocsModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("enrolled");
  const [activeStudentId, setActiveStudentId] = useState(-1);
  const [activeFileId, setActiveFileId] = useState(-1);
  const [mainFileId, setmainFileId] = useState(-1);
  const [activeFileIndex, setActiveFileIndex] = useState(-1);
  const [popStat, setPopStat] = useState();
  const [indStat, setIndStat] = useState();
  const [PaasCountry, setPaasCountry] = useState("");
  const [onClickNew, setOnClickNew] = useState(false);
  const [selectFileIds, setselectFileIds] = useState([]);
  const [csvdata, setcsvdata] = useState([]);
  const [state, setState] = useState({
    isWaiting: false,
    files: [],
    baseUrl: "",
    adminToken: getToken("admin"),
    totalPages: "",
    currentPage: 1,
    remark: "",
    approveFileLoading: -1,

    activeFileIndex: -1,
    popup: false,
  });
  const [statusList, setStatusList] = useState([]);
  const [selectedStatusColor, setSelectedStatusColor] = useState();
  // console.log("selectedStatusColor", selectedStatusColor);
  useEffect(() => {
    getPaginationData(state.currentPage);

  }, [window.location.href]);


  const monthsArray = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_URL}/v2/api/getCountries`);
      const countries = response?.data?.details;
      const result = countries.map((country) => ({
        label: country.countryName,
        value: country.countryId,
      }));
      setCountryOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async () => {
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchStates?countryIds=${selectedCountryIds.join(",")}`
      );
      const states = response?.data?.details;
      const result = states.map((state) => ({
        label: state.stateName,
        value: state.stateId,
      }));
      setStateOptions(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getCities = async () => {
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getSearchCities?stateIds=${selectedStateIds.join(",")}`
      );
      const cities = response?.data?.details;
      const result = cities.map((city) => ({
        label: city.cityName,
        value: city.cityId,
      }));
      setCityOptions(result);
    } catch (error) {
      console.error(error);
    }
  };
  const getColleges = async () => {
    const selectedCountryIds = selectedCountryOptions.map((option) => option.value);
    const selectedStateIds = selectedStateOptions.map((state) => state.value);
    const selectedCityIds = selectedCityOptions.map((city) => city.value);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/getCollegeWithProgram?country=${selectedCountryIds.join(",")}&state=${selectedStateIds.join(",")}&city=${selectedCityIds.join(",")}`
      );
      const colleges = response?.data?.detail;
      const result = colleges.map((college) => ({
        label: college.collegeName,
        value: college.collegeName,
      }));
      setCollegeOptions(result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getCountries();
      await getStates();
      await getCities();
      await getColleges();
    };
    fetchData();
  }, [selectedCountryOptions, selectedStateOptions, selectedCityOptions]);

  const closeModalCode = () => {
    setModalCode(false);
    // getPaginationData(state.currentPage);
  };

  // on click for pagination
  const getPaginationData = (
    page,
    searchName,
    selectedCountry,
    selectedCity,
    selectedState,
    selectType,
    selectCollegeName,
    selectfile,
    date
  ) => {
    // console.log("Coutnry Otio: ", selectedCountry, " type of searchName", searchName)
    setState({
      ...state,
      isWaiting: true,
    });

    const config = { headers: { Authorization: `Bearer ${state.adminToken}` } };
    let data;

    // console.log("DONNEEEEEEE")

    if (searchName === undefined) {
      data = {
        currentPage: page,
        searchName: "",
        fileId: [],
        country: [],
        type: [],
        collegeName: [],
      };
    } else {
      const countryValues = Array.isArray(selectedCountry)
        ? selectedCountry.map((country) => country.value)
        : [];

      const stateValues = Array.isArray(selectedState)
        ? selectedState.map((state) => state.value)
        : [];

      const cityValues = Array.isArray(selectedCity)
        ? selectedCity.map((city) => city.value)
        : [];

      const collegeNameValues = Array.isArray(selectCollegeName)
        ? selectCollegeName.map((college) => college.value)
        : [];
      const typeValues = Array.isArray(selectType)
        ? selectType.map((type) => type.value)
        : [];
      const filevalues = Array.isArray(selectfile)
        ? selectfile.map((file) => file.value)
        : [];
      data = {
        currentPage: page,
        searchName: searchName,
        fileId: filevalues,
        country: countryValues,
        state: stateValues,
        city: cityValues,
        type: typeValues,
        collegeName: collegeNameValues,
        date: startDate ? startDate?.format("YYYY-MM-DD") : "",
      };
    }
    axios
      .post(
        process.env.REACT_APP_NODE_URL + "/admin/getEnrollPrograms",
        data,
        config
      )
      .then((res) => {
        const collegeName = res.data.details.files.map(
          (file) => file.college_details.college_name
        );
        setCollegeName(collegeName);
        axios
          .get(process.env.REACT_APP_NODE_URL + "/admin/fileStatus", config)
          .then((statusResponse) => {
            setState((state) => ({
              ...state,
              files: res.data.details.files,
              baseUrl: res.data.details.baseUrl,
              totalPages: res.data.totalPages,
              isWaiting: false,
              currentPage: page,
            }));
            setcsvdata(res.data.details.csvdata)
            setisrotating(false);
            setStatusList(statusResponse.data.details.list);
          })
          .catch((err) => { });
      })
      .catch((err) => {
        setState({
          ...state,
          isWaiting: false,
        });
      });
  };

  const downloadCSVdata = () => {
    setIsDownloading(true);

    const headers = [
      "Sr", "Date", "College", "Country", "File Id", "Student Name", "Agent Name", "Intake", "Course",
      "Application Fees", "Fees Paid", "Status"];
    const csvRows = [headers];

    csvdata.forEach((enroll, index) => {
      const status = enroll.enroll_status
        ? enroll.enroll_status
          .replace(/_/g, " ")
          .toLowerCase()
          .split(" ")
          .map((word) =>
            word === "ol" ? "OL" : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join(" ")
        : "--";
      const agentName = enroll?.agents_details?.[0]
        ? `${enroll.agents_details[0].first_name} ${enroll.agents_details[0].last_name}`
        : '--';
      const collegeName = enroll.college_details.college_name.charAt(0).toUpperCase() + enroll.college_details.college_name.slice(1);
      csvRows.push([
        index + 1, // Sr starting from 1
        new Date(enroll.createdAt).toLocaleDateString(), // Date
        collegeName, // College with first letter capitalized
        enroll.college_details.country, // Country
        enroll.fileId, // File_Id
        `${enroll.student_details.firstName} ${enroll.student_details.lastName}`, // Student_Name
        agentName, // Agent_Name or '--' if not available
        enroll.intake ? `${monthsArray[enroll.intake.month]}, ${enroll.intake.year}` : '--', // Intake
        enroll.college_details?.collegeprograms?.program_name || '--', // Course
        `${enroll.college_details?.collegeprograms?.application_fee} ${enroll.college_details?.collegeprograms?.currency}`, // Application_Fees
        enroll.fees_status || '--', // Fees_Paid
        status // Status
      ]);
    });

    const csvString = Papa.unparse(csvRows);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'enrollments.csv');
    document.body.appendChild(link);
    link.click();

    toast.success('CSV Downloaded Successfully.');
    setIsDownloading(false);
  };

  const handlenewStatus = async (_id, status) => {
    try {
      const body = {
        enrollId: _id,
        newstatus: false,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/v2/api/changeEnrollStatus`,
        body
      );
      console.log("API Response:", response.data);
      setOnClickNew(true);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const udateFileStatus = (status, index) => {
    let fileData = state.files[index];
    setState({
      ...state,
      approveFileLoading: index,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${state.adminToken}`,
      },
    };

    const data = {
      fileId: fileData._id,
      status: status,
    };

    setstatusModal(false);
    axios
      .patch(
        process.env.REACT_APP_NODE_URL + "/admin/updateEnrollStatus",
        data,
        config
      )
      .then((res) => {
        let OldFiles = state.files;
        OldFiles[index].enroll_status = status;
        setState({
          ...state,
          files: OldFiles,
          approveFileLoading: -1,
        });
        // add history
        const addHistoryData = {
          userRole: "Admin",
          comment: status,
          fileId: fileData.fileId,
        };

        axios.post(
          process.env.REACT_APP_NODE_URL + "/v2/api/addHistory",
          addHistoryData
        );
        if (res.data.status == "1") {
          toast.success(res.data.message);
          getPaginationData(state.currentPage);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setState({
          ...state,
          approveFileLoading: -1,
        });
      });
  };

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Date", value: "Date" },
    { label: "College", value: "College" },
    { label: "File_Id", value: "File_Id" },
    { label: "Student_Name", value: "Student_Name" },
    { label: "Agent_Name", value: "Agent_Name" },
    { label: "Intake", value: "Intake" },
    { label: "Documents", value: "Documents" },
    // { label: "Fees_Receipt", value: "Fees_Receipt" },
    // { label: "Embassy_Documents", value: "Embassy_Documents" },
    { label: "Course", value: "Course" },
    { label: "Application_Fees", value: "Application_Fees" },
    { label: "Fees_Paid", value: "Fees_Paid" },
    { label: "Country", value: "Country" },
    { label: "Status", value: "Status" },
  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsEnrolled")) || [
      "Sr.",
      "Date",
      "College",
      "File_Id",
      "Student_Name",
      "Agent_Name",
      "Intake",
      "Documents",
      // "Embassy_Documents",
      "Status",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Date",
    "College",
    "File_Id",
    "Student_Name",
    "Agent_Name",
    "Intake",
    "Documents",
    // "Embassy_Documents",
    "Status",
  ]);

  useEffect(() => {
    setSelectedColumnsTable([
      "Sr.",
      "Date",
      "College",
      "File_Id",
      "Student_Name",
      "Agent_Name",
      "Intake",
      "Documents",
      // "Embassy_Documents",
      "Status",
    ]);
  }, []);

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns;
    if (tempColumnsTable.includes(columnValue)) {
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    localStorage.setItem(
      "selectedColumnsEnrolled",
      JSON.stringify(updatedColumns)
    );
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const fetchFileIdList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/v2/api/fileIdList`
      );
      setselectFileIds(response.data.detail);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFileIdList();
  }, [])

  // console.log(selectFileIds)

  const handleOk = () => {
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumnsEnrolled",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCodeMain(false);
  };

  useEffect(() => {
    const storedColumns = JSON.parse(
      localStorage.getItem("selectedColumnsEnrolled")
    );
    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Date",
        "College",
        "File_Id",
        "Student_Name",
        "Agent_Name",
        "Intake",
        "Documents",
        // "Embassy_Documents",
        "Status",
      ]);
      setTempColumnsTable([
        "Sr.",
        "Date",
        "College",
        "File_Id",
        "Student_Name",
        "Agent_Name",
        "Intake",
        "Documents",
        // "Embassy_Documents",
        "Status",
      ]);
    }
  }, []);

  useEffect(() => {
    setTempColumnsTable([
      "Sr.",
      "Date",
      "College",
      "File_Id",
      "Student_Name",
      "Agent_Name",
      "Intake",
      "Documents",
      // "Embassy_Documents",
      "Status",
    ]);
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsEnrolled");
    setSelectedColumnsTable([
      "Sr.",
      "Date",
      "College",
      "File_Id",
      "Student_Name",
      "Agent_Name",
      "Intake",
      "Documents",
      // "Embassy_Documents",
      "Status",
    ]);
    setTempColumnsTable([
      "Sr.",
      "Date",
      "College",
      "File_Id",
      "Student_Name",
      "Agent_Name",
      "Intake",
      "Documents",
      // "Embassy_Documents",
      "Status",
    ]);
  };

  const handleSearch = () => {
    getPaginationData(
      1,
      searchName,
      selectedCountryOptions,
      selectedCityOptions,
      selectedStateOptions,
      selectType,
      selectedCollegeOptions,
      selectfile,
      startDate
    );
  };

  const handleConfirm = (id, index) => {
    setstatusModal(true);
    setPopStat(id);
    setIndStat(index);
  };

  const options = statusList.map((status) => ({
    label: status.title
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
    value: status.title,
  }));

  const fileOptions = selectFileIds.map((name) => ({
    label: name.fileId,
    value: name.fileId
  }));

  const startLoader = () => {
    setisrotating(true);
    fetchFileIdList();
    getPaginationData(state.currentPage);
    setSelectedCountry("");
    setSearchName("");
    setSelectfile([]);
    setSelectType([]);
    setSelectedCollegeOptions([]);
    setselectedCountryOptions([]);
    setSelectedCityOptions([]);
    setSelectedStateOptions([]);
    setStartDate(null);
  };

  const handleClear = () => {
    fetchFileIdList();
    getPaginationData(state.currentPage);
    setSelectedCountry("");
    setSearchName("");
    setSelectfile([]);
    setSelectType([]);
    setSelectedCollegeOptions([]);
    setselectedCountryOptions([]);
    setSelectedCityOptions([]);
    setSelectedStateOptions([]);
    setStartDate(null);
  };

  // console.log("PAESSS", state?.currentPage)

  return (
    <>
      <ToastContainer />
      <div heading_title={"Total Students"}>
        <>
          <div className="btn_outerxx mb-3 mt-5">
            {ModalCodeMain ? (
              <div className="modal_cover">
                <div className="modal_inner select-col-popup">
                  <div className="header_modal">
                    <h1 className="capitalize font-bold text-lg mb-2">
                      select columns
                    </h1>
                    <p className="border-b pb-2 mb-2">
                      Select the column you want to display or hide.
                    </p>
                    <div
                      className="columns-popup"
                      style={{ justifyContent: "flex-start" }}
                    >
                      {tableColumns.map((column, index) => {
                        if (selectedColumnsTable.includes(column.value)) {
                          return (
                            <p className="mb-0 gap-2">
                              <input
                                type="checkbox"
                                id={column.value}
                                checked={tempColumnsTable.includes(
                                  column.value
                                )}
                                onChange={() =>
                                  handleColumnCheckboxChange(column.value)
                                }
                              />
                              <label htmlFor={column.value}>
                                {formatLabel(column.label)}
                              </label>
                            </p>
                          );
                        } else {
                          return (
                            <p className="mb-0 gap-2">
                              <input
                                type="checkbox"
                                id={column.value}
                                onClick={() => {
                                  selectedColumnsTable.push(column.value);
                                  setTempColumnsTable(selectedColumnsTable);
                                }}
                              />
                              <label htmlFor={column.value}>
                                {formatLabel(column.label)}
                              </label>
                            </p>
                          );
                        }
                      })}
                    </div>
                    <div className="button-part flex justify-end items-center gap-4">
                      <button
                        className="py-2 px-6"
                        type="btn"
                        onClick={handleResetAll}
                      >
                        Reset all
                      </button>
                      <button
                        className="py-2 px-6"
                        type="btn"
                        onClick={handleOk}
                      >
                        Ok
                      </button>
                    </div>
                    <GrClose
                      className="close"
                      onClick={() => setModalCodeMain(false)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`overlay ${state.popup == true ? "active" : ""}`}
            onClick={() =>
              setState({
                ...state,
                popup: false,
                activeFileIndex: -1,
              })
            }
          ></div>
          <div
            className={`pendingDoctoast popup  ${state.popup == true ? "active" : ""
              }`}
          >
            {state?.activeFileIndex != -1 && (
              <div className="w-full p-5">
                <h1 className="text-2xl font-bold mb-5">Fees Receipt Swift Copy</h1>
                <div className="mb-5">
                  {[
                    "TUTION_FEES_PROCESSING",
                    "TUTION_FEES_REJECTED",
                    "FILE_LODGED",
                    "FILE_LODGED_DOCS_PROCESSING",
                    "FILE_LODGED_DOCS_REJECTED",
                    "VISA_AWAITED",
                    "VISA_APPROVED",
                    "VISA_REFUSED",
                    "CLOSE",
                  ].includes(
                    state?.files[state?.activeFileIndex]?.enroll_status
                  ) && (
                      <a
                        className="inline-block text-blue-500 mx-2 px-4 py-2 border border-blue-500 hover:bg-blue-500 hover:text-white"
                        href={`${process.env.REACT_APP_NODE_URL
                          }/uploads/student/${state?.files[state.activeFileIndex]?.tution_fees_recepit
                          }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    )}
                </div>
              </div>
            )}
          </div>
          {ModalCode && (
            <>
              <StudentDocumentsPopup
                setModalCode={setModalCode}
                sId={activeStudentId}
                fId={activeFileId}
                fileID={mainFileId}
                country={PaasCountry}
                documents={true}
                closeModalCode={closeModalCode}
                getPaginationData={getPaginationData}
                paginationState={state.currentPage}
              />
            </>
          )}
          {EmbassyDocsModal && (
            <div className="modal_cover">
              <div className="modal_inner select-col-popup">
                <div className="header_modal files_documents">
                  <StudentEmbassyDocumentsPopup
                    setEmbassyDocsModal={setEmbassyDocsModal}
                    state={state}
                    setState={setState}
                    fId={activeFileId}
                    documents={true}
                    activeFileIndex={activeFileIndex}
                  />

                  <GrClose
                    className="close"
                    onClick={() => setEmbassyDocsModal(false)}
                  />
                </div>
              </div>
            </div>
          )}
          {statusModal ? (
            <div className="modal_cover">
              <div className="modal_inner select-col-popup">
                <div className="header_modal">
                  <h2 className="capitalize font-bold text-lg mb-2">
                    Change the status to {selectedOption.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())} ?
                  </h2>

                  <div className="button-part flex justify-end items-center gap-4">
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={() => setstatusModal(false)}
                    >
                      No
                    </button>
                    <button
                      className="py-2 px-6"
                      type="btn"
                      onClick={() => udateFileStatus(popStat, indStat)} //call real function here.
                    >
                      Yes
                    </button>
                  </div>
                  <GrClose
                    className="close"
                    onClick={() => setstatusModal(false)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Filter Section */}
          <div className="row min-height-vh-100">
            <div className="row p-45 mob-padding ">
              <div className="setting_icon my-4 flex gap-3 items-center">
                <div className="flex flex-wrap justify-center md:justify-start gap-4 items-center">
                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      Status
                    </label>
                    <Select
                      isMulti
                      name="type"
                      options={options}
                      value={selectType}
                      className="block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      onChange={(selectedOptions) => {
                        setSelectType(selectedOptions);
                      }}
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      Country
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={countryOptions}
                      value={selectedCountryOptions}
                      isMulti
                      onChange={(newValue) => {
                        setselectedCountryOptions(newValue);
                      }}
                      labelledBy="Country Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      State
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={stateOptions}
                      value={selectedStateOptions}
                      isMulti
                      onChange={(newValue) => {
                        setSelectedStateOptions(newValue);
                      }}
                      labelledBy="State Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      City
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={cityOptions}
                      value={selectedCityOptions}
                      isMulti
                      onChange={(newValue) => {
                        setSelectedCityOptions(newValue);
                      }}
                      labelledBy="City Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      College Name
                    </label>
                    <Select
                      className="capitalize block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={collegeOptions}
                      value={selectedCollegeOptions}
                      isMulti
                      onChange={(newValue) => {
                        setSelectedCollegeOptions(newValue);
                      }}
                      labelledBy="College Name"
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      Student Name
                    </label>
                    <input
                      type="text"
                      className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none w-full"
                      placeholder="Student Name"
                      value={searchName}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-[111px] md:w-[200px]">
                    <label
                      htmlFor="country"
                      className="text-gray-700 block font-semibold"
                    >
                      File Id
                    </label>
                    <Select
                      isMulti
                      name="fileId"
                      className="block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                      options={fileOptions}
                      value={selectfile}
                      onChange={(selectedOptions) => {
                        setSelectfile(selectedOptions);
                      }}
                      searchable={true}
                      hideSelectedOptions={false}
                    />
                  </div>

                  <div className="w-[111px] md:w-[200px]">
                    <label htmlFor="dateRange">Date</label>
                    <div className="assesment-date-calender">
                      <DateRangePicker
                        startDate={startDate ? moment(startDate) : null}
                        startDateId="startDate"
                        onDatesChange={({ startDate }) => {
                          setStartDate(startDate);
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        isOutsideRange={() => false}
                        id="dateRangePicker"
                        label="Select Dates"
                      />
                    </div>
                  </div>

                  <div className="w-fit">
                    <button
                      onClick={handleSearch}
                      className="bg-[#1a7335] text-white font-bold h-10 w-24 mt-4 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                    >
                      Find
                    </button>
                  </div>

                  <div className="w-fit">
                    <button
                      onClick={handleClear}
                      className="bg-[#5555] text-[#515151] font-bold h-10 px-4 mt-4 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              {/* Filter section ends */}

              <div className="flex items-center mb-5 justify-end">
                <button
                  onClick={downloadCSVdata}
                  className={`bg-[#1a7335] text-white py-2 px-5 rounded-lg hover:bg-[#065f46] mr-3 ${isDownloading ? "cursor-not-allowed" : ""
                    }`}
                  disabled={isDownloading}
                >
                  {isDownloading ?
                    "Downloading..." :
                    <div className="flex gap-2 items-center">
                      <FiDownload className="text-[20px]" /> CSV</div>
                  }
                </button>
                <div className="reload-icon mx-3">
                  <i
                    className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                    onClick={startLoader}
                  />
                </div>
                <div className="">
                  <AiFillSetting
                    className="Bar_filter"
                    onClick={() => setModalCodeMain(!ModalCodeMain)}
                  />
                </div>
              </div>
              <div className="card-body px-0 pt-0 pb-2 table-border">
                {state.isWaiting ? (
                  <div>
                    <center className="w-full my-10">
                      <img src={loader} alt="" />
                    </center>
                  </div>
                ) : state.files && state.files.length > 0 ? (
                  // Table starts here
                  <div className="overflow-auto card  my-4 col-12 px-0 pt-0 pb-2 agent-table border enrolled-files table-overflow">
                    <table className="table-auto overflow-scroll w-full files-table">
                      <thead>
                        <tr>
                          {tableColumns.map((col) => {
                            if (selectedColumnsTable.includes(col.label)) {
                              return (
                                <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                  {formatLabel(col.label)}
                                </th>
                              );
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {state.files.map((enroll, index) => {
                          const startIndex = (state.currentPage - 1) * itemsPerPage;
                          const currentIndex = startIndex + index + 1;
                          return (
                            <tr
                              onClick={() => handlenewStatus(enroll._id)}
                              id={`file_${index}`}
                            >
                              {selectedColumnsTable.includes("Sr.") && (
                                <td className="p-2 border-2">
                                  <p className="text-xs font-weight-bold mb-0">
                                    <div className="flex items-center">
                                      <div className="mr-1">
                                        {currentIndex}
                                      </div>

                                      {enroll.new === true && (
                                        <span className={`bg-green-500 text-white text-[10px] p-1 rounded`}>
                                          New
                                        </span>
                                      )}
                                    </div>
                                  </p>
                                </td>
                              )}
                              {selectedColumnsTable.includes("Date") && (
                                <td className="p-2 border-2">
                                  {" "}
                                  {new Date(
                                    enroll.createdAt
                                  ).toLocaleDateString()}
                                </td>
                              )}
                              {selectedColumnsTable.includes("College") && (
                                <td
                                  className="border-2 p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left"
                                  scope="col"
                                >
                                  <div className="flex items-center">
                                    <div className="small-image-container">
                                      <img
                                        width={60}
                                        src={
                                          state.baseUrl +
                                          enroll.college_details
                                            .college_meta_details.collegeLogo
                                        }
                                        alt="logo"
                                      />
                                    </div>
                                    <p className="text-sm text-gray-900 font-light ps-2 pe-2 py-2 whitespace-nowrap capitalize">
                                      {enroll.college_details.college_name}
                                    </p>
                                  </div>
                                </td>
                              )}
                              {selectedColumnsTable.includes("File_Id") && (
                                <td className="p-4 text-blue-500 hover:text-blue-700  transition-colors duration-300">
                                  <div
                                    onClick={() => {
                                      navigate(
                                        `/admin/history/${enroll._id}/${enroll.fileId}`
                                      );
                                    }}
                                    className="flex cursor-pointer items-center"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6 mr-1 text-blue-300"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13 4V2H7v2H2v14h16V4h-5zM7 2h6v2H7V2zm7 14H6V8h8v8zm-2-6h2v2h-2v-2zm-3 0h2v2H9v-2zm-3 0h2v2H6v-2zm0-3h8v1H6V7z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <div className="overflow-hidden overflow-ellipsis text-blue mt-1">
                                      {enroll.fileId}
                                    </div>
                                  </div>
                                </td>
                              )}
                              {selectedColumnsTable.includes(
                                "Student_Name"
                              ) && (
                                  <td className="p-2 border-2">
                                    <span className="capitalize">
                                      {enroll.student_details.firstName}{" "}
                                      {enroll.student_details.lastName}
                                    </span>
                                  </td>
                                )}
                              {selectedColumnsTable.includes(
                                "Agent_Name"
                              ) && (
                                  <td className="p-2 border-2">
                                    <span className="capitalize">
                                      {enroll?.agents_details?.[0]?.first_name}{" "}
                                      {enroll?.agents_details?.[0]?.last_name}{" "}
                                    </span>
                                  </td>
                                )}
                              {selectedColumnsTable.includes("Intake") && (
                                <td className="p-2 border-2">
                                  {enroll?.intake ?
                                    `${monthsArray[enroll?.intake?.month]}, ${enroll?.intake?.year}` :
                                    '-'}
                                </td>
                              )}
                              {selectedColumnsTable.includes("Documents") && (
                                <>
                                  {enroll.enroll_status !==
                                    "OL_FEES_AND_DOCUMENTS_PENDING" &&
                                    enroll.enroll_status !==
                                    "OL_FEES_PAID_AND_DOCUMENTS_PENDING" &&
                                    enroll.enroll_status !==
                                    "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED" &&
                                    enroll.enroll_status !==
                                    "OL_FEES_PAID_AND_DOCUMENTS_REJECTED" ? (
                                    <td className="p-2 border-2">
                                      <ButtonPrimary
                                        title="Documents"
                                        onclick={() => {
                                          setActiveStudentId(enroll.student_id);
                                          setPaasCountry(
                                            enroll.college_details.country
                                          );
                                          setActiveFileId(enroll._id);
                                          setmainFileId(enroll.fileId);
                                          setModalCode(!ModalCode);
                                        }}
                                        loading={
                                          state.approveFileLoading === index
                                        }
                                      />
                                    </td>
                                  ) : (
                                    <td className="p-2 border-2">
                                      <button
                                        className="inline-flex justify-center rounded-md border border-transparent 
                                         py-2 px-4 text-sm font-medium text-white shadow-sm bg-[#5f5f5f]"
                                        style={{ cursor: "not-allowed" }}
                                      >
                                        Documents
                                      </button>
                                    </td>
                                  )}
                                </>
                              )}
                              {selectedColumnsTable.includes("Course") && (
                                <td
                                  className="p-2 border-2 hover-text"
                                  title={
                                    enroll.college_details?.collegeprograms
                                      ?.program_name || "--"
                                  }
                                >
                                  {enroll.college_details?.collegeprograms
                                    ?.program_name || "--"}
                                </td>
                              )}
                              {selectedColumnsTable.includes(
                                "Application_Fees"
                              ) && (
                                  <td className="p-2 border-2">
                                    {enroll.college_details?.collegeprograms
                                      ?.application_fee == 0 ? (
                                      "Free"
                                    ) : (
                                      <>
                                        {
                                          enroll.college_details?.collegeprograms
                                            ?.application_fee
                                        }{" "}
                                        {
                                          enroll.college_details?.collegeprograms
                                            ?.currency
                                        }
                                      </>
                                    )}
                                  </td>
                                )}
                              {selectedColumnsTable.includes("Fees_Paid") && (
                                <td className="p-2 border-2 normal">
                                  {enroll?.fees_status || "--"}
                                </td>
                              )}
                              {selectedColumnsTable.includes("Country") && (
                                <td className="p-2 border-2 capitalize">
                                  {enroll.college_details.country}
                                </td>
                              )}
                              {selectedColumnsTable.includes("Status") && (
                                <td>
                                  <select
                                    name=""
                                    id=""
                                    className="border rounded py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline mx-2"
                                    style={{
                                      background:
                                        statusList.find(
                                          (item) => item.title === enroll.enroll_status
                                        )?.color || "initial",
                                    }}
                                    value={enroll.enroll_status}
                                    onChange={(e) => {
                                      setSelectedOption(e.target.value);
                                      handleConfirm(e.target.value, index);
                                    }}
                                  >
                                    {statusList.map((status) => {
                                      const formattedStatus = status.title
                                        ? status.title
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .split(" ")
                                          .map((word) =>
                                            word === "ol" ? "OL" : word.charAt(0).toUpperCase() + word.slice(1)
                                          )
                                          .join(" ")
                                        : "--";

                                      const disabledStatuses = [
                                        "OL_FEES_AND_DOCUMENTS_PENDING",
                                        "OL_FEES_PAID_AND_DOCUMENTS_PENDING",
                                        "OL_FEES_PENDING_AND_DOCUMENTS_UNDER_VERIFICATION",
                                        "OL_FEES_PAID_AND_DOCUMENTS_UNDER_VERIFICATION",
                                        "OL_FEES_PENDING_AND_DOCUMENTS_REJECTED",
                                        "OL_FEES_PAID_AND_DOCUMENTS_REJECTED",
                                        "OL_FEES_PENDING_AND_DOCUMENTS_APPROVED",
                                        "OL_FEES_PAID_AND_DOCUMENTS_APPROVED",
                                      ];

                                      const additionalDisabledStatuses = [
                                        "COLLEGE/UNIVERSITY_APPLICATION_IN_PROCESS",
                                        "OL_APPLIED",
                                        "OL_REJECTED",
                                        "OL_RECEIVED",
                                        "TUTION_FEES_PAID",
                                        "FILE_LODGED",
                                        "VISA_PROCESSING",
                                        "VISA_AWAITED",
                                        "VISA_APPROVED",
                                        "REGISTER_AND_TRAVEL",
                                        "VISA_REFUSED",
                                        "DEFER_REQUESTED",
                                        "DEFER_INITIATED",
                                        "REFUND_REQUESTED",
                                        "REFUND_INITIATED",
                                      ];

                                      const isDisabled =
                                        disabledStatuses.includes(status.title) ||
                                        (additionalDisabledStatuses.includes(status.title) && !enroll.enablebuttons) ||
                                        (additionalDisabledStatuses.includes(status.title) && !enroll.intake)
                                      return (
                                        <option
                                          value={status.title}
                                          disabled={isDisabled}
                                        >
                                          {formattedStatus}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </td>
                              )}

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <div>
                      <p className="text-center p-5 text-red-500">
                        No data available.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {/* Pagination Added here */}
              <div className="pagination">
                <div className="pages">
                  {state?.totalPages >= 2 && (

                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      breakLabel="..."
                      pageCount={state.totalPages}
                      pageRangeDisplayed={2}
                      onPageChange={(event) => {
                        getPaginationData(event.selected + 1,
                          searchName,
                          selectedCountryOptions,
                          selectedCityOptions,
                          selectedStateOptions,
                          selectType,
                          selectedCollegeOptions,
                          selectfile,
                          startDate
                        );
                      }}
                      initialPage={state.currentPage - 1}
                      forcePage={state.currentPage - 1}
                      marginPagesDisplayed={1}
                      activeClassName="selected"
                      containerClassName="pagination"
                      renderOnZeroPageCount={null}
                      subContainerClassName="pages"
                      disableInitialCallback={true}
                      previousClassName={
                        state.currentPage === 0 ? "disabled" : ""
                      }
                      nextClassName={
                        state.currentPage === state.totalPages ? "disabled" : ""
                      }
                      previousLinkClassName={
                        state.currentPage === 0 ? "disabled-link" : ""
                      }
                      nextLinkClassName={
                        state.currentPage === state.totalPages - 1
                          ? "disabled-link"
                          : ""
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default FilesList;
