import axios from "axios";
import React, { useEffect, useState } from "react";
import loader from "../../../images/loader.gif";
import { getToken } from "../../../helper/auth";
import { requestForToken } from "../../../firebase";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

const AgentCommission = () => {
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [collegeName, setCollegeName] = useState([]);
  const [selectCollegeName, setSelectCollegeName] = useState();
  const [tableData, setTableData] = useState([]);
  const [agentId, setAgentId] = useState();
  const [state, setState] = useState({
    token: getToken("agent"),
    isWaiting: true,
    students: [],
    totalPages: 0,
    currentPage: 1,
  });

  useEffect(() => {
    const config = { headers: { Authorization: `Bearer ${state.token}` } };
    requestForToken().then((token) => {
      axios
        .post(
          process.env.REACT_APP_NODE_URL + "/agent/verifyToken",
          { token },
          config
        )
        .then((res) => {
          setAgentId(res.data.details.agent._id);
        })
        .catch((err) => { });
    });
  }, []);

  useEffect(() => {
    if (agentId) {
      getPaginationData(1);
    }
  }, [agentId]);

  const getPaginationData = (currentPage, searchName, selectCollegeName) => {
    setState({
      ...state,
      isWaiting: true,
      currentPage,
    });

    const collegeNameParam = Array.isArray(selectCollegeName)
      ? selectCollegeName.map((name) => name.value)
      : [];
    // console.log("checkkkkkk", collegeNameParam)
    const params = {
      collegeName: collegeNameParam.join(","),
      searchName,
      currentPage,
    };

    axios
      .get(process.env.REACT_APP_NODE_URL + "/student/agentComissionData", {
        params: { agentId, ...params },
      })
      .then((response) => {
        setTableData(response?.data?.students);
        console.log("agentResponse", response.data.students);
        const collegeName = response.data.students.map(
          (college) => college.collegeName
        );
        setCollegeName(collegeName);
        setState({
          ...state,
          isWaiting: false,
        });
        setisrotating(false)
      })
      .catch((error) => { });
  };

  const handleSearch = () => {
    getPaginationData(1, searchName, selectCollegeName);
  };

  const startLoader = () => {
    setisrotating(true);
    getPaginationData();
    setSearchName('')
  };

  return (
    <>
      <div className="row min-h-screen p-45">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="w-full inline-block min-w-full sm:px-6 lg:px-8">
            <div className="row">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 items-center my-5">
                <div className="mb-4">
                  <label
                    htmlFor="country"
                    className="text-gray-700 block font-semibold"
                  >
                    Student Name
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 py-2 px-4 w-full rounded-md focus:outline-none"
                    placeholder="Search By Student Name"
                    value={searchName}
                    onChange={(e) => {
                      setSearchName(e.target.value)
                    }}
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="country"
                    className="text-gray-700 block font-semibold"
                  >
                    College Name
                  </label>
                  <Select
                    isMulti
                    name="collegeName"
                    options={collegeName.map((college) => ({
                      value: college,
                      label: college,
                    }))}
                    className="block w-full flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                    value={selectCollegeName}
                    onChange={(selectedOptions) => {
                      setSelectCollegeName(selectedOptions);
                    }}
                    searchable={true}
                    hideSelectedOptions={false}
                  />
                </div>

                <div className="flex query-button justify-between">
                  <button
                    onClick={handleSearch}
                    className="bg-[#1a7335] text-white font-bold h-10 w-24 rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                  >
                    Find
                  </button>
                </div>
                <div className="reload-icon mr-2 text-right">
                  <i
                    className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                    onClick={startLoader}
                  />
                </div>
              </div>

              <h1 className="pt-0 font-semibold text-xl">Commission</h1>
              {state.isWaiting ? (
                <center className="w-full my-10">
                  <img src={loader} alt="" />
                </center>
              ) : (
                <div className="overflow-auto card  my-3 col-12 px-0 pt-0 pb-0 agent-table agent-commission-table border">
                  <div className="table-responsive table-overflow">

                    <table className="table w-full mb-0">
                      <thead>
                        <tr className="bg-gray-300">
                          <th className="p-4 text-left">Sr. No.</th>
                          <th className="p-4 text-left">Student Name</th>
                          <th className="p-4 text-left">College Name</th>
                          <th className="p-4 text-left">Program ID</th>
                          <th className="p-4 text-left">Application Fees</th>
                          <th className="p-4 text-left">Fees</th>
                          <th className="p-4 text-left">Commission</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData && tableData.length > 0 ? (
                          tableData.map((student, index) => (
                            <tr className="py-2" key={index}>
                              <td className="p-4">{index + 1}</td>
                              <td className="p-4">
                                {student?.studentName +
                                  " " +
                                  student?.studentLastName}
                              </td>
                              <td className="p-4">{student?.collegeName}</td>
                              <td className="p-4">{student?.programId}</td>
                              <td className="p-4">{student?.applicationFee} CAD</td>
                              <td className="p-4">{student?.tuitionFees} </td>
                              <td className="p-4">
                                {student?.commision}
                                {student?.isOtpVerified === true ? (
                                  <span className="ml-3 text-green-500">Paid !</span>
                                ) : (
                                  <span className="ml-3 text-red-500">Unpaid !</span>
                                )}
                              </td>
                            </tr>
                          ))) : (
                          <tr>
                            <td colSpan="12" className="text-center">
                              <div>
                                <p className="text-center p-5 text-red-500">
                                  No data available.
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}

                      </tbody>
                    </table>

                  </div>
                </div>
              )}
              {tableData && tableData.length > 0 && (
                <div className="flex justify-end mt-4">
                  <div className="p-3 bg-gray-200 rounded-lg">
                    <h2 className="text-sm font-semibold mb-0">
                      Total Commission{" "}
                      {tableData.reduce(
                        (total, student) => total + student?.commision,
                        0
                      )}{" "}
                    </h2>
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer pb-0">
              <div className="pagination">
                <div className="pages">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={(event) => {
                      getPaginationData(event.selected + 1);
                    }}
                    pageCount={state.totalPages}
                    previousLabel="prev"
                    renderOnZeroPageCount={null}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={0}
                    activeClassName="selected"
                    containerClassName="pagination"
                    subContainerClassName="pages"
                    initialPage={state.currentPage}
                    disableInitialCallback={true}
                    previousClassName={state.currentPage === 0 ? "disabled" : ""}
                    nextClassName={
                      state.currentPage === state.totalPages - 1 ? "disabled" : ""
                    }
                    previousLinkClassName={
                      state.currentPage === 0 ? "disabled-link" : ""
                    }
                    nextLinkClassName={
                      state.currentPage === state.totalPages - 1
                        ? "disabled-link"
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentCommission;
