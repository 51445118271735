import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AdmissionProcess() {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <div>
      <div className="admission-part relative mt-0 lg:mt-0 px-4 lg:px-0 py-16">
        <div className="container mx-auto">
          <div className="lg:flex discover-cont gap-4 lg:px-0 justify-center content-center lg:gap-16">
            <div className="custom-img lg:w-2/1">
              <img src="/admission.jpg" className="w-100" />
            </div>
            <div className="addmision_process lg:w-2/4">
              <h2 className="text-left font-bold text-6xl pb-5 mb-2 relative">
                <span>
                  <strong>Admission</strong>
                </span>{" "}
                Process
              </h2>
              <Accordion
                className="mb-5"
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Request Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We will send you a confirmation email with login, password.
                    So, you can apply anywhere in the world. Fill up your
                    application according to your requirement.
                  </Typography>
                  <button className="bg-[#059669] hover:bg-[#065f46] text-white py-2 px-4 rounded-full tab-apply-btn">
                    <a
                      className="text-white hover:text-white "
                      href="/eligible"
                    >
                      Apply Now
                    </a>
                  </button>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="mb-5"
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Apply</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We bestow you a guidance from our best experts so that you
                    have the best chances of getting your student visa. All you
                    need is to relax and keep tracking your application status.
                  </Typography>
                  <button className="bg-[#059669] hover:bg-[#065f46] text-white py-2 px-4 rounded-full tab-apply-btn">
                    <a
                      className="text-white hover:text-white "
                      href="/eligible"
                    >
                      Apply Now
                    </a>
                  </button>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className="mb-5"
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Registration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Firstly, complete your profile, pay the application fee and
                    submit all necessary documents online. Our specialists are
                    always available and you can talk to them anytime.
                  </Typography>
                  <button className="bg-[#059669] hover:bg-[#065f46] text-white py-2 px-4 rounded-full tab-apply-btn">
                    <a
                      className="text-white hover:text-white "
                      href="/eligible"
                    >
                      Apply Now
                    </a>
                  </button>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
