import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getToken } from "../../../helper/auth";
import loader from "../../../images/loader.gif";
import { GrClose } from "react-icons/gr";
import { AiFillSetting } from "react-icons/ai";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import cookie from "js-cookie";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

const PAGE_SIZE = 10;
const SearchQueryForms = () => {
  const [isrotating, setisrotating] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [nationality, setNationality] = useState("");
  const [selectedNationality, setSelectedNationality] = useState([]);
  const [destinationCount, setDestinationCount] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [examType, setExamType] = useState("");
  const [selectedExam, setSelectedExam] = useState([]);
  const [overAllScore, setOverAllScore] = useState("");
  const [phone, setPhone] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [country, setCountry] = useState([]);
  const [ModalCode, setModalCode] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [token, setToken] = useState();
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [state, setState] = useState({
    students: [],
    totalPages: 0,
    currentPage: 1,
    dateRange: {
      startDate: null,
    },
  });

  const [tableColumns, setTableColumns] = useState([
    { label: "Sr.", value: "Sr." },
    { label: "Name", value: "Name" },
    { label: "Phone", value: "Phone" },
    { label: "Email", value: "Email" },
    { label: "Nationality", value: "Nationality" },
    { label: "Destination Country", value: "Destination Country" },
    { label: "Exam Type", value: "Exam Type" },
    { label: "Overall Score", value: "Overall Score" },
    { label: "Created", value: "Created" },
  ]);

  const [selectedColumnsTable, setSelectedColumnsTable] = useState(
    JSON.parse(localStorage.getItem("selectedColumnsQuery")) || [
      "Sr.",
      "Name",
      "Phone",
      "Email",
      "Nationality",
      "Destination Country",
      "Exam Type",
    ]
  );

  const [tempColumnsTable, setTempColumnsTable] = useState([
    "Sr.",
    "Name",
    "Phone",
    "Email",
    "Nationality",
    "Destination Country",
    "Exam Type",
  ]);

  const InitialFunction = async () => {
    await axios
      .get(process.env.REACT_APP_NODE_URL + "/address/country")
      .then((countryResponse) => {
        setCountry(countryResponse.data.details.countries);
      });
  };

  const getPaginationData = async (
    page,
    searchName,
    nationality,
    destinationCount,
    examType,
    overAllScore,
    phone,
    date
  ) => {
    console.log(nationality);
    try {
      setLoading(true);
      let data;
      if (searchName === "undefined") {
        data = {
          currentPage: page,
          nationality: "",
          searchName: "",
          destination_country: "",
          examType: "",
          overall_score: "",
          phone: "",
          date: ""
        };
      } else {
        data = {
          currentPage: page,
          nationality: nationality,
          searchName: searchName,
          destination_country: destinationCount,
          examType: examType,
          overall_score: overAllScore,
          phone: phone,
          date: date,
        };
      }
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      console.log("data", data);
      const res = await fetch(
        process.env.REACT_APP_NODE_URL + "/admin/getqueriesform",
        config
      ).then((res) => res.json());

      if (res.status === 1) {
        const updateState = {
          ...state,
          students: res.details.students,
          totalPages: res.details.totalPages,
          currentPage: res.details.currentPage,
        };
        setState(updateState);
        setisrotating(false)
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleColumnCheckboxChange = (columnValue) => {
    let updatedColumns; // Define updatedColumns here

    if (tempColumnsTable.includes(columnValue)) {
      // If the column is already in tempColumnsTable and the checkbox is unchecked, remove it
      updatedColumns = tempColumnsTable.filter((col) => col !== columnValue);
      setTempColumnsTable(updatedColumns);
    } else {
      // If the checkbox is checked and the column is not in tempColumnsTable, add it
      updatedColumns = [...tempColumnsTable, columnValue];
      setTempColumnsTable(updatedColumns);
    }

    // Save the updated columns to localStorage
    localStorage.setItem(
      "selectedColumnsQuery",
      JSON.stringify(updatedColumns)
    );
  };

  function formatLabel(column) {
    const formattedLabel = column.replace(/_/g, " ");
    const words = formattedLabel.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleOk = () => {
    // Check for duplicate columns before updating state
    const uniqueSelectedColumns = [...new Set(tempColumnsTable)];

    localStorage.setItem(
      "selectedColumnsQuery",
      JSON.stringify(uniqueSelectedColumns)
    );

    setSelectedColumnsTable(uniqueSelectedColumns);
    setModalCode(false);
  };

  useEffect(() => {
    // Retrieve selected columns from localStorage
    const storedColumns = JSON.parse(
      localStorage.getItem("selectedColumnsQuery")
    );

    if (storedColumns) {
      setSelectedColumnsTable(storedColumns);
      setTempColumnsTable(storedColumns);
    } else {
      setSelectedColumnsTable([
        "Sr.",
        "Name",
        "Phone",
        "Email",
        "Nationality",
        "Destination Country",
        "Exam Type",
      ]);
      setTempColumnsTable([
        "Sr.",
        "Name",
        "Phone",
        "Email",
        "Nationality",
        "Destination Country",
        "Exam Type",
      ]);
    }
  }, []);

  useEffect(() => {
    const getToken = () => {
      const token = cookie.get("admin_token");
      setToken(token);
    };
    getToken();
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Phone",
      "Email",
      "Nationality",
      "Destination Country",
      "Exam Type",
    ]);
    InitialFunction();
    handleSearch();
  }, []);

  const handleResetAll = () => {
    localStorage.removeItem("selectedColumnsQuery");
    setSelectedColumnsTable([
      "Sr.",
      "Name",
      "Phone",
      "Email",
      "Nationality",
      "Destination Country",
      "Exam Type",
    ]);
    setTempColumnsTable([
      "Sr.",
      "Name",
      "Phone",
      "Email",
      "Nationality",
      "Destination Country",
      "Exam Type",
    ]);
  };

  const handleSearch = () => {
    getPaginationData(
      1,
      searchName,
      nationality,
      destinationCount,
      examType,
      overAllScore,
      phone,
      startDate ? startDate?.format("YYYY-MM-DD") : ""
    );
  };
  
  const handlenewStatus = async (_id) => {
    try {
      const body = {
        queryId: _id,
        newstatus: false,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_URL}/v2/api/changeQueryformStatus`,
        body
      );
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const startLoader =  () => {
    setisrotating(true);
    getPaginationData();
    setSearchName("")
    setNationality("")
    setDestinationCount("")
    setExamType('')
    setSelectedExam([])
    setSelectedOptions([])
    setSelectedNationality([])
    setOverAllScore("")
    setPhone("")
    setStartDate(null)
  };

  const handleClear =  () => {
    getPaginationData();
    setSearchName("")
    setNationality("")
    setDestinationCount("")
    setExamType('')
    setSelectedExam([])
    setSelectedOptions([])
    setSelectedNationality([])
    setOverAllScore("")
    setPhone("")
    setStartDate(null)
  };

  console.log("destinationCount", selectedOptions)

  return (
    <>
      <div heading_title={"Total Students"}>
        <>
          <div className="row min-height-vh-100">
            <div className="row p-45  mob-padding ">
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="col-12 w-full inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="card mb-4 mt-4">
                    <div className="flex flex-wrap gap-4 items-center justify-between">
                      <div className="flex flex-wrap gap-4 items-center">
                        <div className="mb-4 w-[130px]">
                          <label
                            htmlFor="country"
                            className="text-gray-700 block font-semibold"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none w-full"
                            placeholder="Search By Student Name"
                            value={searchName}
                            // onChange={(e) => setSearchName(e.target.value)}
                            onChange={(e) => {
                              setSearchName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-4 w-[130px]">
                          <label
                            htmlFor="country"
                            className="text-gray-700 block font-semibold"
                          >
                            Nationality
                          </label>
                          
                          <Select
                            isMulti={false}
                            name="nationality"
                            options={country.map((country) => ({
                              value: country.countryName,
                              label: country.countryName,
                            }))}
                            className="block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                            value={selectedNationality}
                            onChange={(selectedOption) => {
                              setNationality(selectedOption.value);
                              setSelectedNationality(selectedOption);
                            }}
                            searchable={true}
                            hideSelectedOptions={false}
                          />
                        </div>

                        <div className="mb-4 w-[130px]">
                          <label
                            htmlFor="country"
                            className="text-gray-700 block font-semibold"
                          >
                            Destination Country
                          </label>
                          <Select
                            isMulti={false}
                            name="destination_country"
                            options={country.map((country) => ({
                              value: country.countryName,
                              label: country.countryName,
                            }))}
                            className="block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                            value={selectedOptions}
                            onChange={(selectedOption) => {
                              setDestinationCount(selectedOption.value);
                              setSelectedOptions(selectedOption);
                            }}
                            searchable={true}
                            hideSelectedOptions={false}
                          />
                        </div>

                        <div className="mb-4 w-[130px]">
                          <label
                            htmlFor="country"
                            className="text-gray-700 block font-semibold"
                          >
                            Exam Type
                          </label>
                          <Select
                            isMulti={false}
                            name="examType"
                            options={[
                              { value: "TOFEL", label: "TOFEL" },
                              { value: "IELTS", label: "IELTS" },
                              { value: "PTE", label: "PTE" },
                              { value: "duolingo_lan", label: "DUOLINGO" },
                            ]}
                            value={selectedExam}
                            className="block flex-1 focus:border-black w-full focus:ring-indigo-500 sm:text-sm"
                            onChange={(selectedOptions) => {
                              setExamType(selectedOptions.value);
                              setSelectedExam(selectedOptions)
                            }}
                            searchable={true}
                            hideSelectedOptions={false}
                          />
                        </div>

                        <div className="mb-4 w-[130px]">
                          <label
                            htmlFor="country"
                            className="text-gray-700 block font-semibold"
                          >
                            Over All Score
                          </label>
                          <input
                            name="overall_score"
                            type="number"
                            className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none w-full"
                            placeholder="Search by Score"
                            value={overAllScore}
                            onChange={(e) => {
                              setOverAllScore(e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-4 w-[130px]">
                          <label
                            htmlFor="country"
                            className="text-gray-700 block font-semibold"
                          >
                            Phone
                          </label>
                          <input
                            name="phone"
                            type="number"
                            className="border border-gray-300 py-2 px-4 rounded-l-md focus:outline-none w-full"
                            placeholder="Search by Phone"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-4 w-[130px]">
                        <label htmlFor="dateRange">Date</label>
                          <div className="assesment-date-calender">
                            <DateRangePicker
                              startDate={startDate ? moment(startDate) : null}
                              startDateId="startDate"
                              onDatesChange={({ startDate }) => {
                                setStartDate(startDate);
                              }}
                              focusedInput={focusedInput}
                              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                              isOutsideRange={() => false}
                              id="dateRangePicker"
                              label="Select Dates" 
                            />
                          </div>
                        </div>

                        <div className="flex query-button w-[100px]">
                          <button
                            onClick={handleSearch}
                            className="bg-[#1a7335] text-white font-bold h-10 w-full rounded-md shadow-md hover:bg-[#065f46] transition duration-300 ease-in-out"
                          >
                            Find
                          </button>
                        </div>

                        <div className="w-fit">
                          <button
                            onClick={handleClear}
                            className="bg-[#5555] text-[#515151] font-bold h-10 px-4 rounded-md shadow-md hover:bg-[#aeaeae] transition duration-300 ease-in-out"
                          >
                            Clear
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-end">
                            <div className="reload-icon mr-2">
                              <i
                                className={`fas fa-sync ${isrotating ? "rotate" : ""}`}
                                onClick={startLoader}
                              />
                            </div>
                            <AiFillSetting
                              className="Bar_filter"
                              onClick={() => setModalCode(!ModalCode)}
                            />
                      </div>
                    </div>

                    {/* PopUp Modal Code  */}
                    <div className="btn_outerxx mb-3 mt-5">
                      {ModalCode ? (
                        <div className="modal_cover">
                          <div className="modal_inner select-col-popup">
                            <div className="header_modal">
                              <h1 className="capitalize font-bold text-lg mb-2">
                                select columns
                              </h1>
                              <p className="border-b pb-2 mb-2">
                                Select the column you want to display or hide.
                              </p>
                              <div
                                className="columns-popup"
                                style={{ justifyContent: "space-between" }}
                              >
                                {tableColumns.map((column, index) => {
                                  if (
                                    selectedColumnsTable.includes(column.value)
                                  ) {
                                    return (
                                      <p className="mb-0 gap-2">
                                        <input
                                          type="checkbox"
                                          id={column.value}
                                          checked={tempColumnsTable.includes(
                                            column.value
                                          )}
                                          onChange={() =>
                                            handleColumnCheckboxChange(
                                              column.value
                                            )
                                          }
                                        />
                                        <label htmlFor={column.value}>
                                          {formatLabel(column.label)}
                                        </label>
                                      </p>
                                    );
                                  } else {
                                    return (
                                      <p className="mb-0 gap-2">
                                        <input
                                          type="checkbox"
                                          id={column.value}
                                          onClick={() => {
                                            selectedColumnsTable.push(
                                              column.value
                                            );
                                            setTempColumnsTable(
                                              selectedColumnsTable
                                            );
                                          }}
                                        />
                                        <label htmlFor={column.value}>
                                          {formatLabel(column.label)}
                                        </label>
                                      </p>
                                    );
                                  }
                                })}
                              </div>
                              <div className="button-part flex justify-end items-center gap-4">
                                <button
                                  className="py-2 px-6"
                                  type="btn"
                                  onClick={handleResetAll}
                                >
                                  Reset all
                                </button>
                                <button
                                  className="py-2 px-6"
                                  type="btn"
                                  onClick={handleOk}
                                >
                                  Ok
                                </button>
                              </div>
                              <GrClose
                                className="close"
                                onClick={() => setModalCode(false)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="card-body table-border p-2">
                      <div className="overflow-auto card  col-12 px-0 pt-0 pb-2  table_postioning table-overflow">
                        {loading ? (
                          <div>
                            <center className="w-full my-10">
                              <img src={loader} alt="" />
                            </center>
                          </div>
                        ) : state?.students && state?.students?.length > 0 ? (
                          <table className="table-auto overflow-scroll w-full agent-table files-table">
                            <thead>
                              <tr>
                                {tableColumns.map((col) => {
                                  if (selectedColumnsTable.includes(col.label)) {
                                    return (
                                      <th className="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-left">
                                        {formatLabel(col.label)}
                                      </th>
                                    );
                                  }
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {state?.students?.map((student, index) => {
                                return (
                                  <tr
                                    onClick={() => handlenewStatus(student._id)}
                                    style={{
                                      background:
                                        student.new === true ? "#b9f5e3" : "#fff",
                                    }}>
                                    {selectedColumnsTable.includes("Sr.") && (
                                      <td className="p-2 text-left">
                                        <p className="text-xs font-weight-bold mb-0">
                                          <div className="flex items-center">
                                            <div className="mr-1">
                                              {(currentPage === 0
                                                ? 0
                                                : currentPage - 1) *
                                                PAGE_SIZE +
                                                index +
                                                1}
                                            </div>
                                            {student.new === true && (
                                              <span className="bg-green-500 text-white text-[10px] p-1 rounded">
                                                New
                                              </span>
                                            )}
                                          </div>
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Name") && (
                                      <td className="p-2 text-left">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student?.fullname}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Phone") && (
                                      <td className="p-2 text-left">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student?.phone}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes("Email") && (
                                      <td className="p-2 text-left">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {student?.email}
                                        </p>
                                      </td>
                                    )}
                                    {selectedColumnsTable.includes(
                                      "Nationality"
                                    ) && (
                                        <td className="p-2 text-left">
                                          <p className="text-xs font-weight-bold mb-0">
                                            {student?.nationality}
                                          </p>
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Destination Country"
                                    ) && (
                                        <td className="p-2 text-left">
                                          <p className="text-xs font-weight-bold mb-0">
                                            {student?.destination_country}
                                          </p>
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Exam Type"
                                    ) && (
                                        <td className="p-2 text-left">
                                          <p className="text-xs font-weight-bold mb-0">
                                            {student?.examType}
                                          </p>
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes(
                                      "Overall Score"
                                    ) && (
                                        <td className="p-2 text-left">
                                          {student.examType == "IELTS" ? (
                                            <p className="text-xs font-weight-bold mb-0">
                                              {student.overall_score}
                                            </p>
                                          ) : (
                                            <p className="text-xs font-weight-bold mb-0">
                                              {student.scores}
                                            </p>
                                          )}
                                        </td>
                                      )}
                                    {selectedColumnsTable.includes("Created") && (
                                      <td className="p-2 text-left">
                                        <p className="text-xs font-weight-bold mb-0">
                                          {new Date(
                                            parseInt(student?.created)
                                          ).toLocaleDateString()}
                                        </p>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            <p className="text-center p-5 text-red-500">
                              No data available.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="card-footer pb-0">
                      <div className="pagination">
                        <div className="pages">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="next"
                            onPageChange={(event) => {
                              getPaginationData(event.selected + 1);
                              setCurrentPage(event.selected + 1);
                            }}
                            pageCount={state.totalPages}
                            previousLabel="prev"
                            renderOnZeroPageCount={null}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={0}
                            containerClassName="pagination"
                            subContainerClassName="pages"
                            initialPage={currentPage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default SearchQueryForms;
