import React, { useEffect } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useState } from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { getToken } from "../../../../helper/auth";
import axios from "axios";
import Cross from "../../../website/images/cross.png";
import logo2 from '../../../../images/logo2.png'
var firstTime = true;

const AgentHeader = ({ agentPending }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [active, setActive] = useState("Dashboard");
    const [firstName, setFirstName] = useState("User")
    const [state, setState] = useState({
        menu: -1,
        isWait: false,
    })

    const [activeItem, setActiveItem] = useState(null);
    const location = useLocation();
    const currentPath = location.pathname;

    const handleItemClick = (path) => {
        setActiveItem(path);
    };

    const sidebarItems = [
        {
            label: "Dashboard",
            icon:
                <>
                    <i class="fa-solid fa-home"></i>
                </>,
            path: "/agent/dashboard",
            matchings: ["dashboard"],
            protected: false,
        },
        {
            label: "Manage Students",
            icon: <i class="fa-solid fa-user-graduate"></i>,
            path: "/agent/students",
            matchings: ["students"],
            protected: false,
        },
        {
            label: "Enrolled Files",
            icon: <i class="fas fa-file-alt"></i>,
            path: "/agent/enrolled-list",
            matchings: ["enrolled-list"],
            protected: false,
        }
        ,
        {
            label: "Commission",
            icon: <i class="fas fa-coins"></i>,
            path: "/agent/agentcommission",
            matchings: ["agentcommission"],
            protected: false,
        }
    ]

    return (
        <>
            {/* overlay */}
            <div
                className="overlay"
                id="overlay"
                onClick={() => {
                    document.getElementById("aside").classList.toggle("show");
                    document.getElementById("overlay").classList.toggle("show");
                }}
            ></div>
            <div
                id="aside"
                className={`sidebar_style xl:fixed h-screen transform ease-in-out transition duration-500 flex justify-start items-start h-full  w-full sm:w-64 flex-col dsdf`}
            >
                <img
                    className="closebar-btn"
                    src={Cross}
                    onClick={() => {
                        document.getElementById("aside").classList.add("hidebar");
                        document.getElementById("overlay").classList.add("hidebar");
                    }}
                    alt=''
                />

                {state.isWait ? (
                    <></>
                ) : (
                    sidebarItems.map((singleItem, index) => {
                        if (singleItem.protected && agentPending) return;
                        if (singleItem?.items) {
                            return <></>
                        } else {
                            return (
                                <>
                                    <div className="flex flex-col justify-start items-center w-full" >
                                        <div className="focus:outline-none text-left text-white flex justify-center items-center w-full px-5 py-3" >
                                            <div className={`flex justify-between ${singleItem.path === currentPath && "activeee"}`} onClick={() => handleItemClick(singleItem.path)}>
                                                <span
                                                    title={singleItem.label}
                                                    onClick={() => navigate(singleItem.path)}
                                                    className="text-xl">{singleItem.icon}</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })
                )}
            </div>
        </>
    )

}

export default AgentHeader;