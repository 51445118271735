import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { getToken } from "../../../../helper/auth";
import axios from "axios";
import Cross from "../../../website/images/cross.png";
import loader from '../../../../images/loader.gif'
import OutsideClickHandler from 'react-outside-click-handler';
const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [active, setActive] = useState("Dashboard");
  const [permissions, setPermissions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [firstName, setFirstName] = useState("User");
  const [assesmentForm, setAssesmentForm] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [role, setRole] = useState("");

  const location = useLocation();

  const [state, setState] = useState({
    totalAgentsUnapproved: 0,
    totalAssessmentForms: 0,
    totalSearchQueryForms: 0,
    menu: -1,
    isWait: true,
  });

  const currentPath = location.pathname;
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (path) => {
    setActiveItem(null);
    setActiveItem(path);
  };

  const sidebarItems = [
    {
      label: "Dashboard",
      icon: (
        <>
          <i class="fa-solid fa-home"></i>
        </>
      ),
      path: "/admin/dashboard",
      matchings: ["dashboard"],
    },
    {
      label: "CSV Upload",
      icon: <i class="fa-solid fa-upload"></i>,
      permissions: "csv_programs",
      path: "/admin/addcolleges",
      matchings: ["addcolleges"],
    },
    {
      label: "Colleges",
      permissions: "college_main",
      icon: <i class="fa-solid fa-building"></i>,
      matchings: [
        "addcountry",
        "addcollegename",
        "colleges",
        "collegeupdate",
        "intakes",
        "programs",
      ],
      items: [
        {
          label: "Add College Names",
          icon: <i class="fa-solid fa-college-lock"></i>,
          permissions: "sp_college_names",
          matchings: ["addcollegename"],
          path: "/admin/addcollegename",
        },
        {
          label: "Intakes Management",
          icon: <i class="fa-solid fa-college"></i>,
          permissions: "sp_list",
          matchings: ["intakes"],
          path: "/admin/intakes",
        },
        {
          label: "All Colleges",
          icon: <i class="fa-solid fa-college"></i>,
          permissions: "sp_list",
          matchings: ["colleges", "collegeupdate", "programs"],
          path: "/admin/colleges",
        },
      ],
    },
    // {
    //   label: "Employee",
    //   permissions: "employee_main",
    //   icon: <i class="fa-solid fa-user"></i>,
    //   matchings: ["createemployee", "listemployee"],
    //   items: [
    //     {
    //       label: "Create Employee",
    //       icon: <i class="fa-solid fa-user-tie"></i>,
    //       permissions: "employee_create",
    //       matchings: ["createemployee"],
    //       path: "/admin/createemployee",
    //     },
    //     {
    //       label: "Employees List",
    //       icon: <i class="fa-solid fa-list"></i>,
    //       permissions: "employee_list",
    //       matchings: ["listemployee"],
    //       path: "/admin/listemployee",
    //     },
    //   ],
    // },
    {
      label: "Agents",
      icon: <i class="fa-solid fa-user-tie"></i>,
      matchings: ["manage", "manage-unapproved", "manage-pending"],
      items: [
        {
          label: "Agents",
          icon: <i class="fa-solid fa-person-rays"></i>,
          matchings: ["manage"],
          path: "/admin/manage",
        },
        // {
        //   label: "Unapproved Agents",
        //   icon: <i class="fa-solid fa-user-minus"></i>,
        //   matchings: ["manage-unapproved"],
        //   path: "/admin/manage?status=REJECT",
        // },
        // {
        //   label: "Pending Agents",
        //   id: "totalAgentsUnapproved",
        //   icon: <i class="fa-solid fa-user-minus"></i>,
        //   matchings: ["manage-pending"],
        //   path: "/admin/manage?status=PENDING",
        // },
      ],
    },
    {
      label: "Students",
      icon: <i class="fa-solid fa-user-graduate"></i>,
      matchings: [
        "students",
        "enrolled-files",
        "pending-files",
        "documents-rejected-files",
        "permanent-rejected-files",
        "under-verification-files",
        "fees-pending",
        "in-processing-files",
        "closed-files",
      ],
      items: [
        {
          label: "All Students",
          icon: <i class="fa-solid fa-graduation-cap"></i>,
          matchings: ["students"],
          path: "/admin/students",
        },
        {
          label: "Manage Applications",
          icon: <i class="fa-solid fa-graduation-cap"></i>,
          matchings: ["enrolled-files"],
          path: "/admin/enrolled-files",
        },
        // {
        //   label: "Pending Files",
        //   icon: <i class="fa-solid fa-graduation-cap"></i>,
        //   matchings: ["pending-files"],
        //   path: "/admin/pending-files",
        // },
        // {
        //   label: "Document Verification",
        //   icon: <i class="fa-solid fa-graduation-cap"></i>,
        //   matchings: ["under-verification-files"],
        //   path: "/admin/under-verification-files",
        // },
        // {
        //   label: "In-Process Files",
        //   icon: <i class="fa-solid fa-graduation-cap"></i>,
        //   matchings: ["in-processing-files"],
        //   path: "/admin/in-processing-files",
        // },
        // {
        //   label: "Completed Files",
        //   icon: <i class="fa-solid fa-graduation-cap"></i>,
        //   matchings: ["closed-files"],
        //   path: "/admin/closed-files",
        // },
        // {
        //   label: "Permanent Rejected Files",
        //   icon: <i class="fa-solid fa-graduation-cap"></i>,
        //   matchings: ["rejected-files"],
        //   path: "/admin/permanent-rejected-files",
        // },
      ],
    },
    {
      label: "Forms",
      icon: <i class="fa-solid fa-clipboard-list"></i>,
      matchings: ["assessmentforms", "serachqueries"],
      items: [
        {
          label: "Assessment Form",
          icon: <i class="fa-solid fa-person-rays"></i>,
          // count: assesmentForm,
          id: "totalAssessmentForms",
          matchings: ["assessmentforms"],
          path: "/admin/assessmentforms",
        },
        {
          label: "Search Queries",
          // count: searchQuery,
          id: "totalSearchQueryForms",
          icon: <i class="fa-solid fa-user-minus"></i>,
          matchings: ["serachqueries"],
          path: "/admin/serachqueries",
        },
      ],
    },
    {
      label: "Settings",
      icon: <i class="fa-solid fa-cog"></i>,
      matchings: ["currency", "required-documents", "manage-file-status", "home-page-settings"],
      items: [
        // {
        //   label: "English Speaking Nations",
        //   icon: <i class="fa-solid fa-person-rays"></i>,
        //   matchings: ["english-speaking-nations"],
        //   path: "/admin/english-speaking-nations",
        // },
        {
          label: "Currency Management",
          icon: <i class="fa-solid fa-person-rays"></i>,
          matchings: ["currency"],
          path: "/admin/currency",
        },
        {
          label: "Required Documents",
          icon: <i class="fa-solid fa-person-rays"></i>,
          matchings: ["required-documents"],
          path: "/admin/required-documents",
        },
        {
          label: "Manage File Status",
          icon: <i class="fa-solid fa-person-rays"></i>,
          matchings: ["manage-file-status"],
          path: "/admin/manage-file-status",
        },
        {
          label: "Website Settings",
          icon: <i class="fa-solid fa-person-rays"></i>,
          matchings: ["website-settings"],
          path: "/admin/website-settings",
        },
        {
          label: "Social Icon Settings",
          icon: <i class="fa-solid fa-person-rays"></i>,
          matchings: ["social-icon-settings"],
          path: "/admin/social-icon-settings",
        },
      ],
    },
    {
      label: "Commission",
      icon: <i class="fas fa-coins"></i>,
      path: "/admin/commission",
      matchings: ["AdminCommission"],
      protected: false,
    }
  ];

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${getToken("admin")}` },
    };
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/verifyToken", {}, config)
      .then((res) => {
        setAssesmentForm(res.data.details.totalAssessmentForms)
        setSearchQuery(res.data.details.totalSearchQueryForms)
        setPermissions(res.data.details.userData.permissions);
        setRole(res.data.details.userData.role);
        setFirstName(res.data.details.userData.firstName);
        setIsAdmin(res.data.details.userData.role == "ADMIN");
        // add this line here.. bottom of role or permission management
        document.getElementById("notificationCountSpan").innerText =
          res.data.details.userData.notificationsCount > 99
            ? "99+"
            : res.data.details.userData.notificationsCount;

        // SET ACTIVE MENUS OF SIDEBAR
        setState({
          ...state,
          menu: index,
          totalAgentsUnapproved: res.data.details.totalAgentsUnapproved,
          totalAssessmentForms: res.data.details.totalAssessmentForms,
          totalSearchQueryForms: res.data.details.totalSearchQueryForms,
          isWait: false,
        });

        if (
          window.location.pathname.split("/")[3] == "manage" &&
          searchParams.get("status") &&
          searchParams.get("status") == "unapproved"
        ) {
          setActive(window.location.pathname.split("/")[3] + "-unapproved");
        } else if (
          window.location.pathname.split("/")[3] == "manage" &&
          searchParams.get("status") &&
          searchParams.get("status") == "pending"
        ) {
          setActive(window.location.pathname.split("/")[3] + "-pending");
        } else {
          setActive(window.location.pathname.split("/")[3]);
        }
        let index = sidebarItems.reduce((prev, curr, index) => {
          return sidebarItems[index].matchings.includes(
            window.location.pathname.split("/")[3]
          )
            ? index
            : prev;
        }, 0);
        return;
      })
      .catch((err) => {
        setState({
          ...state,
          isWait: false,
        });
      });
  }, []);

  useEffect(() => {
    // SET ACTIVE MENUS OF SIDEBAR
    if (
      window.location.pathname.split("/")[3] == "manage" &&
      searchParams.get("status") &&
      searchParams.get("status") == "unapproved"
    ) {
      setActive(window.location.pathname.split("/")[3] + "-unapproved");
    } else if (
      window.location.pathname.split("/")[3] == "manage" &&
      searchParams.get("status") &&
      searchParams.get("status") == "pending"
    ) {
      setActive(window.location.pathname.split("/")[3] + "-pending");
    } else {
      setActive(window.location.pathname.split("/")[3]);
    }
    let index = sidebarItems.reduce((prev, curr, index) => {
      return sidebarItems[index].matchings.includes(
        window.location.pathname.split("/")[3]
      )
        ? index
        : prev;
    }, 0);

    setState({
      ...state,
      isWait: false,
      menu: index,
    });
  }, [window.location.href]);

  const openMenu = (menuNumber) => {
    setState({
      ...state,
      menu: state.menu == menuNumber ? -1 : menuNumber,
    });
  };

  useEffect(() => {
    if (sidebarItems?.path == currentPath) {
      console.log("trueuueueues")
      setActiveItem(null)
    }
  }, [sidebarItems])

  console.log("currentPath", currentPath)

  return (
    <>
      {/* overlay */}
      <div
        className="overlay"
        id="overlay"
        onClick={() => {
          document.getElementById("aside").classList.toggle("show");
          document.getElementById("overlay").classList.toggle("show");
        }}
      ></div>
      <div
        id="aside"
        className={`sidebar_style admin-side xl:fixed transform ease-in-out transition duration-500 flex justify-start items-start h-full  w-full sm:w-64 flex-col`}
      >
        <img
          className="closebar-btn"
          src={Cross}
          alt=''
          onClick={() => {
            document.getElementById("aside").classList.add("hidebar");
            document.getElementById("overlay").classList.add("hidebar");
          }}
        />
        {state.isWait ? (
          <>
            <img src={loader} alt="" />
          </>
        ) : (
          sidebarItems.map((singleItem, index) => {
            // multi level sidebar
            if (!permissions.includes(singleItem.permissions) && !isAdmin)
              return;
            if (singleItem?.items) {
              return (
                <div
                  className="flex flex-col justify-start items-center w-full relative"
                >
                  <div
                    className="focus:outline-none text-left text-white flex justify-center items-center w-full px-5 py-3 dfsd"
                  >
                    <div className={`flex justify-between ${singleItem.items.some(subItem => subItem.path === currentPath) ? "activeee" : ""}`}>
                      <span
                        onClick={() => openMenu(index)}
                        className="text-xl" title={singleItem.label}>{singleItem.icon}</span>
                    </div>
                  </div>
                  <div
                    className={`subGroupMenuItems ${state.menu != index ? "hidden-now" : "show-now"
                      } flex justify-end flex-col w-full items-end pb-1 `}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setState({ ...state, menu: -1 });
                      }}
                    >
                      {singleItem.items.map((subItem, subIndex) => {
                        if (
                          !permissions.includes(subItem.permissions) &&
                          !isAdmin
                        )
                          return;
                        return (
                          <button
                            onClick={(event) => {
                              event.stopPropagation();
                              navigate(subItem.path);
                              handleItemClick(index);
                            }}

                            className={`flex justify-start items-center space-x-6 hover:text-white my-1 text-white rounded-l w-11/12 py-2 px-4 ${subItem.path === currentPath ? "activeee-link" : ""}`}
                          >
                            <div className="w-4 subItemIconDot">
                            </div>
                            <p
                              className="sidebaritem text-left leading-4"
                              style={{ marginLeft: "15px", fontSize: '12px' }}
                            >
                              {subItem.label}{" "}
                              {subItem?.count || subItem?.count === 0 ? (
                                <span className="count" id={subItem.id}>
                                  {subItem.count}
                                </span>
                              ) : (
                                <></>
                              )}
                            </p>
                          </button>
                        );
                      })}
                    </OutsideClickHandler>
                  </div>
                </div>
              );

            } else {
              return (
                <>
                  <div
                    className="flex flex-col justify-start items-center w-full"
                  >
                    <div
                      className="focus:outline-none text-left text-white flex justify-center items-center w-full px-5 py-3 sdd"
                    >
                      <div className={`flex justify-between ${singleItem.path === currentPath ? "activeee" : ""}`} onClick={() => handleItemClick(index)}>
                        <span onClick={() => navigate(singleItem.path)} className="text-xl" title={singleItem.label}>{singleItem.icon}</span>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })
        )}
      </div>
    </>
  );
};

export default Header;
