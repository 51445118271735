/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Logo from "../../website/images/logo.png";
import Logoblack from "../../../images/logoblack.png";
import User from "../../../images/user.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import axios from "axios";
import { authenticate, removeCookie } from "../../../helper/auth";
import AuthScreen from "../Screens/Authentication/AuthScreen";
import { setCookie, getCookie } from "../../../helper/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrClose } from "react-icons/gr";
import { FaEnvelope, FaKey } from "react-icons/fa";

export default function Login2(props) {
  const [loading, setLoading] = useState(false);
  const [ModalCode, setModalCode] = useState(false);
  const [ForgotPassword, setForgotPassword] = useState(false);
  const { token } = useParams();
  const [showForgetPop, setShowForgetPop] = useState(false)
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmNewPass, setShowConfirmNewPass] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    email1: "",
    password: "",
    submitProcessing: false,
    isShowPassword: false,
    isCodeSend: false,
    code: "",
    tokenPreserve: "",
    isSave: false,
    confirmpassword: "",
    newpassword: "",
  });


  const [errors, setErrors] = useState({
    email: "",
    password: "",
    newPassword: "",
    confirmpassword: "",
  });

  const toggleNewPassword = () => {
    setShowNewPass(!showNewPass);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmNewPass(!showConfirmNewPass);
  };
  const ForgotNow = async () => {
    if (state.newpassword !== state.confirmpassword) {
      toast.error("Both passwords should be same");
      return;
    }
    if (state.newpassword === "" || state.confirmpassword === "") {
      toast.error("Both passwords are required");
      return;
    }
    setState({
      ...state,
      submitProcessing: true,
    });
    const { data, password } = state;
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const data2 = { newPassword: state.confirmpassword };
    axios
      .patch(
        process.env.REACT_APP_NODE_URL + "/admin/setNewPassword",
        data2,
        config
      )
      .then((res) => {
        setState({
          ...state,
          submitProcessing: false,
        });
        if (res?.data?.status === "0") {
          toast.error(res.data.message);
          return;
        }
        toast.success(res?.data?.message);
        navigate("/adminlogin");
        // redirect
      })
      .catch((err) => {
        setState({
          ...state,
          submitProcessing: false,
        });

        toast.error(err.response.data.message);
      });
  };

  const SendEmail = async () => {
    if (state.email === "") {
      toast.error("Email is required");
      return;
    }
    setState({
      ...state,
      submitProcessing: true,
    });
    const data = { email: state.email };
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/forgotPassword", data)
      .then((res) => {
        if (res?.data?.status === "0") {
          toast.error(res?.data?.message);
          return;
        }
        toast.success(res?.data?.message);
        setForgotPassword(false)
        setState({
          ...state,
          submitProcessing: false,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const validateForm = () => {
    let valid = true;
    const { email, password, confirmpassword } = state;

    if (!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      valid = false;
    }

    if (!password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password is required",
      }));
      valid = false;
    }

    if (!confirmpassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: "Confirm Password is required",
      }));
      valid = false;
    }

    if (password !== confirmpassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: "Passwords do not match",
      }));
      valid = false;
    }

    return valid;
  };


  const LoginNow = async (e) => {
    e.preventDefault();
    if (loading) {
      return; // Do nothing if the button is already in a loading state
    }
    setLoading(true);
    if (state.email.length < 2) {
      setErrors({ ...errors, email: "Email is required." });
      return;
    }
    if (state.password.length < 2) {
      setErrors({ ...errors, password: "Password is required." });
      return;
    }
    if (!validateForm()) {
      const { email, password } = state;
      const d = new Date();
      const date = d.toLocaleDateString();
      const time = d.toLocaleTimeString();

      const data = {
        email,
        password,
        tokenPreserve: getCookie("admin-2fa"),
        mooment: { date, time },
      };
      const config = { "content-type": "application/json" };
      setState({
        ...state,
        submitProcessing: true,
      });

      axios
        .post(process.env.REACT_APP_NODE_URL + "/admin/login", data)
        .then((res) => {
          if (res.data.status == "0") {
            toast.error(res.data.message);
            setState({
              ...state,
              submitProcessing: false,
            });
            return;
          }
          let mode = res.data.details.mode;
          if (mode == "LOGIN") {
            authenticate(res, "admin", () => {
              window.location.href =
                props.role == "ADMIN" ? "/admin/dashboard" : "/admin/dashboard";
            });
            localStorage.setItem("isAuthenticated", true);
          } else if (mode == "2FA") {
            toast.success("Verification Code Successfully Sent");
            setModalCode(true);
            setState({
              ...state,
              isCodeSend: true,
              submitProcessing: false,
              tokenPreserve: res.data.details.token,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setLoading(false); // This will be executed regardless of success or failure
        });
    }

    return;
  };


  const VerifyCode = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const { email } = state;
    const code = parseInt(otp.join(""));
    const data = { email, code };
    if (code === "") {
      toast.error("Verification code is required");
      return;
    }
    const config = { "content-type": "application/json" };
    setState({
      ...state,
      submitProcessing: true,
    });
    axios
      .post(process.env.REACT_APP_NODE_URL + "/admin/verifycode", data)
      .then((res) => {
        // Show code input at frontend

        if (res.data.status == "0") {
          toast.error(res.data.message);
          setState({
            ...state,
            submitProcessing: false,
          });
          return;
        }
        authenticate(res, "admin", () => {
          if (state.isSave) {
            setCookie("admin-2fa", state.tokenPreserve);
          }

          window.location.href =
            props.role == "ADMIN" ? "/admin/dashboard" : "/admin/dashboard";
        });
        localStorage.setItem("isAuthenticated", true);
      })
      .catch((err) => {
        setState({
          ...state,
          submitProcessing: false,
        });
        toast.error(err.response.data.message);
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });

    // Check the length of email and password and update error messages
    if (name === 'email') {
      if (value.length > 1) {
        setErrors({ ...errors, email: '' });
      }
    } else if (name === 'password') {
      if (value.length > 1) {
        setErrors({ ...errors, password: '' });
      }
    }
  };


  // password eye
  const [typeConfirm, setTypeConfirm] = useState("password");
  const [iconConfirm, setIconConfirm] = useState(eye);
  const handleToggleConfirm = () => {
    if (typeConfirm === "password") {
      setIconConfirm(eyeOff);
      setTypeConfirm("text");
    } else {
      setIconConfirm(eye);
      setTypeConfirm("password");
    }
  };
  const [otp, setOtp] = useState(new Array(5).fill(""));

  const handleChange = (e, index) => {
    if (isNaN(e.value)) return false;

    let oldotp = otp;
    oldotp[index] = e.value;
    setOtp(oldotp);

    //Focus next input
    if (e.nextSibling && e.value != "") {
      e.nextSibling.focus();
    }
    if (e.value === "") {
      e.previousSibling.focus();
    }

    if (index + 1 == otp.length && e.value != "") {
      VerifyCode();
    }
  };

  useEffect(() => {
    const savedIsSave = localStorage.getItem("isSave");
    if (savedIsSave === "true") {
      setState({
        ...state,
        isSave: true,
      });
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    // Update the state
    setState({
      ...state,
      isSave: isChecked,
    });

    // Update local storage with the new state
    localStorage.setItem("isSave", isChecked.toString());

    // Check if the checkbox is unchecked
    if (!isChecked) {
      // Remove cookies if "Save for this device" is unchecked
      removeCookie("admin-2fa"); // Remove the admin-2fa cookie
      removeCookie("adminToken"); // Remove any other relevant cookies
      removeCookie("profile_photo"); // Remove any other relevant cookies
    }
  };

  return (
    <>
      <AuthScreen>
        <>
          <div className="new-main">
            {!token ? (
              <>
                <div className="lg:w-full  new-right flex items-center">
                  <div className="rt-inner w-full relative">
                    <div className="admin-login inner-form-data rounded-lg  bg-white p-10 px-4 lg:px-6 mx-auto ">
                      <form className="mt-4" key={"1234"} onSubmit={LoginNow}>
                        {/* <h2>
                            Welcome to
                            <br />
                            <b>Learn Global</b>
                          </h2> */}
                        <p className="admin_log py-2 px-5">
                          <Link to="">
                            {" "}
                            <img className="w-20 mx-auto" src={User} />
                          </Link>
                        </p>

                        <div className="admin_logo text-center flex justify-center">
                          <Link to="/" className="flex w-fit">
                            {" "}
                            <img
                              className="w-40 lg:w-40 mx-auto"
                              src={Logoblack}
                            />
                          </Link>
                        </div>
                        <div className="part-cont relative uppercase text-center my-6">
                          <p>
                            {/* <span className="admin_log relative py-2 px-10">
                                Login
                              </span> */}
                          </p>
                        </div>
                        <div className="input-content px-0 rounded-lg mb-5">
                          <div className="flex items-center gap-4 w-full relative">

                            <div className="icon_for absolute left-3" style={{ zIndex: 9 }}>
                              <FaEnvelope style={{ fill: '#bdbdbd', width: 17, height: 17 }} />
                            </div>
                            <div className="w-full">
                              <label className="custom-field one w-full">
                                {/* <span className="placeholder">
                                    {" "}
                                    Email ID{" "}
                                  </span> */}
                                <input
                                  className="w-full"
                                  type="text"
                                  name="email"
                                  value={state.email}
                                  style={{ paddingLeft: 34 }}
                                  onChange={handleInput}
                                  placeholder=" Email ID"
                                />
                              </label>
                              <p className="error-text" style={{ color: "red" }}>
                                {errors.email}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="input-content px-0 mt-3 rounded-lg">
                          <div className="flex items-center gap-4 relative">
                            <div className="icon_for absolute left-3" style={{ zIndex: 9 }}>
                              <FaKey style={{ fill: '#bdbdbd', width: 17, height: 17 }} />
                            </div>
                            <div className="w-full">
                              <label className="custom-field one w-full">
                                {/* <span className="placeholder">Password</span> */}
                                <input
                                  name="password"
                                  value={state.password}
                                  onChange={handleInput}
                                  className="w-full"
                                  type={typeConfirm}
                                  style={{ paddingLeft: 34 }}
                                  placeholder="Password"
                                />
                              </label>
                              <p className="error-text" style={{ color: "red" }}>
                                {errors.password}
                              </p>
                              <div className="field_eye_view">
                                <div
                                  className="icon icon-eye"
                                  onClick={handleToggleConfirm}
                                >
                                  <Icon icon={iconConfirm} size={20} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="main_flex_box justify-between mt-5 px-0">
                          <div className="cs_ccck py-0  mb-6 rounded-lg mt-0 md:mb-0">
                            <div className="flex  gap-2">
                              <div className="ml-auto">
                                <div
                                  className="text-black underline cursor-pointer"
                                  onClick={() => {
                                    setForgotPassword(!ForgotPassword);
                                    setShowForgetPop(true);
                                  }}
                                >
                                  Forgot Password?
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cs_bg mx-0 p-2 px-4 md:p-0">
                            <div className="bg-gray-50 mx-0">
                              <div className="flex">
                                <input
                                  type="checkbox"
                                  name="isSave"
                                  checked={state.isSave} // Bind the checked state to your component state
                                  onChange={handleCheckboxChange} // Use the handleCheckboxChange function
                                  id="isSave"
                                />

                                <label
                                  htmlFor="isSave"
                                  className="px-2">
                                  {state.isSave
                                    ? "Saved for this device"
                                    : "Save for this device"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="py-2 sm:py-3 px-0 text-right">
                          <button
                            type="submit"
                            className="log-btn w-full py-3 text-white  rounded-lg"
                          >
                            {state.submitProcessing ? (
                              <center>
                                <div aria-label="Loading..." role="status">
                                  <svg
                                    class="h-5 w-5 animate-spin"
                                    viewBox="3 3 18 18"
                                  >
                                    <path
                                      class="fill-gray-200"
                                      d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                                    ></path>
                                    <path
                                      class="fill-gray-800"
                                      d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                                    ></path>
                                  </svg>
                                </div>
                              </center>
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>

                        {ModalCode ? (
                          <div className="modal_cover">
                            <div className="modal_inner">
                              <div className="header_modal login-part-modal">
                                {/* <GrClose onClick={() => setModalCode(false)} /> */}
                                <h4 className="my-2 text-center font-bold text-xl mb-10">
                                  Please enter the One Time Password
                                  <br /> for verify your account
                                </h4>
                                <div className="form-login">
                                  <form key={"123"} onSubmit={VerifyCode}>
                                    <div className="flex items-center justify-center gap-2 my-2">
                                      {otp.map((_, index) => {
                                        return (
                                          <input
                                            className="p-2 border"
                                            type="text"
                                            name="code"
                                            maxLength="1"
                                            key={index}
                                            onChange={(e) =>
                                              handleChange(e.target, index)
                                            }
                                            onFocus={(e) => e.target.select()}
                                          />
                                        );
                                      })}
                                    </div>
                                    <div className="bg-transparent px-4 py-3 text-right sm:px-6">
                                      <p
                                        onClick={
                                          state.submitProcessing
                                            ? null
                                            : VerifyCode
                                        }
                                      >
                                        {state.submitProcessing
                                          ? "Verifing..."
                                          : "Verify"}
                                      </p>
                                    </div>
                                    <div className="flex items-end justify-end">
                                      <span
                                        className="text-[#475569] hover:text-black cursor-pointer"
                                        onClick={LoginNow}
                                      >
                                        Resend
                                      </span>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* <p className="mt-10 text-center">
                        Don’t have an account ?{" "}
                        <Link to="#" className="forgot-link">
                          Register
                        </Link>
                      </p> */}
                      </form>
                    </div>
                  </div>
                </div>
                {
                  ForgotPassword && (
                    <div className="modal_cover add_college_popup new_ccs ">
                      <div className="modal_inner relative">
                        <div className="header_modal login-part-modal">
                          <GrClose
                            style={{
                              position: "absolute",
                              fontSize: "18px",
                              cursor: "pointer",
                              height: "32px",
                              width: "32px",
                            }}
                            onClick={() => setForgotPassword(false)}
                            className="close"
                          />
                          <h4 className="my-2 text-center font-bold text-xl mb-10">
                            Forgot Your Password
                          </h4>
                        </div>
                        <div className="flex flex-col justify-center ">
                          <div className="">
                            <div className="">
                              <div className="sm:overflow-hidden sm:rounded-md">
                                <div className="space-y-6 bg-white px-4 py-2 sm:p-2">
                                  <div className="">
                                    <div className="col-span-3 sm:col-span-2 relative">
                                      <div className="icon_for mt-5 absolute left-3" style={{ top: 14, left: 7, zIndex: 9 }}>
                                        <FaEnvelope style={{ fill: '#bdbdbd', width: 17, height: 17 }} />
                                      </div>
                                      <label
                                        htmlFor="company-website"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Email
                                      </label>
                                      <div className="mt-1 flex rounded-md shadow-sm">
                                        <input
                                          type="email"
                                          name="email"
                                          value={state.email}
                                          onChange={handleInput}
                                          style={{ paddingLeft: 30 }}
                                          id="company-website2"
                                          className="block w-full flex-1 focus:border-black p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center flex justify-center px-4 py-3 text-right sm:px-6">
                                  <button
                                    type="email"
                                    onClick={SendEmail}
                                    className="bg-gradient-primary inline-flex justify-center mx-auto rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </>
            ) : (
              <div className="lg:flex items-center">
                <div className="new-right flex items-center w-full justify-center items-center">
                  <div className="rt-inner w-full flex justify-center items-center">

                    <div className="inner-form-data rounded-lg bg-white p-10 px-4 mx-auto relative lg:px-6">
                      <Link to="/" className="flex w-fit mx-auto">
                        <img className="w-60 mx-auto" src={Logo} alt="" />
                      </Link>
                      <div className="col-12">
                        <h1 className="text-xl mt-5 mb-3 font-black text-center">
                          Set New Password
                        </h1>
                        <div className="sm:overflow-hidden">
                          <div className="space-y-6 pb-2">
                            <div className="">
                              <div className="col-span-3 sm:col-span-2">
                                <label
                                  htmlFor="company-website"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  New Password
                                </label>
                                <div className="mt-1 flex rounded-md pass-input">
                                  <input
                                    type={showNewPass ? "text" : "password"}
                                    name="newpassword"
                                    value={state.newpassword}
                                    onChange={handleInput}
                                    id="company-website"
                                    className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                    placeholder="New Password"
                                  />
                                  <button
                                    className="eye-btn"
                                    onClick={toggleNewPassword}
                                  >
                                    {showNewPass ? <FiEyeOff /> : <FiEye />}
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="about"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Confirm Password
                              </label>
                              <div className="mt-1 pass-input">
                                <input
                                  type={showConfirmNewPass ? "text" : "password"}
                                  name="confirmpassword"
                                  value={state.confirmpassword}
                                  onChange={handleInput}
                                  className="block w-full flex-1 border-gray-300 focus:border-black border-2 border-gray p-2 w-full focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Confirm Password"
                                />
                                <button
                                  className="eye-btn"
                                  onClick={toggleConfirmPassword}
                                >
                                  {showConfirmNewPass ? <FiEyeOff /> : <FiEye />}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="text-right button mt-5">
                            <button
                              type="button"
                              onClick={ForgotNow}
                              className="bg-gradient-primary inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 sm:py-4 rounded-lg px-4 text-sm font-medium text-white shadow-sm"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                        <div className="text-center pt-2 px-lg-2 px-1 mt-4">
                          <p className="mb-4 text-sm mx-auto flex flex-col">
                            Want to login?
                            <span
                              onClick={() => navigate("/adminlogin")}
                              className="pl-2 text-info text-gradient font-bold cursor-pointer underline"
                            >
                              Login
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </AuthScreen>
    </>
  );
}
