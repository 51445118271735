import React, { useState } from "react";
import WebsiteHome from "../Screens/WebsiteHome";
import College from "../Components/College";
import Rightsideform from "../Components/Rightsideform";
import { useEffect } from "react";
import axios from "axios";

export default function Wdiscover() {
  return (
    <div>
      <div page={"discover"}  className="discover">
        <div className="container mx-auto">
        <div className="searchbar-top pt-10">
        <h3 class="mainheading text-left text-xl font-light border border-current rounded p-2">
          Search Your Favorite College, University with Country
        </h3>
      </div>
          <div className="lg:flex discover-cont pt-10 pb-10 gap-2 px-4 lg:px-0">
            <div className="lg:w-full coverleft">
              <College />
            </div>
            {/* <div className="lg:w-1/3">
              <Rightsideform />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
