import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import loader from "../../../images/loader.gif";
import { useParams } from "react-router-dom";
import { RiGraduationCapFill } from "react-icons/ri";
import { FaMoneyBillAlt } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Wviewdetails() {
  const { id } = useParams();
  const [CollegeData, setCollegeData] = useState();
  const [ProgramData, setProgramData] = useState([]);
  const [wait, setWait] = useState(true);
  const [displayCount, setDisplayCount] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);

  const containerRef = useRef(null);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_NODE_URL + `/v2/api/specificcollege/${id}`)
      .then((response) => {
        console.log(response.data.details.details.college_programs);
        setCollegeData(response.data.details.details.collegeDetails[0]);
        setProgramData(response.data.details.details.college_programs);
        setWait(false);
      });
  }, [id]);

  const loadMore = () => {
    if (displayCount < ProgramData.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setDisplayCount(displayCount + 10);
        setLoadingMore(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadMore();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [displayCount, ProgramData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function mapTypes(values) {
    if (Array.isArray(values) && values.length > 0) {
      values = values[0];
    }

    return values
      .split(",")
      .map((value) => {
        switch (value) {
          case "1":
            return "Public";
          case "2":
            return "Private";
          case "3":
            return "Institute";
          case "4":
            return "University";
          case "5":
            return "College";
          case "6":
            return "School";
          default:
            return value;
        }
      })
      .join(" ");
  }
  const uniqueFoundedYears = [...new Set(CollegeData?.founded)];
  if (wait) {
    return (
      <>
        <div className="pt-[150px]">
          <center className="w-full my-10">
            <img src={loader} alt="" />
          </center>
        </div>
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      <div>
        <div id="view-details">
          <div className="view-banr py-32">
            <div className="container mx-auto px-4">
              <div className="lg:flex gap-8">
                <div className="lg:w-3/5 mb-8 lg:mb-0">
                  <h1 className="text-left text-4xl text-white capitalize mb-0">
                    {CollegeData?.college_name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="info-grp">
              <div className="container mx-auto">
                <div className="md:flex gap-8 items-center inner-main p-3 border-b">
                  <div className="inn-main flex gap-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 mt-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-xl">
                        <b>Founded</b>
                        <br />
                        {uniqueFoundedYears}
                      </p>
                    </div>
                  </div>

                  <div className="inn-main flex gap-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 mt-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-xl">
                        <b>Type</b>
                        <br />
                        {mapTypes(CollegeData?.type)}
                      </p>
                    </div>
                  </div>

                  <div className="inn-main flex gap-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 mt-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-xl">
                        <b>Total Students</b>
                        <br />
                        {CollegeData?.total_student}
                      </p>
                    </div>
                  </div>

                  <div className="inn-main flex gap-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 mt-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-xl">
                        <b>Int. Students</b>
                        <br />
                        {CollegeData?.international_student}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div>
            <div className="container mx-auto my-10">
              <div
                className="flex flex-wrap gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8"
                ref={containerRef}
              >
                {ProgramData.slice(0, displayCount).map((program, index) => (
                  <div
                    key={index}
                    className="p-6 border rounded-lg shadow-md md:flex md:flex-wrap md:justify-between items-center w-full"
                  >
                    <div className="md:w-full">
                      <p className="text-lg font-semibold mb-2">
                        {program.program_name}
                      </p>
                    </div>
                    <div className="md:w-9/12 lg:flex lg:flex-wrap lg:justify-between items-start">
                      <div class="flex justify-between items-center mb-2 lg:w-[48%]">
                        <div className="flex items-center">
                          <RiGraduationCapFill
                            className="me-2"
                            style={{ width: 16, height: 16, color: "#7b7b7b" }}
                          />{" "}
                          <p>Program Level</p>
                        </div>
                        <span className="text-green">
                          {program.program_level}
                        </span>
                      </div>

                      {program.min_tution_fee_per_semester !== 0 ? (
                        <div class="flex justify-between items-center mb-2 lg:w-[48%]">
                          <p>Tuition Fee(USD)</p>
                          <span className="text-green">
                            {program.min_tution_fee_per_semester} -
                            {program.max_tution_fee}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {program.application_fee !== 0 ? (
                        <div class="flex justify-between items-center mb-2 lg:w-[48%]">
                          <div className="flex items-center">
                            <FaMoneyBillAlt
                              className="me-2"
                              style={{
                                width: 16,
                                height: 16,
                                color: "#7b7b7b",
                              }}
                            />{" "}
                            <p>Application Fee</p>
                          </div>
                          <span className="text-green">
                            {program.application_fee} {program.currency}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {program.cost_of_living !== 0 ? (
                        <div class="flex justify-between items-center mb-2 lg:w-[48%]">
                          <p>Living Cost</p>
                          <span className="text-green">
                            {program.cost_of_living} {program.currency}/year
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div class="flex flex-col justify-start items-start mb-2 lg:w-[48%]">
                        <div className="flex items-center mb-3">
                          <SlCalender
                            className="me-2"
                            style={{ width: 15, height: 15, color: "#7b7b7b" }}
                          />{" "}
                          <p>Program Intake:</p>
                        </div>
                        <span className="text-green">
                          {program?.intakes_data <= 0 ?
                            <p className="text-red-500">Unfortunately, this program is not currently accepting any new enrollments or applications.</p>
                            :
                            program.intakes_data.map((intake, i) => {
                              const intakeMonths = intake.months
                                .map((month, index) => {
                                  const monthName = months[index];
                                  const currentYear = new Date().getFullYear();
                                  const intakeYear = intake.year;
                                  const currentMonth = new Date().getMonth();
                                  const isClosed = program?.intakes_closed_data && program?.intakes_closed_data[i]?.months[index];
                                  const isWaiting = program?.intakes_waiting_data && program?.intakes_waiting_data[i]?.months[index];
                                  if (month && isWaiting) {
                                    return (
                                      <div className="flex items-center" key={index} title="In Waiting">
                                        <span className="w-3 h-3 rounded-full mr-1 bg-blue-500"></span>
                                        <p className="intakes mr-3 text-blue-500">{monthName}</p>
                                      </div>
                                    );
                                  }
                                  else if (month && intakeYear < currentYear) {
                                    return (
                                      <div className="flex items-center" key={index} title="Closed">
                                        <span className="w-3 h-3 rounded-full mr-1 bg-red-500"></span>
                                        <p className="intakes mr-3 text-red-500">{monthName}</p>
                                      </div>
                                    );
                                  } else if (month && intakeYear === currentYear && index <= currentMonth) {
                                    return (
                                      <div className="flex items-center" key={index} title="Closed">
                                        <span className="w-3 h-3 rounded-full mr-1 bg-red-500"></span>
                                        <p className="intakes mr-3 text-red-500">{monthName}</p>
                                      </div>
                                    );
                                  } else if (month && isClosed) {
                                    return (
                                      <div className="flex items-center" key={index} title="Closed">
                                        <span className="w-3 h-3 rounded-full mr-1 bg-red-500"></span>
                                        <p className="intakes mr-3 text-red-500">{monthName}</p>
                                      </div>
                                    );
                                  }
                                  else if (month) {
                                    return (
                                      <div className="flex items-center" key={index} title="Opened">
                                        <span className="w-3 h-3 rounded-full mr-1 bg-green-500"></span>
                                        <p className="intakes mr-3">{monthName}</p>
                                      </div>
                                    );
                                  }
                                  return null;
                                })
                                .filter((month) => month !== null);

                              return (
                                <div className="flex items-center" key={i}>
                                  <p className="mr-3">{intake.year} - </p>
                                  {intakeMonths}
                                </div>
                              );
                            })
                          }
                        </span>
                      </div>
                      {program.duration !== 0 ? (
                        <div class="flex justify-between items-center mb-2 lg:w-[48%]">
                          <p>Program Length</p>
                          <span className="text-green">
                            {program.duration} years
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex flex-col mt-8 md:mt-0 h-fit md:w-[20%]">
                      <button onClick={() => toast.info('Sorry, this feature is currently under construction. Coming soon!')}
                        className="custon-el-btn bg-[#181739] text-white">
                        Check Eligibility
                      </button>
                      <div class="flex justify-end items-center mt-3">
                        {program.program_url ? (
                          <a
                            href={program.program_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-[50px] w-full flex justify-center"
                          >
                            Program
                          </a>
                        ) : (
                          <span className="text-red-500 w-full flex justify-center">
                            No Program Url
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {loadingMore && (
                <center className="my-4">
                  <img src={loader} alt="Loading more..." />
                </center>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}